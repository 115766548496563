<a class="medium-menu-text back" (click)="back()">
    Go Back
</a>
<!-- <div class="smart-set-intro">
    <div class="title">
        Smart Sets
    </div>
    <div class="smart-set-content">
        <ul class="list">
            <li class="smart-set-content-item">Save the search results with Smart Sets</li>
            <li class="smart-set-content-item">Drag and drop the fetched results to create a Smart Set</li>
            <li class="smart-set-content-item">The smart set will always stay in sync with its external source</li>
            <li class="smart-set-content-item">Rename the created smart set through the panel or on the canvas</li>
        </ul>
    </div>
</div> -->

<div class="pointer" draggable="true" (dragstart)="handleSmartSetDragStart( $event )">
    <div class="search-result-menu">
        <div class="moveble-image">
            <svg class="nu-icon nu-ic-movable">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-movable"></use>
            </svg>
        </div>
        <div class="medium-menu-text no-left-margin">
            Search Result ( {{resultCount}} )
        </div>
        <div class="escalation-image" floatingContainer placement="right">
            <svg class="nu-icon nu-ic-exclamation">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-exclamation"></use>
            </svg>
            <span class="floating-content">
                <div class="image-cover">
                    <img class="smart-into-image" src="https://static.vecteezy.com/system/resources/thumbnails/008/174/698/original/animation-loading-circle-icon-loading-gif-loading-screen-gif-loading-spinner-gif-loading-animation-loading-on-black-background-free-video.jpg" alt="smartset">
                </div>
                <div class="smart-set-intro">
                    <div class="title">
                        Smart Sets
                    </div>
                    <div class="smart-set-content">
                        <ul class="list">
                            <li class="smart-set-content-item">Save the search results with Smart Sets</li>
                            <li class="smart-set-content-item">Drag and drop the fetched results to create a Smart Set
                            </li>
                            <li class="smart-set-content-item">The smart set will always stay in sync with its external
                                source</li>
                            <li class="smart-set-content-item">Rename the created smart set through the panel or on the
                                canvas</li>
                        </ul>
                    </div>
                </div>
            </span>
        </div>
    </div>
    <div class="result-contianer">
        <div #searchResultContainer></div>
    </div>
</div>