import { Injectable } from '@angular/core';
import { Proxied, Sakota } from '@creately/sakota';
import { CommandScenario, IModifier, ISavedModelChange, IUnsavedModelChange, ModifierUtils, StateService } from 'flux-core';
import { DataSync } from 'flux-store';
import { concat, EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DiagramLocatorLocator } from './locator/diagram-locator-locator';
import { DiagramModel } from './model/diagram.mdl';

/**
 * This service can be used to validate, insert and apply changes.
 */
@Injectable()
export class DiagramChangeService {
    private changeModels: { [eventId: string]: Proxied<DiagramModel> } = {};

    /**
     * This property holds the preview stream subscription
     */
    private previewStreamSubscription: { [streamId: string ]: Subscription } = {};

    /**
     * This property holds the last changes of the preview streams
     */
    private latestPreviewStreamChange: { [streamId: string ]: IModifier } = {};

    /**
     * This property hold the last preview change from current client
     */
    private lastPreviewChange: IModifier = {};

    /**
     * This property holds the merged preview changes from the stream through realtime.
     */
    private previewChanges: Subject<IModifier>;

    /**
     * Inject stuff! the Java of JavaScript!
     */
    constructor(
        private ss: StateService<any, any>,
        private ll: DiagramLocatorLocator,
        private ds: DataSync,
    ) {
        this.previewChanges = new Subject();
    }

    /**
     * Returns a change model for the given event id.
     */
    public getChangeModel(
        resourceId: string,
        eventId: string,
        scenario: CommandScenario,
        change: { modifier: IModifier; reverter: IModifier } = null,
    ): Observable<Proxied<DiagramModel>> {
        const cachedModel = this.changeModels[eventId];
        if ( cachedModel ) {
            return of( cachedModel );
        }
        const isPreview = scenario === CommandScenario.PREVIEW;
        const locator = resourceId
            ? this.ll.forDiagram( resourceId, isPreview )
            : this.ll.forCurrent( isPreview );
        return locator.getDiagramOnce().pipe(
            map( model => {
                try {
                    let proxied = Sakota.create( model );
                    if ( change ) {
                        const { modifier, reverter } = change;
                        proxied.__sakota__.mergeChanges( reverter );
                        proxied = Sakota.create( proxied );
                        proxied.__sakota__.mergeChanges( modifier );
                    }
                    this.changeModels[eventId] = proxied;
                } catch {
                    this.changeModels[eventId] = Sakota.create( model );
                }
                return this.changeModels[eventId];
            }),
        );
    }

    /**
     * This is used in restoring a snapshot, which is a specific scenario
     * where we want the raw diagram model.
     */
    public getRawChangeModel(
        resourceId: string,
        eventId: string,
    ): Observable<Proxied<DiagramModel>> {
        const cachedModel = this.changeModels[eventId];
        if ( cachedModel ) {
            return of( cachedModel );
        }
        const locator: any = resourceId
            ? this.ll.forDiagram( resourceId, false )
            : this.ll.forCurrent( false );
        return locator.getRawDiagramDataOnce().pipe(
            map(( model: Proxied<DiagramModel> ) => { // here the model is actually not a DiagramModel
                this.changeModels[eventId] = model;
                return model;
            }),
        );
    }

    /**
     * Get currently recorded changes from the change model.
     */
    public flushChanges( eventId: string ): IModifier {
        const cachedModel = this.changeModels[eventId];
        if ( !cachedModel ) {
            return null;
        }
        delete cachedModel.interactionData;
        // removing the cached model from the map so that it won't grow gradually.
        delete this.changeModels[eventId];
        const modifier = cachedModel.__sakota__.getChanges();
        if ( ModifierUtils.isEmptyModifier( modifier )) {
            return null;
        }
        return modifier;
    }

    /**
     * This method applies the change as a preview change.
     */
    public addPreviewChange( modifier: IModifier ): Observable<unknown> {
        this.ss.set( 'PreviewChanges', modifier );
        this.previewChanges.next( modifier );
        return EMPTY;
    }

    /**
     * Listen to preview resets and update preview chagnes to other clients
     */
    // TODO: This section has some issues related to the Preview reset.
    // Does not recieve proper changes at the time.
    // Needs to be fixed - MN
    /*private listenToPreviewReset() {

        this.ss.changes( 'PreviewChanges' ).pipe(
            tap( modifier => {
                if( modifier !== RESET_PREVIEW ) {
                    this.lastPreviewChange = modifier;
                } else if ( modifier === RESET_PREVIEW ) {
                    this.previewChanges.next( this.lastPreviewChange );
                }
            }),
        ).subscribe();
    }*/

    /**
     * This method adds a preview stream
     * the stream will get merged to all the existing preview changes
     * @param id stream id
     * @param changes preview changes for the stream
     */
    public addPreviewStream( id: string, changes: Observable<IModifier> ) {
        const sub = changes.subscribe( change => {
            // Set Realtime Preview changes
            this.ss.set( 'RealtimePreviewChanges', change );
            this.latestPreviewStreamChange[ id ] = change;
        });
        this.previewStreamSubscription[ id ] = sub;
    }

    /**
     * This method removes a stream from preview stream
     * @param id streamId
     */
    public removePreviewStream( id: string ) {
        const subscription = this.previewStreamSubscription[ id ];
        if ( subscription ) {
            subscription.unsubscribe();
            delete this.latestPreviewStreamChange[ id ];
            delete this.previewStreamSubscription[ id ];
        }
    }

    /**
     * Retrieves the preview changes, Merged streams changes as a whole
     */
    public getPreviewChanges(): Observable<IModifier> {
        const lastPreviews = Object.values( this.latestPreviewStreamChange ).map( change => of( change ));
        lastPreviews.push( of( this.lastPreviewChange ));
        return concat( ...lastPreviews, this.previewChanges );
    }

    /**
     * This method applies the change as an unsaved change.
     */
    public addUnsavedChange( change: IUnsavedModelChange ): Observable<unknown> {
        // console.log( 'DiagramChangeService.addUnsavedChange', change );
        return this.ds.storeChanges( DiagramModel, [ change ]);
    }

    /**
     * This method applies the change as a saved change.
     */
    public addSavedChange( change: ISavedModelChange ): Observable<unknown> {
        // console.log( 'DiagramChangeService.addSavedChange', change );
        return this.ds.applyChanges( DiagramModel, [ change ]);
    }

    /**
     * This method remove the changes from the collection.
     */
    public removeChanges( changeIds: string[]): Observable<unknown> {
        return this.ds.removeChanges( DiagramModel, changeIds );
    }
}
