import { Injectable } from '@angular/core';
import { AbstractModelSubscription, ModelSubscriptionManager, SubscriptionStatus } from 'flux-subscription';
import { Observable, combineLatest, of } from 'rxjs';
import { DiagramSub } from '../subscription/diagram.sub';
import { Router } from '@angular/router';
import { Command } from 'flux-core';
import { AbstractInitializationCommand } from 'flux-core';
import { switchMap, filter, take, tap } from 'rxjs/operators';
import { DataStore } from 'flux-store';
import { DiagramModel } from '../model/diagram.mdl';
import { ProjectSub } from 'flux-diagram';

/**
 * Command for starting diagram subscription.
 * Starts user info subscriptions for each collaborator of the diagram.
 *
 * @author  Rasekaran
 * @since   2023-07-20
 */
@Injectable()
@Command()
export class StartDiagramAndProjectSubscription extends AbstractInitializationCommand {

    constructor( protected modelSubManager: ModelSubscriptionManager,
                 protected router: Router,
                 protected dataStore: DataStore ) {
        super( router )/* istanbul ignore next */;
    }

    public execute(): Observable<any> {
        this.resultData = this.data;
        return this.startSubscription( DiagramSub, this.data.status.input.resourceId ).pipe(
            tap( statusSubject => {
                if ( statusSubject.subStatus === SubscriptionStatus.errored ) {
                    this.navigateToRoute( this.data.status );
                    this.log.error( 'There was an error starting the diagram subscription : ' + statusSubject.error );
                }
            }),
            switchMap( statusSubject =>
                combineLatest([
                    this.startProjectSubscription( this.data.status.input.resourceId ),
                    of( statusSubject ),
                ])),
            tap(([ projSub, statusSubject ]) => {
                this.navigateToRoute( this.data.status );
                if ( statusSubject.subStatus === SubscriptionStatus.errored ) {
                    this.log.error( 'There was an error starting the diagram subscription : ' + statusSubject.error );
                }
            }),
        );
    }

    /**
     * Start the subscription for given type of given resource id.
     * @param subType
     * @param resourceId
     * @returns
     */
    protected startSubscription( subType: typeof AbstractModelSubscription, resourceId: string ) {
        return this.modelSubManager.start( subType, resourceId ).pipe(
            switchMap( sub => sub.status ),
            filter( statusSubject => statusSubject.subStatus !== SubscriptionStatus.created ),
            take( 1 ),
        );
    }

    /**
     * Start project subscription of given diagramId.Before starting the project subscription
     * diagram should be available in local( that means diagram subscription need to start and
     * respond before it's project subacription ).
     * @param diagramId
     * @returns
     */
    protected startProjectSubscription( diagramId: string ) {
        return this.dataStore.findOneRaw( DiagramModel, { id: diagramId }).pipe(
            take( 1 ),
            filter( diagram => diagram && diagram.project ),
            switchMap( diagram => {
                if ( diagram.project === 'home' ) {
                    return of({});
                }
                this.resultData = {
                    ...this.resultData,
                    projectId: diagram.project,
                };
                return this.startSubscription( ProjectSub, diagram.project );
            }),
        );
    }
}

Object.defineProperty( StartDiagramAndProjectSubscription, 'name', {
    value: 'StartDiagramAndProjectSubscription',
});
