import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';
import { DiagramChangeService } from 'apps/nucleus/src/base/diagram/diagram-change.svc';
import { ConnectorModel } from 'apps/nucleus/src/base/shape/model/connector.mdl';

/**
 * CollapseExapndBranch
 * Changes the draw style of one or more connectors by changing the entry class.
 */
@Injectable()
@Command()
export class CollapseExapndBranch extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     * The entryClass is the full class identifier as used in shape definitions.
     */
    public data: {
        [ shapeId: string ]: { gpIds: string[] },
    };

    public constructor( protected state: StateService<any, any>, dcs: DiagramChangeService ) {
        super( dcs );
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData() {
        if ( this.data ) {
            Object.keys( this.data ).forEach( shapeId => {
                const shapeModel = this.changeModel.shapes[shapeId] as ShapeModel;
                if ( shapeModel ) {
                    Object.values( shapeModel.gluepoints ).forEach( gpModel => {
                        if ( this.data[shapeId].gpIds && !this.data[shapeId].gpIds.includes( gpModel.id )) {
                            return;
                        }
                        gpModel.connectionState = gpModel.connectionState === 'collapsed' ? 'expanded' : 'collapsed';
                        const visible = gpModel.connectionState === 'expanded' ? true : false;
                        const { shapeIds, connectorIds } = this.changeModel
                            .getAllShapesStartedFromGp( shapeModel.id, gpModel.id );

                        // find all from gps
                        const collapsed = new Set();
                        connectorIds.forEach( cId => {
                            if ( collapsed.has( cId )) {
                                return;
                            }
                            const connectorModel = this.changeModel.shapes[ cId ] as ConnectorModel;
                            const fromEp =  connectorModel.getFromEndpoint( this.changeModel );
                            if ( fromEp.gluepoint?.connectionState === 'collapsed' ) {
                                if ( fromEp.shape ) {
                                    const v = this.changeModel
                                        .getAllShapesStartedFromGp( fromEp.shape.id, fromEp.gluepoint.id );
                                    v.shapeIds.forEach( id => collapsed.add( id ));
                                    v.connectorIds.forEach( id => collapsed.add( id ));
                                }
                            }
                        });

                        [ ...shapeIds, ...connectorIds ].forEach( id => {
                            const s = this.changeModel.shapes[id];
                            if ( s ) {
                                if ( visible ) { //  we show only expanded gp shapes
                                    if ( !collapsed.has( s.id )) {
                                        s.visible = true;
                                    }
                                } else {
                                    s.visible = false;
                                }
                            }
                        });
                    });
                }
            });
        }
    }
}

// NOTE: class names are lost on minification
Object.defineProperty( CollapseExapndBranch, 'name', {
    value: 'CollapseExapndBranch',
});
