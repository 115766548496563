import { ShapeBoundsLocator } from '../../../editor/diagram/containers/shape-bounds-locator';
import { DataType, IAttachment, IDataItem, IShapeDefinition, IShapePort, ITextStyles, ShapePortSlot, ShapeType,
    SystemType,
    TEXT_PADDING_HORIZONTAL } from 'flux-definition';
import { ShapeLinkModel, ShapeLinkType, RuleModel } from 'flux-diagram';
import { AbstractShapeModel, DiagramDataModel, LOOKUP_CONNECTOR, TextFormatter, TextPostion } from 'flux-diagram-composer';
import { cloneDeep, find, findIndex, flatten, isEmpty, isEqual, maxBy, minBy, sortBy, uniq, values } from 'lodash';
import { IContextMenuAware } from '../../../framework/interaction/context-menu/context-menu-aware.i';
import { INewConnection } from '../../shape/connection/connection.i';
import { Connection } from '../../shape/connection/connection.mdl';
import { ConnectorRegistry } from '../../shape/definition/connector-registry.svc';
import { ShapeModel } from '../../shape/model/shape.mdl';
import { ISidebarContextAware, ISidebarPanelData } from '../../../framework/ui/side-bar/sidebar-framework';
import { ConnectorTextModel } from '../../shape/model/text/connector-text.mdl';
import { ShapeTextModel } from '../../shape/model/text/shape-text.mdl';
import { enumerable, Logger, MapOf, Random, Rectangle } from 'flux-core';
import { ConnectorModel } from '../../shape/model/connector.mdl';
import { DataItemLevel } from '../../edata/model/edata.mdl';
import { EDataRegistry } from '../../edata/edata-registry.svc';
import { DiagramFactory } from '../diagram-factory';
import { CoreDataFieldService } from '../../data-defs';
import { IShapeVoting } from 'apps/nucleus/src/editor/feature/shape-voting.svc';

// tslint:disable:max-file-line-count
/**
 * This is the concrete version of a diagram model with full
 * diagram data and capabilities. This extends DiagramDataModel
 * which contains all data that composes diagram. This model is
 * what composes all data manipulation and processing capabilities needed
 * by Nucleus and similar apps for performing all and any operation
 * on diagram data.
 *
 * @author hiraash
 * @since 2017-09-11
 */
export class DiagramModel extends DiagramDataModel implements ISidebarContextAware, IContextMenuAware {

    /**
     * The list of shapes in the diagram.
     */
    public shapes: { [shapeId: string]: AbstractShapeModel } = {};

    public shapeVotings: { [shapeVotingId: string]: IShapeVoting };

    /**
     * The map of connections in the diagram.
     *
     * FIXME Investigate CJ 18 Jan 2021. Something is broken here.
     * Behaviour: when you create a connector between two shapes, this is empty.
     * when you disconnect it from 1 of the shapes, this is populated with old data of the
     * previously attached shape.
     *
     */
    public connections: { [connectionId: string]: Connection } = {};

    /**
     * The map of image ids asscociated with the diagram
     * These images are supposed to be shown in the image import panel
     * The added property is the timestamp when the image was added and
     * it's usefull when sorting the gallery.
     * @deprecated use attachments, this is needed for old diagrams
     */
    public imageGallery: { [imageId: string]: { added: number }} = {};

    /**
     * This property is to hold the attachmets added to the document via
     * the description text area in the shape data editor,
     * attachments property is supposed to hold the images imported into the canvas as awell
     */
    public attachments: { [id: string]: IAttachment } = {};

    /**
     * This property is to hold the interaction data
     * requied for some calculations done in the shape logic class
     * e.g. We need to know the which type of handle is used to
     * resize the table shape. since corner handles should rezise the cells
     * proportionally and side handles should resize the cells in the same row/column only.
     *
     * This proprty is not a part of the diagram data and is not saved in the diagram model
     * so this property is deleted after binder are run. see DocumentChange command
     */
    public interactionData: any;

    /**
     * Formatting rules that's available on this diagram
     */
    public rules: { [id: string]: RuleModel } = {};

    /**
     * Record the template IDs in the diagram modal
     * This is used for analytics.
     */
    public templates: Array<{ id: string, context: string  }>;

    /**
     * lazy load data for non smart sets.
     */
    public displayLists: { [id: string]: {
        eDataId: string;
        eDefId: string;
        searchQuery: string;
        containerId: string;
        entities: string[];
        added: string[];
    }} = {};

    /**
     * Diagram level show and hide collaborators cursors
     * if hideCursors is false - showEditorsCursors and showModeratorCursors are ignored
     */
    public collaboratorsCursors: {
        hideCursors: boolean,
        showEditorsCursors: boolean,
        showModeratorCursors: boolean,
    };


    protected DESCRIPTION_DATAITEM_ID = 'description';

    /**
     * The maximum width for the text editor
     */
    protected maxTextWidth = 1000;

    /**
     * Returns the highest zIndex value of all shapes added to the diagram.
     * @return highest shape zIndex. If diagram has no shapes, this will
     *         return 0.
     */
    @enumerable( true )
    public get maxZIndex (): number {
        if ( this.hasShapes ) {
            return Math.max( ...values( this.shapes ).map( shape => shape.zIndex ));
        }
        return 0;
    }

    /**
     * Returns the lowest zIndex value of all shapes added to the diagram.
     * @return lowest shape zIndex. If diagram has no shapes, this will
     *         return 0.
     */
    @enumerable( true )
    public get minZIndex(): number {
        if ( this.hasShapes ) {
            return Math.min( ...values( this.shapes ).map( shape => shape.zIndex ));
        }
        return 0;
    }

    /**
     * This function return the common text styles for the given set of shapes
     */
    public getCommonTextStyles( shapeIds: string[], innerSel?: any ): ITextStyles {
        const formatter = new TextFormatter();
        const styles2dArray = values( this.shapes )
            .filter(( shape: AbstractShapeModel )  => shapeIds.includes( shape.id ))
            .map(( shape: AbstractShapeModel ) => {
                let stylesArray = values( shape.texts ).map( text =>
                    formatter.extractCommon( text.rendering === 'carota' ? cloneDeep( text.content ) : text.html ),
                );
                // When selected shape has inner selection, get styles for those corresponding texts
                // only, not shape as a whole
                if ( innerSel && Object.keys( innerSel ).includes( shape.id ) && innerSel[shape.id].length > 0 ) {
                    const texts = Object.keys( shape.texts )
                        .filter( txtId => innerSel[shapeIds[0]].includes( txtId ))
                        .map( txtId => shape.texts[ txtId ]);
                    stylesArray = values( texts ).map( text =>
                        formatter.extractCommon( text.rendering === 'carota' ? cloneDeep( text.content ) : text.html ));
                }
                return formatter.getCommonStyles( stylesArray );
        });
        return formatter.getCommonStyles( flatten( styles2dArray ));
    }

    /**
     * Returns an array of active connections related to given shape.
     */
    public getConnectionsForShape( shapeId: string ): Connection[] {
        const connections: Connection[] = [];
        // tslint:disable-next-line:forin
        for ( const id in this.connections ) {
            const conn = this.connections[id];
            if ( conn.shapeA.shapeId === shapeId || conn.shapeB.shapeId === shapeId ) {
                connections.push( conn );
            }
        }
        return connections;
    }

    /**
     * Returns an array of active connections between given shapes.
     */
    public getConnectionsForShapes( shapeAId: string, shapeBId: string ): Connection[] {
        const connections: Connection[] = [];
        // tslint:disable-next-line:forin
        for ( const id in this.connections ) {
            const conn = this.connections[id];
            if (
                ( conn.shapeA.shapeId === shapeAId && conn.shapeB.shapeId === shapeBId ) ||
                ( conn.shapeA.shapeId === shapeBId && conn.shapeB.shapeId === shapeAId )
            ) {
                connections.push( conn );
            }
        }
        return connections;
    }

    /**
     * Returns an array of shape ports for given shape id considering limits.
     */
    public getAvailableShapePorts( shapeId: string, slot?: ShapePortSlot ): IShapePort[] {
        const shape = this.shapes[shapeId] as ShapeModel;
        if ( !shape.ports ) {
            return [];
        }
        const current = this.getConnectionsForShape( shapeId );
        return shape.ports.filter( port => {
            if ( port.limit ) {
                const portConnections = current.filter( conn => (
                    ( conn.shapeA.shapeId === shapeId && conn.shapeA.portId === port.id ) ||
                    ( conn.shapeB.shapeId === shapeId && conn.shapeB.portId === port.id )
                ));
                if ( portConnections.length >= port.limit ) {
                    return false;
                }
            }
            if ( slot && port.limitSlot ) {
                if ( port.limitSlot !== slot ) {
                    return false;
                }
            }
            return true;
        });
    }

    /**
     * Returns an array of conenctions which can be created between given shapes.
     * This will consider any limits and also whether shape ports are compatible.
     */
    public getPotentialConnections( shapeAId: string, shapeBId: string ): INewConnection[] {
        if ( !this.shapes[shapeAId] || !this.shapes[shapeBId]) {
            return [];
        }
        const portsA = this.getAvailableShapePorts( shapeAId, 'shapeA' );
        const portsB = this.getAvailableShapePorts( shapeBId, 'shapeB' );
        const results: INewConnection[] = [];
        for ( const portA of portsA ) {
            for ( const portB of portsB ) {
                const conn = this.getConnectionForPorts( shapeAId, shapeBId, portA, portB );
                if ( !conn ) {
                    continue;
                }
                results.push( conn );
            }
        }
        return results;
    }

    /**
     * Returns an INewConnection if one can be created between given shapes and ports.
     */
    public getConnectionForPorts(
        shapeAId: string,
        shapeBId: string,
        portA: IShapePort,
        portB: IShapePort,
    ): INewConnection {
        if ( portA.type !== portB.type ) {
            return null;
        }
        if ( portA.type === 'connector' ) {
            const definitions = ConnectorRegistry.instance.defsForPorts( portA, portB );
            if ( !definitions.length ) {
                return null;
            }
            return {
                connection: {
                    type: 'connector',
                    shapeA: { shapeId: shapeAId, portId: portA.id },
                    shapeB: { shapeId: shapeBId, portId: portB.id },
                },
                definitions: definitions,
            };
        }
        return null;
    }

    /**
     * This function Implements ISidebarContextAware method
     * and defines all the relevant side bar panels for the Diagram Model
     * @returns string[] The list of panel ids
     */
    public getSidebarPanels(): ISidebarPanelData[] {
        return [
            { id: 'diagraminfo', features: []},
        ];
    }

    /**
     * This defines all the context menu items for a diagram
     */
    public getContextMenuItems(): Array<string> {
        return [ 'pasteShapes', 'selectAll', 'showGrid', 'snapToGrid', 'showGuides', 'snapToGuides',
            'addFrame', 'addTemplate' ];
    }

    /**
     * Returns a list of shapes that overlap the given shape.
     * Shapes can either be on top of the shape or beneath it.
     * @param shapeId - the shape to check for overlaps
     * @return list of models of overlapping shapes, sorted by ascending
     * order of zIndex. Note that the original shape which is checked for
     * overlaps is also included in this array.
     * FIXME: Right now the connector overlap is detected using rectangular
     * bounds intersection. For this to be more precise, line to shape
     * intersection needs to be checked.
     */
    public getOverlappingShapes( shapeId: string ): AbstractShapeModel[] {
        let shapesList = [];
        if ( this.hasShapes ) {
            const shapeToCheck = this.shapes[shapeId];
            shapesList = values( this.shapes )
                .filter( shape => {
                    const intersection = shapeToCheck.bounds.intersection( shape.bounds );
                    return( intersection && intersection.width > 0 && intersection.height > 0 );
                });
            shapesList = sortBy( shapesList, shape => shape.zIndex );
        }
        return shapesList;
    }

    /**
     * Returns the index to add a given shape to the diagram.
     * By default, a new shape is added as the topmost shape in the diagram.
     * However a shapes definition may contain rules that determine its position
     * in relation to other shapes in the diagram. This method determines the index
     * to add the shape considering all such information.
     * @param modelOrDef - A shape model or a shape definition
     * @return index to add the shape at
     */
    public getIndexToAdd( modelOrDef: IShapeDefinition ): number {
        if ( !this.hasShapes ) {
            return 0;
        }
        if (( modelOrDef as any ).isSendToBack || ( modelOrDef as any ).isContainer ) {
            const minIdx = Math.min( ...( values( this.shapes )).map( shape => shape.zIndex ));
            return this.getIndexBefore( minIdx );
        }
        const sendToBack = ( modelOrDef as any ).sendToBack;
        if ( sendToBack && sendToBack.length > 0 ) {
            const matchingShapes = ( values ( this.shapes )).filter( shape =>
                    !!find ( sendToBack, { defId: shape.defId, version: shape.version }));
            if ( matchingShapes.length > 0 ) {
                const minIdx = Math.min( ...matchingShapes.map( shape => shape.zIndex ));
                // TODO: Use getIndexesToSendBackwards function and remove getIndexToAddBefore.
                return this.getIndexBefore( minIdx );
            }
        }
        return this.maxZIndex + 1;
    }

    /**
     * Returns the shape that comes before a given shape in a diagram.
     * This decision is made by comparing the shapes zIndex properties.
     * If there are no shapes before the given shape( the given shape is
     * the only shape in the diagram or it's at the very bottom ), null
     * is returned
     * @param shapeId - id of the shape for which the shape before needs
     * to be found
     * @return - model of the shape that comes before the given shape when
     * available. null if not.
     */
    public getShapeAtBack( shapeId: string ): AbstractShapeModel {
        const shape = this.shapes[shapeId];
        if ( !shape || shape.zIndex <= this.minZIndex ) {
            return null;
        }
        const shapesList = this.getShapesOrderedByIndex();
        const shapePos = findIndex( shapesList, s => s.zIndex === shape.zIndex );
        return shapesList[ shapePos - 1 ];
    }

    /**
     * Returns the shape that comes after a given shape in a diagram.
     * This decision is made by comparing the shapes zIndex properties.
     * If there are no shapes after the given shape( the given shape is
     * the only shape in the diagram or it's at the very top ), null
     * is returned
     * @param shapeId - id of the shape for which the shape after needs
     * to be found
     * @return - model of the shape that comes after the given shape when
     * available. null if not.
     */
    public getShapeAtFront( shapeId: string ): AbstractShapeModel {
        const shape = this.shapes[shapeId];
        if ( !shape || shape.zIndex >= this.maxZIndex ) {
            return null;
        }
        const shapesList = this.getShapesOrderedByIndex();
        const shapePos = findIndex( shapesList, s => s.zIndex === shape.zIndex );
        return shapesList[ shapePos + 1 ];
    }

    /**
     * When a given set of shapes are to be sent backward of another shape,
     * this function calculates the indexes the shapes being moved need
     * to have in order for the send backwards operation to take effect.
     * @param sendBackwardsOf - shape id of the shape the shapes are being sent backwars of
     * @param movedShapes - list of ids of shapes that are being sent backwards
     * @return array with caluclated zIndexes for each shape being moved
     */
    public getIndexesToSendBackwards(
        sendBackwardsOf: string,
        movedShapes: string[],
    ): Array<{ id: string, index: number}> {
        const indexes = [];
        const shiftedShapes = this.getShapesOrderedByIndex( movedShapes );
        const endShape = this.shapes[sendBackwardsOf];
        const endIndex = endShape.zIndex;
        const startShape = this.getShapeAtBack( endShape.id );
        let gap = 1;
        if ( startShape ) {
            const startIndex = startShape.zIndex;
            gap = Math.abs(( startIndex - endIndex ) / ( shiftedShapes.length + 1 ));
        }
        for ( let i = 0; i < shiftedShapes.length; i++ ) {
            indexes.push({
                id: shiftedShapes[shiftedShapes.length - i - 1].id,
                index: endIndex - ( gap * ( 1 + i )),
            });
        }
        return indexes;
    }

    /**
     * When a given set of shapes are to be brought forward of another shape,
     * this function calculates the indexes the shapes being moved need
     * to have in order for the bring forward operation to take effect.
     * @param bringForwardOf - shape id of the shape the shapes are being br of
     * @param movedShapes - list of ids of shapes that are being brought forward
     * @return array with caluclated zIndexes for each shape being moved
     */
    public getIndexesToBringForward(
        bringForwardOf: string,
        movedShapes: string[],
    ): Array<{ id: string, index: number}> {
        const indexes = [];
        const shiftedShapes = this.getShapesOrderedByIndex( movedShapes );
        const startShape = this.shapes[bringForwardOf];
        const startIndex = startShape.zIndex;
        const endShape = this.getShapeAtFront( startShape.id );
        let gap = 1;
        if ( endShape ) {
            const endIndex = endShape.zIndex;
            gap = Math.abs(( endIndex - startIndex ) / ( shiftedShapes.length + 1 ));
        }
        for ( let i = 0; i < shiftedShapes.length; i++ ) {
            indexes.push({
                id: shiftedShapes[i].id,
                index: startIndex + ( gap * ( 1 + i )),
            });
        }
        return indexes;
    }

    /**
     * Returns true if shapes of given ids belong to a parent group.
     * (In here the parent group is the top most group Id in a group
     * heirarchical manner.)
     * @param shapeIds - Shape Ids.
     */
    public isShapeInGroup( shapeIds: string[]): boolean {
        if ( !shapeIds.length ) {
            throw new Error( 'No shape ids were given' );
        }
        const firstTopGroup = this.getGroupHierarchy( shapeIds[0])[0];
        if ( !firstTopGroup ) {
            // NOTE: first group does not belong to a group
            return false;
        }
        for ( let i = 1; i < shapeIds.length; ++i ) {
            // NOTE: if any shape's top group != first top group, they don't belong to the same group
            const topGroup = this.getGroupHierarchy( shapeIds[i])[0];
            if ( topGroup !== firstTopGroup ) {
                return false;
            }
        }
        return true;
    }

    /**
     * This function returns all the group Ids where the given
     * shape belongs to.
     * Group Ids are returned in the hierarchical manner.
     * Order is from top container group to the closest
     * container group.
     * @param shapeId - The shape Id
     */
    public getGroupHierarchy( shapeId: string ): string[] {
        const groupIds: string[] = [];
        const closestGroupId = this.getGroupId( shapeId );
        if ( closestGroupId ) {
            groupIds.push( closestGroupId );
            let currentGroup = groupIds[0];
            while ( currentGroup ) {
                const parentGroup = this.getParentGroup( currentGroup );
                if ( parentGroup ) {
                    groupIds.push( parentGroup );
                    currentGroup = parentGroup;
                } else {
                    currentGroup = null;
                }
            }
            return groupIds.reverse();
        }
        return groupIds;
    }

    /**
     * This function returns the closest group Id
     * of the given shape Id.
     * @param shapeId - Shape Id
     */
    public getGroupId( shapeId: string ): string {
        for ( const group in this.groups ) {
            if ( this.groups[group].shapes.includes( shapeId )) {
                return group;
            }
        }
        return null;
    }

    /**
     * This function returns the parent group Id
     * of the given group Id.
     * @param groupId - Group Id
     */
    public getParentGroup( groupId: string ): string {
        for ( const group in this.groups ) {
            if ( this.groups[group].groups.includes( groupId )) {
                return group;
            }
        }
        return null;
    }

    /**
     * Returns the corresponding shape model for the given entity Id.
     * @param entityId
     * @returns
     */
    public getShapeFromEntityId( entityId: string ): AbstractShapeModel {
        return Object.values( this.shapes ).find(( shape: any ) => shape.entityId === entityId );
    }

    /**
     * This function returns all anscestors and decendants
     * children shapes of the given shape.
     * @param shapeIds - Shape Ids.
     */
    public getAllShapesInGroupHierarchy( shapeIds: string[]): string[] {
        let shapes: string[] = [];
        shapeIds.forEach( shapeId => {
            if ( !shapes.includes( shapeId )) {
                let groupIds = this.getGroupHierarchy( shapeId );
                if ( groupIds.length > 0 ) {
                    groupIds = groupIds.concat( this.getChildGroups( groupIds[0]));
                    groupIds.forEach( groupId => {
                        shapes = shapes.concat( this.getShapesInGroup( groupId ));
                    });
                }
            }
        });
        shapes = shapes.concat( shapeIds );
        return uniq( shapes );
    }

    /**
     * Returns the descendants/children groups of the given parent group.
     * @param groups - parent group.
     */
    public getChildGroups( groupId: string ): string[] {
        const groupInfo = this.groups[groupId];
        const groupIds: string[] = [];
        if ( groupInfo ) {
            for ( const child of groupInfo.groups ) {
                if ( child ) {
                    groupIds.push( child );
                    groupIds.push( ...this.getChildGroups( child ));
                }
            }
        } else {
            Logger.warning( `Group: group Id "${groupId}" is not available.` );
        }
        return groupIds;
    }

    /**
     * This function returns all shapes in the given group Id.
     * It does not include the child groups' shapes of
     * the given group Id.
     * @param groupIds - Group Ids.
     */
    public getShapesInGroup( groupId: string ): string[] {
        if ( this.groups[groupId]) {
            return this.groups[groupId].shapes;
        } else {
            return [];
        }
    }

    /**
     * Get an array of all shapes in the diagram sorted by z-index.
     */
    public getShapesOrderedByIndex( shapeIds?: string[]): AbstractShapeModel[] {
        const sbl = ShapeBoundsLocator.instance;
        if ( sbl ) {
            let sortedByZIndex = sbl.getShapesOrderedByIndex()
                .map( item => this.shapes[ item.id ]);
            if ( shapeIds ) {
                sortedByZIndex = sortedByZIndex
                    .filter( shape => shapeIds.includes( shape.id ));
            }
            return sortedByZIndex.filter( v => !!v );
        }
         // If ShapeBoundsLocator instance is not availbale at this point, fall back to old way
        return super.getShapesOrderedByIndex( shapeIds );
    }

    /**
     * Returs the most suitable textId with corresponding shapeId to switch the text editor
     * for the given arrow key direction
     */
    public getTextToSwitchByArrowkeys(
        shapeIds: string[], tId: string, sId: string, direction: 'up' | 'down' | 'left' | 'right' | 'tabright' ) {
            let currentText = this.shapes[ sId ].texts[ tId ];
            // When a new text is to be added, its not availanle in diagram model
            if ( !currentText ) {
                currentText = this.shapes[ sId ].isConnector() ? new ConnectorTextModel() : new ShapeTextModel();
            }
            const currentTextPosition = this.getTextPosition( sId, tId ) || {
                x: this.shapes[ sId ].bounds.centerX,
                y: this.shapes[ sId ].bounds.centerY,
            };

            const primarySet = [];
            const secondarySet = [];
            let resultSet;

            if ( direction === 'up' ) {
                shapeIds.forEach( shapeId => {
                    const shape = this.shapes[ shapeId ];
                    for ( const key of Object.keys( shape.texts )) {
                        if ( key === tId && shapeId === sId ) {
                            continue;
                        }
                        const txtModel = shape.texts[ key ];
                        if ( !txtModel.isVisible ) {
                            continue;
                        }
                        const txtPos = this.getTextPosition( shapeId, key );
                        const topDiff = currentTextPosition.y - ( txtPos.y + txtModel.height );
                        const leftDiff = currentTextPosition.x - ( txtPos.x + txtModel.width );
                        const rightDiff = txtPos.x - ( currentTextPosition.x + currentText.width );

                        if ( topDiff > -txtModel.height && ( leftDiff <= 0 && rightDiff <= 0 )) {
                            primarySet.push({ shapeId, textId: key, topDiff });
                        } else if ( topDiff > -txtModel.height ) {
                            secondarySet.push({ shapeId, textId: key, topDiff });
                        }
                    }
                });
                resultSet = minBy( primarySet, item => item.topDiff ) || minBy( secondarySet, item => item.topDiff );
            }

            if ( direction === 'down' ) {
                shapeIds.forEach( shapeId => {
                    const shape = this.shapes[ shapeId ];
                    for ( const key of Object.keys( shape.texts )) {
                        if ( key === tId && shapeId === sId ) {
                            continue;
                        }
                        const txtPos = this.getTextPosition( shapeId, key );
                        const txtModel = shape.texts[ key ];
                        if ( !txtModel.isVisible ) {
                            continue;
                        }
                        const btmDiff = txtPos.y - ( currentTextPosition.y + currentText.height );
                        const leftDiff = currentTextPosition.x - ( txtPos.x + txtModel.width );
                        const rightDiff = txtPos.x - ( currentTextPosition.x + currentText.width );
                        if ( btmDiff > -txtModel.height && ( leftDiff <= 0 && rightDiff <= 0 )) {
                            primarySet.push({ shapeId, textId: key, btmDiff });
                        } else if ( btmDiff > -txtModel.height ) {
                            secondarySet.push({ shapeId, textId: key, btmDiff });
                        }
                    }
                });
                resultSet = minBy( primarySet, item => item.btmDiff ) || minBy( secondarySet, item => item.btmDiff );
            }

            if ( direction === 'right' || direction === 'tabright' ) {
                const bottomSet = [];
                shapeIds.forEach( shapeId => {
                    const shape = this.shapes[ shapeId ];
                    for ( const key of Object.keys( shape.texts )) {
                        if ( key === tId && shapeId === sId ) {
                            continue;
                        }
                        const txtModel = shape.texts[ key ];
                        if ( !txtModel.isVisible ) {
                            continue;
                        }
                        const txtPos = this.getTextPosition( shapeId, key );
                        const rightDiff = txtPos.x - ( currentTextPosition.x + currentText.width );
                        const leftDiff = currentTextPosition.x - ( txtPos.x + txtModel.width );
                        const topDiff = currentTextPosition.y - ( txtPos.y + txtModel.height );
                        const btmDiff = txtPos.y - ( currentTextPosition.y + currentText.height );
                        if ( rightDiff > -txtModel.width && ( topDiff <= 0 && btmDiff <= 0 )) {
                            primarySet.push({ shapeId, textId: key, rightDiff });
                        } else if ( rightDiff > -txtModel.width && shapeId !== sId ) {
                            secondarySet.push({ shapeId, textId: key, rightDiff });
                        }

                        if ( btmDiff > -txtModel.height && btmDiff > 0 ) {
                            bottomSet.push({ shapeId, textId: key, btmDiff, leftDiff, height: txtModel.height });
                        }
                    }
                });
                // If tab and there's no next right, get the next shape below
                if ( direction === 'tabright' && primarySet.length === 0 ) {
                    const minBtmDiffItem = minBy( bottomSet, item => item.btmDiff );
                    const minBottomSet = bottomSet.filter( x =>
                        x.btmDiff <= minBtmDiffItem.btmDiff + minBtmDiffItem.height );
                    resultSet = maxBy( minBottomSet, item => item.leftDiff );
                } else {
                    resultSet = minBy( primarySet, item => item.rightDiff ) ||
                        minBy( secondarySet, item => item.rightDiff );
                }
            }

            if ( direction === 'left' ) {
                shapeIds.forEach( shapeId => {
                    const shape = this.shapes[ shapeId ];
                    for ( const key of Object.keys( shape.texts )) {
                        if ( key === tId && shapeId === sId ) {
                            continue;
                        }
                        const txtModel = shape.texts[ key ];
                        if ( !txtModel.isVisible ) {
                            continue;
                        }
                        const txtPos = this.getTextPosition( shapeId, key );
                        const leftDiff = currentTextPosition.x - ( txtPos.x + txtModel.width );
                        const topDiff = currentTextPosition.y - ( txtPos.y + txtModel.height );
                        const btmDiff = txtPos.y - ( currentTextPosition.y + currentText.height );
                        if ( leftDiff > -txtModel.width && ( topDiff <= 0 && btmDiff <= 0 )) {
                            primarySet.push({ shapeId, textId: key, leftDiff });
                        } else if ( leftDiff > -txtModel.width && shapeId !== sId  ) {
                            secondarySet.push({ shapeId, textId: key, leftDiff });
                        }
                    }
                });
                resultSet = minBy( primarySet, item => item.leftDiff ) || minBy( secondarySet, item => item.leftDiff );
            }

            // Switch to editable text model if it exists
            if ( resultSet && this.shapes[ resultSet.shapeId ].texts[ resultSet.textId ].editableTextId ) {
                resultSet.textId = this.shapes[ resultSet.shapeId ].texts[ resultSet.textId ].editableTextId;
            }
            return resultSet;
    }

    /**
     * This function returns all the connectors' Id in the diagram.
     * @return - list of connector Ids in the diagram.
     */
    public getConnectors(): string[] {
        const connectors = [];
        for ( const shapeId in this.shapes ) {
            if ( this.shapes[shapeId].isConnector()) {
                connectors.push( shapeId );
            }
        }
        return connectors;
    }

    /**
     * This function returns all the container ids
     * @return - list of shape Ids.
     */
    public getAllContainers(): ShapeModel[] {
        return values( this.shapes ).filter( shape => shape.isContainer );
    }

    /**
     * This function returns all the shape links,
     * @param type Links can be liltered by the type
     */
    public getShapeLinks( type?: string | ShapeLinkType ) {
        let links: ShapeLinkModel[] = [];
        for ( const gId in this.groups ) {
            if ( this.groups[gId].links && this.groups[gId].links.length > 0 ) {
                links = links.concat( this.groups[gId].links );
            }
        }
        for ( const sId in this.shapes ) {
            if ( this.shapes[sId].links && this.shapes[sId].links.length > 0 ) {
                links = links.concat ( this.shapes[sId].links );
            }
        }

        if ( type ) {
            return links.filter( l => l.type === type );
        }

        return links;
    }

    /**
     * This function returns the shape Ids which are locked or unlocked based on the input parameter isLocked.
     * If the isLocked parameter is true,
     * this function will return shape ids which are locked in the given set of shape ids.
     * @param shapeIds shape ids list
     * @param isLocked decides whether to check and return locked shapes or unlocked shapes
     */
    public filterShapeIdsByLockedState( shapeIds: string[], isLocked: boolean ): Array<string> {
        const ids: string[] = [];
        for ( const shapeId of shapeIds ) {
            /**
             * NOTE: This method does not consider connectors,
             * because connectors does not have any property called isLocked
             */
            if ( !this.shapes[shapeId].isConnector()) {
                const shape = this.shapes[shapeId] as ShapeModel;
                if ( shape.isLocked === isLocked ) {
                    ids.push( shapeId );
                }
            } else {
                ids.push( shapeId );
            }
        }
        return ids;
    }

    public wordWrap( shapeId: string, textId: string ) {
        let width;
        const shape = this.shapes[ shapeId ] as any;
        const textModel = shape.texts[ textId ] as any;

        if ( shape.type === ShapeType.Connector ) {
            const wordWrap = false;
            // Adding an extra 10px to prevent the cursor jumping to the next line
            return { wordWrap,  width: ( textModel.width || 0 ) + 10  };
        } else {
            const bounds = {
                width: shape.defaultBounds.width * shape.scaleX,
            };
            if ( textModel.hitArea ) {
                width = textModel.hitArea.width - ( TEXT_PADDING_HORIZONTAL * 2 );
            } else if ( bounds.width > textModel.width ) {
                width = bounds.width - ( TEXT_PADDING_HORIZONTAL * 2 );
            } else {
                width = textModel.width;
            }
            if ( TextPostion.isInside( textModel ) || textModel.wordWrap ) {
                if ( shape.autoResizeWidth ) {
                    // Adding an extra 10px to prevent the cursor jumping to the next line
                    width = ( width || 0 ) + 10;
                }
            } else {
                // Set a big number for outside texts so that the text can span without wrapping
                width = this.maxTextWidth;
            }
            return { wordWrap: textModel.wordWrap, width };
        }
    }

    /**
     * Update dataDefs property
     */
    // tslint:disable-next-line: cyclomatic-complexity
    public updateDataDefs( shapeId: string, dataItemId: string, data: any, level: DataItemLevel ) {
        // const optional = data ? ( data.optional ? data.optional : false ) : false;
        if ( dataItemId === this.DESCRIPTION_DATAITEM_ID ) { // skip description related updates
            return;
        }

        // console.log( 'MRX updateDataDefs in diagram ', dataItemId, data, level );
        const shape = this.shapes[ shapeId ];

        if ( !shape ) {
            return;
        }

        if ( data ) {
            data = cloneDeep( data );
            delete data.value;
            delete data.level;

            // does this exist in another level other than the given level?
            const currLevel = this.getDataItemLevel( shapeId, dataItemId );
            let dataItem = this.getDataItem( shapeId, dataItemId );

            if ( currLevel === DataItemLevel.Def ) {
                return;
            }

            if ( level === DataItemLevel.DataDef && currLevel === DataItemLevel.CustomDef ) {
                // dataDef updated - single select or formula
                if ( data && ( data.label !== dataItem.label || !isEqual( data.options, dataItem.options ))) {
                    dataItem = data;
                } else {
                    return;
                }
            } else {
                if ( dataItem && !isEqual( dataItem, data )) { // Updating data item
                    Object.assign( dataItem, data );
                } else if ( !dataItem ) { // Add
                    dataItem = data;
                }
            }

            // set this at the correct level
            if ( shape.eData ) {
                // when shape has just inserted it might not have been deserialized yet.
                // thus avoiding `entityId` getter.
                const entityId = Object.values( shape.eData )[0];
                if ( entityId !== shape.dataSetId ) {
                    // support transitioning from shape to entity. (or even a previous entity to a new one)
                    if ( shape.dataSetId && this.dataDefs [ shape.dataSetId ]) {
                        this.dataDefs [ entityId ] = this.dataDefs [ shape.dataSetId ];
                        delete this.dataDefs [ shape.dataSetId ];
                    }
                    shape.dataSetId = entityId;
                }
            } else if ( !shape.dataSetId ) {
                shape.dataSetId = Random.dataItemId();
            }

            // assign the data to it.
            if ( level === DataItemLevel.CustomDef ) {
                if ( !shape.entityDefId ) {
                    throw Error( ' Trying to update DataItem at the wrong level. No entity def listed' );
                }
                if ( !this.dataDefs[ shape.entityDefId ]) {
                    this.dataDefs[ shape.entityDefId ] = {};
                }
                this.dataDefs[ shape.entityDefId ][ dataItemId ] = dataItem;
                if ( currLevel !== DataItemLevel.CustomDef ) {
                    // this may be in some other shapes def also, so search across for the same def and delete it.
                    const toDelete = [];
                    for ( const key in this.dataDefs ) {
                        if ( key !== shape.entityDefId && this.dataDefs[ key ] && this.dataDefs[ key ][ dataItemId ]) {
                            toDelete.push( key );
                        }
                    }
                    toDelete.forEach( key => {
                        delete this.dataDefs[ key ][ dataItemId ];
                        if ( isEmpty( this.dataDefs[ key ])) {
                            delete this.dataDefs[ key ];
                        }
                    });
                }
            } else if ( level === DataItemLevel.DataDef || ( level === DataItemLevel.Any && currLevel === undefined )) {
                // this is not in the def at the moment at all, so lets add it
                if ( !this.dataDefs[ shape.dataSetId ]) {
                    this.dataDefs[ shape.dataSetId ] = {};
                }
                this.dataDefs[ shape.dataSetId ][ dataItemId ] = dataItem;
            }
        }

        // Delete def props if shapes are no longer using it.
        const def = this.dataDefs[ shape.dataSetId ]; // only for entity level, not for customDef level
        if ( def ) {
            const defData = def[ dataItemId ];
            const canDelete = values( this.shapes ).filter( s => s.dataSetId ===  shape.dataSetId )
                .length === 1;
            if ( !data && defData && canDelete ) {
                delete def[ dataItemId ];
            }
        }
    }

    /**
     * Returns the shapes bound to the given edata model
     * @param edataId
     */
    public getShapesByEdataId( edataId: string ) {
        return values( this.shapes ).filter(( shape: AbstractShapeModel ) => {
            if ( shape.eData ) {
                const edata = Object.keys( shape.eData );
                return edata && edata[0] === edataId;
            }
        });
    }

    /**
     * Gets the DI level from the dataDef map.
     * @param shapeId
     * @param dataItemId
     * @returns
     */
    public getDataItemLevel ( shapeId: string, dataItemId: string ): DataItemLevel {
        const shape = this.shapes[ shapeId ];
        if ( !shape ) {
            return;
        }

        if ( this.dataDefs[ shape.dataSetId ] && this.dataDefs [ shape.dataSetId] [ dataItemId ]) {
            return DataItemLevel.DataDef;
        } else if ( shape.eData && this.dataDefs[ shape.entityDefId ]) {
            if ( this.dataDefs [ shape.entityDefId] [ dataItemId ]) {
                return DataItemLevel.CustomDef;
            }
        }
        if ( shape.entityDefId ) {
            // below code is added for safety, it is not expected to come to this point
            const entityDef = EDataRegistry.instance.findEntityDefById( shape.entityDefId );
            if ( entityDef ) {
                if ( entityDef.shapeDefs && entityDef.shapeDefs[shape.defId]) {
                    const mapping = entityDef.shapeDefs[shape.defId].dataMap.find( m => m.dataItemId === dataItemId );
                    if ( mapping ) {
                        return DataItemLevel.Def;
                    }
                }
                if ( entityDef.dataItems && entityDef.dataItems[dataItemId]) {
                    return DataItemLevel.Def;
                }
            }
        }
    }

    /**
     * Gets the DI level from the dataDef map. actual reference.
     * @param shapeId
     * @param dataItemId
     * @returns
     */
     public getDataItem ( shapeId: string, dataItemId: string ): IDataItem<DataType> {
        const shape = this.shapes[ shapeId ];
        if ( !shape ) {
            return;
        }

        if ( !shape.dataSetId ) {
            return shape.data[ dataItemId];
        } else if ( this.dataDefs[ shape.dataSetId ]) {
            if ( this.dataDefs[ shape.dataSetId][ dataItemId ]) {
                return this.dataDefs [ shape.dataSetId] [ dataItemId ];
            }
            return shape.data[ dataItemId];
        } else if ( shape.eData && this.dataDefs[ shape.entityDefId ]) {
            if ( this.dataDefs [ shape.entityDefId] [ dataItemId ]) {
                return this.dataDefs [ shape.entityDefId] [ dataItemId ];
            }
            // below code is added for safety, it is not expected to come to this point
            const entityDef = EDataRegistry.instance.findEntityDefById( shape.entityDefId );
            if ( entityDef ) {
                if ( entityDef.shapeDefs && entityDef.shapeDefs[shape.defId]) {
                    const mapping = entityDef.shapeDefs[shape.defId].dataMap.find( m => m.dataItemId === dataItemId );
                    if ( mapping ) {
                        return entityDef.dataItems[mapping.eDataFieldId] as any;
                    }
                }
                if ( entityDef.dataItems && entityDef.dataItems[dataItemId]) {
                    return entityDef.dataItems[dataItemId] as any;
                }
            }
        }

    }


    /**
     * Merges datadefs and shape data and returns the resultant dataItem for the
     * specified shape.
     * NOTE: This method is similler to the dataItems @reference getter in the shape model
     * and the dataItems getter is giving outdated values <- FIXME
     * This method returns accuarte values.
     */
    public getShapeDataItems( shapeId: string ): MapOf<IDataItem<DataType>> {
        const model = this.shapes[ shapeId ];
        if ( !model ) {
            return {};
        }
        const data = DiagramFactory.createData( CoreDataFieldService.getCoreDataDefs(), model.data );
        if ( !model.dataSetId && !model.eData ) {
            return data || {};
        }
        const def = this.getDataDef( shapeId ) || {};
        const retVal = {};
        for ( const key in data ) {
            const defData = def[ key ] || {};
            retVal[ key ] = { ...data[ key ], ...defData, value: data[ key ].value };
            if ( retVal[ key ].roleBound && !retVal[ key ].roleId && retVal[ key ]._roleId ) {
                retVal[ key ].roleId = retVal[ key ]._roleId;
                delete retVal[ key ]._roleId;
            }
        }

        // if ( model.data && model.data[ this.DESCRIPTION_DATAITEM_ID ]) {
        //     retVal [ this.DESCRIPTION_DATAITEM_ID ] = model.data[ this.DESCRIPTION_DATAITEM_ID ];
        // }

        return cloneDeep( retVal );
    }

    public getShapeDataItemsWithoutClone( shapeId: string ): MapOf<IDataItem<DataType>> {
        const model = this.shapes[ shapeId ];
        if ( !model ) {
            return {};
        }
        if ( !model.dataSetId && !model.eData ) {
            return model.data || {};
        }
        const def = this.getDataDef( shapeId ) || {};
        const retVal = {};
        for ( const key in model.data ) {
            const defData = def[ key ] || {};
            retVal[ key ] = { ...model.data[ key ], ...defData, value: model.data[ key ].value };
            if ( retVal[ key ].roleBound && !retVal[ key ].roleId && retVal[ key ]._roleId ) {
                retVal[ key ].roleId = retVal[ key ]._roleId;
                delete retVal[ key ]._roleId;
            }
        }

        // if ( model.data && model.data[ this.DESCRIPTION_DATAITEM_ID ]) {
        //     retVal [ this.DESCRIPTION_DATAITEM_ID ] = model.data[ this.DESCRIPTION_DATAITEM_ID ];
        // }

        return retVal;
    }

    /**
     * Retruns all the child shapes of the given container including the nested
     * containers and their children.
     * @param shapeId
     * @returns
     */
    public getNestedChildrenIds( shapeId: string ) {
        const container = this.shapes[ shapeId ] as ShapeModel;
        const children = new Set();
        Object.keys( container.children || {}).forEach( childId => {
            children.add( childId );
            this.getNestedChildrenIds( childId ).forEach( id => children.add( id ));
        });
        return Array.from( children );
    }

    /**
     * Returns the _layoutPadding data item value for the given shape.
     * @param shapeId
     * @returns
     */
    public getLayoutPadding( shapeId: string ): Array<number> {
        const data = this.getShapeDataItemsWithoutClone( shapeId );
        const dataItem = Object.values( data ).find(( item: any ) => {
            if ( item.label === '_layoutPadding' ) {
                return true;
            }
        });
        if ( dataItem ) {
            return JSON.parse( dataItem.value );
        }
        return [ 10, 10, 10, 10 ];
    }

    /**
     * Returns the _layoutPadding data item value for the given shape.
     * @param shapeId
     * @returns
     */
    public getRegionLayoutPadding( shapeId: string, regionId: string ): Array<number> {
        const dataItem = this.getInnerDataItemByLabel( shapeId, regionId, '_layoutPadding' );
        if ( dataItem ) {
            return JSON.parse( dataItem.value );
        }
        return [ 10, 10, 10, 10 ];
    }

    public skipLayout( shapeId: string ): boolean {
        const dataItem = this.getDataItemByLabel( shapeId, '_skipLayout' );
        if ( dataItem ) {
            return !!dataItem.value;
        }
        return false;
    }

    /**
     * Returnst the _id data item value for the given shape.
     * @param shapeId
     * @returns
     */
    public getIdentifier( shapeId: string ): string {
        const data = this.getShapeDataItemsWithoutClone( shapeId );
        const dataItem = Object.values( data ).find(( item: any ) => {
            if ( item.label === '_id' ) {
                return true;
            }
        });
        if ( dataItem ) {
            return dataItem.value;
        }
    }

    /**
     * Returns the data item found for the given label
     * @param shapeId
     * @param label
     * @returns
     */
    public getDataItemByLabel( shapeId: string, label: string ): any {
        const data = this.getShapeDataItemsWithoutClone( shapeId );
        const dataItem = Object.values( data ).find(( item: any ) => {
            if ( item.label === label ) {
                return true;
            }
        });
        if ( dataItem ) {
            return dataItem;
        }
    }

    /**
     * Returns the inner data item found for the given label and inner id
     * @param shapeId
     * @param innerShapeId
     * @param label
     * @returns
     */
    public getInnerDataItemByLabel( shapeId: string, innerShapeId: string, label: string ): any {
        const data = this.getShapeDataItemsWithoutClone( shapeId );
        const dataItem = Object.values( data ).find(( item: any ) => {
            if ( item.label === label && ( item.innerSelection || []).includes( innerShapeId )) {
                return true;
            }
        });
        if ( dataItem ) {
            return dataItem;
        }
    }

    public getRegionIdByIdentifier( containerId: string, identifier: string ) {
        const container = this.shapes[ containerId ] as ShapeModel;
        let regionId = Object.keys( container.containerRegions || {}).find( regId => {
            const reg = container.containerRegions[ regId ];
            if ( reg.width <= 0 ) {
                return;
            }
            const dataItem = this.getInnerDataItemByLabel( containerId, regId, '_id' );
            return dataItem?.value === identifier;
        });
        if ( !regionId ) {
            regionId = container.getContainerRegionByName( identifier );
        }
        return regionId;
    }

    public getRegionIdByColumnName( containerId: string, identifier: string ) {
        const table = this.shapes[ containerId ] as ShapeModel;
        const cells = Object.values( table.data )
            .filter(( data:  any ) => data.type === DataType.CHILD_SHAPE )
            .map(( data:  any )  => data.value );
        const topCell = cells
            .filter( cell => cell.rowId === 1 )
            .find( cell => {
                const textId = cell.id;
                const text = table.texts[ textId ];
                return text.plainText === identifier;
        });
        if ( topCell ) {
            const colId = topCell.columnId;
            const region = cells.find( cell => cell.columnId === colId && cell.rowId === 2 );
            return region.id;
        }
    }

    public getRegionIdByRownName( containerId: string, identifier: string ) {
        const table = this.shapes[ containerId ] as ShapeModel;
        const cells = Object.values( table.data )
            .filter(( data:  any ) => data.type === DataType.CHILD_SHAPE )
            .map(( data:  any )  => data.value );
        const leftCell = cells
            .filter( cell => cell.columnId === 1 )
            .find( cell => {
                const textId = cell.id;
                const text = table.texts[ textId ];
                return text.plainText === identifier;
        });
        if ( leftCell ) {
            const rowId = leftCell.rowId;
            const region = cells.find( cell => cell.rowId === rowId && cell.columnId === 2 );
            return region.id;
        }
    }

    public getRegionIdByColumnRowName( containerId: string, colName: string, rowName: string ) {
        const table = this.shapes[ containerId ] as ShapeModel;
        const cells = Object.values( table.data )
            .filter(( data:  any ) => data.type === DataType.CHILD_SHAPE )
            .map(( data:  any )  => data.value );
        const colRegId = this.getRegionIdByColumnName( containerId, colName );
        const columnId = cells.find( cell => cell.id === colRegId ).columnId;
        const rowRegId = this.getRegionIdByRownName( containerId, rowName );
        const rowId = cells.find( cell => cell.id === rowRegId ).rowId;
        const topCell = cells.find( cell => cell.columnId === columnId && cell.rowId === rowId );
        if ( topCell ) {
            return topCell.id;
        }
    }

    public getRegionName( containerId: string, regionId: string ) {
        const container = this.shapes[ containerId ] as ShapeModel;
        const dataItem = this.getInnerDataItemByLabel( containerId, regionId, '_id' );
        if ( dataItem ) {
            return dataItem.value;
        } else {
            return container.texts[ regionId ]?.plainText;
        }
    }

    /**
     * Returns the _childDefId data item value for the given shape.
     * @param shapeId
     * @returns
     */
    public getChildDefId( shapeId: string ) {
        const data = this.getShapeDataItemsWithoutClone( shapeId );
        const dataItem = Object.values( data ).find(( item: any ) => {
            if ( item.label === '_childDefId' ) {
                return true;
            }
        });
        if ( dataItem ) {
            return dataItem.value;
        }
    }

    /**
     * Returns the _style_ref shape for the given container.
     * @param containerId
     * @returns
     */
    public getStyleRefShape( containerId: string ) {
        const container = this.shapes[ containerId ] as ShapeModel;
        const shapeId = Object.keys( container.children || {}).find( id => {
            const data = this.getShapeDataItemsWithoutClone( id );
            return !!Object.values( data ).find(( item: any ) => {
                if ( item.label === '_style_ref' && item.value ) {
                    return true;
                }
            });
        });
        return shapeId ? this.shapes[ shapeId ] : undefined;
    }

    public getStyleRefShapeInRegion( containerId: string, regionId: string ) {
        const container = this.shapes[ containerId ] as ShapeModel;
        const shapeId = Object.keys( container?.containerRegions[ regionId ]?.shapes || {}).find( sid => {
            const data = this.getShapeDataItemsWithoutClone( sid );
            return !!Object.values( data ).find(( item: any ) => {
                if ( item.label === '_style_ref' && item.value ) {
                    return true;
                }
            });
        });
        return shapeId ? this.shapes[ shapeId ] : undefined;
    }

    /**
     * Returns the child shapes in the layout area of the given container.
     * @param containerId
     * @returns
     */
    public getChildShapesInLayoutArea( containerId: string ) {
        const container = this.shapes[ containerId ] as ShapeModel;
        if ( !container?.isContainer ) {
            return [];
        }
        const pad = this.getLayoutPadding( containerId ).map( v => -( v - 5 )); // NOTE: 5 is just a threshold value
        const bounds = Rectangle.from( container.bounds );
        const containerBounds = bounds.pad( pad );
        const tempChildrn =  Object.keys( container.children ).filter( id => {
            const child = this.shapes[ id ];
            return containerBounds.contains( child.bounds );
        }).map( childId => this.shapes[ childId ]);
        return tempChildrn;
    }

    /**
     * Returns the child shapes subject to layout in the given container.
     */
    public getLayoutingShapesInRegion( containerId: string, regionId: string ) {
        const container = this.shapes[ containerId ] as ShapeModel;
        if ( !container?.isContainer ) {
            return [];
        }
        return Object.keys( container.containerRegions[ regionId ]?.shapes || {})
            .filter( childId => !this.skipLayout( childId ))
            .map( id => this.shapes[ id ]);
    }

    public getDataDef( shapeId: string, includeCoreDefs = false ) {
        let dataDef = super.getDataDef( shapeId );
        if ( includeCoreDefs ) {
            dataDef = Object.assign({}, dataDef, CoreDataFieldService.getCoreDataDefs());
        }
        const shape = this.shapes[shapeId];
        if ( shape && shape.entityDefId ) {
            const entityDef = EDataRegistry.instance.findEntityDefById( shape.entityDefId );
            if ( entityDef && entityDef.dataItems ) {
                const fieldMap = {};
                if ( entityDef.shapeDefs ) {
                    const shapeDef = entityDef.shapeDefs[shape.defId];
                    if ( shapeDef && shapeDef.dataMap ) {
                        shapeDef.dataMap.forEach( m => {
                            fieldMap[m.eDataFieldId] = m.dataItemId;
                        });
                    }
                }
                const dataItems = {};
                for ( const dataItemId in entityDef.dataItems ) {
                    dataItems[fieldMap[dataItemId] || dataItemId] = entityDef.dataItems[dataItemId];
                }
                return Object.assign( dataItems, dataDef );
            }
        }
        return dataDef;
    }

    /**
     * Lookup connector model for given criteria
     */
    public getLookupConnector( lookupId: string, shapeId: string, entityId: string, eDataId: string ) {
        const connectors = this.getLookupConnectors().filter( c => c.handshake[0] === lookupId );
        return connectors.find( c => this.isMatchingConnector( c, shapeId, entityId, eDataId ));
    }

    /**
     * Connector models that represent lookup connection.
     */
    public getLookupConnectors() {
        return Object.values( this.shapes )
            .filter( s => s.isConnector() && s.defId === LOOKUP_CONNECTOR.defId ) as ConnectorModel[];
    }

    /**
     * Lookup connector models for given criteria
     */
    public getMatchingLookupConnectors( lookupId: string, shapeId: string, entityId: string, eDataId: string ) {
        const connectors = this.getLookupConnectors().filter( c => c.handshake[0] === lookupId );
        return connectors.filter( c => this.isMatchingConnector( c, shapeId, entityId, eDataId ));
    }

    /**
     * Returns true if the given shape ids form a tree, false otherwise.
     * @param ids Shape/connector ides to check if they form a tree
     * @returns boolean
     */
    /* istanbul ignore next */
    public isTree( ids: Array<string> = null ): boolean {
        const diagram = this;
        const shapes = [];
        const connectors: Array<ConnectorModel> = ( ids || Object.keys( diagram.shapes ))
            .map( id => diagram.shapes[id])
            .filter( shape => {
                if ( shape.isConnector()) {
                    return true;
                } else {
                    shapes.push( shape );
                }
        }) as any;

        const shapeIds = new Set( shapes.map( shape => shape.id ));
        const connectorIds = new Set( connectors.map( connector => connector.id ));

        const visitedShapes = new Set();
        const visitedConnectors = new Set<string>();

        // DFS traversal function
        const traverse = shapeId => {
          visitedShapes.add( shapeId );

          const connectedConnectors = connectors.filter( connector =>
            connector.getConnectedShapeIds( diagram ).includes( shapeId ),
          );

          for ( const connectedConnector of connectedConnectors ) {
            const { id } = connectedConnector;
            visitedConnectors.add( id );
            for ( const connectedShapeId of connectedConnector.getConnectedShapeIds( diagram )) {
              if ( !shapeIds.has( connectedShapeId )) {
                return false; // Invalid connection, return false
              }
              if ( !visitedShapes.has( connectedShapeId )) {
                if ( !traverse( connectedShapeId )) {
                  return false; // Not a tree, return false
                }
              }
            }
          }
          return true;
        };

        const firstShape = shapes[0];
        if ( !firstShape ) {
          return false; // No shapes, return false
        }

        const rootConnectors = connectors.filter( connector =>
            connector.getConnectedShapeIds( diagram ).includes( firstShape.id ),
        );

        for ( const rootConnector of rootConnectors ) {
            const { id } = rootConnector;
            visitedConnectors.add( id );
            for ( const connectedShapeId of rootConnector.getConnectedShapeIds( diagram )) {
              if ( !shapeIds.has( connectedShapeId )) {
                return false; // Invalid connection, return false
              }
              if ( !visitedShapes.has( connectedShapeId )) {
                if ( !traverse( connectedShapeId )) {
                  return false; // Not a tree, return false
                }
              }
            }
        }
        return visitedShapes.size === shapeIds.size && visitedConnectors.size === connectorIds.size;
    }

    public getAllShapesStartedFromGp( shapeId: string, gpId: string ) {
        const shape = this.shapes[ shapeId ] as ShapeModel;
        const sIds = new Set<string>();
        const connIds = new Set<string>();

        shape.connectorIds?.forEach( cId => {
            const conn = this.shapes[ cId ] as ConnectorModel;
            if ( !conn ) {
                return;
            }
            const fromEndpoint = conn.getFromEndpoint( this );
            if ( fromEndpoint.shape?.id === shapeId && fromEndpoint.gluepoint?.id === gpId ) {
                const toShapeId = conn.getToEndpoint( this ).shape?.id;
                if ( toShapeId ) {
                    const { shapeIds, connectorIds } = this.getAllConnectedShapes( toShapeId, 'OUT' );
                    shapeIds.forEach( id => sIds.add( id ));
                    connectorIds.forEach( id => connIds.add( id ));
                }
            }
        });
        return {
            shapeIds: [ ...sIds ],
            connectorIds: [ ...connIds ],
        };
    }

    public getAllConnectedShapes(
        shapeId: string,
        direction: 'IN' | 'OUT'  = undefined,
    ): { shapeIds: string[], connectorIds: string[] } {
        const s = this.shapes[ shapeId ];
        const shapeSet = new Set<string>();
        const searchedSet = new Set();
        shapeSet.add( shapeId );
        if ( !s.isConnector()) {
            const search = ( _id: string ) => {
                const _shape = this.shapes[ _id ] as ShapeModel;
                const conShapes = _shape.getConnectedShapes( this )
                    .filter( sid => {
                        if ( direction === undefined ) {
                            return true;
                        } else if ( sid.dir === direction ) {
                            return true;
                        }
                    })
                    .map( sid => sid.shapeId );
                searchedSet.add( _id );
                conShapes.forEach( cId => {
                    shapeSet.add( cId );
                    if ( !searchedSet.has( cId )) {
                        search( cId );
                    }
                });
            };
            search( shapeId );
        }
        const connectorSet = new Set<string>();
        shapeSet.forEach( _shapeId => {
            const shape = this.shapes[ _shapeId ] as ShapeModel;
            if ( shape.connectorIds ) {
                shape.connectorIds.forEach( cId => {
                    if ( this.shapes[ cId ]) {
                        connectorSet.add( cId );
                    }
                });
            }
        });

        return {
            shapeIds: [ ...shapeSet ],
            connectorIds: [ ...connectorSet ],
        };

    }

    /* istanbul ignore next */
    public getContainerChildrenWithConnectors( contianerId: string ): Array<string> {
        const container = this.shapes[ contianerId ] as ShapeModel;
        if ( container ) {
            const shapeIds = Object.keys( container.children );
            const connectorIds = [];
            shapeIds.forEach( id => {
                const shape = this.shapes[ id ] as ShapeModel;
                if ( shape instanceof ShapeModel ) {
                    const sourceConnections = shape.getConnectors( this ).filter( c => {
                        // 'connection' is udefined for some diagrams
                        const connection = c.connector.getConnection( this );
                        const fromEndpoint = c.connector.getFromEndpoint( this );
                        if ( connection && connection.shapeA.shapeId === shape.id ) {
                            return true;
                        } else if ( !connection && fromEndpoint.shape.id === shape.id ) {
                            return true;
                        }
                    }).map( c => c.connector );
                    if ( sourceConnections.length ) {
                        sourceConnections.forEach(( c: any ) => {
                            const src = shape.id;
                            const conn = c.getConnection( this );
                            const toEndpoint = c.getToEndpoint( this );
                            const target = conn ? conn.shapeB.shapeId :
                                toEndpoint.shape ? toEndpoint.shape.id : null;
                            if ( target && shapeIds.find( sid => sid === src )
                                && shapeIds.find( sid => sid === target )) { // An edge should connect 2 shapes
                                    connectorIds.push( c.id );
                            }
                        });
                    }
                }
            });
            return [ ...shapeIds, ...connectorIds ];
        }
        return [];
    }

    /**
     * This function returns searchable text data
     */
    public getSearchableTextData( shapeIds: string[]): any {
        const data = {
            shapes: {},
            connectors: {},
        };

        const shouldOmit = ( item: any, primaryText ) => {
            if ( item.id === this.DESCRIPTION_DATAITEM_ID
                || item.type === DataType.CHILD_SHAPE
                || ( item.label === 'Title' && item.value === primaryText )
                ) {
                    return true;
            }
            if ( item.id === '_labelH' || item.id === '_labelV' ) {
                return item.visibility?.[0]?.containerOnly;
            } else if ( item.id?.[0] === '_' )  {
                return true;
            }

        };

        for ( const shapeId of shapeIds ) {
            const s = this.shapes[ shapeId ];
            if ( s && !s.isConnector()) {
                const shape = this.shapes[ shapeId ] as ShapeModel;
                const dataItems = this.getShapeDataItems( shapeId );
                const properties = {};
                const primaryText = shape.primaryTextModel ? shape.primaryTextModel.plainText : '';
                Object.values( dataItems ).forEach( item => {
                    if ( shouldOmit( item, primaryText )) {
                        return;
                    }
                    if ( item.systemType !== undefined ) {
                        const st = typeof item.systemType === 'string'
                            ? item.systemType : SystemType[ item.systemType ];
                        item.label = `${ item.label }(${ st })`;
                    }
                    properties[ item.label || item.id ] = item.value;
                });

                const bounds = shape.bounds;
                data.shapes[ shapeId ] = {
                    shapeType: shape.defId, // .replace( /^(creately\.)/, '' ),
                    primaryText,
                    description: shape.getDescriptionPlainText(),
                    properties,
                    bounds: { x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height },
                };

                if ( shape.containerId ) {
                    const container = this.shapes[ shape.containerId ] as ShapeModel;
                    if ( container ) {
                        const parentIdDataItem = this.getDataItemByLabel( shapeId, '_id' );
                        if ( parentIdDataItem ) {
                            data.shapes[ shapeId ].parentContainerId = parentIdDataItem.value;
                        }

                        if ( shape.containerRegionId
                            && container.containerRegions?.[ shape.containerRegionId ]) {
                                data.shapes[ shapeId ].parentRegionId =
                                    this.getRegionName( container.id, shape.containerRegionId );
                        }
                    }
                }
            } else if ( s ) {
                const connector = this.shapes[ shapeId ] as ConnectorModel;
                const conn = connector.getConnection( this );
                const toEndpoint = connector.getToEndpoint( this );
                const fromEndpoint = connector.getFromEndpoint( this );
                const from = conn ? conn.shapeA.shapeId : fromEndpoint.shape?.id;
                const to = conn ? conn.shapeB.shapeId : toEndpoint.shape?.id;

                const bounds = connector.bounds;
                data.connectors[ shapeId ] = {
                    shapeType: connector.defId, // .replace( /^(creately\.)/, '' ),
                    primaryText: connector.primaryTextModel ? connector.primaryTextModel.plainText : '',
                    from,
                    to,
                    bounds: { x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height },
                };

            }
        }
        return data;
    }

    /**
     * This function returns searchable text data
     */
    public getSimpleSearchableTextData( shapeIds: string[]): any {
        const data = {
            shapes: {},
            connectors: {},
        };
        for ( const shapeId of shapeIds ) {
            const s = this.shapes[ shapeId ];
            if ( s && !s.isConnector()) {
                const shape = this.shapes[ shapeId ] as ShapeModel;
                const primaryText = shape.primaryTextModel ? shape.primaryTextModel.plainText : '';
                data.shapes[ shapeId ] = {
                    shapeType: shape.defId, // .replace( /^(creately\.)/, '' ),
                    primaryText,
                    description: shape.getDescriptionPlainText(),
                };
            } else if ( s ) {
                const connector = this.shapes[ shapeId ] as ConnectorModel;
                const conn = connector.getConnection( this );
                const toEndpoint = connector.getToEndpoint( this );
                const fromEndpoint = connector.getFromEndpoint( this );
                const from = conn ? conn.shapeA.shapeId : fromEndpoint.shape?.id;
                const to = conn ? conn.shapeB.shapeId : toEndpoint.shape?.id;
                data.connectors[ shapeId ] = {
                    shapeType: connector.defId, // .replace( /^(creately\.)/, '' ),
                    primaryText: connector.primaryTextModel ? connector.primaryTextModel.plainText : '',
                    from,
                    to,
                };
            }
        }
        return data;
    }

    protected isMatchingConnector( connector: ConnectorModel, shapeId: string, entityId: string, eDataId: string ) {
        const fromShape = connector.getFromEndpoint( this ).shape;
        const toShape = connector.getToEndpoint( this ).shape;
        if ( !fromShape || !toShape ) {
            return false;
        }
        if ( fromShape.id === shapeId ) {
            return toShape.eDataId === eDataId && toShape.entityId === entityId;
        }
        if ( toShape.id === shapeId ) {
            return fromShape.eDataId === eDataId && fromShape.entityId === entityId;
        }
        return false;
    }

    /*
     * When a shape is being placed before an existing shape,
     * calculates the index to position the shape at.
     * Simply put, this method calculates the next lowest eligible
     * index before a given index.
     * @param index - the index to calculate against
     * TODO: This functio needs to be removed and getIndexesToSendBackwards
     * can be used to serve its purpose.
     */
    protected getIndexBefore( index: number ): number {
        if ( this.numShapes === 0  ) {
            return 0;
        } else if ( this.numShapes === 1 ) {
            return values( this.shapes )[0].zIndex - 1;
        } else {
            const indexesBelow = values( this.shapes )
                .map( shape => shape.zIndex ).filter( idx => idx < index );
            if ( indexesBelow.length <= 0 ) {
                return this.minZIndex - 1;
            }
            // TODO: Optimize the algorithm for inbetween index calculation. Using repeated division
            // may prove to be buggy if the same shape is switched between two other shapes
            // repeatedly (after 53 switches) due to floating point loss of precision.
            return ( index + Math.max( ...indexesBelow )) / 2;
        }
    }
}
