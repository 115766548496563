import { NgModule, Inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { App } from './app.cmp';
import { PageNotFound } from './system/ui/messages/page-not-found.cmp';
import {
    FluxCore,
    FluxCoreDirectives,
    FluxCommandService,
    CommandScenario,
    CommandStepMapper,
    DataValidationExecutionStep,
    ExecuteResultExecutionStep,
    HttpExecutionStep,
    NeutrinoRestExecutionStep,
    RunExecutionStep,
    SetStatesExecutionStep,
    ReceiveStatesExecutionStep,
    AppConfig,
    StateService,
    Tracker,
    AppErrorHandler,
    SecurityErrorHandler,
    DateFNS,
    ExecutionStep,
    ModalController,
    ContainerEnv,
    CommandErrorHandler,
    Random,
    RunNetworkOfflineStep,
    AppPlatform,
    SVGLoader,
    PostMessageSendEventType,
    OTelManager,
} from 'flux-core';
import { FluxCoreUI, DialogBoxController, FluxCoreDialogBoxController, AbstractErrorReporter } from 'flux-core/src/ui';
import { FluxConnection, SendHttpExecutionStep, SendWsExecutionStep,
    SendWsSyncExecutionStep, SendHttpSyncExecutionStep, ConnectionStatus } from 'flux-connection';
import { ModelSubscriptionService } from 'flux-subscription';
import {
    FluxStore,
    StoreAfterReceiveExecutionStep,
    PrepareProxyExecutionStep,
    SubStoreModels,
    SubRemoveChanges,
} from 'flux-store';
import { Database } from '@creately/rxdata';
import { ShapeInjector } from './config/shape-injector';
import { SetDiagramIdExecutionStep } from './framework/diagram/command/exec-step-set-diagram-id';
import { UIContextMenuController } from './editor/interaction/context-menu/ui-context-menu-controller';
import { ServerError } from './system/ui/messages/server-error.cmp';
import { OfflineError } from './system/ui/messages/offline-error.cmp';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FeatureList } from './framework/feature/feature-list.svc';
import { ModelSubscriptionManager } from 'flux-subscription';
import { RouteResponsibility } from './system/responsibility/route-responsibility.svc';
import { DataStore } from 'flux-store';
import { DiagramInfoModel, ProjectModel, ProjectModelStore, DiagramInfoModelStore } from 'flux-diagram';
import { FluxUser, PermissionErrorHandler } from 'flux-user';
import { InitializationSequenceController,
        CommandMapper,
        ImageLoader,
        GraphqlRestExecutionStep,
        PostMessageAPI } from 'flux-core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { take, filter, switchMap } from 'rxjs/operators';
import { EndStateSync } from './base/diagram/command/end-state-sync.cmd';
import { StaticRouteResolver } from './static.resolver';
import { RouteManager } from './system/route-manager.svc';
import { ErrorReporter } from './base/debugger/error-reporter.svc';
import { RedirectRouteResolver } from './framework/controller/redirect.resolver';
import { SendPluginCommandsExecutionStep } from './system/command/steps/exec-step-send-plugin-commands';
import { AttachContainerInfoExecutionStep } from './system/command/steps/exec-step-attach-container-info';
import { PluginAuthentication } from './plugin/plugin-authentication.svc';
import { NucleusAuthentication } from './system/nucleus-authentication';
import { PluginUserResponsibility } from './plugin/responsibility/plugin-user-responsibility.svc';
import { LoadUser } from './plugin/command/load-user.cmd';
import { PluginCommandEvent } from './plugin/command/plugin-command-event';
import { CommonUI } from './common/common-ui.module';
import { WebpackTranslateLoader } from './system/webpack-translate-loader';
import { RedirectRoutePlaceHolder } from './framework/controller/redirect.cmp';
import { parse } from 'qs';
import { v4 } from 'uuid';
import { Clipboard } from '@creately/clipboard';
import {
    TeamPortalWindow,
    TeamPortalPMReceiveEvent,
} from './base/view/team-portal-window/team-portal-window.cmp';
import { AppModalWindowContainer } from './app-modal-window-container.cmp';
import { NewDocumentResolver } from './editor/new-document.resolver';
import { AppNotAuthorized } from './system/ui/messages/app-not-authorized.cmp';
import { DiagramNavigation } from './system/diagram-navigation.svc';
import { NotFoundInRegion } from './system/ui/messages/not-found-in-region.cmp';
import { ViewportIntersectionObserver } from './system/viewport-intersection-observer.svc';
import { ScrollingModule } from '@angular/cdk/scrolling';

/**
 * This file contains the main application entry module that starts nucleus.
 * This contains the root definition of the routes which will load and create
 * the child route structure. Follow the root routes to discover the full
 * route tree.
 *
 * For each part of the application module structure has been created
 * that define components and routes. Please refer each sub folder
 * structure to understand further
 *  - Base for the viewer and editor: ./base#BaseModule
 *  - Viewer: ./viewer#ViewerModule
 *  - Editor: ./editor#EditorModule
 *  - Creator: ./creator#CreatorModule
 *
 * @author hiraash
 * @since 2017-08-02
 */
export const appRoutes: Routes = [
    { path: 'offline', component: OfflineError },
    { path: 'app-not-authorized', component: AppNotAuthorized },
    { path: 'server-error', component: ServerError },
    { path: 'not-in-region', component: NotFoundInRegion, canActivate: [ StaticRouteResolver ]},
    {
        path: 'create',
        loadChildren: /* istanbul ignore next */() => import( './creator/creator.module' ).then( m => m.CreatorModule ),
    },
    {
        path: ':id',
        loadChildren: /* istanbul ignore next */() => import( './base/base.module' ).then( m => m.BaseModule ),
    },
    {
        // filter out proton URLs that should go out.
        // used in desktop (and other app modes) where
        // proton is not packaged
        path: 'redirect/manage/recent',
        canActivate: [ RedirectRouteResolver ],
        component: RedirectRoutePlaceHolder, // May need to remove after adding a outlet component.
        pathMatch: 'full',
        data: {
            url: '/manage/recent',
            options: [
                { condition: 'env.desktop',
                 redirect: '/' },
            ],
        },
    },
    {
        path: '',
        redirectTo: '/start/dashboard',
        pathMatch: 'full',
    },
    { path: '**', component: PageNotFound, canActivate: [ StaticRouteResolver ]},
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        // Flux
        FluxCore,
        FluxStore,
        FluxCoreDirectives,
        FluxCommandService,
        FluxConnection,
        FluxUser.forRoot(),
        FluxCoreUI,
        CommonUI,
        FluxCoreDialogBoxController,
        // Router
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: false, onSameUrlNavigation: 'reload' }, // <-- debugging purposes only
        ),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
        }),
        NgIdleKeepaliveModule.forRoot(),
        ScrollingModule,
    ],
    providers: [
        PostMessageAPI,
        { provide: 'BrowserWindow', useValue: window },
        ShapeInjector,
        InitializationSequenceController,
        RouteResponsibility,
        { provide: 'RouteResponsibility', useExisting: RouteResponsibility },
        PluginUserResponsibility,
        { provide: 'PluginUserResponsibility', useExisting: PluginUserResponsibility },
        UIContextMenuController,
        FeatureList,
        { provide: 'FeatureList', useExisting: FeatureList },
        ModelSubscriptionService,
        // FIXME ModelSubscriptionService should not be provided here as it's provided by flux-connection
        // Find out why the no provider errors if coming if not provided here
        ModelSubscriptionManager,
        ImageLoader,
        SVGLoader,
        StaticRouteResolver,
        RedirectRouteResolver,
        NewDocumentResolver,
        RouteManager,
        DiagramNavigation,
        ErrorReporter,
        PluginAuthentication,
        NucleusAuthentication,
        ViewportIntersectionObserver,
        { provide: AbstractErrorReporter, useExisting: ErrorReporter },
        { provide: Clipboard, useFactory: /* istanbul ignore next */() => new Clipboard() },
    ],
    declarations: [
        App,
        PageNotFound,
        OfflineError,
        NotFoundInRegion,
        AppNotAuthorized,
        ServerError,
        RedirectRoutePlaceHolder,
        AppModalWindowContainer,
    ],
    entryComponents: [],
    bootstrap: [ App ],
})
export class AppModule {

    public static createTranslateLoader( http: HttpClient ): TranslateHttpLoader {
        return new TranslateHttpLoader( http, './assets/i18n/', '.json' );
    }

    constructor( @Inject( StateService ) protected state: StateService<any, any>,
                 protected stepMapper: CommandStepMapper,
                 shapeInjector: ShapeInjector,
                 protected dataStore: DataStore,
                 protected database: Database,
                 protected mapper: CommandMapper,
                 protected errorHandler: AppErrorHandler,
                 protected dialogBoxController: DialogBoxController,
                 protected securityErrorHandler: SecurityErrorHandler,
                 protected permissionErrorHandler: PermissionErrorHandler,
                 protected commandErrorHandler: CommandErrorHandler,
                 protected routeManager: RouteManager,
                 protected postMessage: PostMessageAPI,
                 protected authentication: NucleusAuthentication,
                 protected modalController: ModalController,
                 protected env: ContainerEnv,
                 protected router: Router,
        ) {
        // The default configuration for moment is set to avoid issues when translation is not loaded
        DateFNS.setLocale( 'en' );
        // Following will inject any exported shapes from shape projects
        // only on development environment. See the README for more information
        // about how this shape injection works and how to set it up.
        this.state.set( 'LoadingIndicatorState', { main: true });
        this.state.set( 'PlusCreateToolbarState', { show: false });
        if ( AppConfig.get( 'NAME' ) === 'dev' ) {
            shapeInjector.injectShapes();
        }
        this.initializeEmbeddedMode();
        this.registerExecutionSteps();
        this.registerDiagramInfoModelStore();
        this.registerProjectModelStore();
        this.createHomeProject();
        this.registerUnmappedCommands();
        this.registerErrorMessageHandlers();
        this.trackUser();
        this.routeManager.initialize();
        this.state.set( 'ActiveShapes', []);
        this.state.set( 'InitializedLeftSizebarPanelIds', new Set([ 'folders' ]));
        /**
         * PanZoomState state emits 'started' | 'stopped' when pan-zoom interaction
         * start/stop excluding the automatic pan happens when the selection
         * is moved/scaled beyond the viewport boundries.
         */
        this.state.initialize( 'PanZoomState', 'stopped' );

        if ( AppConfig.get( 'APP_MODE' ) === 'plugin' ) {
            // FIXME: This value is usually set by NeutrinoConnection class.
            //        Changes have been done to make sure that doesn't happen but
            //        it's possible we may have missed something.
            // FIXME: Make sure this value does not change after this is set here.
            //        One option is to add a feature to StateService to freeze states.
            this.state.set( ConnectionStatus, ConnectionStatus.ONLINE );
            PluginCommandEvent.register( this.mapper );
        } else {
            // FIXME: The connection status is updated inside app component.
            //        It was also updated inside NeutrinoConnection constructor
            //        Ideally, this should not happen in either places. Until
            //        it is fixed, initializing the connection state here.
            this.state.set( ConnectionStatus, ConnectionStatus.ONLINE );
        }
        // Listen to post messages to set the gravity token to local storage.
        // Nucleus should set the token if the running environment
        // does not support cookie. In other cases, phoenix is
        // responsible of setting the cookie using gravity-client.
        // NOTE: Commented below condition to check whether the login loop
        // has been fixed in online app as well.
        // if ( this.env.isDesktop || this.state.get( 'ApplicationIsEmbedded' )) {
        this.postMessage.recv().subscribe( msg => this.handleGravityToken( msg ));
        // }
        // Listen to post messages to open team portal when needed
        this.postMessage.recv().subscribe( msg => this.handleTeamPortalMessages( msg ));

        // set a unique sessionID for this session
        this.state.initialize( 'SessionId', Random.sessionId());

        // NOTE: Check whether gravity token is authorized
        // for the currently running app platform.
        if ( !this.isAppPlatformAuthorized()) {
            this.state.set( 'LoadingIndicatorState', { main: false });
            this.router.navigate([ '../', 'app-not-authorized' ]);
        }
    }

    /**
     * registerExecutionSteps registers all execution steps with command scenarios.
     * For all commands executed in the application, a subset of these execution steps
     * will run in the order they are registered below (according to command interfaces).
     */
    protected registerExecutionSteps() {
        // Register all command execution steps for the EXECUTE scenario.
        this.stepMapper.registerSequence( CommandScenario.EXECUTE, this.getExecuteScenarioSteps());
        // Register all command execution steps for the EXECUTE_OFFLINE scenario.
        this.stepMapper.registerSequence( CommandScenario.EXECUTE_OFFLINE, this.getExecuteScenarioStepsOffline());
        // Register all command execution steps for the PREVIEW scenario.
        this.stepMapper.registerSequence( CommandScenario.PREVIEW, [
            PrepareProxyExecutionStep,
            DataValidationExecutionStep,
            RunExecutionStep,
        ]);
        this.stepMapper.registerSequence( CommandScenario.UNDO, [
            SetDiagramIdExecutionStep,
            RunExecutionStep,
            this.getSendCommandExecutionStep(),
            ExecuteResultExecutionStep,
        ]);
        this.stepMapper.registerSequence( CommandScenario.REDO, [
            SetDiagramIdExecutionStep,
            RunExecutionStep,
            this.getSendCommandExecutionStep(),
            ExecuteResultExecutionStep,
        ]);
        // Register all command execution steps for the COLLAB scenario.
        this.stepMapper.registerSequence( CommandScenario.COLLAB, [
            ExecuteResultExecutionStep,
            ReceiveStatesExecutionStep,
        ]);
    }

    /**
     * Returns the list of execution steps for the execute scenario.
     */
    protected getExecuteScenarioSteps() {
        const steps = [
            SetDiagramIdExecutionStep,
            PrepareProxyExecutionStep,
            DataValidationExecutionStep,
            RunExecutionStep,
            SetStatesExecutionStep,
            AttachContainerInfoExecutionStep,
            this.getSendCommandExecutionStep(),
            this.getSendSyncCommandExecutionStep(),
            HttpExecutionStep,
            GraphqlRestExecutionStep,
            this.getNeutrinoRestExecutionStep(),
            ExecuteResultExecutionStep,
            this.getReceiveStatesExecutionStep(),
            StoreAfterReceiveExecutionStep,
        ];
        return steps.filter( step => !!step );
    }

    /**
     * Returns the list of execution steps for the execute offline scenario.
     */
     protected getExecuteScenarioStepsOffline() {
        const steps = [
            SetDiagramIdExecutionStep,
            PrepareProxyExecutionStep,
            DataValidationExecutionStep,
            RunExecutionStep,
            SetStatesExecutionStep,
            AttachContainerInfoExecutionStep,
            RunNetworkOfflineStep,
            ExecuteResultExecutionStep,
            this.getReceiveStatesExecutionStep(),
            StoreAfterReceiveExecutionStep,
        ];
        return steps.filter( step => !!step );
    }

    /**
     * Returns the receive states execution step.
     * This is disabled in plugin mode.
     */
    protected getReceiveStatesExecutionStep() {
        if ( AppConfig.get( 'APP_MODE' ) !== 'plugin' ) {
            return ReceiveStatesExecutionStep;
        }
    }

    /**
     * Returns the neutrino rest execution step value.
     * This is disabled in plugin mode.
     */
    protected getNeutrinoRestExecutionStep() {
        if ( AppConfig.get( 'APP_MODE' ) !== 'plugin' ) {
            return NeutrinoRestExecutionStep;
        }
    }

    /**
     * Returns the execution step to use for sending commands to the server.
     */
    protected getSendCommandExecutionStep(): typeof ExecutionStep {
        if ( AppConfig.get( 'APP_MODE' ) === 'plugin' ) {
            return SendPluginCommandsExecutionStep;
        }
        if ( AppConfig.get( 'DISABLE_WEBSOCKET_CONNECTION' )) {
            return SendHttpExecutionStep;
        }
        return SendWsExecutionStep;
    }

    /**
     * Returns the execution step to use for sending commands to the server.
     */
    protected getSendSyncCommandExecutionStep(): typeof ExecutionStep {
        if ( AppConfig.get( 'APP_MODE' ) === 'plugin' ) {
            return SendHttpSyncExecutionStep;
        }
        if ( AppConfig.get( 'DISABLE_WEBSOCKET_CONNECTION' )) {
            return SendHttpSyncExecutionStep;
        }
        return SendWsSyncExecutionStep;
    }

    protected registerDiagramInfoModelStore() {
        this.dataStore.setModelStore( DiagramInfoModel, new DiagramInfoModelStore( DiagramInfoModel, this.database ));
    }

    protected registerProjectModelStore() {
        this.dataStore.setModelStore( ProjectModel, new ProjectModelStore( ProjectModel, this.database ));
    }

    /**
     * This creates a home project in the data base if it's available
     */
    protected createHomeProject() {
        this.dataStore.findOne( ProjectModel, { id: 'home' })
            .pipe(
                take( 1 ),
                filter( model => !model ),
                switchMap(() => this.dataStore.insert( ProjectModel, { id: 'home', name: 'LABELS.HOME' })),
            ).subscribe();
    }

    /**
     * Associate current user with the event trackers we use.
     */
    protected trackUser() {
        this.state.changes( 'CurrentUser' ).pipe(
            filter( user => !!user ),
        ).subscribe( userId => {
            Tracker.setUser( userId );
            OTelManager.setUserId( userId );
        });
    }

    /**
     * Register commands which does not have an event associated with them. If the command
     * is mapped to an event, they will be registered automatically. Commands which needs
     * to be registered are exceptions.
     */
    protected registerUnmappedCommands() {
        // FIXME: SubStoreModels is not detected as typeof AbstractCommand ???
        this.mapper.registerCommand( SubStoreModels as any );
        this.mapper.registerCommand( SubRemoveChanges as any );
        this.mapper.registerCommand( EndStateSync as any );
        this.mapper.registerCommand( LoadUser as any );
    }

    /**
     * Registers Error message handlers to AppErrorHandler. Errors which are captured by
     * the AppErrorHandler will treated differently by registered the handlers.
     */
    protected registerErrorMessageHandlers() {
        this.errorHandler.addErrorMessageHandler( this.dialogBoxController );
        this.errorHandler.addErrorMessageHandler( this.securityErrorHandler );
        this.errorHandler.addErrorMessageHandler( this.permissionErrorHandler );
        this.errorHandler.addErrorMessageHandler( this.commandErrorHandler );
    }

    /**
     * This method will check whether the gravity token has the valid claim
     * for the given app platform / currently running kind of the Creately app.
     */
    private isAppPlatformAuthorized() {
        // NOTE: Plugin mode uses a different way of authentication. Hence
        // ignoring for this check.
        if ( this.env.getCurrentPlatform === AppPlatform.PLUGIN ) {
            return true;
        }
        return this.authentication.isAppPlatformAuthorized( this.env.getCurrentPlatform );
    }

    /**
     * This function will send the cookie to gravity client.
     * Applicable for Desktop app and Safari iFrame.
     */
    private handleGravityToken( msg: any ): void {
        if ( !msg || typeof msg !== 'object' ) {
            return;
        }
        const { event, data } = msg as any;
        // NOTE: Token will be sent by phoenix always
        if ( event === 'phoenix:gravityToken' && data && data.token ) {
            this.setUserToken( data.token );
        }
    }

    /**
     * Emit the "document:load" message when Nucleus PostMessageAPI is ready to
     * receive messages from the parent window. Setup all listeners for incoming
     * messages before sending this message.
     */
    private initializeEmbeddedMode() {
        const embedded = this.getWindow().self !== this.getWindow().parent;
        this.state.set( 'ApplicationIsEmbedded', embedded );
        if ( !embedded ) {
            return;
        }
        const hashStr = this.getWindow().location.hash.slice( 1 );
        const hashAuth = parse( hashStr ).auth;
        // FIXME: temporary patch for init sequence or the router going nuts
        //        it keeps logging errors and eventually the browser crashes
        // NOTE: do not add unit test to cover this line, it is only a patch
        this.getWindow().location.hash = '';
        if ( hashAuth && typeof hashAuth === 'string' ) {
            this.setUserToken( hashAuth );
            this.state.set( 'EmbeddedAppAuthenticated', this.authentication.isAuthenticated );
        }
        this.postMessage.recv().subscribe( msg => this.handleUserMessages( msg ));

        this.postMessage.sendToParent( 'document:load', { documentId: this.state.get( 'CurrentDiagram' ) });
    }

    /**
     * handle incoming post messages
     */
    private handleUserMessages( msg: any ): void {
        if ( !msg || typeof msg !== 'object' ) {
            return;
        }
        const { event, data } = msg as any;
        if ( event === 'user:setToken' ) {
            this.handleSetUserToken( data );
        } else if ( event === 'user:setRefreshToken' ) {
            this.handleSetUserRefreshToken( data );
        } else if ( event === 'app:enablePluginOpts' ) {
            this.handleSetPluginOptions( data );
        } else if ( event === 'app:enableLeftSidebar' ) {
            this.handleLeftSidebarOptions( data );
        } else if ( event === 'use:template' && data && data.templateId ) {
            this.state.set( 'ToggleTemplatesModal', true );
        } else if ( event === 'use:context' && data ) {
            this.state.set( 'TemplateContext', { _id: v4(), id: data.contextId,
                containerId: data.containerId, type: data.diagramType });
        }
    }

    /**
     * Handles set auth token events sent from parent window
     */
    private handleSetUserToken( data: any ): void {
        this.setUserToken( data.token );
        this.state.set( 'EmbeddedAppAuthenticated', this.authentication.isAuthenticated );
        this.postMessage.sendToParent( PostMessageSendEventType.documentInit, {});
    }

    /**
     * Handles set auth refresh token events sent from parent window
     */
    private handleSetUserRefreshToken( data: any ): void {
        ( window.gravity.api as any ).setGravityRefreshTokenCookie( data );
    }

    /**
     * Handles set plugin options
     */
    private handleSetPluginOptions( data: any ): void {
        this.state.set( 'ShowAppCloseButton', true );
        this.state.set( 'PluginApp', data.pluginApp );
        this.state.set( 'EmbedClient', data?.clientType || 'web' );
        if ( data.templateSelect ) {
            this.state.set( 'SelectedFloatingPanel', 'template' );
        }
    }

    /**
     * Handles set plugin options
     */
    private handleLeftSidebarOptions( data: any ): void {
        this.state.set( 'AvailablePanels', data.showPanels || []);
        this.state.set( 'InitLeftSidePanel', data.selectPanel || 'folders' );
    }

    /**
     * Opens team portal window when receiving the open team portal message.
     * @param msg the message to check.
     */
    private handleTeamPortalMessages( msg: any ): void {
        if ( !msg || typeof msg !== 'object' ) {
            return;
        }
        const { event } = msg as any;
        if ( event === TeamPortalPMReceiveEvent.Open ) {
            this.modalController.show( TeamPortalWindow );
        }
    }

    /**
     * Authenticates the app with given token
     */
    private setUserToken( token: string ): void {
        if ( this.authentication.isAuthenticated ) {
            // TODO: enable this part after fixing gravity client issue
            // if ( this.authentication.isSameUserToken( token )) {
            //     // NOTE: no need to do anything if the user is the same
            //     return;
            // }
            if ( this.authentication.token === token ) {
                // NOTE: no need to do anything if the token is the same
                return;
            }
            // NOTE: logout if a different user token is received
            this.authentication.logOut();
        }
        this.authentication.token = token;
    }

    /**
     * Returns the current window.
     */
    private getWindow() {
        return window;
    }
}
