import { Logger } from 'libs/flux-core/src/logger/logger.svc';
import { isEqual } from 'lodash';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { IConnectorPoint } from 'flux-diagram-composer';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';
import { AbstractDiagramChangeCommand } from '../../diagram/command/abstract-diagram-change-command.cmd';
import { PathingService } from '../../diagram/pathing/pathing.svc';

/**
 * EditConnectorPoint
 * Applies transformations (scale, translate, rotate) on a given set of shapes.
 *
 * FIXME: fix on locator, when transform values are unset it should fallback to
 *        default values. This is not done for certain fields for performance.
 *        Because of this, if user Undo's after insert+transform the shape hides.
 *
 */
@Injectable()
@Command()
export class EditConnectorPoint extends AbstractDiagramChangeCommand {
    /**
     * Command input data format
     */
    public data: {
        changes: {
            [shapeId: string]: IConnectorPoint[],
        },
    };

    /**
     * Inject pathing service.
     */
    constructor(
        protected ds: DiagramChangeService,
        protected pathingSvc: PathingService ) {
        super( ds ) /* istanbul ignore next */;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public prepareData(): void {
        for ( const shapeId in this.data.changes ) {
            const connector = this.changeModel.shapes[shapeId] as ConnectorModel;
            // FIXME: Remove this check when selection blocker is fixed
            if ( !connector ) {
                return;
            }
            const points = this.data.changes[shapeId];
            // FIXME: the pathing service should read the change model for data
            try {
                const newPoints = this.pathingSvc.repath( shapeId, this.changeModel, points, true );
                const newPath = ConnectorModel.createPathFromPoints( newPoints );
                if ( !isEqual( connector.path, newPath )) {
                    connector.path = newPath;
                }
            } catch ( e ) {
                Logger.warning( 'Connector repathing failed due to', e );
            }
        }
    }
}

Object.defineProperty( EditConnectorPoint, 'name', {
    value: 'EditConnectorPoint',
});
