import { EMPTY, Observable, from, of } from 'rxjs';
import { take, switchMap, map } from 'rxjs/operators';
import { ModelStore } from 'flux-store';
import { CollabModel, CollaboratorType } from '../collab/model/collaborator.mdl';
import { PrivacyModel } from '../model/privacy.mdl';
import { GroupShareModel } from '../group-share/model/group-share.mdl';

/**
 * ResourceModelStore
 * ResourceModelStore class is responsible for performing storage related
 * operations for any resource type (i,e typeof DiagramInfoModel or ProjectModel). DataStore service
 * will create instances of this class for this model type.
 *
 * For more details see the {@link ModelStores}
 *
 * @author: Ramishka
 * @since: 2019-06-21
 */
export class ResourceModelStore extends ModelStore {
    /**
     * Returns the collabs found for the given resource id
     */
    public getCollabs( resourceId: string ): Observable<CollabModel[]> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.collabs ) {
                    throw new Error( 'The collabs field is missing on the resource model' );
                }
                return of( model.collabs );
            }),
        );
    }
    /**
     * Returns the collabs found for the given resource id
     */
    public getGroupShare( resourceId: string ): Observable<GroupShareModel[]> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.groupShare ) {
                    throw new Error( 'The groupShare field is missing on the resource model' );
                }
                return of( model.groupShare );
            }),
        );
    }

    /**
     * Returns the privacy found for the given resource id
     */
    public getPrivacy( resourceId: string ): Observable<PrivacyModel> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.privacy ) {
                    throw new Error( 'The privacy field is missing on the resource model' );
                }
                return of( model.privacy );
            }),
        );
    }

    public getAllTeamShare( resourceId: string ): Observable<PrivacyModel> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            map( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.teamShare ) {
                    throw new Error( 'The privacy field is missing on the resource model' );
                }
                return model.teamShare;
            }),
        );
    }

    /**
     * Merges the list of collaborators available in the storage with a list
     * of new collaborators.
     */
    public storageMergeCollabs( resourceId: string, collaborators: any[]): Observable<any> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.collabs ) {
                    throw new Error( 'The collabs field is missing on the resource model' );
                }
                const collabs = this.mergeCollabs( model.collabs, collaborators );
                return from( this.updateStatic( query, { $set: { collabs: collabs }}));
            }),
        );
    }

    /**
     * Merges the list of groupShare available in the storage with a list
     * of new group shares.
     */
    public storageMergeGroupShares( resourceId: string, groupShares: any[]): Observable<any> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.groupShare ) {
                    model.groupShare = [];
                }
                const groupShare = this.mergeGroupShares( model.groupShare, groupShares );
                return from( this.updateStatic( query, { $set: { groupShare }}));
            }),
        );
    }

    /**
     * Replace the owner with new owner
     */
    public replaceOwner( resourceId: string, newOwnerJson: Object ): Observable<any> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.collabs ) {
                    throw new Error( 'The collabs field is missing on the resource model' );
                }
                const collabs = model.collabs;
                const owner = collabs.find( c => c.role === 0 );
                Object.assign( owner, newOwnerJson );
                return from( this.updateStatic( query, { $set: { collabs: collabs }}));
            }),
        );
    }

    /**
     * Removes matching collaborators from the list of collaborators available in the
     * storage.
     */
    public storageFilterCollabs( resourceId: string, removeBy: Function ): Observable<any> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.collabs ) {
                    throw new Error( 'The collabs field is missing on the resource model' );
                }
                const collabs = model.collabs.filter( c => c.role === 0 || !removeBy( c ));
                return from( this.updateStatic( query, { $set: { collabs: collabs }}));
            }),
        );
    }

    /**
     * Removes matching groupShare from the list of group shares available in the
     * storage.
     */
    public storageFilterGroupShare( resourceId: string, removeBy: Function ): Observable<any> {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.groupShare ) {
                    throw new Error( 'The groupShare field is missing on the resource model' );
                }
                const groupShare = model.groupShare.filter( c => !removeBy( c ));
                return from( this.updateStatic( query, { $set: { groupShare }}));
            }),
        );
    }

    /**
     * Updates the privacy for the given resource
     */
    public storgaeUpdatePrivacy( resourceId: string, privacy: any ) {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.privacy ) {
                    throw new Error( 'The privacy field is missing on the resource model' );
                }
                return from( this.updateStatic( query, { $set: { privacy: privacy }}));
            }),
        );
    }

    /**
     * Updates the privacy for the given resource
     */
    public storageUpdateTeamShare( resourceId: string, teamShareId: string,
                                   teamId: string, role: CollaboratorType, discoverable: boolean, hash?: string ) {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.teamShare ) {
                    throw new Error( 'The privacy field is missing on the resource model' );
                }
                let teamShare = model.teamShare.find( team => team.id === teamShareId );
                if ( teamShare ) {
                    teamShare.role = role;
                    teamShare.teamId = teamId;
                    teamShare.discoverable = discoverable;
                    if ( !!hash ) {
                        teamShare.hash = hash;
                    } else {
                        delete teamShare.hash;
                    }
                } else {
                    teamShare = { id: teamShareId, teamId, role, discoverable, hash };
                    model.teamShare.push( teamShare );
                }
                return from( this.updateStatic( query, { $set: { teamShare: model.teamShare }}));
            }),
        );
    }

    /**
     * Updates the privacy for the given resource
     */
    public storageRemoveTeamShare( resourceId: string, teamShareId: string ) {
        const query = { id: resourceId };
        return this.staticCollection.findOne( query ).pipe(
            take( 1 ),
            switchMap( model => {
                if ( !model ) {
                    return EMPTY;
                }
                if ( !model.teamShare ) {
                    throw new Error( 'The privacy field is missing on the resource model' );
                }
                const teamShares = model.teamShare.filter( teamShare => teamShare.id !== teamShareId );
                return from( this.updateStatic( query, { $set: { teamShare: teamShares }}));
            }),
        );
    }

    /**
     * Merges the new collaboraors with the existing collabs by id or email.
     * Returns an array of all collaborators.
     */
    private mergeCollabs( oldCollabs: CollabModel[], newCollabs: CollabModel[]): CollabModel[] {
        // start with all existing collabs
        const merged = oldCollabs.slice();
        for ( let i = 0; i < newCollabs.length; i++ ) {
            const collab = newCollabs[i];
            const index = merged.findIndex( c => (
                c.id === collab.id ||
                ( c.email !== undefined && c.email === collab.email )
            ));
            if ( index === -1 ) {
                merged.push( collab );
                continue;
            }
            // This is to check whether the collaborator already exists or not.
            // If exists, replace the collaborator with the new collaborator.
            if ( merged[index].role !== 0 ) {
                merged[index] = collab;
            }
        }
        return merged;
    }

    /**
     * Merges the new collaboraors with the existing collabs by id or email.
     * Returns an array of all collaborators.
     */
    private mergeGroupShares( oldGroupShares: GroupShareModel[], newGroupShares: GroupShareModel[]): GroupShareModel[] {
        // start with all existing collabs
        const merged = oldGroupShares.slice();
        for ( let i = 0; i < newGroupShares.length; i++ ) {
            const collab = newGroupShares[i];
            const index = merged.findIndex( c => (
                c.id === collab.id
            ));
            if ( index === -1 ) {
                merged.push( collab );
            } else {
                merged[index] = collab;
            }
        }
        return merged;
    }
}
