import { DisplayObject } from '@creately/createjs-module';
import { isEmpty } from 'lodash';
import { Injectable } from '@angular/core';
import { tap, map, switchMap } from 'rxjs/operators';
import { NEVER, of } from 'rxjs';
import { Command, StateService, CommandInterfaces, CommandScenario, ImageLoader, NotifierController } from 'flux-core';
import { RetinaStage } from '../../../framework/easeljs/retina-stage';
import { DiagramLocatorLocator } from '../locator/diagram-locator-locator';
import { AbstractDiagramExportCommand } from './abstract-diagram-export-command.cmd';
import { TranslateService } from '@ngx-translate/core';

/**
 * Command to export diagram as a JPEG image.
 *
 * Note: Current implementation is done using the "cache" feature of the EaselJS.Stage.
 * Functionality follows the folowing sequence
 * - cache the diagram as an image using the diagram bounds
 * - exctract the cached image data url
 * - uncache the diagram.
 * Uncaching is importent to keep the diagraming functionality working and must be done ASAP.
 * Any delay to uncache will cause the diagram area not to render changes to the diagram.
 */
@Injectable()
@Command()
export class ExportAsJpeg extends AbstractDiagramExportCommand {

    /**
     * Defaults
     */
    public static get implements(): Array<CommandInterfaces> {
        return [ 'ICommand' ];
    }
    /**
     * Diagram locator locator is used to get the diagram model and state service to access RetinaStage
     * @param ll    DiagramLocatorLocator
     * @param state StateService
     */
    constructor(
        ll: DiagramLocatorLocator,
        protected state: StateService<any, any>,
        resources: ImageLoader,
        nc: NotifierController,
        translate: TranslateService,
    ) {
        super( ll, resources, nc , translate, state )/* istanbul ignore next */;
    }

    /**
     * Prepare the jpeg image data to be exported.
     */
    public execute() {
        const locator = this.ll.forCurrent( this.eventData.scenario === CommandScenario.PREVIEW );
        locator.getDiagramOnce().pipe(
            switchMap( diagram => {
                const bounds = this.state.get( 'Selected' ).length === 0
                            ? diagram.getBounds() : diagram.getBounds( this.state.get( 'Selected' ));
                const width = bounds.width + 40;
                const height = bounds.height + 40;
                if ( this.isDiagramExceededMaxClientExport( width, height )) {
                    return this.fetchDiagramFromServer( 'jpg', width * 2 ).pipe(
                        map( blob => ({ name: diagram.name, blob })),
                    );
                } else {
                    const selectedShapes = this.state.get( 'Selected' );
                    const shapeViews = this.state.get( 'ShapeViews' ) as DisplayObject[];
                    const stageCanvas = this.state.get( 'DiagramCanvas' ) as RetinaStage;
                    // Add all shape views to the canvas prior to exporting
                    stageCanvas.removeAllChildren();
                    shapeViews
                        .filter( sv =>  isEmpty( selectedShapes ) ? true : selectedShapes.includes( sv.name ))
                        .forEach( sv =>  {
                            sv.visible = true;
                            stageCanvas.addChild( sv );
                        });
                    stageCanvas.cache( bounds.x - 20, bounds.y - 20, width, height, 2 );
                    const cachedCanvas = ( stageCanvas.cacheCanvas as HTMLCanvasElement );
                    const clonedCanvas = cachedCanvas.cloneNode( true ) as HTMLCanvasElement;
                    const cloneContext = clonedCanvas.getContext( '2d' );
                    cloneContext.fillStyle = '#FFF';
                    cloneContext.fillRect( 0, 0, clonedCanvas.width, clonedCanvas.height );
                    cloneContext.drawImage( cachedCanvas, 0, 0 );
                    const jpegData = clonedCanvas.toDataURL( 'image/jpg', 1 );
                    if ( jpegData.length < 10 ) {
                        this.showExportLimitExceededNotification();
                        return NEVER;
                    }
                    stageCanvas.uncache();

                    const activeShapes = this.state.get( 'ActiveShapes' );
                    stageCanvas.removeAllChildren();
                    shapeViews
                        .filter( sv =>  activeShapes.includes( sv.name ))
                        .forEach( sv =>  {
                            sv.visible = true;
                            stageCanvas.addChild( sv );
                        });
                    stageCanvas.update();
                    return of({ name: diagram.name, blob: jpegData });
                }
            }),
            tap(( data: any ) => {
                this.data = { content: data.blob, name: data.name + '.jpg', type: 'image/jpg', format: 'blob' };
            }),
            switchMap(() => super.execute()),
        ).subscribe({
            complete: () => this.hideExportNotification(),
            error: () => {
                this.hideExportNotification();
                this.showErrorNotification();
            },
        });
        return of({});
    }
}

Object.defineProperty( ExportAsJpeg, 'name', {
    value: 'ExportAsJpeg',
});
