import { BehaviorSubject, of, Subject } from 'rxjs';
import { EDataLocatorLocator } from './locator/edata-locator-locator';
import { DataType, EDataType, ShapeType } from 'flux-definition';
import { Injectable } from '@angular/core';
import { StateService, TagMap } from 'flux-core';
import { IEDataDef, IEntityDef } from 'flux-definition';
import { find, isEmpty, isEqual } from 'lodash';
import { filter, take, mapTo } from 'rxjs/operators';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
    // tslint:disable:member-ordering
/**
 * This registry keeps track of all the loaded EDataDefs in the client.
 * When shapes are added to the canvas, if they map to an Entity Type and an EDataDef
 * is registered (ie. was loaded by the user with intent to use as a model), this registry
 * can be used to figure out if the added shape to the canvas has a corresponding EDataModel which
 * has a matching EntityModel.
 */
@Injectable()
export class EDataRegistry {

    /**
     * def id for the custom edata model definition
     */
    public static customEdataDefId = 'creately.edata.custom';

    public static get instance(): EDataRegistry {
        return EDataRegistry._instance;
    }
    /**
     * Holds the singleton instance of EDataRegistry, enabling it to be used as a utility
     * class as well as a service. Should not be used until the app is initialized completely.
     */
    private static _instance = null;

    /**
     * indicator that the service is ready or not.
     */
    public initialized = new BehaviorSubject([]);

    /**
     * A map of handshakes with associated connector definitions.
     */
    private data = new TagMap<{ id: string, def: IEntityDef}>();


    /**
     * A map of eData definition ids and definition objects
     */
    private defs: { [ defId: string ]: IEDataDef } = {};

    /**
     * entity type definition indexed by eDefId and defId
     */
     private defsById: { [ eDefId: string ]: { [ defId: string ]: IEntityDef } } = {};


    /**
     * Extensions to current loaded definitions.
     * Extensions are scoped to project level as 1 type has only 1 DB per folder.
     *
     * A partial of the IEntityDef is the type.
     */
    private extensions: {[ projectId: string ]: { [ defId: string ]: any }} = {};

    private eDataIdByDefId: {[ projectId: string ]: { [ defId: string ]: string }} = {};

    public static getEDataId( defId: string ) {
        return EDataRegistry.instance.getEDataId( defId );
    }

    /**
     * Emit the defId when the defs are updated, this is usefull to
     * wait until the registry is updated rather than adding delays.
     */
    private customEntityDefsUpdatedSubject: Subject<string>;

    /**
     * Creates the singleton ConnectorRegistry instance.
     */
    constructor(
        protected ell: EDataLocatorLocator,
        protected state: StateService<any, any>,
    ) {
        if ( EDataRegistry._instance ) {
            return EDataRegistry._instance;
        }
        this.customEntityDefsUpdatedSubject = new Subject();
        EDataRegistry._instance = this;
        this.registerCustomEdata();
        this.updateRegistry();
    }

    public getEDataId( defId: string ) {
        const projId = this.state.get( 'CurrentProject' );
        return this.eDataIdByDefId[ projId ] && this.eDataIdByDefId[ projId ][defId];
    }

    /**
     * Registers the blueprint for the custom edata models
     */
    protected registerCustomEdata() {
        const custom = {
            defId: EDataRegistry.customEdataDefId,
            version: 1,
            isCustom: true,
            type: ShapeType.EData,
            isSearchable: false,
            sourceType: EDataType.DATABASE,
            name: 'Custom Database',
            entityDefs: {},
        };
        this.register( custom );
    }

    /**
     * Updates EDataRegistry when an entity blueprints are updated
     */
    protected updateRegistry() {
        this.ell.currentEDataModels( true ).subscribe( models => {
            const projId = this.state.get( 'CurrentProject' );
            models.forEach( model => {
                if ( !isEmpty( model.customEntityDefs ) && this.defs[ model.defId ]) {
                    const edataDef = this.defs[ model.defId ];
                    if ( model.isCustom ) {
                        // Re-register
                        Object.keys( model.customEntityDefs ).forEach( key => {
                            const currentEntityDef = edataDef.entityDefs[ key ];
                            const newEntityDef = model.customEntityDefs[ key ];
                            if ( currentEntityDef && !isEqual( newEntityDef, currentEntityDef )) {
                                Object.assign( currentEntityDef, newEntityDef );
                                this.customEntityDefsUpdatedSubject.next( key );
                            } else {
                                edataDef.entityDefs[ key ] = newEntityDef as any;
                                this.customEntityDefsUpdatedSubject.next( key );
                            }
                        });
                        this.register( edataDef );
                    } else {
                        if ( !this.extensions[ projId ]) {
                            this.extensions[ projId ] = {};
                        }
                        const extList = this.extensions[ projId ];
                        Object.keys( model.customEntityDefs ).forEach( key => {
                            if ( !extList[ key ]) {
                                extList [ key ] = Object.assign({}, model.customEntityDefs[ key ]) as any;
                            } else if ( !isEqual( extList [ key ], model.customEntityDefs[ key ])) {
                                Object.assign( extList [ key ], model.customEntityDefs[ key ]);
                            }
                        });
                    }
                }
                if ( !model.isCustom ) {
                    if ( !this.eDataIdByDefId[ projId ]) {
                        this.eDataIdByDefId[ projId ] = {};
                    }
                    this.eDataIdByDefId[ projId ][model.defId] = model.id;
                }
            });
            this.initialized.next( models );
        });
    }

    /**
     * Registers all entity definition
     */
    public register( def: IEDataDef ): void {
        if ( !this.defs[def.defId]) {
            this.defs[def.defId] = def;
            if ( def.entityDefs ) {
                Object.keys( def.entityDefs ).forEach( key => {
                    const entityDef = def.entityDefs[key];
                    this.fixDescriptionDataItem( entityDef );
                    // create the mapping from shapes to entities.
                    const shapeDefIds = Object.keys( entityDef.shapeDefs );
                    this.data.set( shapeDefIds, { id: def.defId, def: entityDef });
                    if ( !this.defsById[key]) {
                        this.defsById[key] = {
                            [def.defId]: entityDef,
                        };
                    } else {
                        this.defsById[key][def.defId] = entityDef;
                    }
                });
            }
        }
    }

    /**
     * Emits when the entityDefId in the custom entity data is updated in the registry
     * @param defId
     * @returns Observable
     */
    public customEntityDefUpdated( entityDefId: string ) {
        if ( this.defs[EDataRegistry.customEdataDefId]?.entityDefs[ entityDefId ]) {
            return of( true );
        }
        return this.customEntityDefsUpdatedSubject.pipe(
            filter( v => v === entityDefId ),
            take( 1 ),
            mapTo( true ),
        );
    }

    /**
     * Returns the EDataDef for the ID or defId.
     */
    public getEDataDef( defId: string ): IEDataDef {
        return this.defs[defId];
    }

    /**
     * When you give the shapeDef and the eDataDef to get a matchin entity def
     * @param shapeDefId
     * @param eDataDefId
     */
    public getEntityDefByShapeId ( shapeDefId: string, eDataDefId?: string ): IEntityDef {
        const result = this.data.get([ shapeDefId ]);
        if ( !eDataDefId ) { // when you are populating by data this can be undefined
            if ( result && result[0]) {
                return this.mergedEntityDef( result[0].def );
            }
        } else {
            const ret = find( result, res => res.id === eDataDefId );
            if ( ret ) {
                return this.mergedEntityDef( ret.def );
            }
        }
    }

    /**
     * Gets an entity Def from the eDataDef
     * @param entityDefId
     * @param eDataDefId eData defId or eData id
     */
    public getEntityDefById( entityDefId: string, eDataDefId: string ): IEntityDef {
        const eDef = this.getEDataDef( eDataDefId );
        if ( eDef ) {
            return this.mergedEntityDef( eDef.entityDefs[entityDefId]);
        }
    }

    /**
     * Returns the first [IEDataDef ID , IEntityDef] with given handshake.
     */
    public searchEntityDef( shapeDefIds: string[]): { id: string, def: IEntityDef} {
        const result = this.data.get( shapeDefIds );
        if ( result && result[0]) {
            return { ...result[0], def: this.mergedEntityDef( result[0].def ) };
        }
        return;
    }

    /**
     * Gets an entity Def from the eDataDef
     * @param entityDefId
     * @param eDataDefId
     */
    public findEntityDefById( entityDefId: string, eDataDefId?: string ): IEntityDef {
        const eDefs = this.defsById[entityDefId];
        if ( eDefs ) {
            return eDataDefId ? eDefs[eDataDefId] : Object.values( eDefs )[0];
        }
    }

    public findEntityDefId( shapeDefId: string, eDataDefId: string ) {
        const entityDefs = this.defs[eDataDefId].entityDefs;
        return Object.keys( entityDefs ).find( key => entityDefs[key].shapeDefs.hasOwnProperty( shapeDefId ));
    }

    /**
     * Returns a def that includes extensions
     * @param modelId
     * @param entityDef
     * @returns
     */
    protected mergedEntityDef ( entityDef: IEntityDef ): IEntityDef {
        const projId = this.state.get( 'CurrentProject' );
        if ( entityDef && this.extensions[ projId ] && this.extensions[ projId ][entityDef.id ]) {
            const dataItems = Object.assign({}, entityDef.dataItems || {},
                this.extensions[ projId ][entityDef.id ].dataItems );
            return Object.assign({}, entityDef, this.extensions[ projId ][entityDef.id ], { dataItems });
        }
        return entityDef;
    }

    /**
     * This converts the description data item to html
     * this method mutates the entityDef
     * Something similar to `ShapeModelFactory::setDescriptionDataItem`
     * @param entityDef entity def
     */
    private fixDescriptionDataItem( entityDef: IEntityDef ) {
        const dataItems = entityDef.dataItems || {};
        for ( const dataItemId in dataItems ) {
            const dataItem = dataItems[dataItemId];
            if ( dataItem.type === DataType.STRING_HTML && dataItem.default?.ops ) {
                dataItem.default = ( new QuillDeltaToHtmlConverter( dataItem.default.ops )).convert();
            }
        }
    }
}
