import { UserLocator } from '../user/user-locator.svc';
import { UserPermModel } from './model/user-perm.mdl';
import { map, filter, switchMap } from 'rxjs/operators';
import { GeneralPlanPermHandler, IPlanPermHandler } from './handler/general-plan-perm-handler';
import { Injectable, Injector } from '@angular/core';
import { Tracker } from 'flux-core';
import { Observable, of } from 'rxjs';
import { PlanPermission } from 'flux-definition';

/**
 * Types of user plans.
 */
export enum PlanType {
    Public = 'PUBLIC',
    Demo = 'DEMO',
    Lite = 'LITE',
    Enterprise = 'ENTERPRISE',
}

/**
 * A Plan permission manager which has user's permissions and helps to check the
 * permission availability of the user for user's plan.
 */

@Injectable()
export class PlanPermManager {

    /**
     * A General permission check handler.
     */
    public static GENERAL_PERM_HANDLER: string = 'GeneralPermHandler';

    /**
     * Id of the user's plan.
     */
    protected planId: string;

    /**
     * Type of the user's plan.
     */
    protected planType: string;

    /**
     * User's plan permissions.
     */
    protected userPerms: {[ permission: string]: UserPermModel };

    /**
     * Handlers to check user's plan.
     */
    protected permHandlers: {[ permission: string ]: IPlanPermHandler };

    constructor( protected userLocator: UserLocator,
                 protected injector: Injector ) {
        userLocator.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( user => !!user && !!user.plan ),
            map( user => user.plan ),
        ).subscribe( plan => {
            this.planId = plan.id;
            this.planType = plan.type;
            this.userPerms = plan.permissions;
            Tracker.setGlobalProperty( 'plan', this.planType );
        });
        this.initPermissionHandlers();
    }

    /**
     * Returns true if the user plan is free.
     */
    public isFreeUser(): Observable<boolean> {
        return this.userLocator.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( user => !!user && !!user.plan ),
            map( user =>
                user.plan.type.startsWith( PlanType.Public )),
        );
    }

    /**
     * Returns true if the user plan is team plan.
     * We are checking here plan id insted plan type
     * since team plan can be bublic or team plan both types
     */
    public isTeamUser(): Observable<boolean> {
        return this.userLocator.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( user => !!user && !!user.plan ),
            map( user => !!user.team?.id ),
        );
    }

    /**
     * Returns true if the user plan is demo.
     */
    public isDemoUser(): Observable<boolean> {
        return this.userLocator.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( user => !!user && !!user.plan ),
            map( user =>
                user.plan.type.startsWith( PlanType.Demo )),
        );
    }

    /**
     * Returns true if the user plan is Lite.
     */
     public isLiteUser(): Observable<boolean> {
        return this.userLocator.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( user => !!user && !!user.plan ),
            map( user =>
                user.plan.type.startsWith( PlanType.Lite )),
        );
    }

    /**
     * This function checks for the user plan is free or demo.
     * Returns true if the user plan belongs to either free or
     * demo.
     */
    public isFreeOrDemoUser(): Observable<boolean> {
        return this.isFreeUser().pipe(
            switchMap( isFreeUser => {
                if ( !isFreeUser ) {
                    return this.isDemoUser();
                } else {
                    return of( true );
                }
            }),
        );
    }

    /**
     * Check all given permissions one by one. Checks are done by calling the check
     * method of corresponding permission handler's check method. If there is no
     * specific handler defined in the map then it uses the generic handler to check
     * the permission. If one of the permission is not allowed then immediately
     * returns false without checking rest of the permissions in given array.
     * @param permNames the permissions to check
     * @param permData Any supporting data to evaluate the permission.( Optional )
     */
    public check( permNames: string[], permData?: any ) {
        if ( !this.userPerms ) {
            return false;
        }
        for ( const permName of permNames ) {
            if ( permName.includes( '|' )) {
                // checks at least one of the permission is available
                if ( !permName.split( '|' ).some( perm => this.check([ perm ]))) {
                    return false;
                }
                continue;
            }
            let handler: IPlanPermHandler = this.permHandlers[ permName ];
            if ( !handler ) {
                handler = this.permHandlers[ PlanPermManager.GENERAL_PERM_HANDLER ];
            }
            if ( !this.userPerms[ permName ] || !handler.check( this.userPerms[ permName ], permData )) {
                return false;
            }
        }
        return true;
    }

    /**
     * Gets the max usage for the given permission.
     * @param perm the permission to get the max usage for
     */
    public getMaxUsage( perm: PlanPermission ): number {
        return this.userPerms[perm] && this.userPerms[perm].maxUsage || 0;
    }

    /**
     * This method register the permission handlers.
     * @param handlerName - name of the handler
     * @param handler - handler instance
     */
    public registerHandler( handlerName: PlanPermission, handler: IPlanPermHandler ) {
        this.permHandlers[ handlerName ] = handler;
    }

    /**
     * Initializes permission -> handler map.
     */
    protected initPermissionHandlers() {
        this.permHandlers = {};
        this.permHandlers[ PlanPermManager.GENERAL_PERM_HANDLER ] = this.injector.get( GeneralPlanPermHandler );
    }
}
