import { DiagramToViewportCoordinate } from './../../../base/coordinate/diagram-to-viewport-coordinate.svc';
import { sortBy, find, maxBy } from 'lodash';
import { ConnectorModel } from './../../../base/shape/model/connector.mdl';
import { ShapeModel } from './../../../base/shape/model/shape.mdl';
import { AbstractShapeModel } from 'flux-diagram-composer';
import { Injectable } from '@angular/core';
import { Command, CommandScenario, Line, Point, Rectangle } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, merge, Observable, of } from 'rxjs';
import { EntityModel } from '../../../base/edata/model/entity.mdl';
import { EDataModel } from '../../../base/edata/model/edata.mdl';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { IShapeDefinition } from 'flux-definition';
import { SmartContainerService } from '../containers/smart-container.svc';
import { ShapeBoundsLocator } from '../containers/shape-bounds-locator';

/**
 * ChangeContainerData
 * This will change the contaner and child data
 */
@Injectable()
@Command()
export class ChangeContainerData extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {

        /**
         * shape ids to check if added to a container or not
         */
        shapeIds?: string[],

        /**
         * To convert a shape into a container or vice versa
         */
        containerData?: {[shapeId: string]: {
            /**
             * container shape id
             */
            isContainer?: boolean,
        }},

        /**
         * To add / remove shapes into contaienrs
         */
        childrenData?: { [shapeId: string]: {
            /**
             * Expand the container if the children are outside of bounds.
             * Useful for plus create inside containers.
             */
            expand: boolean;
            /**
             * To specify whether a child shape entered or left a container
             */
            action?: 'enter' | 'leave',
            /**
             * container shape id
             */
            containerId?: string,
            /**
             * To force fully add shape in to container that now top of the
             * container.
             */
            force?: true,
        }},
    };

    constructor(
        protected ds: DiagramChangeService,
        protected defLocator: DefinitionLocator,
        protected ell: EDataLocatorLocator,
        protected smartContainerSvc: SmartContainerService,
        protected shapeBoundsLocator: ShapeBoundsLocator,
        protected dToV: DiagramToViewportCoordinate,
        ) {
        super( ds )/* istanbul ignore next */;
    }

    public prepareData(): any {
        if ( this.eventData.scenario === CommandScenario.PREVIEW ) {
            return true;
        }
        const commandData = {} as any;
        if ( this.data.shapeIds ) {
            const movingShapes = this.data.shapeIds.map( shapeId => this.changeModel.shapes[ shapeId ]);
            const shapes =  movingShapes.filter(( shape: AbstractShapeModel ) => shape && !shape.isConnector());
            const allContainers = this.changeModel.getAllContainers();
            if ( allContainers.length === 0 ) {
                return true;
            }
            for ( let index = 0; index < shapes.length; index++ ) {
                const shape = shapes[index] as ShapeModel;
                if ( this.data.shapeIds.includes( shape.containerId )) { // Select all and move scenario
                    break;
                }
                const b =  shape.bounds;
                const exclude = new Set( this.data.shapeIds );
                if ( !shape.isSticker ) {
                    const supported = shape
                        .getSupportedContainers( allContainers ).map( s => s.id );
                    Object.keys( this.changeModel.shapes ).forEach( id =>  {
                        if ( !supported.includes( id )) {
                            exclude.add( id );
                        }
                    });
                }
                const foundContainer = maxBy(
                    ( this.shapeBoundsLocator
                        .searchShapes( b.left, b.right, b.top, b.bottom, Array.from( exclude )) || [])
                        // The expected container should be larger than the shape
                        .filter( v => {
                            const isInside = Rectangle.from( v )
                                .toPolygon()
                                .isInside(
                                    Point.from({
                                        x: b.x + b.width / 2,
                                        y: b.y + b.height / 2,
                                    }),
                                );
                            return isInside && v.width >= b.width && v.height >= b.height;
                        }),
                    v => v.zIndex,
                );
                if ( foundContainer ) {
                    const per = Rectangle.from( foundContainer ).getIntersectionPercentage( b );
                    const container: ShapeModel = this.changeModel.shapes[ foundContainer.id ] as any;
                    if ( shape.isSticker || ( container && container.getSupportedChildren([ shape ]).length )) {
                        commandData[ shape.id ] = {
                            action: 'enter',
                            containerId: per < 50 ? shape.containerId : container.id,
                        };
                    }
                } else {
                    commandData[ shape.id ] = {
                        action: 'leave',
                    };
                }
            }
            this.data = { childrenData: commandData };
        }
        return true;
    }

    /**
     * Prepare command data by modifying the change model.
     */
    public execute() {
        if ( this.eventData.scenario === CommandScenario.PREVIEW ) {
            return true;
        }
        this.manageContainers();
        this.manageChildren();
        return true;
    }


    protected manageChildren() {
        if ( !this.data.childrenData ) {
            return;
        }
        // get max expand scale if we have expand property
        const expandTo = { scaleX: 0, scaleY: 0, containerId: '' };
        this.sortChildrenByZindex().forEach( childId => {
            const item = this.data.childrenData[ childId ];
            if ( childId === item.containerId ) { // Should never happen
                return;
            }
            const child = this.changeModel.shapes[ childId ] as ShapeModel;
            if ( item.action === 'enter' ) {
                const container = this.changeModel.shapes[ item.containerId ] as ShapeModel;
                // NOTE: When containerizing, we should check whether the children are supported
                // by the container.
                if ( container.getSupportedChildren([ child ]).length === 0 ) {
                    return;
                }
                if ( !this.isGroupEntered( child, container )) {
                    if ( item.expand ) {
                        const oldRightPoint = container.x + container.bounds.width;
                        const newRightPoint = child.x + child.userSetWidth;
                        const oldBottomPoint = container.y + container.bounds.height;
                        const newBottomPoint = child.y + child.userSetHeight;
                        // find appropriate sacle to expand for x and y
                        if ( newRightPoint > oldRightPoint ) {
                            const oldWidth = container.bounds.width;
                            const newWidth = oldWidth + newRightPoint - oldRightPoint + 20;
                            const newScaleX = newWidth / oldWidth;
                            const oldScaleX = container.scaleX;
                            const expandScaleX = oldScaleX * newScaleX;
                            if ( expandScaleX > expandTo.scaleX ) {
                                expandTo.scaleX = expandScaleX;
                                expandTo.containerId = container.id;
                            }
                        }
                        if ( newBottomPoint > oldBottomPoint ) {
                            const oldHeight = container.bounds.height;
                            const newHeight = oldHeight + newBottomPoint - oldBottomPoint + 20;
                            const newScaleY = newHeight / oldHeight;
                            const oldScaleY = container.scaleY;
                            const expandScaleY = oldScaleY * newScaleY;
                            if ( expandScaleY > expandTo.scaleY ) {
                                expandTo.scaleY = expandScaleY;
                                expandTo.containerId = container.id;
                            }
                        }
                    } else if ( !item.force ) {
                        return;
                    }
                }
                if ( item.force ) {
                    child.x = container.x + 10;
                    child.y = container.y + 10;
                }
                const transFormData = this.getTransformData( container, child ) || {};
                if ( !container.children[ childId ]) { // Child enteretd into the cotainer
                    if ( this.isChildEntityInContainer( container, child )) {
                        return;
                    }
                    if ( child.containerId ) { // Detaching the previous container
                        // remove previous entity link

                        const previousContainer = this.changeModel.shapes[ child.containerId ] as ShapeModel;
                        delete previousContainer.children[ childId ];
                    }
                    child.containerId = item.containerId;
                    const relativeZIndex = Object.keys( container.children ).length;
                    const originalZIndex = child.zIndex;
                    this.setZIndex( child, container.zIndex + 1 + relativeZIndex );
                    container.children[ childId ] = { ...transFormData, relativeZIndex, originalZIndex };
                    this.setShapeContainerContext( container, child, true );
                    this.runContainerEntityActions( container, child, true );
                    this.smartContainerSvc.updateChildIdentifiers( this.changeModel, container, child );

                 } else { // Child moved inside the container and update only changed properties
                    Object.keys( transFormData ).forEach( key => {
                        if ( container.children[ childId ][ key ] !== transFormData[ key ]) {
                            container.children[ childId ][ key ] = transFormData[ key ];
                        }
                    });
                 }

            } else  {
                if ( child.containerId ) { // Child Left the container
                    const container = this.changeModel.shapes[ child.containerId ] as ShapeModel;
                    this.changeModel.getAllShapesInGroupHierarchy([ child.id ]).forEach( sid => {
                        const childShape = this.changeModel.shapes[ sid ] as ShapeModel;
                        const childToRemove = container.children[ sid ];
                        if ( childToRemove ) {
                            // remove previous entity link
                            this.setShapeContainerContext( container, child, false );
                            this.runContainerEntityActions( container, childShape, false );
                            this.setZIndex( child, childToRemove.originalZIndex );
                            delete container.children[ sid ];
                            delete childShape.containerId;
                            delete childShape.containerHandshake;
                        }
                    });
                }
            }
        });
        if ( expandTo.scaleX || expandTo.scaleY ) {
            const { shapes } = this.changeModel;
            const { containerId, scaleX, scaleY } = expandTo;
            if ( scaleX ) {
                ( shapes[ containerId ] as ShapeModel ).scaleX = scaleX;
            }
            if ( scaleY ) {
                ( shapes[ containerId ] as ShapeModel ).scaleY = scaleY;
            }
        }
        return true;
    }

    protected sortChildrenByZindex() {
        return sortBy( Object.keys( this.data.childrenData ).map(
            childId => this.changeModel.shapes[ childId ]), s => s.zIndex ).map( s => s.id );
    }

    protected setShapeContainerContext( container: ShapeModel, child: ShapeModel, isEnter: boolean ) {
        if ( isEnter ) {
            if ( container.containerContexts && container.containerContexts.length > 0  ) {
                child.shapeContext = container.containerContexts[0];
            } else {
                if ( child.shapeDefaultContext ) {
                    child.shapeContext = child.shapeDefaultContext;
                } else {
                    child.shapeContext = '*';
                }
            }
        } else {
            if ( child.shapeDefaultContext ) {
                child.shapeContext = child.shapeDefaultContext;
            } else {
                child.shapeContext = '*';
            }
        }
    }

    /**
     * If the shape is in a group ( the group may be simple or nested )
     * This function returns true if all the grouped shapes are inside the container's bounds
     */
    protected isGroupEntered( child: ShapeModel, container: ShapeModel ) {
        // NOTE: getAllShapesInGroupHierarchy method handles non-grouped shapes as well.
        // ( It won't return an empty array for un-grouped shape ids )
        const allShapes = this.changeModel.getAllShapesInGroupHierarchy([ child.id ]);
        const bounds = this.changeModel.getBounds( allShapes );
        if ( container.bounds
            .toPolygon()
            .isInside(
                Point.from({
                    x: bounds.x + bounds.width / 2,
                    y: bounds.y + bounds.height / 2,
                }),
            )
        ) {
            return true;
        }
        return false;
    }

    protected isChildEntityInContainer(  container: ShapeModel, child: ShapeModel ) {
        if ( child.entityId ) {
            return Object.keys( container.children )
            .map( containerChildId => this.changeModel.shapes[ containerChildId ].entityId )
            .includes( child.entityId );
        }
        return false;
    }

    protected manageContainers() {
        if ( this.data.containerData ) {
            for ( const shapeId in this.data.containerData ) {
                const item = this.data.containerData[ shapeId ];
                ( this.changeModel.shapes[ shapeId ] as any ).isContainer = item.isContainer;
            }
        }
    }

    /**
     * Updated the zIndex of the shapes and connected connectors
     */
    private setZIndex( shape: ShapeModel, zIndex: number ) {
        shape.zIndex = zIndex;
        const connectors: ConnectorModel[] =
            ( shape.getConnectors ? shape.getConnectors( this.changeModel ) || [] : [])
                .map( c => this.changeModel.shapes[ c.connector.id ]) as any;
        connectors.forEach( c => {
            const ids = c.getConnectedShapeIds( this.changeModel ).filter( id => id !== shape.id );
            let maxIndex = zIndex;
            if ( ids.length ) { // The shape at the other end of the connector
                const otherShapeIndex = this.changeModel.shapes[ ids[0] ].zIndex;
                maxIndex = Math.max( zIndex, otherShapeIndex );
            }
            if ( this.changeModel.shapes[ c.id ].zIndex !== maxIndex ) {
                this.changeModel.shapes[ c.id ].zIndex = maxIndex;
            }
        });
    }

    /**
     * Returns the data required to locate the child in the container relative to the
     * container's cordinartes
     */
    private getTransformData( container: ShapeModel, child: AbstractShapeModel ) {
        const childShape = child as ShapeModel;
        // All shapes without defaultBounds needs to be ignored e.g. connectors
        if ( !childShape.defaultBounds ) {
            return;
        }
        const containerTb = container.defaultBounds.getTransformedPoints( container.transform );
        const childTb =  childShape.defaultBounds.getTransformedPoints( childShape.transform );
        const relativeAngle = childShape.angle - container.angle;

        const baseXPoint = Line
            .from( containerTb.topLeft.x, containerTb.topLeft.y,
                containerTb.bottomLeft.x, containerTb.bottomLeft.y )
            .perpendicularTo( childTb.topLeft );
        const relativeX = Line.from( baseXPoint.x, baseXPoint.y, childTb.topLeft.x, childTb.topLeft.y ).length();

        const baseYPoint = Line
            .from( containerTb.topLeft.x, containerTb.topLeft.y, containerTb.topRight.x, containerTb.topRight.y )
            .perpendicularTo( childTb.topLeft );
        const relativeY = Line.from( baseYPoint.x, baseYPoint.y, childTb.topLeft.x, childTb.topLeft.y ).length();
        return {
            relativeX,
            relativeY,
            relativeAngle,
        };
    }


    /**
     * Makes changes that entity related. Basically swaps the shape if needed
     * and adds entity connectors between container and the child.
     *
     * @param container
     * @param child
     * @param isEnter
     */
    private runContainerEntityActions( container: ShapeModel, child: ShapeModel, isEnter: boolean ) {
        if ( this.eventData.scenario !== CommandScenario.PREVIEW && child.eData ) {
            const entObs = of([ 1 ]);
            entObs.pipe(
                take( 1 ),
                switchMap( x => {
                    // get the container eData and then the child's eData
                    if ( container.eData ) {
                        const eDataIds = Object.keys( container.eData );
                        if ( eDataIds[0]) {
                            const containerEDataId = eDataIds[0];
                            const containerEntityId = container.eData[containerEDataId];
                            return this.ell.getEntityOnce( containerEDataId, containerEntityId ).pipe(
                                take( 1 ),
                                switchMap( containerEntity => {
                                    const allObservers = this.runChildEntityActions(
                                        containerEDataId, containerEntity, container, child, isEnter,
                                    );
                                    return merge( ...allObservers );
                                }),
                            );

                        }
                    } else {
                        // just a normal container, swap the shape if we have to
                        const allObs = this.runChildEntityActions( undefined, undefined, container, child, isEnter );
                        return merge( ...allObs );
                    }
                }),
            ).subscribe();


        }
    }


    /**
     * Execute the actions per child shape and return the observable for it.
     * @param containerEDataId
     * @param containerEntity
     * @param container
     * @param child
     * @param isEnter
     */
    private runChildEntityActions(
            containerEDataId: string,
            containerEntity: EntityModel,
            container: ShapeModel,
            child: ShapeModel,
            isEnter: boolean ): Observable<EDataModel>[] {
        const allObservers = [];
        for ( const eDataId in child.eData ) {
            const entityId = child.eData[eDataId];
            const entityObs = this.ell.getEntityOnce( eDataId, entityId ).pipe(
                take( 1 ),
                tap( entity => {
                    // swap shape if needed
                    this.swapShape( container, child, entity, isEnter );

                    // add new entity link
                    if ( containerEntity && !isEnter ) {
                        // FIXME this is a hack. Find a better method. CJ 13/1/2021
                        ( child as any ).containerOld = container.id;
                    }
                }),
            );
            allObservers.push( entityObs );
        }

        return allObservers;
    }


    private swapShape( container: ShapeModel, child: ShapeModel, childEntity: EntityModel, isEnter: boolean ) {
        let targetContext;
        if ( isEnter ) {
            targetContext = container.containerContexts;
        } else {
            targetContext = [ '*' ];
        }
        // if the container has a specific context, may be we have to swap shape
        if ( targetContext ) {
            let shapeDefId;
            for ( let i = 0; i < targetContext.length; i++ ) {
                shapeDefId =
                childEntity.getShapeDefIdForContext( targetContext[i]);
                if ( shapeDefId ) {
                    // this is the handshake used.
                    child.containerHandshake = targetContext[i];
                    break;
                }
            }
            if ( shapeDefId && child.defId !== shapeDefId.id ) {
                // swap the shape
                this.updateShapeDefId( child, shapeDefId.id, shapeDefId.version );
            }
        }
    }


    /**
     * Swaps the shapes defId. Essentially swapping the shape.
     * @param shape
     * @param defId
     * @param version
     */
    private updateShapeDefId( shape: ShapeModel, defId: string, version: number ) {
        if ( shape.vectors ) { // remove vectors
            shape.vectors = undefined;
        }

        if ( !version ) {
            version = 1;
        }

        // def
        const currDefObs = this.defLocator.getDefinition( shape.defId, shape.version );
        const newDefObs = this.defLocator.getDefinition( defId, version );

        forkJoin( currDefObs, newDefObs ).pipe(
            take( 1 ),
        ).subscribe( vals => {
            const currDef: IShapeDefinition = find( vals, { defId: shape.defId });
            const newDef: IShapeDefinition = find( vals, { defId: defId  });

            if ( currDef && newDef ) {
                // if the new def has named texts, just show them, hide others
                if ( newDef.texts && Object.values( newDef.texts ).length > 0 ) {
                    if ( shape.texts ) {
                        const mdls = Object.values( shape.texts );
                        mdls.forEach( mdl => {
                            if ( newDef.texts[ mdl.id ]) {
                                mdl.isVisible = true;
                                const newDefTextMdl = newDef.texts[ mdl.id ];
                                mdl.x = newDefTextMdl.x;
                                mdl.y = newDefTextMdl.y;
                                const replaceKeysWithDefaults = {
                                    xType: 'relative', yType: 'relative',
                                    alignY: 0,
                                };
                                Object.keys( replaceKeysWithDefaults ).forEach( key => {
                                    mdl[key] = newDefTextMdl[key] ? newDefTextMdl[key] : replaceKeysWithDefaults[key];
                                });
                            } else {
                                mdl.isVisible = false;
                            }
                        });
                    }
                } else { // not defined, just show all of it (for dynamic texts)
                    if ( shape.texts ) {
                        const mdls = Object.values( shape.texts );
                        mdls.forEach( mdl => {
                            mdl.isVisible = true;
                        });
                    }
                }

                // swap the defs
                shape.defId =  defId;
                shape.version = version;
            }
        });
    }
}

Object.defineProperty( ChangeContainerData, 'name', {
    value: 'ChangeContainerData',
});
