import { first, last, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Random, StateService, CommandService, Tracker, EventCollector, EventIdentifier, EventSource } from 'flux-core';
import {
    IShapeDefinition,
    IPoint2D,
    ITextFormat,
    ITextContent,
    ShapeType,
} from 'flux-definition';
import { cloneDeep, sample, forEach, merge as lodashMerge } from 'lodash';
import { IConnectorWithEndpoints, IShapeWithDistanceAndEnpoints, PlusCreatePosition } from './plus-create-helpers';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { ConnectorModel, IConnectorEndPointWithRef } from '../../../base/shape/model/connector.mdl';
import { PlusCreateHelpers, ShapeSide } from './plus-create-helpers';
import { FloatingToolbarManager } from '../../../framework/ui/floating-toolbar/floating-toolbar-manager.svc';
import { PlusCreateToolbar } from '../../ui/plus-create/plus-create-toolbar.cmp';
import { ShapeDataModel, TextFormatter } from 'flux-diagram-composer';
import { EntityModel } from '../../../base/edata/model/entity.mdl';
import { DiagramCommandEvent } from '../../diagram/command/diagram-command-event';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { ViewportService } from './../../../base/diagram/viewport.svc';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { DiagramToViewportCoordinate } from 'apps/nucleus/src/base/coordinate/diagram-to-viewport-coordinate.svc';
import { BaseDiagramCommandEvent } from 'apps/nucleus/src/base/diagram/command/base-diagram-command-event';
import { GluePoint } from '../../selection/gluepoints/gluepoint';

/**
 * This is the plus create service, which implements plus create automatic positioning
 * and handling keyboard shortcuts
 *
 * @author Ivan
 * @since 29.11.2022
 */
@Injectable()
export class PlusCreateService {

    /**
     * Text formatter for extracting text styles for plus create functionality
     */
     protected textFormatter: TextFormatter;

    /**
     * Default offset for new shapes, created using plus create button.
     * Used if no other shapes are connected to the same gluepoint
     */
    protected defaultFirstShapeOffset: number = 100;

    /**
     * Default offset for new shapes, whose positions are being determined by flowdown alogorithm
     */
    protected defaultFlowdownShapeOffset: number = 40;

    constructor(
        protected commandService: CommandService,
        protected state: StateService<any, any>,
        protected toolbarManager: FloatingToolbarManager,
        protected viewport: ViewportService,
        protected vToD: ViewportToDiagramCoordinate,
        protected dToV: DiagramToViewportCoordinate,
        protected ll: DiagramLocatorLocator,
    ) {
        this.textFormatter = new TextFormatter();
    }

    /**
     * handle keybord action
     * @param type "child" or "sibling" for tab and enter
     * @param diagram current diagram
     * @param selectedShapeModel selected shape
     * @param def selected shape definition
     */
    public handleKeyboardAction( type: 'child' | 'sibling', diagram, selectedShapeModel, def ) {
        if ( type === 'child' ) {
            this.handleAddChild( diagram, selectedShapeModel );
        } else {
            this.handleAddSibling( diagram, selectedShapeModel, def );
        }
    }

    // refactor this piece to reduce cyclomatic complexity
    // tslint:disable-next-line:cyclomatic-complexity
    public dispatchEvent( diagram: DiagramModel,
                          selectedShapeModel: ShapeModel,
                          side: ShapeSide,
                          def: any,
                          shouldConnect = true,
                          cursorPosition?: IPoint2D,
                          dragged = false ) {
        if ( selectedShapeModel.isConnector()) {
            return;
        }

        const randoms = [];
        EventCollector.log({
            message: EventIdentifier.PLUS_CREATE_EVENT_DISPATCHED,
            eventData: {
                diagramId: diagram.id,
                selectedShapeId: selectedShapeModel.id,
                side,
                def,
                shouldConnect,
                cursorPosition,
                dragged,
            },
        });

        Tracker.track(
            'left.library.shape.drop',
            {
                value1Type: 'defId', value1: def.id,
                value3Type: 'location', value3: 'plusCreate',
            },
        );

        const parent = selectedShapeModel.getParent( diagram );
        let spacingX: number;
        let spacingY: number;
        if ( parent ) {
            const currentRegion = Object.values(( parent as ShapeDataModel ).containerRegions )[0];
            [ spacingX, spacingY ] = ( currentRegion?.layoutingData )?.spacing || [];
        }

        const nextShapeData =
            this.getNextShapeData( diagram, selectedShapeModel, side, shouldConnect, def, spacingX, spacingY );
        const {
            coordinates,
            shapeToCopyStylesFrom,
            connectorToCopyStylesFrom,
            receivingSide,
        } = nextShapeData;
        const sameDef = shapeToCopyStylesFrom.defId === def.defId;

        let style = null;
        let defaultTextFormat = null; let textFormat: { [id: string]: ITextFormat } = null;

        const data: any = Object.assign({}, cloneDeep( def ), {
            id: Random.shapeId(),
            x: dragged ? cursorPosition.x : coordinates.x,
            y: dragged ? cursorPosition.y : coordinates.y,
        });

        // if we drag from left, we expect the shape to be rendered more to the left.
        // same with bottom
        if ( dragged ) {
            if ( side === 'left' ) {
                data.x -= shapeToCopyStylesFrom.drawWidth;
                data.y -= shapeToCopyStylesFrom.drawHeight / 2;
            } else if ( side === 'bottom' ) {
                data.x -= shapeToCopyStylesFrom.drawWidth / 2;
            } else if ( side === 'right' ) {
                data.y -= shapeToCopyStylesFrom.drawHeight / 2;
            } else if ( side === 'top' ) {
                data.x -= shapeToCopyStylesFrom.drawWidth / 2;
                data.y -= shapeToCopyStylesFrom.drawHeight;
            }
        }

        if ( sameDef ) {
            style = JSON.parse( JSON.stringify( shapeToCopyStylesFrom.style ));
            if ( shapeToCopyStylesFrom.defaultTextFormat ) {
                defaultTextFormat = JSON.parse( JSON.stringify( shapeToCopyStylesFrom.defaultTextFormat ));
            }
            if ( shapeToCopyStylesFrom.texts ) {
                Object.values( shapeToCopyStylesFrom.texts ).forEach( text => {
                    const commonStyle = text.rendering === 'carota' ? text.content.reduce(( res, next ) => {
                        const common = {};
                        Object.entries( next ).forEach(([ name, value ]) => {
                            if ( res[name] && res[name] === value ) {
                                common[name] = value;
                            }
                        });
                        return common as ITextContent;
                    }) : {} as any;
                    if ( !commonStyle.color && defaultTextFormat && defaultTextFormat.color ) {
                        commonStyle.color = defaultTextFormat.color;
                    }
                    if ( !textFormat ) {
                                // Not copying alignment since all text wont have the same alignment
                                const commonStyleWtihoutAlignment = commonStyle;
                                delete commonStyleWtihoutAlignment.align;
                                textFormat = { '*' : {
                                    styles: commonStyleWtihoutAlignment, indexStart: 0, indexEnd: undefined,
                                }};
                            }
                    textFormat[ text.id ] = { styles: commonStyle, indexStart: 0, indexEnd: undefined  };
                });
                const dimensions = PlusCreateHelpers.getNextShapeDimensions( shapeToCopyStylesFrom, def );
                data.userSetWidth = dimensions.userSetWidth || 100;
                data.userSetHeight = dimensions.userSetHeight || 100;
                data.scaleX = dimensions.scaleX || 1;
                data.scaleY = dimensions.scaleY || 1;
                data.autoResizeHeight = false;
                data.autoResizeWidth = false;
                data.defaultBounds = shapeToCopyStylesFrom.bounds;
            }
        }

        delete data.transformSettings;
        delete data.create;
        delete data.tags;
        delete data.switchable;
        delete data.thumbnail;
        delete data.entryClass;
        delete data.logicClass;

        let entity;
        if ( data.eData ) {
            const edataId = Object.keys( data.eData )[0];
            if ( edataId ) {
                data.eData = { [edataId]: null };
                if ( def.sidebarEntity ) {
                    const id = def.sidebarEntity.id;
                    const edefId = def.sidebarEntity.edefId;
                    entity =  Object.assign( new EntityModel( id, edefId ), def.sidebarEntity ) ;
                }
                data.triggerNewEData = true;

            }
        }

        if ( style ) {
            data.style = style;
        }
        if ( defaultTextFormat ) {
            data.defaultTextFormat = defaultTextFormat;
        }
        if ( textFormat ) {
            data.format = textFormat;
        }

        this.setPropertiesFromDef( def as any, data );
        const cmdData: {
            shapeData: any,
            connectorToCopyStylesFrom: ConnectorModel, fromGluepoints: any, entity?: any } = {
            shapeData: data, connectorToCopyStylesFrom,
            fromGluepoints: null,
        };
        if ( entity ) {
            cmdData.entity = entity;
        }
        if ( selectedShapeModel.hasDefaultGluePoint ) {
            const fromGluepoint = Object.values( selectedShapeModel.gluepoints )[0];
            const toGluepoint = Object.values( cmdData.shapeData.gluepoints )[0] as GluePoint;
            cmdData.shapeData.endpoints = {
                from: { x: fromGluepoint.x, y: fromGluepoint.y },
                to: { x: toGluepoint.x, y: toGluepoint.y },
            };
        } else {
            cmdData.shapeData.endpoints = {
                from: PlusCreateHelpers.sideToGluepointCoordinates( side as ShapeSide ),
                to:  PlusCreateHelpers.sideToGluepointCoordinates( receivingSide ),
            };
        }

        // We use top left corner or our original shape here to prevent connector
        // from flashing for 1 frame. Getting to draw the correct connector shape on this step
        // is extremely complicated
        cmdData.shapeData.points = [
            { id: Random.pointId(), x: selectedShapeModel.x, y: selectedShapeModel.y, shapeId: selectedShapeModel.id },
            { id: Random.pointId(), x: selectedShapeModel.x, y: selectedShapeModel.y, shapeId: data.id },
        ];

        randoms.push({
            caller: 'shapeId',
            value: data.id,
        });

        cmdData.shapeData.points.forEach( point => {
            randoms.push({
                caller: 'pointId',
                value: point.id,
            });
        });

        if ( def.type === ShapeType.Basic  ) {
            this.state.set( 'LastAddedShape', def.defId );
        }
        if (( def as any ).eDataCandidates ) {
            this.state.set( 'LastAddedEDataCandidateShape', def.defId );
        }

        if ( this.isUnsupportedShape( selectedShapeModel ) && shouldConnect ) {
            data.center = true;
            if ( cursorPosition ) {
                // This 'center' data is needed for the
                // StartMovingShape command, the shape sohuld move in such a way
                // that the center of the shape is alway under the pointer.
                // This behaviour is important for plus create.
                data.x = this.vToD.x( cursorPosition.x ) - data.defaultBounds.width / 2;
                data.y = this.vToD.y( cursorPosition.y ) - data.defaultBounds.height / 2;
            }
            this.commandService.dispatch( DiagramCommandEvent.plusCreateConnectAndMove, cmdData ).pipe(
                last(),
                tap(() => {
                    // select shape and start moving it manually.
                    // This breaks otherwise.
                    this.commandService.dispatch( BaseDiagramCommandEvent.selectShapes, { shapeIds: [ data.id ]});
                    this.commandService.dispatch( DiagramCommandEvent.startMovingShape, cmdData );
                }),
            ).subscribe();
            return;
        }
        const isCard = selectedShapeModel?.name?.includes( 'Card' ) ||
            selectedShapeModel?.tags?.includes( 'card' ) ||
            selectedShapeModel.defId.includes( 'timecard' );

        const isPlusCreateCard = def?.name?.includes( 'Card' ) ||
            def?.tags?.includes( 'card' ) ||
            def.defId.includes( 'timecard' );

        cmdData.fromGluepoints = selectedShapeModel.gluepoints;

        return this.commandService.dispatch( shouldConnect && !isCard ?
            DiagramCommandEvent.plusCreateConnect :
            DiagramCommandEvent.plusCreate, cmdData ).pipe(
            last(),
            tap(({ resultData }) => {
                if ( parent ) {
                    this.processContainerChild( parent, data );
                }

                let $set = {};
                // This will cause the shape to update after plus create,
                // triggering connector-reconnect-binder, so that our shapes are properly connected.
                // #FIXME: We could optimize this by drawing the correct connector line at the initial render time,
                // but it has proven to be quite a challenge.
                $set[`shapes.${data.id}.x`] = data.x + 1;

                const undoGluepointLockQuery = resultData[4];
                    // This command will cause the shape to update after plus create,
                    // triggering connector-reconnect-binder, so that our shapes are properly connected
                if ( undoGluepointLockQuery ) {
                    $set = { ...$set, ...undoGluepointLockQuery };
                }

                const textEditParams: {textId?: string} = {};
                if ( isCard ) {
                    textEditParams.textId = 'title';
                }
                const moveUpdateEvent = new DiagramCommandEvent( 'ApplyModifierDocument', EventSource.SYSTEM );
                this.commandService
                    .dispatch( moveUpdateEvent, { modifier: { $set }}).pipe(
                        last(),
                        tap(() => this.commandService.dispatch( DiagramCommandEvent.openTextEditorSystem,
                            isPlusCreateCard ? textEditParams : null )),
                    ).subscribe();
                this.commandService.dispatch( moveUpdateEvent, { modifier: {
                    $set: { [`shapes.${data.id}.x`]: data.x },
                }});
            }),
        ).subscribe();
    }

    public dispatchDragEvent( selectedShapeModel: ShapeModel,
                              def: any,
                              cursorPosition: IPoint2D, blankId ) {
        const data: any = {};
        // create empty shape with no dimensions to get our connctor going
        const x = this.vToD.x( selectedShapeModel.x );
        const y = this.dToV.y( selectedShapeModel.y );
        const shapeData = cloneDeep( def );
        shapeData.texts = {};
        shapeData.width = 0.01;
        shapeData.height = 0.01;
        shapeData.blank = true;
        shapeData.id = blankId;
        shapeData.x = this.vToD.x( cursorPosition.x );
        shapeData.y = this.vToD.y( cursorPosition.y );
        shapeData.defaultBounds = {
            x: 0,
            y: 0,
            width: 0.01,
            height: 0.01,
        };
        shapeData.points = [
            { id: Random.pointId(), x, y, shapeId: selectedShapeModel.id },
            { id: Random.pointId(), x, y, shapeId: shapeData.id },
        ];
        data.center = true;
        if ( cursorPosition ) {
            // This 'center' data is needed for the start moving command
            data.x = this.vToD.x( cursorPosition.x ) - shapeData.defaultBounds.width / 2;
            data.y = this.vToD.y( cursorPosition.y ) - shapeData.defaultBounds.height / 2;
        }
        data.shapeData = shapeData;
        return this.commandService.dispatch( DiagramCommandEvent.plusCreateDragStart, data );
    }

    protected getPlusCreateToolbar(): PlusCreateToolbar {
        return this.toolbarManager.get( 'plus-create-toolbar' ) as any;
    }

    /**
     * Identifies unsupported shapes.
     * TODO: not going to be needed in plus-create 2, as all shapes would be supported.
     * Current implementation doesn't handle > 4 gluepoints and shapes with broken gluepoint in their def
     * or shapes that are rotated
     * @param shapeModel
     * @returns if shape is supported or not
     */
    protected isUnsupportedShape( shapeModel ): boolean {
        // We apply old logic to rotated shapes
        if ( shapeModel.angle > 10 || shapeModel.angle < -10 ) {
            return true;
        }
        // FIXME: Some shapes are not working with the current flowdown implementation:
        // shapes with more than 4 gluepoints doesn't have proper logic yet, and
        // orgchart shapes have messed gluepoint models. This all is to be resolved in the next update.
        return Object.entries( shapeModel?.gluepoints || {}).length > 4 ||
        shapeModel.defId === 'creately.orgchart.circularimagetop' ||
        shapeModel.defId === 'creately.orgchart.circularimagenobase' ||
        shapeModel.defId === 'creately.orgchart.squareimagetop' ||
        shapeModel.defId === 'creately.orgchart.squareimageleft';
    }

    /**
     * Add new shape to container's children and expand container shape accordingly
     * @param parent parent
     * @param data child
     */
    protected processContainerChild( container: ShapeModel, data: ShapeModel ) {
        this.commandService.dispatch( DiagramCommandEvent.changeContainerData, {
            childrenData: { [data.id]: { action: 'enter', containerId: container.id, expand: true }},
        });
    }

    /**
     * Get selection history.
     * @param diagram diagram model
     * @returns selection history
     */
    protected getSelectionHistory( diagram ) {
        return this.state.get( 'SelectionHistory' ).filter( id => diagram.shapes[id]).reverse().slice( 1 );
    }

    protected findLastSelectedShapeConnector( diagram,
                                              connectors: IConnectorWithEndpoints[],
                                              dir: ( 'to' | 'from' )[]):
                                              [ IConnectorWithEndpoints, 'to' | 'from' ] {
        let foundConnector: IConnectorWithEndpoints;
        let foundIndex: number;
        let outDir: 'to' | 'from';
        const selectionHistory = this.getSelectionHistory( diagram );

        dir.forEach( direction => {
            selectionHistory.forEach(( id, i ) => {
                if ( foundConnector && foundIndex < i ) {
                    return;
                }
                connectors.forEach( conn => {
                    if ( foundConnector && foundIndex < i ) {
                        return;
                    }
                    if ( conn?.[`${direction === 'to' ? 'from' : 'to'}Endpoint`]?.shape?.id === id ) {
                        foundConnector = conn;
                        outDir = direction;
                        foundIndex = i;
                    }
                });
            });
        });
        // if we don't have a related connector, we pick a random one
        return [ ( foundConnector || sample( connectors )) as IConnectorWithEndpoints, outDir ];
    }

    /**
     * adds new adjacent shape to the diagram, connected with the selected one
     * @param diagram current diagram
     * @param selectedShapeModel selected shape
     * @returns observable
     */
    protected handleAddChild( diagram: DiagramModel, selectedShapeModel: ShapeModel ) {
        if ( selectedShapeModel.isConnector()) {
            return;
        }
        const connectors = this.getAllConnectors( diagram );
        const incomingConnectors = this.getIncomingConnectors( selectedShapeModel, connectors );
        const outgoingConnectors = this.getOutgoingConnectors( selectedShapeModel, connectors );
        let currentSide: ShapeSide = 'right';
        let connector: IConnectorWithEndpoints;

        if ( incomingConnectors.length > 0 && outgoingConnectors.length === 0 ) {
            [ connector ] = this.findLastSelectedShapeConnector( diagram, incomingConnectors, [ 'to' ]);
            const connectorSide = PlusCreateHelpers.gluepointCoordinatesToSide( connector?.toEndpoint?.gluepoint );
            currentSide = PlusCreateHelpers.getOppositeSide( connectorSide );
        } else if ( incomingConnectors.length > 0 || outgoingConnectors.length > 0 ) {
            let dir: 'to' | 'from';
            [ connector, dir ] = this.findLastSelectedShapeConnector( diagram,
                [ ...incomingConnectors, ...outgoingConnectors ],
                [ 'to', 'from' ]);
            if ( !connector && outgoingConnectors.length > 0 ) {
                [ connector ] = this.findLastSelectedShapeConnector( diagram, outgoingConnectors, [ 'from' ]);
                currentSide = PlusCreateHelpers.gluepointCoordinatesToSide( connector?.fromEndpoint?.gluepoint );
            } else if ( connector ) {
                currentSide = PlusCreateHelpers.gluepointCoordinatesToSide( connector?.[`${dir}Endpoint`]?.gluepoint );
            }
        }
        return this.viewport.getShapeOnce( selectedShapeModel.id ).pipe(
            first(),
            tap( shape => {
                this.getPlusCreateToolbar().showAndPosition( shape, currentSide as PlusCreatePosition );
            }),
        ).subscribe();
    }

    /**
     * Adds new flowdown shape to the diagram, connected to the selected one
     * @param diagram current diagram
     * @param selectedShapeModel selected shape
     * @param def selected shape def
     * @returns observable
     */
    protected handleAddSibling( diagram: DiagramModel, selectedShapeModel: ShapeModel, def: IShapeDefinition ) {
        if ( selectedShapeModel.isConnector()) {
            return;
        }
        const connectors = this.getAllConnectors( diagram );
        const incomingConnectors = this.getIncomingConnectors( selectedShapeModel, connectors );

        if ( incomingConnectors.length === 0 ) {
            return this.dispatchEvent( diagram, selectedShapeModel, 'bottom', def, false );
        }

        const [ incomingConnector ] = this.findLastSelectedShapeConnector( diagram, incomingConnectors, [ 'from' ]);

        const incomingShape = incomingConnector?.fromEndpoint?.shape;
        const fromSide = PlusCreateHelpers.gluepointCoordinatesToSide( incomingConnector?.fromEndpoint?.gluepoint );
        if ( this.isUnsupportedShape( incomingShape )) {
            if ( !incomingConnector ) {
                return this.dispatchEvent( diagram, null, fromSide, def, false );
            }
            this.commandService.dispatch(
                BaseDiagramCommandEvent.selectShapes,
                this.state.get( 'CurrentDiagram' ),
                { shapeIds: [ incomingShape.id ], add: false },
            );
            return this.viewport.getShapeOnce( selectedShapeModel.id ).pipe(
                tap( shape => {
                   this.getPlusCreateToolbar()
                       .showAndPosition( shape, fromSide as PlusCreatePosition );
               }),
           ).subscribe();
        }
        return this.dispatchEvent( diagram, incomingShape, fromSide, def );
    }

    /**
     * Get incoming connectors
     * @param shape
     * @param connectors
     */
    protected getIncomingConnectors( shape: ShapeModel,
                                     connectors: IConnectorWithEndpoints[]): IConnectorWithEndpoints[] {
        const incomingConnectors = connectors.filter( c => c?.toEndpoint?.shape?.id === shape.id
            && c?.toEndpoint?.gluepoint );
        return incomingConnectors;
    }

    /**
     * Get outgoing connectors
     * @param shape
     * @param connectors
     */
    protected getOutgoingConnectors( shape: ShapeModel,
                                     connectors: IConnectorWithEndpoints[]): IConnectorWithEndpoints[] {
        const outgoingConnectors = connectors.filter( c => c?.fromEndpoint?.shape?.id === shape.id
            && c?.fromEndpoint?.gluepoint );
        return outgoingConnectors;
    }

    protected getEndpointsForShape( shape: ShapeModel, connectors: IConnectorWithEndpoints[]) {
        let toEndpoint: IConnectorEndPointWithRef;
        let fromEndpoint: IConnectorEndPointWithRef;

        connectors.forEach( c => {
            if ( c?.toEndpoint?.shape?.id === shape.id && c?.toEndpoint?.gluepoint ) {
                toEndpoint = c.toEndpoint;
            }
            if ( c?.fromEndpoint?.shape?.id === shape.id && c?.fromEndpoint?.gluepoint ) {
                fromEndpoint = c.fromEndpoint;
            }
        });
        return { toEndpoint, fromEndpoint };
    }

    protected getAllConnectors( diagram ) {
        if ( !diagram.shapes ) {
            return [];
        }

        const connectors: IConnectorWithEndpoints[] = ( Object.values( diagram.shapes ))
            .filter(( shape: ShapeModel ) => shape.type === 'connector' )
            .filter(( connector: ConnectorModel ) =>
                connector.getFromEndpoint( diagram ).gluepoint && connector.getToEndpoint( diagram ).gluepoint )
            .map(( connector: ConnectorModel ) => {
                const fromEndpoint = connector.getFromEndpoint( diagram );
                const toEndpoint = connector.getToEndpoint( diagram );

                return { connector, fromEndpoint, toEndpoint };

        });
        return connectors;
    }

    protected getNextShapeData( diagram: DiagramModel,
                                selectedShapeModel: ShapeModel,
                                fromSide: ShapeSide,
                                shouldConnect: boolean,
                                def: IShapeDefinition,
                                spacingX: number,
                                spacingY: number ): {
        coordinates: IPoint2D,
        receivingSide: ShapeSide,
        shapeToCopyStylesFrom: ShapeModel,
        connectorToCopyStylesFrom: ConnectorModel,
        connectedShapes: IShapeWithDistanceAndEnpoints[],
    } {
        if ( !selectedShapeModel ) {
            return;
        }
        let flowdownOffset = this.defaultFlowdownShapeOffset;
        let adjOffset = this.defaultFirstShapeOffset;
        if ( spacingX || spacingY ) {
            if ( fromSide === 'right' || fromSide === 'left' ) {
                flowdownOffset = spacingX || spacingY;
                adjOffset = spacingX || spacingY;
            } else {
                flowdownOffset = spacingY || spacingX;
                adjOffset = spacingY || spacingX;
            }
        }

        const [ connectedShapes ] =
            PlusCreateHelpers.getAllConnectedShapes( diagram, selectedShapeModel, selectedShapeModel.id );
        const connectors = this.getAllConnectors( diagram );

        const shapesConnectedToTheSameSide = PlusCreateHelpers.getShapesConnectedToTheSameSide(
            connectors,
            selectedShapeModel,
            fromSide,
        ).filter(({ shape, connector }) => shape && connector );

            // figure out if we should expand vertically or horizontally.
        const expansionScenario = PlusCreateHelpers.getShapeExpansionScenario( shapesConnectedToTheSameSide, fromSide );


        let coordinates: IPoint2D;

        // if we use flowdown/balanced automatic positioning, we need to derive styles
        // from the eastmost/southmost shape. Defaults to root shape
        let shapeToCopyStylesFrom: ShapeModel = selectedShapeModel;
        let connectorToCopyStylesFrom: ConnectorModel;
        let receivingSide: ShapeSide = null;

        // If we have shapes to expand upon, we do flowdown algorithm. Otherwise,
        // we move the shape in the direction of the plus create button.
        // If we shouldn't connect our shape, that means we're creating a child,
        // and it should be closer
        if ( !shouldConnect || !expansionScenario.scenario ) {
            // if we don't need to connect shapes, use sibling offset
            coordinates = PlusCreateHelpers.getNewAdjacentShapeCoordinates( selectedShapeModel,
                fromSide, shouldConnect ? adjOffset : flowdownOffset, def );
            receivingSide = PlusCreateHelpers.getOppositeSide( fromSide as ShapeSide );
        } else {
            const { coords, shape, side, connector } = PlusCreateHelpers.
                getFlowdownCoordinates( expansionScenario, selectedShapeModel, flowdownOffset );
            coordinates = coords;
            receivingSide = side;
            shapeToCopyStylesFrom = shape;
            connectorToCopyStylesFrom = connector;
        }
        // if we don't have a sibling connector, check for parent's incoming connector.
        if ( !connectorToCopyStylesFrom ) {
            const parentConnector = this?.getIncomingConnectors( shapeToCopyStylesFrom, connectors )?.[0];
            if ( parentConnector ) {
                connectorToCopyStylesFrom = parentConnector.connector;
            }
        }
        return { coordinates, receivingSide, shapeToCopyStylesFrom, connectorToCopyStylesFrom, connectedShapes };
    }

    /*
     * NOTE:
     * Def data are merged to the newly plus created shape after all the commands are completed.
     * but, shape texts and ports are neccesray in the intermediade commands of pluscreate.
     */
    private setPropertiesFromDef( def: IShapeDefinition, data: any ) {
        if ( def && def.type !== 'connector' ) {
            if ( def.texts ) {
                data.texts = {};
                forEach( def.texts, ( value, key ) => {
                    data.texts[ key ] = lodashMerge({}, value );
                    data.texts[ key ].id = key;
                });
            }
            if ( def.ports ) {
                data.ports = [];
                forEach(( def as IShapeDefinition ).ports , value => {
                    data.ports.push( lodashMerge({}, value ));
                });
            }
        }
    }

}
