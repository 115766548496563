/**
 * a list of tooltips to be displayed on nucleus
 */

import { TempAddLibsMenu } from '../../../../editor/ui/temp-add-libs-menu/temp-add-libs-menu.cmp';
import { TooltipAutomateState } from '../../../base-states';
import {
    ITooltipTourStep,
    KnownApplicationLayouts,
    TooltipPrerequisites,
} from '../tooltip-tour.interface';

export const tooltipData: { [id: string]: ITooltipTourStep } = {
    'new-fab-tooltip': {
        stepId: 'new-fab-tooltip',
        title: 'TOOLTIP_TOUR.STEPS.FAB_BUTTON.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB_BUTTON.DESCRIPTION',
        animationURL: 'FAB.mp4',
        level: 1,
        bodyDisplacementOverride: -30,
        playOnce: true,
        preventOverlapping: [
            KnownApplicationLayouts.LEFTSIDEBAR,
            KnownApplicationLayouts.MODALWINDOW,
            KnownApplicationLayouts.USERMENU,
        ],
    },
    'fab': {
        stepId: 'fab',
        title: 'TOOLTIP_TOUR.STEPS.FAB.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB.DESCRIPTION',
        level: 1,
        learnMoreStep: 'fab-1.1',
        prerequisites: [
            TooltipPrerequisites.TOOLTIP_VIEWED,
        ],
        preReqTooltips: [
            'new-fab-tooltip',
        ],
        preventOverlapping: [
            KnownApplicationLayouts.MODALWINDOW,
            KnownApplicationLayouts.USERMENU,
            KnownApplicationLayouts.LEFTSIDEBAR,
        ],
    },
    'fab-1.1': {
        stepId: 'fab-1.1',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-1.DESCRIPTION',
        level: 2,
        stateChanges: [
            { SelectedFloatingPanel: 'shapes' },
            { ToggleTemplatesModal: false },
        ],
        prevStepId: 'fab',
        nextStepId: 'fab-1.2',
        animationURL: 'Quick Tools.mp4',
        playOnce: true,
    },
    'fab-1.2': {
        stepId: 'fab-1.2',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-2.DESCRIPTION',
        level: 2,
        stateChanges: [
            { SelectedFloatingPanel: 'templates' },
            { ToggleTemplatesModal: true },
        ],
        positionOverride: {
            top: -15,
            left: 20,
        },
        tooltipPositionOverride: {
            left: 200,
            top: 20,
        },
        prevStepId: 'fab-1.1',
        nextStepId: 'fab-1.3',
        animationURL: 'Templates.mp4',
    },
    'fab-1.3': {
        stepId: 'fab-1.3',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-3.DESCRIPTION',
        level: 2,
        prevStepId: 'fab-1.2',
        nextStepId: 'fab-1.4',
        animationURL: '1.3 Frames.mp4',
        stateChanges: [
            { SelectedFloatingPanel: 'frames' },
            { ToggleTemplatesModal: false },
        ],
        positionOverride: {
            top: 20,
        },
        delay: 1000,

    },
    'fab-1.4': {
        stepId: 'fab-1.4',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-4.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-4.DESCRIPTION',
        level: 2,
        prevStepId: 'fab-1.3',
        learnMoreStep: 'fab-1.4.1',
        animationURL: 'Add Shapes onto the Workspace.mp4',
        stateChanges: [{ SelectedFloatingPanel: 'shapes' }],
    },
    'fab-1.4.1': {
        stepId: 'fab-1.4.1',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-4-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-4-1.DESCRIPTION',
        level: 3,
        stateChanges: [{ SelectedFloatingPanel: 'shapes' }],
        animationURL: 'Browse Shapes Library.mp4',
        nextStepId: 'fab-1.4.2',
        prevStepId: 'fab-1.4',
    },
    'fab-1.4.2': {
        stepId: 'fab-1.4.2',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-4-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-4-2.DESCRIPTION',
        level: 3,
        animationURL: 'Customize Shape Panel.mp4',
        modal: {
            type: TempAddLibsMenu,
        },
        prevStepId: 'fab-1.4.1',
        nextStepId: 'fab-1.4.3',
        delay: 200,
    },
    'fab-1.4.3': {
        stepId: 'fab-1.4.3',
        title: 'TOOLTIP_TOUR.STEPS.FAB-1-4-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FAB-1-4-3.DESCRIPTION',
        level: 3,
        animationURL: 'Search shapes.mp4',
        prevStepId: 'fab-1.4.2',
    },
    'header': {
        stepId: 'header',
        title: 'TOOLTIP_TOUR.STEPS.HEADER.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER.DESCRIPTION',
        level: 1,
        learnMoreStep: 'header-2.1',
        delay: 1000,
    },
    'header-2.1': {
        stepId: 'header-2.1',
        title: 'TOOLTIP_TOUR.STEPS.HEADER-2-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER-2-1.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.HEADER-2-1.TOOLTIP',
        level: 2,
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandHeader },
        ],
        prevStepId: 'header',
        nextStepId: 'header-2.2',
        delay: 500,
    },
    'header-2.2': {
        stepId: 'header-2.2',
        title: 'TOOLTIP_TOUR.STEPS.HEADER-2-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER-2-2.DESCRIPTION',
        level: 2,
        learnMoreStep: 'header-2.2.1',
        animationURL: '2.1 Workspace Preference.mp4',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandHeader },
        ],
        delay: 500,
    },
    'header-2.2.1': {
        stepId: 'header-2.2.1',
        title: 'TOOLTIP_TOUR.STEPS.HEADER-2-2-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER-2-2-1.DESCRIPTION',
        level: 3,
        prevStepId: 'header-2.2',
        nextStepId: 'header-2.2.2',
        animationURL: '2.1.2 Workspace Status.mp4',
        stateChanges: [
            { OpenRightSidebar: 'diagraminfo' },
            { TooltipAutomateState: TooltipAutomateState.collapseHeader },
        ],
        delay: 500,
        left: true,
    },
    'header-2.2.2': {
        stepId: 'header-2.2.2',
        title: 'TOOLTIP_TOUR.STEPS.HEADER-2-2-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.HEADER-2-2-2.DESCRIPTION',
        level: 3,
        animationURL: '2.1.3 Grids And Guides.mp4',
        stateChanges: [{ OpenRightSidebar: 'diagraminfo' }],
        delay: 0,
        left: true,
        prevStepId: 'header-2.2.1',
    },
    'shape-prop': {
        stepId: 'shape-prop',
        title: 'TOOLTIP_TOUR.STEPS.SHAPE_PROP.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.SHAPE_PROP.DESCRIPTION',
        level: 1,
        animationURL: 'Shape Properties.mp4',
        skippable: true,
        stateChanges: [
            { OpenRightSidebar: 'shapePanel' },
        ],
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
        ],
        positionOverride: {
            top: -15,
            left: 20,
        },
        tooltipPositionOverride: {
            left: -250,
            top: 20,
        },
    },
    'shape-data': {
        stepId: 'shape-data',
        title: 'TOOLTIP_TOUR.STEPS.SHAPE_DATA.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.SHAPE_DATA.DESCRIPTION',
        level: 1,
        animationURL: 'Notes.mp4',
        stateChanges: [
            { OpenRightSidebar: 'shapeData' },
        ],
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
            TooltipPrerequisites.TOOLTIP_VIEWED,
        ],
        preReqTooltips: [
            'floating-tools',
        ],
        positionOverride: {
            top: -15,
            left: 20,
        },
        tooltipPositionOverride: {
            left: -530,
            top: 70,
        },
    },
    'left-sidebar': {
        stepId: 'left-sidebar',
        title: 'TOOLTIP_TOUR.STEPS.LSB.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB.DESCRIPTION',
        animationURL: 'LSB.mp4',
        level: 1,
        positionOverride: {
            top: 50,
            left: 300,
        },
        bodyDisplacementOverride: -50,
        playOnce: true,
    },
    'lsb-1': {
        stepId: 'lsb-1',
        title: 'TOOLTIP_TOUR.STEPS.LSB-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-1.DESCRIPTION',
        level: 2,
        animationURL: '1.2 Folder Panel.mp4',
        positionOverride: {
            left: -10,
            top: 10,
        },
    },
    // Navigation Panel
    'lsb-2': {
        stepId: 'lsb-2',
        title: 'TOOLTIP_TOUR.STEPS.LSB-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-2.DESCRIPTION',
        level: 1,
        positionOverride: {
            left: 0,
            top: 50,
        },
        prerequisites: [
            TooltipPrerequisites.LSB_IS_NAV,
        ],
        bodyDisplacementOverride: -50,
    },
    // Task Panel
    'lsb-3': {
        stepId: 'lsb-3',
        title: 'TOOLTIP_TOUR.STEPS.LSB-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-3.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.LSB-3.TOOLTIP',
        animationURL: 'Access your Tasks.mp4',
        learnMoreStep: 'lsb-3.1',
        skippable: true,
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandTask },
            { TooltipAutomateState: TooltipAutomateState.hideTaskOption },

        ],
        ripplePrequisites: [
            TooltipPrerequisites.LSB_IS_TASKS,
        ],
        level: 1,
        positionOverride: {
            left: 0,
            top: 100,
        },
        bodyDisplacementOverride: -50,
    },
    'lsb-3.1': {
        stepId: 'lsb-3.1',
        title: 'TOOLTIP_TOUR.STEPS.LSB-3-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-3-1.DESCRIPTION',
        level: 3,
        animationURL: 'Set Task status.mp4',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandTask },
            { TooltipAutomateState: TooltipAutomateState.expandTaskGroup },
            { TooltipAutomateState: TooltipAutomateState.showTaskOption },
        ],
        prevStepId: 'lsb-3',
        nextStepId: 'lsb-3.2',
        prerequisites: [ TooltipPrerequisites.TASKS ],
        registerFirstAnchorOnly: true,

    },
    'lsb-3.2': {
        stepId: 'lsb-3.2',
        title: 'TOOLTIP_TOUR.STEPS.LSB-3-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.LSB-3-2.DESCRIPTION',
        level: 3,
        animationURL: 'Extended task panel.mp4',
        prevStepId: 'lsb-3.1',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandTask },
            { TooltipAutomateState: TooltipAutomateState.hideTaskOption },
            { TooltipAutomateState: TooltipAutomateState.openTaskDetail },

        ],
        prerequisites: [ TooltipPrerequisites.TASKS ],
        positionOverride: {
            top: 100,
            left: 890,
        },
    },
    'data': {
        stepId: 'data',
        title: 'TOOLTIP_TOUR.STEPS.DATA.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA.DESCRIPTION',
        level: 1,
        animationURL: 'Bring Your Data onto Creately.mp4',
        learnMoreStep: 'data-1',
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
            TooltipPrerequisites.LSB_IS_DATA,

        ],
        skipIfPrerequisiteNoMet: false,
        positionOverride: {
            top: 50,
        },
        bodyDisplacementOverride: -50,
    },
    'data-1': {
        stepId: 'data-1',
        title: 'TOOLTIP_TOUR.STEPS.DATA-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-1.DESCRIPTION',
        level: 2,
        animationURL: 'Create a Database.mp4',
        prevStepId: 'data',
        nextStepId: 'data-2',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandEData },
            { OpenRightSidebar: 'none' },
        ],
        delay: 500,
        positionOverride: {
            left: 100,
        },
        prerequisites: [
            TooltipPrerequisites.EDATA,
        ],
    },
    'data-2': {
        stepId: 'data-2',
        title: 'TOOLTIP_TOUR.STEPS.DATA-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-2.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.DATA-2.TOOLTIP',
        level: 2,
        animationURL: 'Save your Customized Shape.mp4',
        prevStepId: 'data-1',
        nextStepId: 'data-3',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.collapseLSB },
            { OpenRightSidebar: 'shapeData' },
        ],
        left: true,
        delay: 1000,
        bodyDisplacementOverride: -50,
        tooltipPositionOverride: {
            left: -30,
            top: 60,
        },
    },
    'data-3': {
        stepId: 'data-3',
        title: 'TOOLTIP_TOUR.STEPS.DATA-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-3.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.DATA-3.TOOLTIP',
        level: 2,
        animationURL: 'Create Data Instances.mp4',
        prevStepId: 'data-2',
        nextStepId: 'data-4',
        stateChanges: [
            { OpenRightSidebar: 'none' },
            { TooltipAutomateState: TooltipAutomateState.expandEData },
        ],
        delay: 500,
        positionOverride: {
            top: 20,
        },
        bodyDisplacementOverride: -20,
        useAnchorOf: 'data',
    },
    'data-4': {
        stepId: 'data-4',
        title: 'TOOLTIP_TOUR.STEPS.DATA-4.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-4.DESCRIPTION',
        level: 2,
        animationURL: 'Import CSV.mp4',
        prevStepId: 'data-3',
        nextStepId: 'data-5',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandEData },
        ],
        positionOverride: {
            left: 40,
            top: -75,
        },
        useAnchorOf: 'data',
    },
    'data-5': {
        stepId: 'data-5',
        title: 'TOOLTIP_TOUR.STEPS.DATA-5.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-5.DESCRIPTION',
        level: 3,
        prevStepId: 'data-4',
        nextStepId: 'data-6',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.expandEData },
        ],
        delay: 1000,
        useAnchorOf: 'data',

    },
    'data-6': {
        stepId: 'data-6',
        title: 'TOOLTIP_TOUR.STEPS.DATA-6.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.DATA-6.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.DATA-6.TOOLTIP',
        level: 3,
        prevStepId: 'data-5',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.collapseLSB },
            { SelectedFloatingPanel: 'frames' },
        ],
        positionOverride: {
            top: 50,
        },
        bodyDisplacementOverride: -50,
    },

    'floating-tools': {
        stepId: 'floating-tools',
        title: 'TOOLTIP_TOUR.STEPS.FLOATING_TOOLS.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.FLOATING_TOOLS.DESCRIPTION',
        tooltip: 'TOOLTIP_TOUR.STEPS.FLOATING_TOOLS.TOOLTIP',
        animationURL: 'FLOATING_TOOLS.mp4',
        level: 1,
        nextStepId: 'plus-create',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.hidePlusCreateTooltip },
        ],
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
        ],
        canvasElement: true,
        preventOverlapping: [
            KnownApplicationLayouts.HEADER,
            KnownApplicationLayouts.LEFTSIDEBAR,
            KnownApplicationLayouts.MODALWINDOW,
            KnownApplicationLayouts.RIGHTSIDEBAR,
            KnownApplicationLayouts.USERMENU,
            KnownApplicationLayouts.DIAGRAMTOOLBAR,
            KnownApplicationLayouts.FLOATINGPANEL,
            KnownApplicationLayouts.SHAPEPROPACTIONS,
        ],
    },
    'plus-create': {
        stepId: 'plus-create',
        title: 'TOOLTIP_TOUR.STEPS.PLUS_CREATE.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.PLUS_CREATE.DESCRIPTION',
        level: 2,
        prevStepId: 'floating-tools',
        animationURL: 'PLUS_CREATE.mp4',
        stateChanges: [
            { TooltipAutomateState: TooltipAutomateState.showPlusCreateTooltip },
        ],
        prerequisites: [
            TooltipPrerequisites.SHAPE_SELECTED,
        ],
        canvasElement: true,
        preventOverlapping: [
            KnownApplicationLayouts.HEADER,
            KnownApplicationLayouts.LEFTSIDEBAR,
            KnownApplicationLayouts.MODALWINDOW,
            KnownApplicationLayouts.RIGHTSIDEBAR,
            KnownApplicationLayouts.USERMENU,
            KnownApplicationLayouts.DIAGRAMTOOLBAR,
            KnownApplicationLayouts.FLOATINGPANEL,
            KnownApplicationLayouts.SHAPEPROPACTIONS,
        ],
    },

    'root': {
        stepId: 'root',
        title: 'TOOLTIP_TOUR.STEPS.ROOT.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ROOT.DESCRIPTION',
        level: 0,
        nextStepId: 'root-1',
        animationURL: 'ROOT.mp4',
    },
    'root-1': {
        stepId: 'root-1',
        title: 'TOOLTIP_TOUR.STEPS.ROOT-1.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ROOT-1.DESCRIPTION',
        level: 0,
        prevStepId: 'root',
        nextStepId: 'root-2',
        animationURL: 'ROOT-1.mp4',
    },
    'root-2': {
        stepId: 'root-2',
        title: 'TOOLTIP_TOUR.STEPS.ROOT-2.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ROOT-2.DESCRIPTION',
        level: 0,
        prevStepId: 'root-1',
        nextStepId: 'root-3',
        animationURL: 'ROOT-2.mp4',
    },
    'root-3': {
        stepId: 'root-3',
        title: 'TOOLTIP_TOUR.STEPS.ROOT-3.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ROOT-3.DESCRIPTION',
        level: 0,
        prevStepId: 'root-2',
        nextStepId: 'root-4',
        animationURL: 'ROOT-3.mp4',
    },
    'root-4': {
        stepId: 'root-4',
        title: 'TOOLTIP_TOUR.STEPS.ROOT-4.TITLE',
        description: 'TOOLTIP_TOUR.STEPS.ROOT-4.DESCRIPTION',
        level: 0,
        prevStepId: 'root-3',
        animationURL: 'ROOT-4.mp4',
    },
};
