<div class="edata-library-item" [style.display]="isVisible ? 'none' : 'flex'" [draggable]="isDraggable"
    [style.padding-left]="indentation + 'px'">
    <div class="col-menu-item-heading-inner" (click)="toggle()">
        <div class="col-menu-item-icon">
            <svg *ngIf="isParent" class="nu-icon nu-ic-close-thin"
                [ngClass]="{ 'collapsed': (closed.value | async), 'expand': !(closed.value | async) }">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-thin"></use>
            </svg>
        </div>
    </div>
    <svg *ngIf="icon" class="nu-icon xmedium-grey">
        <use [attr.xlink:href]="iconHref"></use>
    </svg>
    <div *ngIf="!(editing | async)" [attr.title]="label.length > 15 ? label: ''"
        class="text-container fx-ellipsis">{{label}} <span *ngIf="refChangeCount>0">{{refChangeCount}}</span></div>
    <editable-label *ngIf="(editing | async)" class="col-menu-editable-label" [value]="label" [focus]="true"
        [selectAll]="true" (changedText)="titleChanged.emit($event);editing.next( false );">
    </editable-label>
    <abs-dropdown #dropdown
        [settings]="{ closeOnItemClicked: true, openOnHover: true, closeOnBlur: true, multiselectable: false }"
        direction="top" alignment="right">
        <simple-dropdown-button ddbutton>
            <button class="nu-btn-small nu-btn-icon">
                <svg class="nu-icon">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-more"></use>
                </svg>
            </button>
        </simple-dropdown-button>
        <simple-dropdown-item [style.display]="'block'" dditem>
            <a (click)="editing.next(true)">Rename</a>
        </simple-dropdown-item>
        <simple-dropdown-item [style.display]="'block'" dditem>
            <a (click)="deleteSmartSet.emit()">Delete</a>
        </simple-dropdown-item>
    </abs-dropdown>
</div>