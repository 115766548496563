import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { Observable, empty } from 'rxjs';
import { AbstractDeleteDiagram } from './abstract-delete-diagram.cmd';
import { DiagramInfoModel } from '../../diagram/model/diagram-info.mdl';
import { Router } from '@angular/router';
import { StateService } from 'flux-core';
import { switchMap, tap } from 'rxjs/operators';
import { ResourceModelStore } from '../../storage/resource-model.store';
import { CollaboratorType } from '../../collab/model/collaborator.mdl';

@Injectable()
@Command()
export class DeleteDiagram extends AbstractDeleteDiagram {

    constructor(
            protected state: StateService<any, any>,
            protected dataStore: DataStore,
            protected modelSubManager: ModelSubscriptionManager,
            protected router: Router ) {
        super( dataStore, modelSubManager )/* istanbul ignore next */;
    }

    /**
     * Delete the diagram from cache and stops diagram subscription.
     */
    public executeResult( response: any ): Observable<any> {
        const modelStore = this.dataStore.getModelStore( DiagramInfoModel ) as ResourceModelStore;
        // NOTE: Document Manage service handles the state update and
        // moving to the next available diagram.
        return super.executeResult( response ).pipe(
            switchMap( result => {
                const userId = this.state.get( 'CurrentUser' );
                return modelStore.getCollabs( this.resourceId ).pipe(
                    tap( collabs => {
                        const collabUser = collabs.find( c => c.id === userId );
                        if ( collabUser && collabUser.role > CollaboratorType.OWNER ) {
                            return this.router.navigate([ '../', this.state.get( 'CurrentDiagram' ), 'edit' ]);
                        } else {
                            return empty();
                        }
                    }),
                );
            }),
        );
    }
}

Object.defineProperty( DeleteDiagram, 'name', {
    value: 'DeleteDiagram',
});
