import { Injectable } from '@angular/core';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { clone } from 'lodash';

/**
 * Updates the Edata references for the document.
 *
 *
 */
@Injectable()
@Command()
export class AddEDataModel extends AbstractDiagramChangeCommand {

    /**
     * Input data format for the command
     */
    public data:  {
        eDataModelId: string,
    };

    constructor(
        protected ds: DiagramChangeService,
    ) {
        super( ds );
    }

    public prepareData() {
        if ( this.data.eDataModelId ) {
            let eDataArr;
            if ( this.changeModel.eData ) {
                if ( this.changeModel.eData.indexOf( this.data.eDataModelId ) === -1 ) {
                    eDataArr = clone( this.changeModel.eData );
                    eDataArr.push( this.data.eDataModelId );
                }
            } else {
                eDataArr = [ this.data.eDataModelId ];
            }
            this.changeModel.eData = eDataArr;
            return;
        }
        throw Error( 'There was an error setting the data to the modifier because data is not valid' );
    }
}

Object.defineProperty( AddEDataModel, 'name', {
    value: 'AddEDataModel',
});
