import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'logic-label',
    styleUrls: [ 'custom-entity-search.cmp.scss' ],
    template: `
        <!-- <div class="logic-label">
            {{ logic }}
        </div> -->
        <div class="col align-self-center button-raw">
            <div class="search-button-row">
                <button [ngClass]="(logic | async) === 'AND' ? 'active':''" class="search-button-logic nu-btn-med fx-span-width fx-no-padding fx-no-margin"
                    mat-stroked-button (click)="addAnd()">AND</button>
                    <div class="medium-text"> | </div>
                <button [ngClass]="(logic | async) === 'OR' ? 'active':''" class="search-button-logic nu-btn-med fx-span-width fx-no-padding fx-no-margin"
                    mat-stroked-button (click)="addOr()">OR</button>
            </div>
        </div>
    `,
})

export class LogicLabelComponent implements OnInit {

    @Input() public logic: BehaviorSubject<string>;
    constructor() { }

    ngOnInit() { }

    public addAnd() {
        this.logic.next( 'AND' );
    }
    public addOr() {
        this.logic.next( 'OR' );
    }
}
