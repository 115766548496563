import { Logger } from 'flux-core';
import { TextPostion } from './../../framework/text/text-position';
import { ConnectorTextDataModel } from './connector-text-data.mdl';
import {
    LinePath,
    CurvePath,
    INode,
    Rectangle,
    Curve,
    Line,
    ILinkedList,
    ComplexType,
} from 'flux-core';
import { IConnectorDefinition } from 'flux-definition';
import { AbstractShapeModel } from './abstract-shape.mdl';
import { ILineStyle, IPoint2D, IPath } from 'flux-definition';

/**
 * This is the full representation of a connector. All data necessary to render a connector
 * will be in this model.
 * There will be a extended version of this model outside of this module.
 *
 * @author Ramishka
 * @since 2017-09-22
 */
export class ConnectorDataModel extends AbstractShapeModel implements IConnectorDefinition {
    /**
     * Prepares a connector path using given connector points.
     */
    public static createPathFromPoints( points: Partial<IConnectorEndPoint>[]): any {
        if ( points.length < 2 ) {
            throw new Error( 'Failed to create connector path. It should have at least 2 points.' );
        }
        const path: any = {};
        let previous = null;
        for ( let i = 0; i < points.length; ++i ) {
            const point = { ...points[i] };
            // NOTE: head and tail point ids should be fixed (using 'h' and 't')
            point.id = i === 0 ? 'h' : i === points.length - 1 ? 't' : `p${i}`;
            point.x = Math.round( point.x );
            point.y = Math.round( point.y );
            if ( i === 0 ) {
                path.headId = point.id;
                point.prevId = null;
            }
            if ( i === points.length - 1 ) {
                path.tailId = point.id;
                point.nextId = null;
            }
            if ( previous ) {
                previous.nextId = point.id;
                point.prevId = previous.id;
            }
            if ( i === 0 || i === points.length - 1 ) {
                if ( point.shapeId && point.gluepointId ) {
                    point.gluepointLocked = Boolean( point.gluepointLocked );
                } else if ( point.shapeId ) {
                    point.gluepointId = null;
                    point.gluepointLocked = null;
                } else {
                    point.shapeId = null;
                    point.gluepointId = null;
                    point.gluepointLocked = null;
                }
            }
            path[ point.id ] = point;
            previous = point;
        }
        return path;
    }

    /**
     * Returns all points in the connector path.
     */
    public static getPathPoints( path: any ): ( IConnectorPoint | IConnectorEndPoint )[] {
        if ( !path ) {
            return [];
        }
        const points = [];
        let pointer = path.headId as string;
        while ( pointer ) {
            const point = path[pointer] as IConnectorPoint;
            points.push( point );
            pointer = point.nextId;
        }
        return points;
    }

    /**
     * The path used to draw the connector. All points in the path
     * will implement the IConnectorPoint interface. The first and
     * the last point in the path will implement IConnectorEndPoint.
     */
    public path: ILinkedList<IConnectorPoint | IConnectorEndPoint>;

    /**
     * Texts for the connector, single connector can have multiple texts
     * Each text contains data to position itself on the shape
     * and also the text and text styles as html.
     */
    @ComplexType({ '*' : ConnectorTextDataModel })
    public texts: { [id: string]: ConnectorTextDataModel } = {};

    /**
     * This flag determines whether when pathing the connector can draw
     * over other shapes or if it should draw around them. The default
     * connector and most other connector types draw over shapes.
     */
    public drawAroundShapes: boolean;

    /**
     * This represents the visual connector ends and icons on them
     */
    public ends: IConnectorEnds = {};

    /**
     * This adds a bump on a line if it crosses another line. For bumps to be
     * created, both lines must have this flag set to "true". The line on top
     * by z-index gets the bump. The default value is "false"
     */
    public showBumps: boolean = false;

    /**
     * Line styles for the connector
     */
    public style: ILineStyle = {
        lineColor: '#1a1a1a',
        lineThickness: 2,
    };

    /**
     * Used to toggle visibility of a connector on the canvas. By default connectors
     */
    public hidden: boolean = false;

    /**
     * Used to toggle highlighted text mode on the connectors
     */
    public isHighlighted: boolean = false;

    /**
     * used to determine if connector draw style can be changed. Setting it to true for a
     * connector will prevent users from changing that connector's draw style.
     */
    public preventChangeDrawStyle: boolean = false;

    /**
     * Creates a new model instance
     * @param id id of the shape
     * @param extension
     */
    public constructor( id: string, extension?: Object ) {
        super( id, extension )/* istanbul ignore next */;
    }

    /**
     * Returns the bounds of this connector. The bounds
     * can change as a point in the connector moves
     */
    public get bounds(): Rectangle {
        const points = this.getPoints();
        if ( !points.length ) {
            return new Rectangle( 0, 0, 0, 0 );
        }
        if ( this.entryClassName === 'ConnectorCurved' ) {
            return this.getCurvedPathBounds();
        }
        return Rectangle.withPoints( ...points );
    }

    /**
     * Returns the total length of the connector
     */
    public get length() {
        return this.getPath().length;
    }

    /**
     * Returns all points in the connector path.
     */
    public getPoints(): ( IConnectorPoint | IConnectorEndPoint )[] {
        return ConnectorDataModel.getPathPoints( this.path );
    }

    /**
     * This function returns the shape bounds considering the text bounds
     */
    public getBoundsWithTexts() {
        if ( !this.hasAnyText ) {
            return this.bounds;
        }
        const boundsWithText = Rectangle.from( this.bounds );
        for ( const key of Object.keys( this.texts )) {
            const text = this.texts[ key ];
            if ( typeof text.height === 'number' && typeof text.width === 'number' ) {
                const position = TextPostion.forConnector(
                        <ConnectorDataModel>this,
                        text,
                        { x: 0, y: 0, width: text.width, height: text.height } as any );
                if ( position ) {
                    // FIXME: This if condition should not be here, for some unknown reason getting
                    //        undefined. I didn't investigate why this is happening. Please fix this.
                    const textBounds = new Rectangle( position.x, position.y, text.width, text.height );
                    boundsWithText.absorb( textBounds );
                } else {
                    Logger.error( `Unable to find connector text height and width "${text.id}" on diagram` );
                }
            }
        }
        return boundsWithText;
    }

    /**
     * Calculates the coordinates of the point on the connector at the
     * given position.
     *
     * @param   pos    A decimal in the range [0-1]
     *                 which indicates a point some proportion along the length of the connector.
     *
     * @param start boolean The point to start calculation from, by default it measures
     *                      fron the start point,if false, it measures from the end point
     */
    public split( pos: number, start: boolean = true ): IPoint2D {
        const location = this.length * pos;
        return this.splitByLength( location, start );
    }

    /**
     * Calculates the coordinates of the point on the given connector at the
     * given position.
     *
     * @param length number  indicates a point some proportion along the length of connector
     *
     * @param start boolean The point to start calculation from, by default it measures
     *                      fron the start point,if false, it measures from the end point
     */
    public splitByLength( location: number, start: boolean = true ): IPoint2D {
        return this.getPath().splitByLength( location, start );
    }

    /**
     * Calculates the nearest point to the given point on the path.
     * the given point can be either on the path or outside
     * returns the nearest point and the length fraction to that point
     * along the path
     *
     * @param point IPoint2D  The test point
     * @param { point: IPoint2D, length: number } The nearest point cordinates and the length fraction along the path
     */
    public getNearestPoint( point: IPoint2D ): { point: IPoint2D, location: number } {
        return this.getPath().getNearestPoint( point );
    }

    /**
     * Returns the IPath of the connector,
     * depending on the Connector type
     */
    protected getPath(): IPath {
        const className = this.entryClassName;
        if ( className === 'ConnectorStraight' ||
            className === 'ConnectorDouble' ||
            className === 'ConnectorDivided' ||
            className === 'ConnectorTriple' ) {
            return new LinePath( this.getPoints());
        } else if ( className === 'ConnectorCurved' ) {
            return new CurvePath( this.getPoints());
        } else if ( className === 'ConnectorAngled' ||
            className === 'ConnectorSmoothAngled'  ||
            className === 'ConnectorDoubleWavy' ||
            className === 'ConnectorWavy' ||
            className === 'ConnectorAngledDivided' ||
            className === 'ConnectorIndented' ) {
                const points = [];
                this.getPoints().forEach( p => {
                    if ( p.c1 ) {
                        points.push( p.c1 );
                    }
                    points.push( p );
                });
                return new LinePath( points );
        }
    }

    /**
     * Bounds are calculated differently for curved paths
     * because the curve can exists outside point boundaries.
     */
    protected getCurvedPathBounds( points = undefined ): Rectangle {
        points = points || this.getPoints();
        const bounds = Rectangle.withPoints( points[0]);
        for ( let i = 1; i < points.length; ++i ) {
            const pointA = points[i - 1];
            const pointB = points[i];
            const segmentBounds = ( pointB.c1 && pointB.c2 ) ?
                Curve.fromPoints( pointA, pointB ).bounds :
                new Line( pointA, pointB ).bounds();
            bounds.absorb( segmentBounds );
        }
        return bounds;
    }


}

/**
 * Describes points where connectors should draw bumps over other connectors.
 */
export interface IConnectorBump extends IPoint2D {
    /**
     * The arc radius of the connector bump.
     */
    radius?: number;
}

/**
 * This is an interface that defines a point of the path used
 * to draw a connector. It may also include control points.
 */
export interface IConnectorPoint extends INode, IPoint2D {
    /**
     * First control point or the point where angled connectors turn.
     */
    c1?: IPoint2D;

    /**
     * The second control point on curved connectors.
     */
    c2?: IPoint2D;

    /**
     * An array of points where connector goes over another connector
     * for each segment on the connector path. A single connector point
     * may have multiple connector segments.
     */
    bumps?: IConnectorBump[][];

    /**
     * For each path this property indicates whether the
     * connector angled paths are manually adjusted by the user.
     * true - manually adjusted.
     * false - system generated.
     */
    pathAdjustedManually?: boolean;
}

/*
 * Connector end points are also stored on the connector path but they
 * hold more information than other path points.
 */
export interface IConnectorEndPoint extends IConnectorPoint {
    /**
     * The direction the endpoint is facing towards.
     * Arrow Heads drawn on endpoints will face this direction.
     */
    direction: number;

    /**
     * The id of the shape the connector endpoint is connected to.
     */
    shapeId?: string;

    /**
     * The id of the gluepoint on the shape the endpoint is connected to.
     */
    gluepointId?: string;

    /**
     * Will be set to true if the user selected a specific glue point.
     * If locked, the application will not override the glupoint.
     */
    gluepointLocked?: boolean;

    /**
     * Will be set to true if the connector endpoint is on a shape,
     * but the endpoint is not connected to a gluepoint.
     */
    onShape?: boolean;

    /**
     * The x and y coordinates of endpoint relative to the shape.
     * The value of x and y should be ratios between 0 and 1
     */
    position?: {
        x: number,
        y: number,
    };
}

/**
 * This represents the visual connector ends and icons on them
 */
export interface IConnectorEnds {
    /**
     * Icon/arrow options for the user to select from for both sides of the connector.
     */
    allOptions?: {
        options?: Array<string>;
        defaults?: boolean;
    };

    /**
     * Icon/arrow options for the user to select from for the starting side of the connector.
     */
    fromOptions?: {
        options?: Array<string>;
        defaults?: boolean;
    };

    /**
     * Icon/arrow options for the user to select from for the ending side of the connector.
     */
    toOptions?: {
        options?: Array<string>;
        defaults?: boolean;
    };

    /**
     * The path and entry class name of an arrow head or line end to attach to the starting
     * side of the connector.
     */
    from?: string;

    /**
     * The path and entry class name of an arrow head or line end to attach to the ending
     * side of the connector.
     */
    to?: string;
}
