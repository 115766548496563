import { HorizontalAlignment, VerticalAlignment } from 'flux-core';

export const ALIGNMENT_OPTIONS = [
    {
        id: 'h-left',
        isVertical: false,
        alignment: HorizontalAlignment.Left,
        label: 'Align Left',
        icon: 'align-left',
    },
    {
        id: 'h-center',
        isVertical: false,
        alignment: HorizontalAlignment.Center,
        label: 'Align Horizontal Center',
        icon: 'align-v-center',
    },
    {
        id: 'h-right',
        isVertical: false,
        alignment: HorizontalAlignment.Right,
        label: 'Align Right',
        icon: 'align-right',
    },
    {
        id: 'v-top',
        isVertical: true,
        alignment: VerticalAlignment.Top,
        label: 'Align Top',
        icon: 'align-top',
    },
    {
        id: 'v-center',
        isVertical: true,
        alignment: VerticalAlignment.Center,
        label: 'Align Vertical Center',
        icon: 'align-h-center',
    },
    {
        id: 'v-bottom',
        isVertical: true,
        alignment: VerticalAlignment.Bottom,
        label: 'Align Bottom',
        icon: 'align-bottom',
    },
];


export const LAYOUTING_DATA = {
    'elk-box': {
      id: 'elk-box',
      layoutingData: {
        source: 'elk',
        algorithm: 'box',
        padding: [ 10, 10, 10, 10 ],
        spacing: [ 10, 10, 10, 10 ],
        options: {
          'org.eclipse.elk.algorithm': 'box',
        },
        aspectRatio: 'dynamic',
      },
      label: 'Grid',
      icon: 'layout-grid',
    },
    'native-list': {
      id: 'native-list',
      layoutingData: {
        source: 'native',
        algorithm: 'simpleVerticalList',
        padding: [ 10, 10, 10, 10 ],
        spacing: [ 10, 10, 10, 10 ],
        options: {
          nodeBehavior: 'fill',
        },
      },
      label: 'List',
      icon: 'layout-list',
    },
    'elk-rectpacking': {
      id: 'elk-rectpacking',
      layoutingData: {
        source: 'elk',
        algorithm: 'rectpacking',
        padding: [ 10, 10, 10, 10 ],
        spacing: [ 10, 10, 10, 10 ],
        options: {
          'org.eclipse.elk.algorithm': 'rectpacking',
        },
        aspectRatio: 'dynamic',
      },
      label: 'Pack',
      icon: 'layout-pack',
    },
    'elk-mrtree-down': {
      id: 'elk-mrtree-down',
      layoutingData: {
        source: 'elk',
        algorithm: 'mrtree-down',
        options: {
          'org.eclipse.elk.algorithm': 'mrtree',
          'org.eclipse.elk.direction': 'DOWN',
          'org.eclipse.elk.spacing.nodeNode': '80',
        },
      },
      isVertical: true,
      alignment: VerticalAlignment.Top,
      label: 'Tree - Down',
      icon: 'layout-tree-down',
      type: 'tree',
    },
    'elk-mrtree-left': {
      id: 'elk-mrtree-left',
      layoutingData: {
        source: 'elk',
        algorithm: 'mrtree-left',
        options: {
          'org.eclipse.elk.algorithm': 'mrtree',
          'org.eclipse.elk.direction': 'LEFT',
          'org.eclipse.elk.spacing.nodeNode': '80',
        },
      },
      isVertical: true,
      alignment: VerticalAlignment.Top,
      label: 'Tree - Left',
      icon: 'layout-tree-left',
      type: 'tree',
    },
    'elk-mrtree-up': {
      id: 'elk-mrtree-up',
      layoutingData: {
        source: 'elk',
        algorithm: 'mrtree-up',
        options: {
          'org.eclipse.elk.algorithm': 'mrtree',
          'org.eclipse.elk.direction': 'UP',
          'org.eclipse.elk.spacing.nodeNode': '100',
        },
      },
      isVertical: true,
      alignment: VerticalAlignment.Top,
      label: 'Tree - Up',
      icon: 'layout-tree-up',
      type: 'tree',
    },
    'elk-mrtree-right': {
      id: 'elk-mrtree-right',
      layoutingData: {
        source: 'elk',
        algorithm: 'mrtree-right',
        options: {
          'org.eclipse.elk.algorithm': 'mrtree',
          'org.eclipse.elk.direction': 'RIGHT',
          'org.eclipse.elk.spacing.nodeNode': '80',
        },
      },
      label: 'Tree - Right',
      icon: 'layout-tree-right',
      type: 'tree',
    },
    'elk-layered-up': {
      id: 'elk-layered-up',
      layoutingData: {
        source: 'elk',
        options: {
          'org.eclipse.elk.algorithm': 'layered',
          'org.eclipse.elk.spacing.nodeNode': '80',
          'org.eclipse.elk.spacing.edgeEdge': '80',
          'org.eclipse.elk.spacing.edgeNode': '80',
          'org.eclipse.elk.direction': 'UP',
          'org.eclipse.elk.layered.spacing.nodeNodeBetweenLayers': '80',
          'org.eclipse.elk.layered.crossingMinimization.forceNodeModelOrder': false,
          'org​.eclipse​.elk​.layered​.spacing​.edgeEdgeBetweenLayers': '80',
          'org​.eclipse​.elk​.layered​.spacing​.edgeNodeBetweenLayers': '80',
          // 'org.eclipse.elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',
          'org.eclipse.elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
        },
        aspectRatio: 'dynamic',
      },
      label: 'Flow - Up',
      icon: 'layout-flow-up',
      type: 'tree',
    },
    'elk-layered-down':     {
        id: 'elk-layered-down',
        layoutingData: {
            source: 'elk',
            options: {
                'org.eclipse.elk.algorithm': 'layered',
                'org.eclipse.elk.spacing.nodeNode': '80',
                'org.eclipse.elk.spacing.edgeEdge': '80',
                'org.eclipse.elk.spacing.edgeNode': '80',
                'org.eclipse.elk.direction': 'DOWN',
                'org.eclipse.elk.layered.spacing.nodeNodeBetweenLayers': '80',
                'org.eclipse.elk.layered.crossingMinimization.forceNodeModelOrder':  false,
                'org​.eclipse​.elk​.layered​.spacing​.edgeEdgeBetweenLayers': '80',
                'org​.eclipse​.elk​.layered​.spacing​.edgeNodeBetweenLayers': '80',
                // 'org.eclipse.elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',
                'org.eclipse.elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
            },
            aspectRatio: 'dynamic',
        },
        label: 'Flow - Down',
        icon: 'layout-flow-down',
        type: 'tree',
    },
    'elk-layered-left': {
        id: 'elk-layered-left',
        layoutingData: {
            source: 'elk',
            options: {
                'org.eclipse.elk.algorithm': 'layered',
                'org.eclipse.elk.spacing.nodeNode': '80',
                'org.eclipse.elk.spacing.edgeEdge': '80',
                'org.eclipse.elk.spacing.edgeNode': '80',
                'org.eclipse.elk.direction': 'LEFT',
                'org.eclipse.elk.layered.spacing.nodeNodeBetweenLayers': '80',
                'org.eclipse.elk.layered.crossingMinimization.forceNodeModelOrder':  false,
                'org​.eclipse​.elk​.layered​.spacing​.edgeEdgeBetweenLayers': '80',
                'org​.eclipse​.elk​.layered​.spacing​.edgeNodeBetweenLayers': '80',
                // 'org.eclipse.elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',
                'org.eclipse.elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
            },
            aspectRatio: 'dynamic',
        },
        label: 'Flow - Left',
        icon: 'layout-flow-left',
        type: 'tree',
    },
    'elk-layered-right': {
        id: 'elk-layered-right',
        layoutingData: {
            source: 'elk',
            options: {
                'org.eclipse.elk.algorithm': 'layered',
                'org.eclipse.elk.spacing.nodeNode': '80',
                'org.eclipse.elk.spacing.edgeEdge': '80',
                'org.eclipse.elk.spacing.edgeNode': '80',
                'org.eclipse.elk.direction': 'RIGHT',
                'org.eclipse.elk.layered.spacing.nodeNodeBetweenLayers': '80',
                'org.eclipse.elk.layered.crossingMinimization.forceNodeModelOrder':  false,
                'org​.eclipse​.elk​.layered​.spacing​.edgeEdgeBetweenLayers': '80',
                'org​.eclipse​.elk​.layered​.spacing​.edgeNodeBetweenLayers': '80',
                // 'org.eclipse.elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',
                'org.eclipse.elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
            },
            aspectRatio: 'dynamic',
        },
        label: 'Flow - Right',
        icon: 'layout-flow-right',
        type: 'tree',
    },
};


export const VIZ_DATA = {

    // Click creately viz template in template panel
    template: {
        id: 'template',
        label: '',
        userInput: {
            description: 'Accuracy increases with more background and details.',
            header: 'What should this template cover?',
            placeholder: 'ex. this is for a product launch for our new burger range that features... for our target audience of... to achieve...',
        },
        loadingText: 'Generating template',
        templatePanel: {},
        rendering: 'template',
        requestConfig: {},
        context: [ 'template' ],
    },

    // Selection - both single and multi select
    generateSimilarItems: {
        id: 'generateSimilarItems',
        label: 'Generate 5 similar items',
        context: [ 'select-single' ],
        loadingText: 'Generating similar items',
        // Create new items
        rendering: 'create',
        requestConfig: {
            count: 5,
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
            },
        },
        styleRefShape: 'selected',
    },
    generateCounterPoints: {
        id: 'generateCounterPoints',
        label: 'Generate 5 counter points',
        context: [ 'select-single' ],
        loadingText: 'Generating counter points',
        rendering: 'create',
        requestConfig: {
            count: 5,
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
            },
        },
        styleRefShape: 'selected',
    },
    generateWithCustomPrompt: {
        id: 'generateWithCustomPrompt',
        label: 'Generate with custom prompts',
        context: [ 'select-single' ],
        userInput: {
            description: 'Provide a detailed description and background information to get the best results',
            header: 'What do you want to generate?',
            placeholder: 'ex. a plan for our social media campaign for the new burger range',
        },
        loadingText: 'Generating',
        rendering: 'create',
        requestConfig: {
            count: 5,
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
            },
        },
        styleRefShape: 'selected',
    },

    //  Multi select  - group
    groupByIdentifiedThemes: {
        id: 'groupByIdentifiedThemes',
        label: 'Group by identified themes',
        loadingText: 'Grouping by identified themes',
        context: [ 'select-multi' ],
        template: 'VERTICAL.GROUP',
        // Will move existing items into a dynamically generated table
        rendering: 'group',
        requestConfig: {
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
            },
        },
    },
    groupBySentiment: {
        id: 'groupBySentiment',
        label: 'Group by sentiment',
        loadingText: 'Grouping by sentiment',
        context: [ 'select-multi' ],
        rendering: 'group',
        template: 'VERTICAL.GROUP',
        requestConfig: {
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
            },
        },
    },
    groupByListedTags: {
        id: 'groupByListedTags',
        label: 'Group by listed tags',
        loadingText: 'Grouping by listed tags',
        context: [ 'select-multi' ],
        rendering: 'group',
        template: 'VERTICAL.GROUP',
        requestConfig: {
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
            },
        },
    },
    groupCustom: {
        id: 'groupCustom',
        label: 'Group with a custom prompt',
        context: [ 'select-multi' ],
        loadingText: 'Grouping by custom input',
        userInput: {
            description: 'Describe how to group or categorize the items. You can even try multiple dimensions.',
            header: 'Organize and Group automatically',
            placeholder: 'ex. group these tasks by project status and tags.',
        },
        rendering: 'group',
        requestConfig: {
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
            },
        },
    },

    // Multi select - transform
    generateKanbanWithActionItems: {
        id: 'generateKanbanWithActionItems',
        promptType: 'generateKanbanWithActionItems',
        loadingText: 'Generating kanban board',
        label: 'Generate a kanban board with action items',
        context: [ 'select-single', 'select-multi' ],
        rendering: 'create-group', // Populate new item in a template
        template: 'VERTICAL.KANBAN',
        requestConfig: {
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
            },
        },
    },
    generateFlowchartFromSelectedItems: {
        id: 'generateFlowchartFromSelectedItems',
        promptType: 'generateFlowchartFromSelectedItems',
        loadingText: 'Generating flowchart',
        label: 'Generate a flowchart from selected items',
        context: [ 'select-single', 'select-multi' ],
        rendering: 'create',
        requestConfig: {
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
            },
        },
    },
    customTransformWithTemplate: {
        id: 'customTransformWithTemplate',
        promptType: 'customTransformWithTemplate',
        loadingText: 'Generating custom transform',
        label: 'Custom transform with a template',
        context: [ 'select-single', 'select-multi' ],
        templatePanel: {},
        rendering: 'template',
        requestConfig: {
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
            },
        },
    },

    // PlusCreate
    plusCreateNextItem: {
        id: 'plusCreateNextItem',
        label: 'Next item',
        loadingText: 'Generating item',
        context: [ 'plus-create' ],
        rendering: 'plus-create',
        requestConfig: {
            count: 1,
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
                bounds: true,
            },
            connectedShapesData: true,
        },
    },
    plusCreateNextFiveItem: {
        id: 'plusCreateNextFiveItem',
        label: 'Next 5 item',
        loadingText: 'Generating items',
        context: [ 'plus-create' ],
        rendering: 'plus-create',
        requestConfig: {
            count: 5,
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
                bounds: true,
            },
            connectedShapesData: true,
        },
    },
    plusCreateCustom: {
        id: 'plusCreateCustom',
        label: 'Custom',
        userInput: {
            description: 'Explain what you want to generate and how many of it.',
            header: 'Add the next set of items',
            placeholder: 'Ex. generate 3 more items from a risk analysis perspective',
        },
        loadingText: 'Generating items',
        context: [ 'plus-create' ],
        rendering: 'plus-create',
        requestConfig: {
            count: 5,
            shapeJson: {
                shapeType: true,
                primaryText: true,
                description: true,
                properties: true,
                bounds: true,
            },
            connectedShapesData: true,
        },
    },
};
