// tslint:disable:max-file-line-count
import { IAlignmentNode } from '../../../editor/diagram/autoalign/alignment-node-locator';
import { ComplexType, Point, Matrix, enumerable, MapOf } from 'flux-core';
import { DiagramModel } from '../../diagram/model/diagram.mdl';
import { ShapeDataModel, DiagramDataModel, TextFormatter } from 'flux-diagram-composer';
import {
    IDataItem,
    IContextualToolbarItem,
    IShapeContextualToolbarAware,
    IShapeDefinition,
    IShapePort,
    IPoint2D,
    DEFUALT_TEXT_STYLES,
    IShapeModel,
    ShapeType,
    DataType,
    ITransform,
} from 'flux-definition';
import { ShapeTextModel } from './text/shape-text.mdl';
import { ISidebarContextAware, ISidebarPanelData } from '../../../framework/ui/side-bar/sidebar-framework';
import { ICanvasContextMenuAware } from '../../../framework/interaction/context-menu/canvas-context-menu-aware.i';
import { GluePointModel } from './gluepoint.mdl';
import { IContextualToolbarAware } from '../../../framework/interaction/contextual-toolbar/contextual-toolbar-aware.i';
import { ConnectorModel, IConnectorEndPointWithRef } from './connector.mdl';
import { ShapeStyle } from '../../../editor/feature/style/shape-style';
import { IPlusCreateOptions } from './plus-create-options.i';
import { VisualVectorModel } from './visual-vector.mdl';
import { IDataFeatureItem } from '../../../framework/feature/data-feature-item.i';
import { isObject, isEmpty, find, merge } from 'lodash';
import { ALIGNMENT_OPTIONS, LAYOUTING_DATA } from './shape-common';
import { Proxied } from '@creately/sakota';
import { PeopleRoleType } from '../../../framework/ui/components/people-picker-uic.cmp';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import * as Carota from '@creately/carota';
import { DiagramFactory } from '../../diagram/diagram-factory';
import { CoreDataFieldService } from '../../data-defs';


// tslint:disable:member-ordering

/**
 * Describes how a connector connects to a shape. Contains the connector
 * model and the connector endpoint.
 */
export interface IConnectorConnectionInfo {
    connector: ConnectorModel;
    endpoint: IConnectorEndPointWithRef;
}

/**
 * This is the concrete model of a shape. Extends the ShapeDataModel
 * which is the full data of a shape. This model will contain all data
 * processing and manipulation functionality needed for the shape data.
 *
 * @author hiraash
 * @since 2017-09-11
 */
export class ShapeModel extends ShapeDataModel implements
                                            IShapeDefinition,
                                            ISidebarContextAware,
                                            ICanvasContextMenuAware,
                                            IContextualToolbarAware,
                                            IShapeContextualToolbarAware {

    /**
     * Returns an array of all connectors connected to given shape.
     */
    public static getConnectedConnectors(
        diagram: DiagramDataModel, shapeId: string, outShapeId?: string ): IConnectorConnectionInfo[] {
        const connectors: IConnectorConnectionInfo[] = [];
        if ( !diagram.shapes ) {
            return connectors;
        }

        const shape = diagram.shapes[ shapeId ] as ShapeModel;
        if ( shape && shape.connectorIds ) {
            shape.connectorIds
                .filter( id => !!diagram.shapes[ id ])
                .map( id => {
                    const connector = diagram.shapes[ id ] as ConnectorModel;
                    const head = connector.getFromEndpoint( diagram as DiagramModel );
                    const tail = connector.getToEndpoint( diagram as DiagramModel );
                    if ( head && head.shape && ( head.shape.id === shapeId || head.shape.id === outShapeId )) {
                        connectors.push({ connector, endpoint: head });
                    }
                    if ( tail && tail.shape && ( tail.shape.id === shapeId || tail.shape.id === outShapeId )) {
                        connectors.push({ connector, endpoint: tail });
                    }
            });
            return connectors;
        }

        Object.keys( diagram.shapes ).forEach( id => {
            const connector = diagram.shapes[id];
            if ( !( connector instanceof ConnectorModel )) {
                return;
            }
            const head = connector.getFromEndpoint( diagram as DiagramModel );
            const tail = connector.getToEndpoint( diagram as DiagramModel );
            if ( head?.shape?.id === shapeId ) {
                connectors.push({ connector, endpoint: head });
            }
            if ( tail?.shape?.id === shapeId ) {
                connectors.push({ connector, endpoint: tail });
            }
        });
        return connectors;
    }

    /**
     * Texts for the shape, single shape can have multiple texts
     * Each text contains data to position itself on the shape
     * and also the text and text styles as html.
     */
    @ComplexType({ '*' : ShapeTextModel })
    public texts: { [id: string]: ShapeTextModel } = {};

    /**
     * The set of gluepoints that belong to this shape. These
     * are in priciple coming from the definition. The defined
     * gluepoints are not expected to change in principle.
     */
    @ComplexType({ '*': GluePointModel })
    public gluepoints: { [id: string]: GluePointModel };

    /**
     * If set to true, plus create will only create connections to
     * the first gluepoint in the sequence of gluepoints. it is importand for
     * Genogram shapes that connect to the bottom by default.
     */
    public hasDefaultGluePoint: boolean = false;

    /**
     * This property is to store the connector ids in the shape model since the connectors prperty
     * which calls getConnectedConnectors is inefficient when the diagram has so many shapes
     * as it loops thorugh all the connector end points in the diagram
     */
    public connectorIds: string[] = null;

    /**
     * stickyGluePoints
     * defines the pair of sticky glue points for each
     * glue point availble for this shap.
     * e.g.
     *  "gpnorth" : [
     *     { "x": 0, "y": 0 },
     *     { "x": 0, "y": 1 }
     *   ],
     * means that "gpnorth" glue point has two glue points
     * with the specified x y values, type is relative
     */
    public stickyGluePoints: { [id: string]: { x, y, xType?, yType? }[]};

    /**
     * An array of data fields to be rendered in the shape view
     */
    public renderedDataFields: string[] = [];

    /**
     * Determines whether a shape should support data fields toggled on view
     */
    public isDataFieldsDisabledInView: boolean = false;

    /**
     * Contains style properties which are used to style a shape's
     * text color, line and fill styles.
     */
    @ComplexType()
    public style: ShapeStyle;

    /**
     * A port is what enables creating a connection with another
     * shape with a compatible port. Ports use handshakes to check
     * whether they are compatible.
     */
    public ports?: IShapePort[];

    /**
     * The minimum bounds this shape can have. The shape should not be smaller than
     * this bounds at any given time.
     */
    public minBounds: { width: number, height: number };

    /**
     * A shape can be added behind other shapes on the canvas.
     * The shape will be placed behind all existing shapes of
     * types defined in the <code>sendToBack</code> array.
     */
    public sendToBack?: { defId: string, version: number }[];

     /**
      * This property holds the shape specific features defined in this
      * shapes definition. This only exists as a means to access the
      * feature definitions for very specific purposes.
      * This property MUST NOT be used to access or make decisions about
      * features. All feature related operations must be done via the
      * {@link FeatureList}. The featue list contains an up to date list of
      * features registered across the app at all time. Feature list
      * may also have made changes to the features defined on the shape
      * definition which are not relected in this property.
      */
    public features: any[];

    /**
     * This value determines if connectors should snap to the edge of the shape.
     */
    public snapToShape: boolean = false;

    /**
     * An array of values that determinse which sides of the shape a connector should
     * snap to. Connectors should only snap to flat sides that align to the grid.
     */
    public snapEdges: string[];

    public supportLazyLoading: boolean = false;

    /**
     * NOTE: This property is avaible when the model is fetched from the
     * viewpport serivce and the intention of this is to append viewport
     * specific caclulations to the model in place without having to clone
     * the shape models in viewport service which is a performance hit.
     */
    public viewTransform?: ITransform;
    public get viewBounds() {
        if ( !this.viewTransform ) {
            throw new Error( 'ShapeModel.viewTransform is undefined. Use viewport service to get the shape model' );
        }
        return this.defaultBounds
            .clone()
            .transform( this.viewTransform );
    }

    /**
     * A set of visual vectors belongs to the shape. These are in
     * principle comming from the definition. A visual vector is
     * what enables the alteration of internal strcture of the
     * shape without any transformation.
     */
    @ComplexType({ '*' : VisualVectorModel })
    public vectors?: { [id: string]: VisualVectorModel };

    /**
     * Shape creator and create date
     */
    public createdBy: { userId: string, time: number };

    /**
     * Array of shape editors with last edited date, each editor should be stored once
     */
    public editedBy: { userId: string, time: number }[];

    /**
     * Get connectors with option to add
     * @param root Root diagram model
     * @param outShapeId Optional connected shape id to get connected endpoint data
     * @returns connectors
     */
    public getConnectors( root: DiagramModel, outShapeId?: string ) {
        return ShapeModel.getConnectedConnectors( root, this.id, outShapeId );
    }

    public getDataItems( root: DiagramModel ): MapOf<IDataItem<DataType>> {
        const data = DiagramFactory.createData( CoreDataFieldService.getCoreDataDefs(), this.data );
        if ( !this.dataSetId ) {
            return data || {};
        }
        const def = root.getDataDef( this.id ) || {};
        const retVal = {};
        for ( const key in data ) {
            const defData = def[ key ] || {};
            const value = data[ key ].value;
            retVal[ key ] = { ...data[ key ], ...defData, value };
        }
        return retVal;
    }

    /**
     * If present, get parent this shape is contained within
     * @param root Root diagram model
     * @returns parent
     */
    public getParent( root: DiagramModel ): ShapeModel {
        const shapes = Object.values( root?.shapes );
        return shapes.find(( shape: ShapeModel ) => shape?.children?.[this.id]) as ShapeModel;
    }

    protected _createOptions: IPlusCreateOptions;

    constructor( id: string, extention?: Object ) {
        super( id, extention )/* istanbul ignore next */;
    }

    /**
     * The plus create definition based on the shape definition
     * Specifies how plus create works. More details can be found in
     * {@link IPlusCreateOptions}
     */
    @enumerable( true )
    public get create(): IPlusCreateOptions {
        return this._createOptions;
    }

    /**
     * TODO: Move to ShapeModelFactory
     */
    public set create( value: IPlusCreateOptions ) {
        if ( !value ) {
            this._createOptions = undefined;
            return;
        }
        const starter = isObject( value ) ? value : {};
        this._createOptions = this.getDefaultCreateOptions( starter );
    }

    /**
     * This function Implements ISidebarContextAware method
     * and defines the side bar panels for the Shape Model
     * @returns string[] The list of panel ids
     */
    public getSidebarPanels(): ISidebarPanelData[] {
        const shapePanelFeatures: any = [
            { featureId: 'boldModelText', data: {}},
            { featureId: 'boldText', data: {}},
            { featureId: 'italicModelText', data: {}},
            { featureId: 'italicText', data: {}},
            { featureId: 'underlineModelText', data: {}},
            { featureId: 'underlineText', data: {}},
            { featureId: 'strikeoutModelText', data: {}},
            { featureId: 'strikeoutText', data: {}},
            { featureId: 'leftAlignModelText', data: {}},
            { featureId: 'leftAlignText', data: {}},
            { featureId: 'centerAlignModelText', data: {}},
            { featureId: 'centerAlignText', data: {}},
            { featureId: 'rightAlignModelText', data: {}},
            { featureId: 'rightAlignText', data: {}},
            { featureId: 'justifyModelText', data: {}},
            { featureId: 'justifyText', data: {}},
            { featureId: 'colorModelText', data: {}},
            { featureId: 'colorTextAlt', data: {}},
            {
                featureId: 'sizeModelText',
                data: {
                    style: 'size',
                    value: DEFUALT_TEXT_STYLES.size,
                    // FIXME This format should be converted and should use type params.
                    data: [ 10, 12, 14, 18, 20, 24, 30, 36, 48, 60, 72, 96 ]
                        .map( i => ({ id: `${i}`, label: `${i}`, buttonLabel: `${i}`, value: i })),
                },
            },
            {
                featureId: 'sizeText',
                data: {
                    style: 'size',
                    value: DEFUALT_TEXT_STYLES.size,
                    // FIXME This format should be converted and should use type params.
                    data: [ 10, 12, 14, 18, 20, 24, 30, 36, 48, 60, 72, 96 ]
                        .map( i => ({ id: `${i}`, label: `${i}`, buttonLabel: `${i}`, value: i })),
                },
            },
            {
                featureId: 'fontModelText',
                data: {
                    style: 'font',
                    value: DEFUALT_TEXT_STYLES.font,
                    // FIXME This format should be converted and should use type params.
                    data: [
                        { id: `noto_regular`,
                            label: `<span style="font-family:noto_regular">Noto</span>`,
                            buttonLabel: `Noto`,
                            value: 'noto_regular' },
                        { id: `lt_regular`,
                            label: `<span style="font-family:lt_regular">Lato</span>`,
                            buttonLabel: `Lato`,
                            value: 'lt_regular' },
                        { id: `champagne`,
                            label: `<span style="font-family:champagne">Champagne</span>`,
                            buttonLabel: `Champagne`,
                            value: 'champagne' },
                        { id: `indie`,
                            label: `<span style="font-family:indie">Indie</span>`,
                            buttonLabel: `Indie`,
                            value: 'indie' },
                        { id: `bebas`,
                            label: `<span style="font-family:bebas">Bebas</span>`,
                            buttonLabel: `Bebas`,
                            value: 'bebas' },
                        { id: `bree`,
                            label: `<span style="font-family:bree">Bree</span>`,
                            buttonLabel: `Bree`,
                            value: 'bree' },
                        { id: `spartan`,
                            label: `<span style="font-family:spartan">Spartan</span>`,
                            buttonLabel: `Spartan`,
                            value: 'spartan' },
                        { id: `montserrat`,
                            label: `<span style="font-family:montserrat">Montserrat</span>`,
                            buttonLabel: `Montserrat`,
                            value: 'montserrat' },
                        { id: `open_sanscondensed`,
                            label: `<span style="font-family:open_sanscondensed">Open Sans</span>`,
                            buttonLabel: `Open Sans`,
                            value: 'open_sanscondensed' },
                        { id: `playfair`,
                            label: `<span style="font-family:playfair">Playfair</span>`,
                            buttonLabel: `Playfair`,
                            value: 'playfair' },
                        { id: `raleway`,
                            label: `<span style="font-family:raleway">Raleway</span>`,
                            buttonLabel: `Raleway`,
                            value: 'raleway' },
                        { id: `courier_prime`,
                            label: `<span style="font-family:courier_prime">Courier Prime</span>`,
                            buttonLabel: `Courier Prime`,
                            value: 'courier_prime' },
                        { id: `droid_serifregular`,
                            label: `<span style="font-family:droid_serifregular">Droid Serif</span>`,
                            buttonLabel: `Droid Serif`,
                            value: 'droid_serifregular' },
                        { id: `abhaya_libreregular`,
                            label: `<span style="font-family:abhaya_libreregular">Abhaya Libre</span>`,
                            buttonLabel: `Abhaya Libre`,
                            value: 'abhaya_libreregular' },
                        { id: `gandhi_serifregular`,
                            label: `<span style="font-family:gandhi_serifregular">Gandhi Serif</span>`,
                            buttonLabel: `Gandhi Serif`,
                            value: 'gandhi_serifregular' },
                        { id: `sans_serif`,
                            label: `<span style="font-family:arial,helvetica,sans-serif">` +
                            `Sans-Serif (System)</span>`,
                            buttonLabel: `Sans-Serif`,
                            value: 'arial,helvetica,sans-serif' },
                        { id: `serif`,
                            label: `<span style="font-family:Times New Roman,Courier New,` +
                            `Courier,Georgia,serif">Serif (System)</span>`,
                            buttonLabel: `Serif`,
                            value: 'Times New Roman,Courier New,Courier,Georgia,serif' },
                    ],
                },
            },
            {
                featureId: 'fontText',
                data: {
                    style: 'font',
                    value: DEFUALT_TEXT_STYLES.font,
                    // FIXME This format should be converted and should use type params.
                    data: [
                        { id: `noto_regular`,
                            label: `<span style="font-family:noto_regular">Noto</span>`,
                            buttonLabel: `Noto`,
                            value: 'noto_regular' },
                        { id: `lt_regular`,
                            label: `<span style="font-family:lt_regular">Lato</span>`,
                            buttonLabel: `Lato`,
                            value: 'lt_regular' },
                        { id: `champagne`,
                            label: `<span style="font-family:champagne">Champagne</span>`,
                            buttonLabel: `Champagne`,
                            value: 'champagne' },
                        { id: `indie`,
                            label: `<span style="font-family:indie">Indie</span>`,
                            buttonLabel: `Indie`,
                            value: 'indie' },
                        { id: `bebas`,
                            label: `<span style="font-family:bebas">Bebas</span>`,
                            buttonLabel: `Bebas`,
                            value: 'bebas' },
                        { id: `bree`,
                            label: `<span style="font-family:bree">Bree</span>`,
                            buttonLabel: `Bree`,
                            value: 'bree' },
                        { id: `spartan`,
                            label: `<span style="font-family:spartan">Spartan</span>`,
                            buttonLabel: `Spartan`,
                            value: 'spartan' },
                        { id: `montserrat`,
                            label: `<span style="font-family:montserrat">Montserrat</span>`,
                            buttonLabel: `Montserrat`,
                            value: 'montserrat' },
                        { id: `open_sanscondensed`,
                            label: `<span style="font-family:open_sanscondensed">Open Sans</span>`,
                            buttonLabel: `Open Sans`,
                            value: 'open_sanscondensed' },
                        { id: `playfair`,
                            label: `<span style="font-family:playfair">Playfair</span>`,
                            buttonLabel: `Playfair`,
                            value: 'playfair' },
                        { id: `raleway`,
                            label: `<span style="font-family:raleway">Raleway</span>`,
                            buttonLabel: `Raleway`,
                            value: 'raleway' },
                        { id: `courier_prime`,
                            label: `<span style="font-family:courier_prime">Courier Prime</span>`,
                            buttonLabel: `Courier Prime`,
                            value: 'courier_prime' },
                        { id: `droid_serifregular`,
                            label: `<span style="font-family:droid_serifregular">Droid Serif</span>`,
                            buttonLabel: `Droid Serif`,
                            value: 'droid_serifregular' },
                        { id: `abhaya_libreregular`,
                            label: `<span style="font-family:abhaya_libreregular">Abhaya Libre</span>`,
                            buttonLabel: `Abhaya Libre`,
                            value: 'abhaya_libreregular' },
                        { id: `gandhi_serifregular`,
                            label: `<span style="font-family:gandhi_serifregular">Gandhi Serif</span>`,
                            buttonLabel: `Gandhi Serif`,
                            value: 'gandhi_serifregular' },
                        { id: `sans_serif`,
                            label: `<span style="font-family:arial,helvetica,sans-serif">` +
                            `Sans-Serif (System)</span>`,
                            buttonLabel: `Sans-Serif`,
                            value: 'arial,helvetica,sans-serif' },
                        { id: `serif`,
                            label: `<span style="font-family:Times New Roman,Courier New,` +
                            `Courier,Georgia,serif">Serif (System)</span>`,
                            buttonLabel: `Serif`,
                            value: 'Times New Roman,Courier New,Courier,Georgia,serif' },
                    ],
                },
            },
            { featureId: 'selectionPositionX' },
            { featureId: 'selectionPositionY' },
            { featureId: 'selectionWidth' },
            { featureId: 'selectionHeight' },
            { featureId: 'selectionAngle' },
            // Shape specific features
            ...this.getShapeSpecificSidebarFeatures(),
        ];

        // Text position feature is supported for single text shapes
        const textKeys = Object.keys( this.texts );
        if ( this.texts ) {
            const txt = this.texts[ textKeys[0] ];
            const data = this.data;
            const cellIds = Object.keys( data )
                .filter( id => data[ id ].type === DataType.CHILD_SHAPE );
            const selectedCells = cellIds.filter( id => !!data[ id ].value.selected );
            if ( selectedCells.length > 0 ) {
                shapePanelFeatures.push({ featureId: 'textPosition',
                    data: { inner: true, positionString: txt.positionString }});
            } else if ( txt && txt.isPositionable()) {
                shapePanelFeatures.push({ featureId: 'textPosition', data: txt.isPartiallyAlinged() ? {} : {
                    positionString: txt.positionString,
                    alignX : txt.alignX, alignY : txt.alignY,
                     xType : txt.xType, yType : txt.yType,
                    x: txt.x, y: txt.y, transform: this.transform,
                }});
            }
        }

        // If shape model has style definitions, activate custom style definition feature
        // If not activate standard shape style features
        if ( !isEmpty( this.styleDefinitions ) || Object.keys( this.styleDefinitions ).length > 0 ) {
            let mergedStyleDefs;
            if ( !!this.madeOfInnerShapes ) {
                // For shapes with inner shapes that use both the styleDefinitions and style properties,
                // merge them and choose what is shown based on innerSelection state in the transformer
                mergedStyleDefs = Object.assign({}, this.styleDefinitions );
                Object.keys( this.styleDefinitions ).forEach( key => {
                    mergedStyleDefs[ key ].style = { ...this.style, ...mergedStyleDefs[ key ].style };
                });
                mergedStyleDefs.commonStyles = {
                    locked: false,
                    priority: 2,
                    style: this.style,
                };
            }
            const data = mergedStyleDefs ? mergedStyleDefs : this.styleDefinitions;
            shapePanelFeatures.push({ featureId: 'customShapeStyles', data: data });
        } else if ( !this.isTextShape()) {
            if ( !this.imageType ) {
                shapePanelFeatures.push({ featureId: 'colorFill', data: { value: this.style.fillColor }});
            }
            shapePanelFeatures.push({ featureId: 'colorLine', data: { value: this.style.lineColor }});
            shapePanelFeatures.push(
                {
                    featureId: 'styleLine',
                    data: {
                        value: this.style.lineStyle || [ 0, 0 ],
                        data: [
                            { id: `solid`, value: [ 0, 0 ]},
                            { id: `dotted`, value: [ 1, 3 ]},
                            { id: `dashed`, value: [ 3, 3 ]},
                            { id: `dashed_2`, value: [ 5, 5 ]},
                        ],
                    },
                },
            );
            shapePanelFeatures.push({ featureId: 'lineThickness', data: { value: this.style.lineThickness }});
        }

        const panels = [
            { id: 'shapePanel', features: shapePanelFeatures },
            { id: 'diagraminfo', features: null },
            { id: 'shapeData', features: null },
        ];
        return panels;
    }

    /**
     * This function Implements ISidebarContextAware method
     * and defines the multi select side bar panels for the same
     * type of shape models are selected
     * @returns string[] The list of panel ids
     */
    public getSidebarPanelsForSameType(): ISidebarPanelData[] {
        return this.getSidebarPanels().map( p => {
            if ( p.id === 'shapePanel' ) {
                let index =  p.features.findIndex( f => f.featureId === 'selectionAngle' );
                p.features.splice( index, 1 );

                index =  p.features.findIndex( f => f.featureId === 'customShapeStyles' );
                if ( index > -1 ) {
                    p.features.splice( index, 1 );
                }
            }
            return p;
        }).filter( p => p.id !== 'shapeData' );
    }

    /**
     * This function Implements ISidebarContextAware method
     * and defines the multi select side bar panels for multiple
     * types of shape models are selected
     * @returns string[] The list of panel ids
     */
    public getSidebarPanelsForMultiTypes(): ISidebarPanelData[] {
        return this.getSidebarPanelsForSameType().map( p => {
            if ( p.id === 'shapePanel' ) {
                const index =  p.features.findIndex( f => f.data && !!f.data.shapeDefId );
                if ( index > -1 ) {
                    p.features.splice( index, 1 );
                }
            }
            return p;
        }).filter( p => p.id !== 'shapeData' );
    }

    /**
     * This defines all the context menu items for a shape
     */
    public getContextMenuItems(): Array<string> {
        const items = this.getCommonContextMenuItems();
        return items.concat([
            'lockShape',
            'unlockShape',
        ]);
    }

    /**
     * This defines multi select context menu items for the same type of
     * shape models taht are selected
     */
    public getContextMenuForSameType(): Array<string> {
        const items = this.getCommonContextMenuItems();
        return items.concat([
            'lockShape',
            'unlockShape',
        ]);
    }

    /**
     * This defines multi select context menu items for the multi types of
     * shape models that are selected
     */
    public getContextMenuForMultipleTypes(): Array<string> {
        return this.getCommonContextMenuItems();
    }

    /**
     * Returns all contextual toolbar items that can appear in a single shape
     * selection.
     * @return array of toolbar items
     */
    public getContextualToolbarItems(): IContextualToolbarItem[] {
        const items = [
            { featureId: 'switchShape' },
            { featureId: 'editText' },
            { featureId: 'switchReplaceText' },
            { featureId: 'createConnector' },
            { featureId: 'createNewSlideFromSelection' },
            { featureId: 'styleShape' },
            { featureId: 'openEmojiList' },
            { featureId: 'manageIndicators' },
            { featureId: 'toggleDataFields' },
            { featureId: 'layoutShapes', data: { shapeId: this.id, options: Object.values( LAYOUTING_DATA ) }},
            { featureId: 'openShapelinkEditor' },
            { featureId: 'vizPrompt' },
            { featureId: 'pasteAsItem' },
            ...this.getContextualToolbarItemsForShapeImages(),
            ...this.getContextualToolbarItemsBoundToDataItems(),
        ];

        if ( this.supportLazyLoading ) {
            items.unshift({ featureId: 'loadMoreShapes' });
        }

        const secondaryItems = [
            { featureId: 'copyShapes', visibility: { level: 'secondary' } as any },
            { featureId: 'cutShapes', visibility: { level: 'secondary' } as any },
            { featureId: 'copyShapeLink', visibility: { level: 'secondary' } as any },
            { featureId: 'duplicateShapes', visibility: { level: 'secondary' } as any },
            { featureId: 'remove', visibility: { level: 'secondary' } as any },
            { featureId: 'bringToFront', visibility: { level: 'secondary' } as any },
            { featureId: 'bringForward', visibility: { level: 'secondary' } as any },
            { featureId: 'sendToBack', visibility: { level: 'secondary' } as any },
            { featureId: 'sendBackward', visibility: { level: 'secondary' } as any },
            { featureId: 'shapeVoting', visibility: { level: 'secondary' } as any },
            { featureId: 'convertToObject', visibility: { level: 'secondary' } as any },
            { featureId: 'containerize', visibility: { level: 'secondary' } as any },
            { featureId: 'lockShape', visibility: { level: 'secondary' } as any },
            { featureId: 'unlockShape', visibility: { level: 'secondary' } as any },
        ];

        if ( this.eDataCandidates && this.eDataCandidates.length > 0 ) {
            const index = secondaryItems.findIndex( item => item.featureId === 'convertToObject' );
            const prefix = `${this.defId}.${this.version}`;
            secondaryItems.splice( index + 1, 0, ...this.eDataCandidates.map( eDefId => ({
                featureId: `${prefix}.convertToObject.${eDefId}`,
                visibility: { level: 'secondary' } as any,
                data: { defId: eDefId },
            })));
            if ( !this.eData ) {
                items.push({ featureId: 'convertToPredefinedObject' });
            }
        }

        if ( 'getShapeSpecificContextualToolbarItems' in this ) {
            return [ ...( <IShapeContextualToolbarAware>this )

                .getShapeSpecificContextualToolbarItems( this as Proxied<IShapeModel> )
                    .map( item => {
                        item.featureId = this.getQualifiedFeatureId( item.featureId );
                        return item;
                    }),
                    ...items,
                    ...secondaryItems,
                ];
        }

        return [ ...items, ...secondaryItems ];
    }

    /**
     * Returns all contextual toolbar items that can appear during a multi selection
     * where all selected shapes are of the same type.
     * @return array of toolbar items
     */
    public getContextualToolbarItemsForSameType(): IContextualToolbarItem[] {
        const items = [
            this.getFDAlignShapes(),
            { featureId: 'layoutShapes', data: { shapeId: this.id, options: Object.values( LAYOUTING_DATA ) }},
            { featureId: 'groupShapes' },
            { featureId: 'ungroupShapes' },
            { featureId: 'createNewSlideFromSelection' },
            { featureId: 'styleShape' },
            { featureId: 'openShapelinkEditor' },
            { featureId: 'vizPrompt' },
            { featureId: 'pasteAsItem' },
            { featureId: 'manageIndicators' },
            ...this.getContextualToolbarItemsBoundToDataItems(),

        ];

        const secondaryItems = [
        { featureId: 'copyShapes', visibility: { level: 'secondary' } as any },
        { featureId: 'cutShapes', visibility: { level: 'secondary' } as any },
        { featureId: 'copyShapeLink', visibility: { level: 'secondary' } as any },
        { featureId: 'duplicateShapes', visibility: { level: 'secondary' } as any },
        { featureId: 'remove', visibility: { level: 'secondary' } as any },
        { featureId: 'bringToFront', visibility: { level: 'secondary' } as any },
        { featureId: 'bringForward', visibility: { level: 'secondary' } as any },
        { featureId: 'sendToBack', visibility: { level: 'secondary' } as any },
        { featureId: 'sendBackward', visibility: { level: 'secondary' } as any },
        { featureId: 'shapeVoting', visibility: { level: 'secondary' } as any },
        { featureId: 'containerize', visibility: { level: 'secondary' } as any },
        { featureId: 'lockShape', visibility: { level: 'secondary' } as any },
        { featureId: 'unlockShape', visibility: { level: 'secondary' } as any },

        ];

        if ( 'getShapeSpecificContextualToolbarItemsForSameType' in this ) {
            return [ ...( <IShapeContextualToolbarAware>this )

                .getShapeSpecificContextualToolbarItemsForSameType( this as Proxied<IShapeModel> )
                    .map( item => {
                        item.featureId = this.getQualifiedFeatureId( item.featureId );
                        return item;
                    }),
                    ...items,
                    ...secondaryItems,
            ];
        }
        return [ ...items, ...secondaryItems ];
    }

    /**
     * Returns all contextual toolbar items that can appear during a multi selection
     * where selected shapes are of different types.
     * @return array of toolbar items
     */
    public getContextualToolbarItemsForMultipleTypes(): IContextualToolbarItem[] {
        const items = [
            this.getFDAlignShapes(),
            { featureId: 'layoutShapes', data: { shapeId: this.id, options: Object.values( LAYOUTING_DATA ) }},
            { featureId: 'groupShapes' },
            { featureId: 'ungroupShapes' },
            { featureId: 'createNewSlideFromSelection' },
            { featureId: 'styleShape' },
            { featureId: 'openShapelinkEditor' },
            { featureId: 'vizPrompt' },
            { featureId: 'pasteAsItem' },
            { featureId: 'manageIndicators' },
            { featureId: 'copyShapes', visibility: { level: 'secondary' } as any },
            { featureId: 'cutShapes', visibility: { level: 'secondary' } as any },
            { featureId: 'copyShapeLink', visibility: { level: 'secondary' } as any },
            { featureId: 'duplicateShapes', visibility: { level: 'secondary' } as any },
            { featureId: 'remove', visibility: { level: 'secondary' } as any },
            { featureId: 'bringToFront', visibility: { level: 'secondary' } as any },
            { featureId: 'bringForward', visibility: { level: 'secondary' } as any },
            { featureId: 'sendToBack', visibility: { level: 'secondary' } as any },
            { featureId: 'sendBackward', visibility: { level: 'secondary' } as any },
            { featureId: 'shapeVoting', visibility: { level: 'secondary' } as any },
            { featureId: 'lockShape', visibility: { level: 'secondary' } as any },
            { featureId: 'unlockShape', visibility: { level: 'secondary' } as any },
        ];
        return items;
    }

    /**
     * Returns all context menu items when text is being edited on a shape.
     */
    public getContextualToolbarItemsForTextEdit(): IContextualToolbarItem[] {
        const textToolbarItems: IContextualToolbarItem[] = [
            {
                featureId: 'boldText',
                data: { style: 'bold', value: DEFUALT_TEXT_STYLES.bold },
            },
            {
                featureId: 'italicText',
                data: { style: 'italic', value: DEFUALT_TEXT_STYLES.italic },
            },
            {
                featureId: 'strikeoutText',
                data: { style: 'strikeout', value: DEFUALT_TEXT_STYLES.strikeout },
            },
            {
                featureId: 'underlineText',
                data: { style: 'underline', value: DEFUALT_TEXT_STYLES.underline },
            },
            {
                featureId: 'sizeText',
                data: {
                    style: 'size',
                    value: DEFUALT_TEXT_STYLES.size,
                    // FIXME This format should be converted and should use type params.
                    data: [ 10, 12, 14, 18, 20, 24, 30, 36, 48, 60, 72, 96 ]
                        .map( i => ({ id: `${i}`, buttonLabel: `${i}`, label: `${i}`, value: i })),
                },
            },
            {
                featureId: 'alignText',
                data: {
                    style: 'align',
                    value: DEFUALT_TEXT_STYLES.align,
                    data: [
                        { id: `left`, buttonLabel: `left`, label: 'left',
                            buttonIcon: { type: 'svg', value: `text-align-left` }, value: 'left' },
                        { id: `right`, buttonLabel: `right`, label: 'right',
                            buttonIcon: { type: 'svg', value: `text-align-right` }, value: 'right' },
                        { id: `center`, buttonLabel: `center`, label: 'center',
                            buttonIcon: { type: 'svg', value: `text-align-center` }, value: 'center' },
                        { id: `justify`, buttonLabel: `justify`, label: 'justify',
                            buttonIcon: { type: 'svg', value: `text-align-justify` }, value: 'justify' },
                    ],
                },
            },
            {
                featureId: 'colorText',
                data: {
                    style: 'color',
                    value: DEFUALT_TEXT_STYLES.color,
                    // FIXME This format should be converted and should use type params.
                    // Color must be given in rgb color code
                    data: [
                        'rgb(255, 255, 255)', 'rgb(0, 0, 0)', 'rgb(231, 230, 230)',
                        'rgb(69, 85, 105)', 'rgb(2, 34, 95)',
                        'rgb(70, 116, 193)', 'rgb(17, 114, 189)', 'rgb(94, 156, 211)',
                        'rgb(157, 194, 227)', 'rgb(222, 234, 245)',
                        'rgb(132, 19, 26)', 'rgb(190, 7, 18)', 'rgb(252, 13, 27)',
                        'rgb(254, 103, 112)', 'rgb(255, 193, 196)',
                        'rgb(218, 139, 20)', 'rgb(255, 153, 0)', 'rgb(238, 186, 107)',
                        'rgb(253, 208, 103)', 'rgb(254, 233, 184)',
                        'rgb(84, 128, 57)', 'rgb(114, 172, 77)', 'rgb(148, 206, 88)',
                        'rgb(169, 207, 144)', 'rgb(197, 223, 181)',
                    ].map( i => ({ id: `${i}`, value: i })),
                },
            },
            {
                featureId: 'openHyperlinkEditor',
            },
            {
                featureId: 'unlinkHyperlink',
            },
        ];
        return this.disableTextStyle ? [] : textToolbarItems;
    }

    /**
     * Get gluepoints where more connectors can be connected.
     * Accepts a list of connector ids to ignore when counting limits.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public getAvailableGluepoints(
        root: DiagramModel,
        ignored: string[] = [],
        endpoint?: 'from' | 'to',
    ): GluePointModel[] {
        const connectorData = this.getConnectors( root );
        const availableGluepoints = [];
        for ( const id in this.gluepoints ) {
            const gp = this.gluepoints[id];
            // connectors connected to `gp` except ignored connectors
            const connected = connectorData.filter( data => (
                data.endpoint.gluepoint &&
                data.endpoint.gluepoint.id === gp.id &&
                ignored.indexOf( data.connector.id ) === -1
            ));
            // check the connector limit for this gluepoint
            if ( gp.limit !== undefined && gp.limit !== -1 ) {
                if ( connected.length >= gp.limit ) {
                    continue;
                }
            }
            // check the endpoint limit for this gluepoint
            if ( endpoint && gp.limitEnd !== undefined && gp.limitEnd !== 'all' ) {
                if ( gp.limitEnd === 'from' || gp.limitEnd === 'to' ) {
                    if ( gp.limitEnd !== endpoint ) {
                        continue;
                    }
                }
                if ( gp.limitEnd === 'one' ) {
                    if ( connected.length > 0 ) {
                        const first = connected[0];
                        const firstEnd = ( first.endpoint.id === first.connector.path.headId ) ? 'from' : 'to';
                        if ( firstEnd !== endpoint ) {
                            continue;
                        }
                    }
                }
            }
            availableGluepoints.push( gp );
        }
        return availableGluepoints;
    }

    public getGluepointsCentre(): IPoint2D {
        const gluepoints = Object.values( this.gluepoints ).map( gp => {
            const p = GluePointModel.getPosition( gp, this.defaultBounds, this.transform );
            return { model: gp, point: p };
        });

        if ( gluepoints.length === 4 ) {
            const pointA = gluepoints[0].point;
            const pointB = gluepoints[1].point;
            const pointC = gluepoints[3].point;
            const pointD = gluepoints[2].point;
            let slopeA;
            let slopeB;
            if ( pointA.x !== pointB.x ) {
                slopeA = ( pointB.y - pointA.y ) / ( pointB.x - pointA.x );
            } else {
                slopeA = Infinity;
            }
            if ( pointC.x !== pointD.x ) {
                slopeB = ( pointD.y - pointC.y ) / ( pointD.x - pointC.x );
            } else {
                slopeB = Infinity;
            }

            // Calculate the intersection point
            let intersectionX;
            let intersectionY;
            if ( slopeA !== slopeB ) {
                if ( slopeA === Infinity ) {
                    intersectionX = pointA.x;
                    intersectionY = slopeB * ( intersectionX - pointC.x ) + pointC.y;
                } else if ( slopeB === Infinity ) {
                    intersectionX = pointC.x;
                    intersectionY = slopeA * ( intersectionX - pointA.x ) + pointA.y;
                } else {
                    intersectionX = ( pointA.y - pointC.y + slopeB * pointC.x - slopeA * pointA.x ) /
                        ( slopeB - slopeA );
                    intersectionY = slopeA * ( intersectionX - pointA.x ) + pointA.y;
                }
            } else {
                // Lines are parallel, return NaN
                intersectionX = NaN;
                intersectionY = NaN;
            }

            return { x: intersectionX, y: intersectionY };
        }

        if ( gluepoints.length === 2 ) {
            const x = ( gluepoints[0].point.x + gluepoints[1].point.x ) / 2;
            const y = ( gluepoints[0].point.y + gluepoints[1].point.y ) / 2;
            return { x, y };
        }
        return { x: this.bounds.centerX, y: this.bounds.centerY };
    }

    /**
     * Returns an array of available gluepoints and it's distance to given point.
     * The array will be sorted by distance ascending ( smallest to largest ).
     */
    public getAvailableGluepointsByPos(
        root: DiagramModel,
        pos: IPoint2D,
        ignored: string[] = [],
        endpoint?: 'from' | 'to',
    ) {
        const available = this.getAvailableGluepoints( root, ignored, endpoint );
        const distances = available.map( gluepoint => {
            const position = GluePointModel.getPosition( gluepoint, this.defaultBounds, this.transform );
            const distance = Point.distanceTo( pos, position );
            return { gluepoint, distance };
        });
        // NOTE: sort gluepoints by distance in ascending order
        return distances.sort(( a, b ) => a.distance - b.distance );
    }

    /**
     * Returns the position of the gluepoint
     */
    public getGluepoint( id: string ): GluePointModel {
        const gluepoint = this.gluepoints[id];
        if ( !gluepoint ) {
            throw new Error( `Unexpected Error: unable to find gluepoint ${id}` );
        }
        return gluepoint;
    }


    /**
     * Returns the pair of Sticky glue points for the given gluepoint id
     * NOTE: Following ids are predefined in createDefaultGluePoints function in ShapeModelFactory
     * for top, bottom, left and right glue points.
     * Sticky Glue Points are to be taken into account in the Reconnect service when claculating the
     * nearest glupoints to connect two shapes
     * @param id: string Gluepoint Id
     */
    public getStickyGluePoints( id: string ): GluePointModel[] {
        if ( this.stickyGluePoints && this.stickyGluePoints[ id ]) {
            return ( this.stickyGluePoints[ id ]) .map(( gpData, i ) => {
                const gp = new GluePointModel();
                gp.id = `${id}-stickyGP${i}`;
                gp.x = gpData.x;
                gp.y = gpData.y;
                gp.xType = gpData.xType || 'relative';
                gp.yType = gpData.yType || 'relative';
                return gp;
            });
        }
        return [];
    }

    /**
     * Returns the position of the gluepoint
     */
    public getGluepointPosition( id: string ): IPoint2D {
        const gluepoint = this.getGluepoint( id );
        return GluePointModel.getPosition( gluepoint, this.defaultBounds, this.transform );
    }

    /**
     * Returns the name of the angle calculation method of the gluepoint
     */
    public getGluepointAngleBy( id: string ): typeof GluePointModel.prototype.angleBy {
        const gluepoint = this.getGluepoint( id );
        // If angleBy is not set return the framework default angle strategy
        if ( gluepoint.angleBy === undefined ) {
            return 'closest-edge';
        }
        return gluepoint.angleBy;
    }

    /**
     * Returns the angle in degrees with which a connector should connect to the shape.
     *
     * Edited so shape left and right bounds are used even when there is text out of the shape.
     */
    public getGluepointConnectingAngle( id: string ): number {
        const position = this.getGluepointPosition( id );
        const angleBy = this.getGluepointAngleBy( id );
        if ( typeof angleBy === 'number' ) {
            return angleBy;
        }
        const bounds = this.hasOuterMiddleTexts() ? this.getBoundsWithTexts() : this.bounds;
        if ( angleBy === 'from-center' ) {
            return new Point( bounds.centerX, bounds.centerY ).angleTo( position );
        }
        if ( angleBy === 'closest-edge' ) {
            const distance = [
                Math.abs( position.y - bounds.top ),
                Math.abs( position.y - bounds.bottom ),
                Math.abs( position.x - this.bounds.right ),
                Math.abs( position.x - this.bounds.left ),
            ];
            const minIdx = distance.indexOf( Math.min( ...distance ));
            return [ -90, 90, 0, 180 ][ minIdx ];
        }
        throw new Error( `Unknown gluepoint connecting angle calculation strategy "${angleBy}"` );
    }

    /**
     * Returns the angle in degrees with which a connector should connect to the shape.
     */
    public getEndpointConnectingAngle( pos: any ): number {
        const position = this.getPointFromPosition( pos );
        const bounds = this.hasOuterMiddleTexts() ? this.getBoundsWithTexts() : this.bounds;
        const distance = [
            Math.abs( position.y - bounds.top ),
            Math.abs( position.y - bounds.bottom ),
            Math.abs( position.x - bounds.right ),
            Math.abs( position.x - bounds.left ),
        ];
        const minIdx = distance.indexOf( Math.min( ...distance ));
        return [ -90, 90, 0, 180 ][ minIdx ];
        throw new Error( `Unknown gluepoint connecting angle calculation strategy closest-edge` );
    }

    /**
     * This abstract method creates a new text model with a generated text id,
     *
     */
    public createText(): ShapeTextModel {
        const text = new ShapeTextModel();
        text.id = this.generateTextId();
        return text;
    }

    /**
     * Converts Quill deltas to html
     * This converter added to the shape model to be called in the shape logic class
     */
    public convertQuillToHtml( deltaOps: any ) {
        const converter = new QuillDeltaToHtmlConverter( deltaOps );
        return converter.convert();
    }

    /**
     * Converts Carota content to html
     * This converter added to the shape model to be called in the shape logic class
     */
     public convertCarotaToHtml( carota: any ) {
        return Carota.html.html( carota );
    }

    public convertHTMLToCarota( html: string, align: 'left' | 'center' | 'right' | 'justify' = 'left' ) {
        const tf = new TextFormatter();
        return tf.applyRaw( html, {
            indexStart: 0,
            indexEnd: -1,
            styles: { ...this.defaultTextFormat, align },
        });
    }

    /**
     * Converts given cordinate point of a diagram to shape relative cordinate.
     * @param point Diagram's cordinate point to convert.
     */
    public diagramToShapeCordinate( point: IPoint2D ): IPoint2D {
        const shapeTransform = this.transform;
        const matrix = Matrix.fromTransform( shapeTransform ).invert();
        point = matrix.transformPoint( point.x, point.y );
        return { x: point.x * shapeTransform.scaleX, y: point.y * shapeTransform.scaleY };
    }

    /*
     * Check whether shape is svg or not
     * Will be used to hide the style panel and styles in style document palette
     */
    public isStyleEnabledShape(): boolean {
        if ( this.isTextShape()) {
            return false;
        }

        if ( this.styleDefinitions && !isEmpty( this.styleDefinitions ) && !this.madeOfInnerShapes ) {
            return false;
        }

        if ( this.imageType ) {
            return false;
        }

        if ( this.type === ShapeType.Freehand ) {
            return false;
        }

        return true;
    }

    public isTextShape(): boolean {
        return this.defId.includes( 'creately.basic.text' );
    }

    /**
     * This method will returns the nearest connected shapes.
     * Direction will be considered related to current shape.
     * @param onGP if set to true will only return shapes connected to shape gluepoints.
     * @returns an array of shape id with connected direction.
     * Returning only shapeId, rather than shape model because, if we
     * need to get the latest changes, use the shape id from here via locator.
     */
    public getConnectedShapes( root: DiagramModel, onGP: boolean = false ): { shapeId: string, dir: 'OUT' | 'IN' }[] {
        const connectedShapes = [];
        const connectors = this.getConnectors( root );
        if ( onGP ) {
            connectors.forEach( connector => {
                const fromEndpoint = connector.connector.getFromEndpoint( root );
                const toEndpoint = connector.connector.getToEndpoint( root );
                if ( fromEndpoint?.shape?.id === this.id && toEndpoint.shape && toEndpoint.gluepoint ) {
                    connectedShapes.push({ shapeId: toEndpoint.shape.id, dir: 'OUT' });
                }
                if ( toEndpoint?.shape?.id === this.id && fromEndpoint.shape && fromEndpoint.gluepoint ) {
                    connectedShapes.push({ shapeId: fromEndpoint.shape.id, dir: 'IN' });
                }
            });
        } else {
            connectors.forEach( connector => {
                const fromEndpoint = connector.connector.getFromEndpoint( root );
                const toEndpoint = connector.connector.getToEndpoint( root );
                if ( fromEndpoint?.shape?.id === this.id && toEndpoint.shape ) {
                    connectedShapes.push({ shapeId: toEndpoint.shape.id, dir: 'OUT' });
                }
                if ( toEndpoint?.shape?.id === this.id && fromEndpoint.shape ) {
                    connectedShapes.push({ shapeId: fromEndpoint.shape.id, dir: 'IN' });
                }
            });
        }
        return connectedShapes;
    }

    /**
     * Returns alignment nodes for the shape
     */
    public getAlignmentNodes(): IAlignmentNode[] {
        if ( Math.abs( this.angle % 90 ) === 0 ) { // Neglect tilted shapes
            const bounds = this.bounds;
            return [
                { shapeId: this.id, pos: 'top', x: bounds.centerX, y: bounds.y, type: 'shape' },
                { shapeId: this.id, pos: 'bottom', x: bounds.centerX, y: bounds.y + bounds.height, type: 'shape' },
                { shapeId: this.id, pos: 'left', x: bounds.x, y: bounds.centerY, type: 'shape' },
                { shapeId: this.id, pos: 'right', x: bounds.x + bounds.width, y: bounds.centerY, type: 'shape' },
            ];
        }
        return [];
    }

    public getCollapsedGlupepoints(): GluePointModel[] {
        return Object.values( this.gluepoints || {})
            .filter( gp => gp.connectionState === 'collapsed' );
    }

    /**
     * This returns only the containers supported by this shape and this method can be overrden in the logic class
     * @param containers An Array of any shapes to filter out the unsupported containers
     * @return Containers that this shape supports
     */
    public getSupportedContainers( containers: Array<ShapeModel> ): Array<ShapeModel> {
        if (( this as any ).filterContainers ) {
            return ( this as any ).filterContainers( this, containers );
        }
        return containers;
    }

    /**
     * Returns the top, bottom, left and right gluepoints of the shape
     * If the shape has more than 4 gluepoints, this method will return undefined
     */
    public getBasicGluepoints(): {[side: string ]: string } {
        const gluepointIds = Object.keys( this.gluepoints );
        if ( gluepointIds.length === 4 ) {
            const between = ( value, a, b ) =>
                value >= a && value <= b;
            const top = gluepointIds.find( id =>
                this.gluepoints[ id ].y === 0 && this.gluepoints[ id ].yType !== 'fixed-end' ||
                this.gluepoints[ id ].y === 1 && this.gluepoints[ id ].yType === 'fixed-end',
            );
            const bottom = gluepointIds.find( id =>
                this.gluepoints[ id ].y === 1 && this.gluepoints[ id ].yType !== 'fixed-end' ||
                this.gluepoints[ id ].y === 0 && this.gluepoints[ id ].yType === 'fixed-end',
            );
            const left = gluepointIds.find( id =>
                this.gluepoints[ id ].x === 0 && this.gluepoints[ id ].xType !== 'fixed-end' ||
                this.gluepoints[ id ].x === 1 && this.gluepoints[ id ].xType === 'fixed-end',
            );
            const right = gluepointIds.find( id =>
                this.gluepoints[ id ].x === 1 && this.gluepoints[ id ].xType !== 'fixed-end' ||
                this.gluepoints[ id ].x === 0 && this.gluepoints[ id ].xType === 'fixed-end',
            );
            if ( top && bottom && left && right ) {
                if ( between( this.angle, -45, 45 )) {
                    return {
                        top,
                        bottom,
                        left,
                        right,
                    };
                } else if ( between( this.angle, 45, 135 )) {
                    return {
                        top: left,
                        bottom: right,
                        left: bottom,
                        right: top,
                    };

                } else if ( between( this.angle, -135, -45 )) {
                    return {
                        top: left,
                        bottom: right,
                        left: bottom,
                        right: top,
                    };
                } else {
                    return {
                        top: bottom,
                        bottom: top,
                        left: right,
                        right: left,
                    };
                }
            }
        }
    }

    /**
     * This returns only the children supported by this 'Container' model
     * This method can be overridden in the logic class of the container shape.
     * @param children An Array of any shapes to filter out the unsupported
     * shapes from the container.
     * @return Containers that this shape supports
     */
    public getSupportedChildren( children: Array<ShapeModel> ): Array<ShapeModel> {
        // NOTE: Define supported children def Ids in shape definition. Check UML class
        // packages or use case package json for sample.
        if ( this.supportedChildren ) {
            return children.filter( child => this.supportedChildren.includes( child.defId ));
        }
        // NOTE: This is another option to filter / get supported children. We can use this
        // hook for more complex scenarios. This should be implemented in container shape logic
        // class.
        if (( this as any ).filterChildren ) {
            return ( this as any ).filterChildren( this, children );
        }
        return children;
    }

    /**
     * Convinence function to get all the assignees on this item
     */
    public getAssignees() {
        if ( this.data ) {
            return find( this.data, item => item.value && item.value.people
                        && item.value.type && item.value.type === PeopleRoleType.Active );
        }
    }

    public getContainerRegionByName( name: string ): any {
        const text =  Object.values( this.texts ).find( txt => txt.plainText === name );
        if ( text ) {   // If text is found, return the region
            return this.containerRegions[ text.id ];
        }
    }

    public getDescriptionPlainText() {
        if ( this.data.description ) {
            let htmlString = this.data.description.value || '';
            // Remove the `primary-text-node` element.
            htmlString = htmlString.replace( /<primary-text-node.*?>.*?<\/primary-text-node>/g, '' );
              // Extract the plain text from the HTML string.
            const plainText = htmlString.replace( /<[^>]*?>/g, '' );
            return plainText;
        }
        return '';
    }

    /**
     * Retrieves a list of sidebar features from data items.
     * An item is created for each data item that has a sidebar
     * visibility.
     * @return array of sidebar features
     */
    protected getShapeSpecificSidebarFeatures(): any {
        const featureItems = [];
        if ( !this.features || this.features.length === 0 ) {
            return featureItems;
        }
        this.features.filter( feature => !!feature.dataItemId ).forEach(( featureDef: any ) => {
            const dataItem = !!this.data ? this.data[ featureDef.dataItemId ] : undefined;
            if ( !dataItem ) {
                throw new Error( 'Data item bound to feature item could not be found' );
            }
            const sidebarItem = this.getSidebarItemFromDataItem( featureDef, dataItem );
            if ( sidebarItem ) {
                featureItems.push( sidebarItem );
            }
        });
        return featureItems;
    }

    /**
     * A sidebar item is created for each data item that has a sidebar
     * visibility.
     * @return sidebar feature
     */
    protected getSidebarItemFromDataItem( featureDef: IDataFeatureItem,
                                          dataItem: IDataItem<any> ): any {
        const sidebarVisibility = !!dataItem.visibility ?
                find( dataItem.visibility, { type: 'panel' }) : undefined;
        if ( sidebarVisibility ) {
            const sidebarItem = {
                featureId: this.getQualifiedFeatureId( featureDef.id ),
                data: <any>{
                    dataItemId: featureDef.dataItemId,
                    value: dataItem.value,
                    typeParams: {
                        readOnly: false,
                        ...dataItem.typeParams,
                    },
                    validationRules: {
                        ...dataItem.validationRules,
                    },
                    icon: featureDef.data.icon,
                },
                visibility: sidebarVisibility,
                shapeDefId: featureDef.shapeDefId,
            };
            return sidebarItem;
        }
    }

    /**
     * Retrieves a list of contextual toolbar items based on data item bound features.
     * An item is crated for each data item bound feature that has a toolbar
     * visibility.
     * @return array of toolbar items
     */
    protected getContextualToolbarItemsBoundToDataItems(): IContextualToolbarItem[] {
        const items = [];
        if ( !this.features || this.features.length === 0 ) {
            return items;
        }
        this.features.filter( feature => !!feature.dataItemId ).forEach(( featureDef: any ) => {
            const dataItem = !!this.data ? this.data[ featureDef.dataItemId ] : undefined;
            if ( !dataItem ) {
                throw new Error( 'Data item bound to featute item could not be found' );
            }
            const toolBarItem = this.getToolbarItemFromDataItem( featureDef, dataItem );
            if ( toolBarItem ) {
                items.push( toolBarItem );
            }
        });
        return items;
    }

    /**
     * Takes a given (partial) plus create option and populates it with
     * correct default values and returns a valid IPlusCreateOptions
     * @param starter The plus create options that is being set.
     *
     * TODO: Move to ShapeModelFactory
     */
    private getDefaultCreateOptions( starter: any ): IPlusCreateOptions {
        const options: IPlusCreateOptions = {
            // FIXME: Consider if the shape is a container before makeing this call
            type: 'flow',
            connect: true,
            primary: 0,
            defs: [
                { defId: this.defId, version: this.version, connect: true },
            ],
        };

        if ( !isEmpty( starter )) {
            if ( starter.type  === 'container' ) {
                options.type = starter.type;
            }
            if ( starter.connect === false ) {
                options.connect = starter.connect;
            }
            if ( Array.isArray( starter.defs )) {
                if ( starter.defs.length > 0 ) {
                    options.defs = [ ...starter.defs ];

                    if ( starter.primary > 0 && starter.primary < starter.defs.length ) {
                        options.primary = starter.primary;
                    }
                }

                options.defs.forEach( def => {
                    if ( def.connect !== false ) {
                        def.connect = options.connect;
                    }
                });
            }
            if ( starter.positions ) {
                options.positions = starter.positions;
            }
        }
        return options;
    }

    /**
     * This defines the context menu items for all three types
     * such as single selection, multi selection with same type
     * and multi selection with different types.
     */
    private getCommonContextMenuItems(): Array<string> {
        return [
            'copyShapes',
            'cutShapes',
            'copyShapeLink',
            'duplicateShapes',
            'remove',
            'bringToFront',
            'sendToBack',
            'sendBackward',
            'shapeVoting',
            'bringForward',
            'groupShapes',
            'ungroupShapes',
            'containerize',
        ];
    }

    /**
     * Returns the contextual toolbar feature data.
     */
    private getFDAlignShapes(): IContextualToolbarItem {
        return {
            featureId: 'alignShapes',
            data: { shapeId: this.id, options: ALIGNMENT_OPTIONS },
        };
    }

    /**
     * Return true if the shape has at least one outer middle text.
     */
    private hasOuterMiddleTexts() {
        for ( const key in this.texts ) {
            if ( this.texts.hasOwnProperty( key )) {
                const text = this.texts[ key ];
                if ( text.y < 0 && text.yType === 'fixed-end'
                        && ( !text.xType || text.xType === 'relative' ) && text.x === 0.5 ||  // bottom outside middle
                    text.y < 0 && text.yType === 'fixed-start'
                        && ( !text.xType || text.xType === 'relative' ) && text.x === 0.5 ||  // top outside middle
                    text.x < 0 && text.xType === 'fixed-start'
                        && ( !text.yType || text.yType === 'relative' ) && text.y === 0.5 ||  // left outside middle
                    text.x < 0 && text.xType === 'fixed-end'
                        && ( !text.yType || text.yType === 'relative' ) && text.y === 0.5 // right outside middle
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    private getContextualToolbarItemsForShapeImages() {
        const features = [];
        if ( this.images && !this.isImageUploadDisabled ) {
            for ( const imageId of Object.keys( this.images )) {
                features.push({
                    featureId: 'uploadShapeImage',
                    data: { position: { shapeId: this.id, imageId: imageId }},
                });
            }
        }
        return features;
    }

    /**
     * Creates and returns a contextual toolbar item from a given feature bound
     * data item.
     * @param featureId - id of relevant feature
     * @param datItem - data item the feature is bound to
     * @return IContextualToolbarItem
     */
    private getToolbarItemFromDataItem( featureDef: IDataFeatureItem,
                                        dataItem: IDataItem<any> ): IContextualToolbarItem {
        const toolbarVisibility = !!dataItem.visibility ?
                find( dataItem.visibility, { type: 'contextual-toolbar' }) : undefined;
        if ( toolbarVisibility ) {
            const toolbarItem: IContextualToolbarItem = {
                featureId: this.getQualifiedFeatureId( featureDef.id ),
                data: <any>{
                    dataItemId: featureDef.dataItemId,
                    value: dataItem.value,
                },
                visibility: toolbarVisibility,
            };
            merge( toolbarItem.data, dataItem.typeParams );
            return toolbarItem;
        }
    }

    /**
     * Returns the fully qualified feature id which includes
     * the definition info.
     */
    private getQualifiedFeatureId( featureId: string ): string {
        return `${this.defId}.${this.version}.${featureId}`;
    }

}
