import { ResourceModel } from '../../model/resource.mdl';
import { ComplexType, enumerable } from 'flux-core';
import { DiagramTypeModel } from './diagram-type.mdl';
import { ProjectModel } from '../../project/model/project.mdl';
import { Relationship } from 'flux-store';
import { IDiagramSource } from './diagram-source.i';

/**
 * DiagramInfoModel
 * This is the smallest form of a diagram model. Contains the very
 * basic information needed to render a listing of diagrams. Extends
 * the features of a {@link ResourceModel}.
 *
 * DEPRECATED FIELDS: Note that there are fields in this model that
 * is part of old Creately system and they will not be available and
 * used in new systems. Be careful about using them in the correct
 * environment.
 *
 * @author  hiraash
 * @since   2015-11-07
 */
export class DiagramInfoModel extends ResourceModel {

    public static hasHistoryMetaInfo() {
        return true;
    }

    /**
     * Indicates if the diagram is a public diagram.
     * @deprecated DEPRECATED FIELD
     */
    public isPublic: boolean;

    /**
     * Indicates the current revision of the diagram
     * @deprecated DEPRECATED FIELD
     */
    public version: number;

    /**
     * This is the project of this
     * diagram belongs to.
     */
    @Relationship()
    public project: ProjectModel;

    /**
     * This is the name of the project the diagram
     * belongs to.
     * @deprecated DEPRECATED FIELD
     */
    public projectName: string;

    /**
     * This holds the comment which was added during save
     * @deprecated DEPRECATED FIELD
     */
    public saveComment: string;

    /**
     * This holds the diagram save type
     * @deprecated DEPRECATED FIELD
     */
    public saveType: string;

    /**
     * This holds the data about diagram type
     * @deprecated DEPRECATED FIELD
     */
    @ComplexType()
    public diagramType: DiagramTypeModel;

    /**
     * All the tags available for the diagram,
     * holds each tag as one comma separated string
     */
    public tags: string;

    /**
     * This holds the data about diagram document status
     */
    public status: string;

    /**
     * This holds the data about diagram is template property
     * if diagram consider as template this will be true
     */
    public isTemplate: boolean;


    /**
     * This flag indicates if the diagram is editable or not
     */
    public editable: boolean;

    /**
     * Describes where the diagram is stored. It can be a third party service such as Google Drive
     * or it can be stored in Creately S3 Bucket. Assume Creately S3 if this property is not set.
     */
    public source: IDiagramSource;


    /**
     * List of eDataModel's linked in this document
     */
    public eData: string[];

    /**
     * The URL of the standard thumbnail image of the diagram
     * 130px x 80px
     * Digarm thumbnail url
     */
    private _thumbnailUrl: string;

    constructor( id: string, name: string, extension?: Object ) {
        super( id, name, extension );
    }

    /**
     * Returns the tags as an array of string by
     * splitting the tags string
     *
     * @return string[]  tags
     */
    @enumerable( true )
    public get tagsArray (): string [] {
        if ( this.tags ) {
            return this.tags.split( ',' );
        }
    }

    public set thumbnailUrl( thumbnailUrl: string ) {
       this._thumbnailUrl = thumbnailUrl;
    }

    @enumerable( true )
    public get thumbnailUrl (): string {
        return this._thumbnailUrl;
    }
}

Object.defineProperty( DiagramInfoModel, 'name', {
  writable: true,
  value: 'DiagramInfoModel',
});
