<div id='globalSearchContainer' class="global-search-container fx-cover fx-pointer-events-auto">
    <div class="global-search-input-field">
        <text-input #searchInputField
            [showClearButton]="true"
            icon="search-thin"
            [emitForInputChange]="true"
            placeholder="{{ searchPlaceholder }}"
            [debounce]="100"
            (updateText)="this.onSearchQueryChange.next( $event )"
            [autoFocus]="true"
            [transparentTextBox]="{ withPointerEvents: true }">
        </text-input>

        <!-- document search result items -->
        <document-search *ngIf="showDocumentSearchResults | async"></document-search>
    </div>
</div>
