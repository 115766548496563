import { once } from 'lodash';
import { Injectable } from '@angular/core';
import { fromEvent, Observable, of, EMPTY } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { StateService } from '../controller/state.svc';
import { Logger } from '../logger/logger.svc';

/**
 * Defines the types of postmesssage send events
 */
export enum PostMessageSendEventType {
    /**
     * Fired when a shape is touched
     */
    shapeTouch = 'shape:touch',

    /**
     * Fired when the selection changes
     */
    shapeSelect = 'shape:select',

    /**
     * Fired when the document is loaded
     */
    documentLoad = 'document:load',

    /**
     * Fired when diagram data changes
     */
    documentData = 'document:data',

    /**
     * Fired when diagram share related events happen
     */
    documentShare = 'document:share',

    /**
     * Fired when document token is successfully set
     */
    documentInit = 'document:init',

    /**
     * Fired when requesting the conference to start
     * data: { token: <jitsiJWTToken>, room: <roomName(documentId)> }
     */
    conferenceStart = 'creately:conference:start',

    /**
     * Fired to leave the conference
     */
    conferenceStop = 'creately:conference:stop',


    /**
     * Fired to mute own audio
     */
    conferenceMuteAudio = 'creately:conference:muteAudio',


    /**
     * Fired to mute video
     */
    conferenceMuteVideo = 'creately:conference:muteVideo',


    /**
     * Fired to request focus for the sender
     */
    conferenceRequestFocus = 'creately:conference:requestFocus',

    /**
     * Sends the init message to the plugin after it's loaded.
     * Also sets an identifier for the plugins to communicate via postMessage.
     * Done only at initialization.
     */
    pluginInit = 'creately:plugin:init',

    /**
     * Sends when clicked the close button to close
     * the diagram editor for plugin apps
     */
    documentClose = 'document:close',


    commandExecute = 'creately:command:execute',
}


/**
 * Defines the types of postmesssage revice events
 */
export enum PostMessageRecvEventType {
    /**
     * Subscribe to the shape touch
     */
    shapeTouchSubscribe = 'shape:touch:subscribe',

    /**
     * Subscribe to selection changes
     */
    shapeSelectSubscribe = 'shape:select:subscribe',

    /**
     * Subscribe to diagram data changes
     */
    documentDataSubscribe = 'document:data:subscribe',

    /**
     * Event type to modify document
     */
    documentModify = 'document:modify',

    /**
     * Event type to set user token
     */
    userSetToken = 'user:setToken',

    /**
     * When the connection is ready to start the room
     */
    conferenceConnectionSuccess = 'creately:conference:connnectionSuccess',

    /**
     * When the conference starts
     */
    conferenceStarted = 'creately:conference:started',


    /**
     * When there is an error in the conference
     */
    conferenceError = 'creately:conference:error',


    /**
     * When you are disconnected
     */
    conferenceDisconected = 'creately:conference:disconnected',

    /**
     * When another user joins or leaves the conference
     */
    conferenceUserListChanged = 'creately:conference:userListChanged',

    /**
     * This enables plugin options when recieved.
     */
    appEnablePluginOpts = 'app:enablePluginOpts',


    /**
     * Event type to execute a command
     */
    commandExecute = 'creately:command:execute',
}


/**
 * The PostMessage API is used to communicate with the parent window
 * when Creately Editor/Viewer is embedded inside a third party website.
 */
@Injectable()
export class PostMessageAPI {

    protected static authRequiredEvents = [
        PostMessageRecvEventType.documentDataSubscribe,
        PostMessageRecvEventType.shapeSelectSubscribe,
        PostMessageRecvEventType.shapeTouchSubscribe,
        PostMessageSendEventType.documentData,
        PostMessageSendEventType.shapeTouch,
        PostMessageSendEventType.shapeSelect,
        PostMessageSendEventType.commandExecute,
    ];

    constructor( protected states: StateService<any, any> ) {
        this.recv = once( this.recv.bind( this ));
    }

    /**
     * Sends a message to the parent window.
     */
    public sendToParent( event: string, data: object ) {
        if ( this.states.get( 'ApplicationIsEmbedded' ) && this.validateEvent( event )) {
            this.sendToWindow( event, data, this.getParentWindow());
        }
    }

    /**
     * Sends a message to the given window.
     */
    public sendToWindow( event: string, data: object, targetWindow: any, targetOrigin: string = '*' ) {
        Logger.debug( 'PostMessage: send:', event, data );
        try {
            const message = JSON.stringify({ source: 'creately', event, data });
            targetWindow.postMessage( message, targetOrigin );
        } catch ( err ) {
            // FIXME: check whether this behavior is okay
            Logger.debug( err.message );
        }
    }


    /**
     * Returns an observable which emits received messages.
     * TODO: Use a decorator for memoizing this function
     */
    public recv( targetOrigins?: Array<string>, source?: string ): Observable<unknown> {
        return this.listenToWindowMessage().pipe(
            switchMap(( event: MessageEvent ) => {
                try {
                    const data = JSON.parse( event.data );

                    // check if the origin and the source are matching
                    if ( targetOrigins && targetOrigins.indexOf( event.origin ) === -1 ) {
                        Logger.debug( 'invalid origin at host --- ', targetOrigins, event.origin );
                        return EMPTY;
                    }

                    if ( source && source !== data.source ) {
                        Logger.debug( 'invalid source at host ---', source, data.source );
                        return EMPTY;
                    }

                    if ( this.validateEvent( data.event )) {
                        return of( data );
                    }
                    // FIXME: check whether this behavior is okay
                    return EMPTY;
                } catch ( err ) {
                    // FIXME: check whether this behavior is okay
                    return EMPTY;
                }
            }),
            share(),
        );
    }

    /**
     * Returns an observable which emits received messages.
     * TODO: Use a decorator for memoizing this function
     */
    public recvCyclerEvents( targetOrigins?: Array<string>, source?: string ): Observable<unknown> {
        return this.listenToWindowMessage().pipe(
            switchMap(( event: MessageEvent ) => {
                try {
                    const data = event.data ;

                    // check if the origin and the source are matching
                    if ( targetOrigins && targetOrigins.indexOf( event.origin ) === -1 ) {
                        Logger.debug( 'invalid origin at host --- ', targetOrigins, event.origin );
                        return EMPTY;
                    }

                    if ( source && source !== data.source ) {
                        Logger.debug( 'invalid source at host ---', source, data.source );
                        return EMPTY;
                    }

                    if ( data && typeof data === 'string' )  {
                        return of( data );
                    }
                    // FIXME: check whether this behavior is okay
                    return EMPTY;
                } catch ( err ) /* istanbul ignore next */ {
                    // FIXME: check whether this behavior is okay
                    return EMPTY;
                }
            }),
            share(),
        );
    }


    /**
     * Validate the event using the current EmbeddedAppAuthenticated state
     */
    protected validateEvent( eventType ) {
        if ( !eventType ) {
            return false;
        }

        if ( PostMessageAPI.authRequiredEvents.includes( eventType )) {
            return this.states.get( 'EmbeddedAppAuthenticated' );
        }
        return true;
    }

    /**
     * Returns an observable which emits whenever the window dispatches
     * a 'message' event. This function is added for testability.
     */
    private listenToWindowMessage(): Observable<Event> {
        return fromEvent( window, 'message' );
    }

    /**
     * Returns the parent window.
     */
    private getParentWindow() {
        return window.parent;
    }

}
