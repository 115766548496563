import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DEFUALT_TEXT_STYLES, ITextContent, TEXT_PADDING_HORIZONTAL, DataType } from 'flux-definition/src';
import { EDataRegistry } from '../../../base/edata/edata-registry.svc';
import { AbstractShapeModel, TextFormatter } from 'flux-diagram-composer';
import { Rectangle } from '@creately/createjs-module';
import * as Carota from '@creately/carota';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';
import { TiptapDocumentsManagerShapeText } from '../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { tap } from 'rxjs/operators';

const DATA_ITEMS_TEXT_ID = 'data_items';

/**
 * SyncShapeDataItems
 * This commad runs after the ChangeShapeDataItems command
 * It cleans up the data_items text field, removing data items that have been deleted.
 * It also removes items with modified labels.
 */
@Injectable()
@Command()
export class SyncShapeDataItems extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        shapeIds: any,
    };

    protected formatter: TextFormatter;

    constructor( protected ds: DiagramChangeService ) {
        super( ds ) /* istanbul ignore next */;
        this.formatter = new TextFormatter();
    }

    public prepareData() {
        for ( const shapeId in this.data ) {
            const dataItems = this.data[ shapeId ];
            const shape = this.changeModel.shapes[ shapeId ] as ShapeModel;

            for ( const path in dataItems ) {
                const dataItem = dataItems[ path ];
                if ( dataItem && shape.renderedDataFields?.includes( path )) {
                    this.updateText( dataItem, shape );
                } else if ( dataItem === undefined ) {
                    this.removeText( path, shape );
                }
            }
        }
    }

    protected updateText ( dataItem, shape: ShapeModel ) {
        const text = shape.texts[ DATA_ITEMS_TEXT_ID ] as any;
        if ( text ) {
            if ( text.content.length > 0 && shape?.renderedDataFields.includes( dataItem.id )) {
                const segments = text.content.filter( segment => segment.data_item === dataItem.id );
                const prevLabel = segments[0].text;
                if ( prevLabel.indexOf( '\n' ) === -1 ) {
                    segments[0].text = `${dataItem.label}:`;
                } else {
                    segments[0].text = `\n${dataItem.label}:`;
                }
                segments[1].text = this.parseDataItem( dataItem );
            }
        }
    }

    protected parseDataItem( dataItem ): string {
        if ( dataItem && dataItem.type === 'date' ) {
            const value = dataItem.value;
            const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
            if ( value ) {
                const date = new Date( value );
                const dateString = date.toLocaleDateString( 'en-US', options );
                return ` ${dateString}`;
            } else {
                return '';
            }
        } else if ( dataItem && dataItem.type === DataType.LOOKUP ) {
            const entity = EDataRegistry.instance.getEntityDefById( dataItem.options.eDefId, 'creately.edata.custom' );
            return dataItem.value.length > 0 ? ` ${entity.name}` : '';
        }
        return ` ${dataItem.value}`;
    }

    protected removeText ( dataItem: string, shape: any ) {
        const template = shape.texts[ DATA_ITEMS_TEXT_ID ];
        if ( template ) {
            const content = template?.content.filter(( text: any ) => text.data_item !== dataItem );
            const bounds = this.calculateBounds( shape, content );
            if ( content.length > 0 ) {
                template.content = content;
                template.width = bounds.width;
                template.height = bounds.height;
            } else {
                delete shape.texts[ DATA_ITEMS_TEXT_ID ];
                this.repositionPrimaryText( shape );
            }
            shape.scaleY = ( shape.defaultBounds.height + bounds.height ) / shape.defaultBounds.height;
        }
    }

    protected calculateBounds( shape: AbstractShapeModel, content: ITextContent[]): Rectangle {
        return Carota.bounds( content, shape.bounds.width - 2 * TEXT_PADDING_HORIZONTAL, DEFUALT_TEXT_STYLES );
    }

    /**
     * Position primary text back to center of shape when all dataItems are removed
     * @param shape
     */
    protected repositionPrimaryText( shape: ShapeModel ) {
        if ( Object.keys( shape.texts ).length === 1 ) {
            const primary: any = shape.primaryTextModel;
            if ( primary.rendering === 'tiptapCanvas' || primary.rendering === 'dom' ) {
                TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shape.id, primary.id, { align: 'center' }).pipe(
                    tap( formatted => {
                        if ( formatted ) {
                            primary.html = formatted;
                        }
                    }),
                ).subscribe();
            } else {
                TiptapDocumentsManagerShapeText
                .applyTextStyles( this.changeModel.id, shape.id, primary.id, { align: 'center' }).subscribe();
                const primaryContent = this.formatter.applyRaw(
                    primary.content, { indexStart: 0, indexEnd: undefined, styles: { align: 'center' }}, false );
                primary.content = primaryContent;
                primary.value = Carota.html.html( primaryContent );
            }
            primary.xType = 'relative';
            primary.yType = 'relative';
            primary.positionString = 'in-center-center';
            primary.x = 0.5;
            primary.y = 0.5;
            primary.alignX = 0;
            primary.alignY = 0;
            primary._alignX = 0;
        }
    }
}

Object.defineProperty( SyncShapeDataItems, 'name', {
    value: 'SyncShapeDataItems',
});
