import { Injectable } from '@angular/core';
import { Sakota } from '@creately/sakota';
import { TranslateService } from '@ngx-translate/core';
import { AbstractCommand, AbstractNotification, Command,
    CommandService, MapOf, NotificationType, NotifierController, Random } from 'flux-core';
import { set } from 'lodash';
import { EMPTY, Observable, Subject } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AbstractShapeModel } from '../../../../../../libs/flux-diagram-composer/src';
import { DiagramCommandEvent } from '../../../editor/diagram/command/diagram-command-event';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { Notifications } from '../../notifications/notification-messages';
import { EDataLocatorLocator } from '../locator/edata-locator-locator';
import { EntityListModel } from '../model/entity-list.mdl';
import { EDataCommandEvent } from './edata-command-event';
import { ShapeModel } from '../../shape/model/shape.mdl';


@Injectable()
@Command()
export class CreateSavedSet extends AbstractCommand {
    public data: {
        shapes: MapOf<AbstractShapeModel>;
        addedShapes: MapOf<AbstractShapeModel>;
    };

    constructor(
        private ll: DiagramLocatorLocator,
        private ell: EDataLocatorLocator,
        private commandSvc: CommandService,
        private notifierController: NotifierController,
        private translate: TranslateService,
        ) {
        super();
    }

    public get executeTimeout(): number {
        return -1; // not to timeout
    }

    execute(): boolean | Observable<any> {
        return this.getDiagramModel().pipe(
            switchMap( diagram => {
                const shapes = Object.keys( this.data.addedShapes ).map( shapeId => diagram.shapes[ shapeId ]);
                const firstShape = shapes[ 0 ];
                if ( firstShape.entityListId ) { // already added as a saved set
                    const { entityListId, eDataId, containerId } = firstShape as ShapeModel;
                    if ( Object.keys( this.data.shapes ).length > shapes.length ) {
                        this.ell.getEDataModel( firstShape.eDataId ).pipe(
                            tap( eModel => {
                                const eDataModel = Sakota.create( eModel );
                                const entityList = eDataModel.entityLists[entityListId];
                                for ( const shapeId in this.data.shapes ) {
                                    if ( !this.data.addedShapes[shapeId]) {
                                        const entityId = this.data.shapes[shapeId].eData[eDataId];
                                        set( entityList.containers, [ diagram.id, containerId, entityId ], {
                                            isMoved: false,
                                            pending: true,
                                        });
                                    }
                                }
                                const modifier = eDataModel.__sakota__.getChanges();
                                this.commandSvc.dispatch( EDataCommandEvent.applyModifierEData, eDataId, { modifier });
                            }),
                        ).subscribe();
                    }
                    return EMPTY;
                }
                // ask for user confirmation.
                const s = new Subject<boolean>();
                this.createSmartSetDialog( s );
                return s;
            }),
        );
    }

    protected getDiagramModel() {
        return this.ll.forCurrentObserver( false ).pipe(
            take( 1 ),
            switchMap( locator => locator.getDiagramOnce()),
        );
    }

    protected createSmartSet() {
        this.getDiagramModel().pipe(
            switchMap( diagram => {
                const addedIds = Object.keys( this.data.addedShapes );
                const shapes = addedIds.map( shapeId => diagram.shapes[ shapeId ]);
                const pendingIds = Object.keys( this.data.shapes )
                    .filter( shapeId => !this.data.addedShapes[ shapeId ]);
                const pendingShapes = pendingIds.map( shapeId => this.data.shapes[ shapeId ]);
                const firstShape = shapes[ 0 ];
                const eDataId = firstShape.eDataId;
                const definedSearchQuery = this.data.shapes[ firstShape.id ].definedSearchQuery;
                return this.ell.getEDataModel( eDataId ).pipe(
                    tap( eDataModel => {
                        const entityListId = Random.entityListId();
                        const eDataSakotaModel = Sakota.create( eDataModel );
                        const newEntityListModel = new EntityListModel( entityListId, eDataModel.defId );
                        newEntityListModel.search = definedSearchQuery;
                        const entityListCount = eDataModel.entityLists
                            ? Object.keys( eDataModel.entityLists ).length : 0;
                        newEntityListModel.name = 'Saved Set ' + ( entityListCount + 1 );
                        if ( !eDataModel.entityLists ) {
                            eDataSakotaModel.entityLists = {};
                        }
                        eDataSakotaModel.entityLists[entityListId] = newEntityListModel;
                        let entityIds = shapes.map( shape => shape.entityId );
                        entityIds = entityIds.concat( pendingShapes.map( shape => shape.eData[eDataId]));
                        const entities = entityIds.map( eId => eDataModel.entities[ eId ]);
                        newEntityListModel.entities = entityIds;
                        const containerId = ( firstShape as any ).containerId;
                        for ( const entity of entities ) {
                            eDataSakotaModel.entities[entity.id]
                                .entityListsIds = [ ...entity.entityListsIds, entityListId ];
                            set( newEntityListModel.containers,
                                [ diagram.id, containerId, entity.id, 'isMoved' ], false );
                        }
                        for ( const shape of pendingShapes ) {
                            set( newEntityListModel.containers,
                                [ diagram.id, containerId, shape.eData[eDataId], 'pending' ], true );
                        }
                        const dModel = Sakota.create( diagram );
                        addedIds.forEach( shapeId => {
                            dModel.shapes[ shapeId ].entityListId = entityListId;
                        });
                        this.commandSvc.dispatch( EDataCommandEvent.applyModifierEDataUser, eDataId, {
                            modifier: eDataSakotaModel.__sakota__.getChanges(),
                        });
                        this.commandSvc.dispatch( DiagramCommandEvent.applyModifierDocument, diagram.id, {
                            modifier: dModel.__sakota__.getChanges(),
                        });
                    }),
                );
            }),
        ).subscribe();
    }

    protected createDisplayList() {
        if ( Object.keys( this.data.shapes ).length < 200 ) {
            return true;
        }
        if ( Object.keys( this.data.shapes ).length === Object.keys( this.data.addedShapes ).length ) {
            return true;
        }
        this.getDiagramModel().pipe(
            tap( diagram => {
                const { addedShapes, shapes } = this.data;
                const addedIds = Object.keys( this.data.addedShapes );
                const firstShape = Object.values( shapes )[ 0 ];
                const eDataId = Object.keys( firstShape.eData )[0];
                const definedSearchQuery = this.data.shapes[ firstShape.id ].definedSearchQuery;
                const entityListId = Random.entityListId();
                const entityDefId = firstShape.entityDefId
                    || ( diagram.shapes[addedIds[0]] as ShapeModel ).entityDefId;
                const dModel = Sakota.create( diagram );
                if ( !dModel.displayLists ) {
                    dModel.displayLists = {};
                }
                dModel.displayLists[entityListId] = {
                    eDataId, searchQuery: definedSearchQuery, eDefId: entityDefId,
                    containerId: ( diagram.shapes[addedIds[0]] as ShapeModel ).containerId,
                    entities: Object.values( shapes ).map( shape => shape.eData[ eDataId ]),
                    added: Object.values( addedShapes ).map( shape => shape.eData[ eDataId ]),
                };
                addedIds.forEach( shapeId => {
                    dModel.shapes[ shapeId ].displayListId = entityListId;
                });
                this.commandSvc.dispatch( DiagramCommandEvent.applyModifierDocument, diagram.id, {
                    modifier: dModel.__sakota__.getChanges(),
                });
            }),
        ).subscribe();
    }

    protected createSmartSetDialog( s: Subject<boolean> ) {
        const translate = this.translate.instant.bind( this.translate );
        const notificationData = {
            id: Notifications.CREATE_SAVED_SET,
            component: AbstractNotification,
            type: NotificationType.Neutral,
            collapsed: false,
            options: {
                inputs: {
                    heading: translate( `NOTIFICATIONS.EDATA.CREATE_SAVED_SET.HEADING` ),
                    description: translate( `NOTIFICATIONS.EDATA.CREATE_SAVED_SET.DESCRIPTION` ),
                    autoDismiss: false,
                    buttonOneText: translate( `NOTIFICATIONS.EDATA.CREATE_SAVED_SET.BUTTON_ONE_TEXT` ),
                    buttonTwoText: translate( `NOTIFICATIONS.EDATA.CREATE_SAVED_SET.BUTTON_TWO_TEXT` ),
                    onDismiss: () => {
                        this.createDisplayList();
                        s.error( new Error( 'User Opted not to create a saved set' ));
                    },
                    buttonOneAction: () => {
                        this.notifierController.hide( Notifications.CREATE_SAVED_SET );
                        this.createSmartSet();
                        s.complete();
                    },
                    buttonTwoAction: () => {
                        this.notifierController.hide( Notifications.CREATE_SAVED_SET );
                        this.createDisplayList();
                        s.complete();
                    },
                },
            },
        };
        return this.notifierController.show( Notifications.CREATE_SAVED_SET, notificationData.component,
            notificationData.type, notificationData.options, notificationData.collapsed );
    }
}

Object.defineProperty( CreateSavedSet, 'name', {
    value: 'CreateSavedSet',
});
