import {
    TiptapDocumentsManagerShapeText,
} from './../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { ResultChangeBindingService } from './../../../framework/diagram/bindings/result-change-binding.svc';
import { SessionHistoryManager } from './../../../system/session-history-manager.svc';
import { IRevertableCommandEventData } from './../../../base/diagram/command/scenario/abstract-revertable-scenario';
import { Injectable } from '@angular/core';
import { invert } from '@creately/mungo';
import {
    AbstractCommand,
    Command,
    CommandScenario,
    Random,
    CommandInterfaces,
    IUnsavedModelChange,
    CommandCancelError,
    CommandResultError,
    StateService,
    EventSource,
} from 'flux-core';
import { cloneDeep, isEmpty } from 'lodash';
import { Observable, defer, concat, EMPTY } from 'rxjs';
import { switchMap, ignoreElements, tap, catchError } from 'rxjs/operators';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';
import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { ChangeBindingService } from '../../../framework/diagram/bindings/change-binding.svc';
import { StoredDiagramLocator } from 'flux-diagram-composer';
import { TiptapDocumentsManager } from '../../../base/ui/shape-data-editor/tiptap-documents-manager.cmp';

/**
 * This DocumentChange command is to update the document by a given change modifier
 */
@Injectable()
@Command()
export class DocumentChange extends AbstractCommand {
    /**
     * This command should be sent to the server
     */
    public static get implements(): CommandInterfaces[] {
        return [ 'IMessageCommand', 'IDiagramCommand' ];
    }

    /**
     * Inject services and stuff!
     */
    constructor(
        private sessionHistoryManager: SessionHistoryManager,
        private ll: DiagramLocatorLocator,
        private cs: DiagramChangeService,
        private changeBindingService: ChangeBindingService,
        private resultChangeBindingService: ResultChangeBindingService,
        protected state: StateService<any, any>,
    ) {
        super();
    }

    /**
     * Inserts a preview model change for the PREVIEW scenario.
     * Inserts an unsaved model change for the EXECUTE scenario.
     */
    public execute(): Observable<unknown> {
        if ( this.eventData.scenario === CommandScenario.REDO ||
            this.eventData.scenario === CommandScenario.UNDO ) {
            return this.runUndoRedo();
        } else if ( this.eventData.scenario === CommandScenario.PREVIEW ) {
            return this.runPreview();
        }
        return this.runExecute();
    }

    /**
     * Inserts a saved model change for the EXECUTE scenario.
     */
    public executeResult(): Observable<unknown> {
        return concat(
            this.bindResultChanges( this.resultData.change ),
            defer(() => {
                const change = this.resultData.change;
                if ( change.ctx && change.ctx.isAPIChange ) {
                    TiptapDocumentsManager.updateDocumentByModifier( this.resourceId, change.modifier );
                }
                const updatedModifier = this.cs.flushChanges( this.eventData.eventId );
                if ( updatedModifier ) {
                    change.modifier = updatedModifier;
                }
                return this.cs.addSavedChange({
                    ...change,
                    modelId: this.resourceId,
                    command: this.name,
                    status: 'saved',
                });
            }),
        );
    }

    /**
     * Remove the given changes from local cache. If a change fails to get applied
     * on the server.
     */
    public onError( error: CommandResultError ) {
        // NOTE: Revert the changes, except for offline changes.
        if ( error.code ) {
            return this.cs.removeChanges([ this.data.change.id ]).toPromise().then(() => {
                this.state.set( 'Selected', []);
            });
        }
        super.onError( error );
    }


    protected bindChanges() {
        const eventId = this.eventData.eventId;
        const scenario = this.eventData.scenario;
        return this.cs.getChangeModel( this.resourceId, eventId, scenario ).pipe(
            switchMap( model => this.changeBindingService.apply( model, scenario, { eventData: this.eventData })),
            // tslint:disable-next-line: no-console
            tap({ error: e => console.log( 'DocumentChange.bindChanges errors', e ) }),
            ignoreElements(),
        );
    }

    protected bindResultChanges( change ) {
        const eventId = this.eventData.eventId;
        const scenario = this.eventData.scenario;
        return this.cs.getChangeModel( this.resourceId, eventId, scenario, change ).pipe(
            switchMap( model => {
                if ( !isEmpty( model.__sakota__.getChanges())) {
                    return this.resultChangeBindingService.apply( model );
                }
                return EMPTY;
            }),
            // tslint:disable-next-line: no-console
            tap({ error: e => console.log( 'DocumentChange.bindResultChanges errors', e ) }),
            // below line is important. in case of a failure, we should not discard the change
            catchError(() => EMPTY ),
        );
    }

    protected runPreview(): Observable<unknown> {
        return concat(
            this.bindChanges(),
            defer(() => {
                const modifier = this.cs.flushChanges( this.eventData.eventId );
                if ( !modifier || ( isEmpty( modifier.$set ) && isEmpty( modifier.$unset ))) {
                    throw new CommandCancelError( 'the modifier is empty, ignoring it' );
                }
                return this.cs.addPreviewChange( modifier );
            }),
        );
    }

    protected runExecute(): Observable<unknown> {
        return concat(
            this.data && this.data.skipChangeBinders ? EMPTY : this.bindChanges(),
            defer(() => {
                const modifier = this.cs.flushChanges( this.eventData.eventId );
                if ( !modifier || ( isEmpty( modifier.$set ) && isEmpty( modifier.$unset ))) {
                    throw new CommandCancelError( 'the modifier is empty, ignoring it' );
                }
                if ( this.data?.__origin__ ) {
                    const origin = this.data.__origin__;
                    delete this.data.__origin__;
                    return this.setUnsavedChange( modifier, { origin });
                }
                return this.setUnsavedChange( modifier );
            }),
        );
    }

    protected runUndoRedo(): Observable<unknown> {
        const modifierData = ( this.eventData as IRevertableCommandEventData ).modifierData.shift();
        if ( modifierData.command === this.name ) {
            return this.getLocator( false ).getDiagramOnce().pipe(
                switchMap( model => {
                    TiptapDocumentsManagerShapeText.updateTiptp( modifierData.reverter, model );
                    return this.setUnsavedChange( modifierData.reverter );
                }),
            );
        } else {
            throw new Error( 'Modifier data to be overriden for the command '
                + this.name + ' did not match.' );
        }
    }

    private setUnsavedChange( modifier, ctx?: any ) {
        if ( this.eventData.source !== EventSource.EXTERNAL ) {
            this.sessionHistoryManager.recordExecution(
                this.eventData.eventId, this.eventData.scenario, this.eventData.source as EventSource );
        }
        const isUndoOrRedo = this.eventData.scenario === CommandScenario.UNDO
            || this.eventData.scenario === CommandScenario.REDO;
        const locator: StoredDiagramLocator<any, any> = this.getLocator( false ) as any;
        return locator.getRawDiagramDataOnce().pipe(
            switchMap( diagram => {
                const change: IUnsavedModelChange = {
                    id: Random.changeId(),
                    event: cloneDeep( this.eventData ),
                    userId: '',
                    modelId: this.resourceId,
                    command: this.name,
                    clientTime: Date.now(),
                    modifier,
                    reverter: invert( diagram, modifier ),
                    status: 'unsaved',
                };
                if ( ctx ) {
                    change.ctx = ctx;
                }
                this.data = { change: change };
                if ( isUndoOrRedo ) {
                    // skipping adding unsaved change to preserve the reverse order
                    // with server calculated (esync) changes
                    // anyway this change will be saved once neutrino responses.
                    // SIDE EFFECT: there will be a delayed feedback for the user
                    return EMPTY;
                }
                return this.cs.addUnsavedChange( change );
            }),
        );
    }

    /**
     * Returns the diagram locator considering the id.
     */
    private getLocator( preview: boolean ) {
        return this.resourceId
            ? this.ll.forDiagram( this.resourceId, preview )
            : this.ll.forCurrent( preview );
    }
}

Object.defineProperty( DocumentChange, 'name', {
    value: 'DocumentChange',
});
