import { IAlignmentNode } from './../../../editor/diagram/autoalign/alignment-node-locator';
import { last, uniq, uniqBy, union, get, cloneDeep } from 'lodash';
import { Logger, ComplexType, Number, Rectangle } from 'flux-core';
import { ConnectorTextModel } from './text/connector-text.mdl';
import { ISidebarContextAware, ISidebarPanelData } from './../../../framework/ui/side-bar/sidebar-framework';
import {
    ConnectorDataModel,
    IConnectorPoint,
    IConnectorEndPoint,
    DEFAULT_CONNECTOR,
    EDATAREF_CONNECTOR,
    LOOKUP_CONNECTOR,
    IShapeTypeDefiner,
} from 'flux-diagram-composer';
import { ICanvasContextMenuAware } from '../../../framework/interaction/context-menu/canvas-context-menu-aware.i';
import { ShapeModel } from './shape.mdl';
import { GluePointModel } from './gluepoint.mdl';
import { DataType, IContextualToolbarItem } from 'flux-definition';
import { IContextualToolbarAware } from '../../../framework/interaction/contextual-toolbar/contextual-toolbar-aware.i';
import { LineStyle } from '../../../editor/feature/style/line-style';
import { DiagramModel } from '../../diagram/model/diagram.mdl';
import { ConnectorRegistry } from '../definition/connector-registry.svc';
import { IConnection, IShapePortIdentifier } from '../connection/connection.i';
import {
    IConnectorDefinition,
    IShapePort,
    DEFUALT_TEXT_STYLES,
} from 'flux-definition';
import { ALIGNMENT_OPTIONS, LAYOUTING_DATA } from './shape-common';
import { EntityLinkType } from 'flux-definition';
import { EDataRegistry } from '../../edata/edata-registry.svc';

// tslint:disable:max-file-line-count
// tslint:disable:member-ordering
/**
 * Information about connector endpoints with references
 * to connected shape and gluepoint models.
 */
export interface IConnectorEndPointWithRef extends IConnectorPoint {
    /**
     * The shape the connector endpoint is connected to.
     */
    shape: ShapeModel;

    /**
     * The gluepoint on the shape the endpoint is connected to.
     */
    gluepoint: GluePointModel;

    /**
     * Will be set to true if the user selected a specific glue point.
     * If locked, the application will not override the glupoint.
     */
    gluepointLocked: boolean;
}

/**
 * Contains a shape model and a shape port which is used to create a connection.
 */
export interface IShapePortIdentifierWithRef extends IShapePortIdentifier {
    shape: ShapeModel;
    port: IShapePort;
}

/**
 * Information about a connector connection with references to connected shapes.
 */
export interface IConnectionWithRef extends IConnection {
    shapeA: IShapePortIdentifierWithRef;
    shapeB: IShapePortIdentifierWithRef;
}

/**
 * Information about a connector type
 */
export interface IConnectorTypeDefiner extends IShapeTypeDefiner {
    handshake: string[];
}

/**
 * This is the concrete model of a connector. Extends the ConnectorDataModel
 * which is the full data of a conenctor. This model will contain all data
 * processing and manipulation functionality needed for the connector data.
 *
 * @author Ramishka
 * @since 2017-09-20
 */
export class ConnectorModel extends ConnectorDataModel implements
                                                    IConnectorDefinition,
                                                    ISidebarContextAware,
                                                    ICanvasContextMenuAware,
                                                    IContextualToolbarAware {
    /**
     * Prepares IConnectorEndPointWithRef using a connector end point and the connected shape
     */
    private static getGluePointInfoWithRef(
        point: IConnectorEndPoint,
        diagram: DiagramModel,
    ): IConnectorEndPointWithRef {
        const info: IConnectorEndPointWithRef = {
            id: point.id,
            x: point.x,
            y: point.y,
            c1: point.c1,
            c2: point.c2,
            nextId: point.nextId,
            prevId: point.prevId,
            shape: null,
            gluepoint: null,
            gluepointLocked: null,
        };
        if ( !point.shapeId ) {
            return info;
        }
        info.shape = ( diagram.shapes[point.shapeId] as ShapeModel ) || null;
        if ( !info.shape ) {
            // NOTE: endpoint is referring to a shape which is not available
            //       on the diagram. Consider the endpoint is not connected.
            Logger.error( `Unable to find connected shape "${point.shapeId}" on diagram` );
            return info;
        }
        if ( !point.gluepointId ) {
            return info;
        }
        info.gluepoint = info.shape.gluepoints[point.gluepointId];
        info.gluepointLocked = point.gluepointLocked;
        return info;
    }

    /**
     * Texts for the shape, single shape can have multiple texts
     * Each text contains data to position itself on the shape
     * and also the text and text styles as html.
     */
    @ComplexType({ '*' : ConnectorTextModel })
    public texts: { [id: string]: ConnectorTextModel } = {};

    /**
     * Contains style properties which are used to style a shape's
     * text color, line and fill styles.
     */
    @ComplexType()
    public style: LineStyle;

    /**
     * A list of strings used to find matching ports. These will be used along
     * with the hanshake property of a port to identify if this connector can
     * connect to a port. Both ports and this connector must share a common
     * handshake to form a connection (eg. if handshake "hey" is available on both
     * shapes and this connector, they can form a connection).
     */
    public handshake?: string[];

    /**
     * gluepoint of the source shape calculated by auto layouting
     * e.g. 'shapeId-gluepointId'
     */
    public fromGPAutolayout: string;

    /**
     * gluepoint of the target shape calculated by auto layouting
     * e.g. 'shapeId-gluepointId'
     */
    public toGPAutolayout: string;

    /**
     * The connection type when this represents an entity connection.
     * Only acceptable values are CONNECTOR_IN, CONNECTOR_OUT, CONNECTOR_BI
     */
    public connType?: EntityLinkType;


    /**
     * This field will store the id of a shape-to-shape connection if this connector
     * has created one. This id may only belong to a "connector" type connection.
     */
    public connectionId?: string;

    /**
     * NOTE: This property is avaible when the model is fetched from the
     * viewpport serivce and the intention of this is to append viewport
     * specific caclulations to the model in place without having to clone
     * the shape models in viewport service which is a performance hit.
     */
    public  viewPoints?: IConnectorPoint[];
    public get viewBounds(): Rectangle {
        const points = this.viewPoints;
        if ( !points ) {
            throw new Error( 'ConnectorModel.viewBounds undefined, use viewportService to get the connector model' );
        }
        if ( !points.length ) {
            return new Rectangle( 0, 0, 0, 0 );
        }
        if ( this.entryClassName === 'ConnectorCurved' ) {
            return this.getCurvedPathBounds( points );
        }
        return Rectangle.withPoints( ...points );
    }

    public getFromEndpoint( root: DiagramModel ) {
        const point = this.getPoints()[0] as IConnectorEndPoint;
        return ConnectorModel.getGluePointInfoWithRef( point, root );
    }
    public getToEndpoint( root: DiagramModel ) {
        const point = last( this.getPoints());
        return ConnectorModel.getGluePointInfoWithRef( point, root );
    }

    public getDataItems() {
        return {} as any;
    }

    public getAvailableTypes( root: DiagramModel ) {
        const fromEndpoint = this.getFromEndpoint( root );
        const toEndpoint = this.getToEndpoint( root );
        const shapeA = fromEndpoint.shape;
        const shapeB = toEndpoint.shape;
        const defs: IConnectorDefinition[] = [ DEFAULT_CONNECTOR ];

        if ( this.defId !== DEFAULT_CONNECTOR.defId && this.defId !== LOOKUP_CONNECTOR.defId ) {
            defs.push( ConnectorRegistry.instance.fetch( this.defId ));
        }
        if ( !shapeA || !shapeB ) {
            return defs;
        }
        root.getPotentialConnections( shapeA.id, shapeB.id )
            .forEach( con => con.definitions.forEach( def => defs.push( def )));

        // if either one is eData, add the ref. connector last
        if ( shapeA.eData || shapeB.eData ) {
            defs.push( EDATAREF_CONNECTOR );
        }
        const lookupDefs = [];
        if ( shapeA.eData && shapeB.eData ) {
            const data = shapeA.getDataItems( root ) || root.getShapeDataItems( shapeA.id );
            const dataB = shapeB.getDataItems( root ) || root.getShapeDataItems( shapeB.id );
            const connectable = ( d, shape = shapeB ) => {
                if ( !d.options.isMirrorField ) {
                    return d.options.allowMultiple ||
                    ( !d.value || d.value.length === 0 || d.value[0] === shape.entityId );
                }
                if ( shapeA.entityDefId === shapeB.entityDefId ) { // same type
                    const reverseId = d.id.split( '' ).reverse().join( '' );
                    return connectable( dataB[reverseId], shapeA );
                }
                return connectable( dataB[d.id], shapeA );
            };
            const lookups = Object.values( data )
                .filter( d => d.type === DataType.LOOKUP )
                .filter( d => {
                    const { eDefId, eDataDefId } = d.options;
                    const eDataId = d.options.eDataId || EDataRegistry.getEDataId( eDataDefId );
                    return eDataId === shapeB.eDataId && eDefId === shapeB.entityDefId;
                })
                .filter( d => connectable( d ));
            lookups.forEach( d => {
                lookupDefs.push({ ...LOOKUP_CONNECTOR, name: d.label, handshake: [ d.id ]});
            });
        }
        return uniqBy( defs, def => def.defId ).concat( uniqBy( lookupDefs, def => def.handshake[0]));
    }

    public getConnection( root: DiagramModel ): IConnectionWithRef {
        if ( !this.connectionId ) {
            return null;
        }
        const conn = root.connections && root.connections[this.connectionId];
        if ( !conn ) {
            // NOTE: connector is referring to a connection which is not available
            //       on the diagram. Considering the connection is not available.
            Logger.error( `Unable to find connection "${this.connectionId}" on diagram` );
            return null;
        }
        const info: IConnectionWithRef = cloneDeep( conn );
        info.shapeA.shape = root.shapes[ info.shapeA.shapeId ] as ShapeModel;
        if ( !info.shapeA.shape ) {
            // NOTE: connector is referring to a shape which is not available
            //       on the diagram. Considering the connection is not available.
            Logger.error( `Unable to find shape "${info.shapeA.shapeId}" on diagram` );
            return null;
        }
        info.shapeA.port = info.shapeA.shape.ports && info.shapeA.shape.ports.find( p => p.id === info.shapeA.portId );
        if ( !info.shapeA.port ) {
            // NOTE: connector is referring to a port which is not available
            //       on the shape. Considering the connection is not available.
            Logger.error( `Unable to find shape port "${info.shapeA.shapeId}/${info.shapeA.portId}" on diagram` );
            return null;
        }
        info.shapeB.shape = root.shapes[ info.shapeB.shapeId ] as ShapeModel;
        if ( !info.shapeB.shape ) {
            // NOTE: connector is referring to a shape which is not available
            //       on the diagram. Considering the connection is not available.
            Logger.error( `Unable to find shape "${info.shapeB.shapeId}" on diagram` );
            return null;
        }
        info.shapeB.port = info.shapeB.shape.ports && info.shapeB.shape.ports.find( p => p.id === info.shapeB.portId );
        if ( !info.shapeB.port ) {
            // NOTE: connector is referring to a port which is not available
            //       on the shape. Considering the connection is not available.
            Logger.error( `Unable to find shape port "${info.shapeB.shapeId}/${info.shapeB.portId}" on diagram` );
            return null;
        }
        return info;
    }

    constructor( id: string, extention?: Object ) {
        super( id, extention )/* istanbul ignore next */;
    }

    /**
     * Returns the ids of the connected shape(s)
     */
    public getConnectedShapeIds( root: DiagramModel ): string[] {
        const fromEndpoint = this.getFromEndpoint( root );
        const toEndpoint = this.getToEndpoint( root );
        const ids = [];
        if ( fromEndpoint && fromEndpoint.shape ) {
            ids.push( fromEndpoint.shape.id );
        }
        if ( toEndpoint && toEndpoint.shape ) {
            ids.push( toEndpoint.shape.id );
        }
        return ids;
    }

    /**
     * This function Implements ISidebarContextAware method
     * and defines the side bar panels for the connector Model
     * @returns string[] The list of panel ids
     */
    public getSidebarPanels(): ISidebarPanelData[] {
        const panels = [
            { id: 'shapePanel', features: [
                { featureId: 'boldModelText', data: {}},
                { featureId: 'italicModelText', data: {}},
                { featureId: 'underlineModelText', data: {}},
                { featureId: 'strikeoutModelText', data: {}},
                { featureId: 'leftAlignModelText', data: {}},
                { featureId: 'leftAlignModelText', data: {}},
                { featureId: 'centerAlignModelText', data: {}},
                { featureId: 'rightAlignModelText', data: {}},
                { featureId: 'colorModelText', data: {}},
                {
                    featureId: 'sizeModelText',
                    data: {
                        style: 'size',
                        value: DEFUALT_TEXT_STYLES.size,
                        // FIXME This format should be converted and should use type params.
                        data: [ 10, 12, 14, 18, 20, 24, 30, 36, 48, 60, 72, 96 ]
                            .map( i => ({ id: `${i}`, label: `${i}`, buttonLabel: `${i}`, value: i })),
                    },
                },
                {
                    featureId: 'fontModelText',
                    data: {
                        style: 'font',
                        value: DEFUALT_TEXT_STYLES.font,
                        // FIXME This format should be converted and should use type params.
                        data: [
                            { id: `noto_regular`,
                                label: `<span style="font-family:noto_regular">Noto</span>`,
                                buttonLabel: `Noto`,
                                value: 'noto_regular' },
                            { id: `lt_regular`,
                                label: `<span style="font-family:lt_regular">Lato</span>`,
                                buttonLabel: `Lato`,
                                value: 'lt_regular' },
                            { id: `champagne`,
                                label: `<span style="font-family:champagne">Champagne</span>`,
                                buttonLabel: `Champagne`,
                                value: 'champagne' },
                            { id: `indie`,
                                label: `<span style="font-family:indie">Indie</span>`,
                                buttonLabel: `Indie`,
                                value: 'indie' },
                            { id: `bebas`,
                                label: `<span style="font-family:bebas">Bebas</span>`,
                                buttonLabel: `Bebas`,
                                value: 'bebas' },
                            { id: `bree`,
                                label: `<span style="font-family:bree">Bree</span>`,
                                buttonLabel: `Bree`,
                                value: 'bree' },
                            { id: `spartan`,
                                label: `<span style="font-family:spartan">Spartan</span>`,
                                buttonLabel: `Spartan`,
                                value: 'spartan' },
                            { id: `montserrat`,
                                label: `<span style="font-family:montserrat">Montserrat</span>`,
                                buttonLabel: `Montserrat`,
                                value: 'montserrat' },
                            { id: `open_sanscondensed`,
                                label: `<span style="font-family:open_sanscondensed">Open Sans</span>`,
                                buttonLabel: `Open Sans`,
                                value: 'open_sanscondensed' },
                            { id: `playfair`,
                                label: `<span style="font-family:playfair">Playfair</span>`,
                                buttonLabel: `Playfair`,
                                value: 'playfair' },
                            { id: `raleway`,
                                label: `<span style="font-family:raleway">Raleway</span>`,
                                buttonLabel: `Raleway`,
                                value: 'raleway' },
                            { id: `courier_prime`,
                                label: `<span style="font-family:courier_prime">Courier Prime</span>`,
                                buttonLabel: `Courier Prime`,
                                value: 'courier_prime' },
                            { id: `droid_serifregular`,
                                label: `<span style="font-family:droid_serifregular">Droid Serif</span>`,
                                buttonLabel: `Droid Serif`,
                                value: 'droid_serifregular' },
                            { id: `abhaya_libreregular`,
                                label: `<span style="font-family:abhaya_libreregular">Abhaya Libre</span>`,
                                buttonLabel: `Abhaya Libre`,
                                value: 'abhaya_libreregular' },
                            { id: `gandhi_serifregular`,
                                label: `<span style="font-family:gandhi_serifregular">Gandhi Serif</span>`,
                                buttonLabel: `Gandhi Serif`,
                                value: 'gandhi_serifregular' },
                            { id: `sans_serif`,
                                label: `<span style="font-family:arial,helvetica,sans-serif">` +
                                `Sans-Serif (System)</span>`,
                                buttonLabel: `Sans-Serif`,
                                value: 'arial,helvetica,sans-serif' },
                            { id: `serif`,
                                label: `<span style="font-family:Times New Roman,Courier New,` +
                                `Courier,Georgia,serif">Serif (System)</span>`,
                                buttonLabel: `Serif`,
                                value: 'Times New Roman,Courier New,Courier,Georgia,serif' },
                        ],
                    },
                },
                {
                    featureId: 'colorLine',
                    data: { value: this.style.lineColor },
                },
                {
                    featureId: 'styleLine',
                    data: {
                        value: this.style.lineStyle || [ 0, 0 ],
                        data: [
                            { id: `solid`, value: [ 0, 0 ]},
                            { id: `dotted`, value: [ 1, 3 ]},
                            { id: `dashed`, value: [ 3, 3 ]},
                            { id: `dashed_2`, value: [ 5, 5 ]},
                        ],
                    },
                },
                {
                    featureId: 'lineThickness',
                    data: {
                        value: this.style.lineThickness,
                    },
                },
                { featureId: 'connectorStartX' },
                { featureId: 'connectorStartY' },
                { featureId: 'connectorEndX' },
                { featureId: 'connectorEndY' },
            ]},
            { id: 'diagraminfo', features: null },
        ];
        return panels;
    }

    /**
     * This function Implements ISidebarContextAware method
     * and defines the multi select side bar panels for the same
     * type of shape models are selected
     * @returns string[] The list of panel ids
     */
    public getSidebarPanelsForSameType(): ISidebarPanelData[] {
        return this.getSidebarPanels().map( p => {
            if ( p.id === 'shapePanel' ) {
                [
                    'connectorStartX',
                    'connectorStartY',
                    'connectorEndX',
                    'connectorEndY',
                ].forEach( id => {
                    const index =  p.features.findIndex( f => f.featureId === id );
                    p.features.splice( index, 1 );
                });
                p.features.push( ...[
                    { featureId: 'selectionPositionX' },
                    { featureId: 'selectionPositionY' },
                    { featureId: 'selectionWidth' },
                    { featureId: 'selectionHeight' },
                ]);
            }
            return p;
        });
    }

    /**
     * This function Implements ISidebarContextAware method
     * and defines the multi select side bar panels for multiple
     * types of shape models are selected
     * @returns string[] The list of panel ids
     */
    public getSidebarPanelsForMultiTypes(): ISidebarPanelData[] {
        return this.getSidebarPanelsForSameType().map( p => {
            if ( p.id === 'shapePanel' ) {
                p.features.push({ featureId: 'colorFill', data: {}});
            }
            return p;
        });
    }

    /**
     * This defines all the context menu items for a connector model
     */
    public getContextMenuItems(): Array<string> {
        const items = this.getCommonContextMenuItems();
        return items.concat([
            'showConnectorBumps',
            'hideConnectorBumps' ]);
    }

    /**
     * This defines multi select context menu items for the same type of
     * connector models that are selected
     */
    public getContextMenuForSameType(): Array<string> {
        const items = this.getCommonContextMenuItems();
        return items.concat([
            'showConnectorBumps',
            'hideConnectorBumps' ]);
    }

    /**
     * This defines multi select context menu items for the multi types of
     * connector models that are selected
     */
    public getContextMenuForMultipleTypes(): Array<string> {
        return this.getCommonContextMenuItems();
    }

    /**
     * Returns all contextual toolbar items that can appear in a single connector selection.
     * @return array of toolbar items
     */
    public getContextualToolbarItems( root: DiagramModel ): IContextualToolbarItem[] {
        const changeArrowHeads = this.getFDChangeArrowHeads( root );
        // check if connectors can switch drawstyles
        const showTypes = this.preventChangeDrawStyle ? null : this.getFDChangeDrawStyle();
        const features = [
            this.getFDChangeConnectorType( root ),
            showTypes,
            this.getFDEditText(),
            changeArrowHeads[0],
            this.getFDFlipConnector( root ),
            changeArrowHeads[1],
            { featureId: 'createNewSlideFromSelection' },
            { featureId: 'styleShape' },
            { featureId: 'bringToFront', visibility: { level: 'secondary' } as any },
            { featureId: 'bringForward', visibility: { level: 'secondary' } as any },
            { featureId: 'sendToBack', visibility: { level: 'secondary' } as any },
            { featureId: 'sendBackward', visibility: { level: 'secondary' } as any },
            { featureId: 'hideConnectorBumps', visibility: { level: 'secondary' } as any },
            { featureId: 'showConnectorBumps', visibility: { level: 'secondary' } as any },
            { featureId: 'hideConnector', visibility: { level: 'secondary' } as any },
            { featureId: 'showConnector', visibility: { level: 'secondary' } as any },
        ];
        return features.filter( fd => Boolean( fd ));
    }

    /**
     * Returns all contextual toolbar items that can appear during a multi selection
     * where all selected connectors are of the same type.
     * @return array of toolbar items
     */
    public getContextualToolbarItemsForSameType(): IContextualToolbarItem[] {
        return [
            this.getFDAlignShapes(),
            { featureId: 'layoutShapes', data: { shapeId: this.id, options: Object.values( LAYOUTING_DATA ) }},
            { featureId: 'groupShapes' },
            { featureId: 'ungroupShapes' },
            { featureId: 'createNewSlideFromSelection' },
            { featureId: 'styleShape' },
            { featureId: 'bringToFront', visibility: { level: 'secondary' } as any },
            { featureId: 'bringForward', visibility: { level: 'secondary' } as any },
            { featureId: 'sendToBack', visibility: { level: 'secondary' } as any },
            { featureId: 'sendBackward', visibility: { level: 'secondary' } as any },
            { featureId: 'hideConnectorBumps', visibility: { level: 'secondary' } as any },
            { featureId: 'showConnectorBumps', visibility: { level: 'secondary' } as any },
            { featureId: 'hideConnector', visibility: { level: 'secondary' } as any },
            { featureId: 'showConnector', visibility: { level: 'secondary' } as any },
        ];
    }

    /**
     * Returns all contextual toolbar items that can appear during a multi selection
     * where selected connectors are of different types.
     * @return array of toolbar items
     */
    public getContextualToolbarItemsForMultipleTypes(): IContextualToolbarItem[] {
        return [
            this.getFDAlignShapes(),
            { featureId: 'layoutShapes', data: { shapeId: this.id, options: Object.values( LAYOUTING_DATA ) }},
            { featureId: 'groupShapes' },
            { featureId: 'ungroupShapes' },
            { featureId: 'createNewSlideFromSelection' },
            { featureId: 'styleShape' },
            { featureId: 'manageIndicators' },
            { featureId: 'bringToFront', visibility: { level: 'secondary' } as any },
            { featureId: 'bringForward', visibility: { level: 'secondary' } as any },
            { featureId: 'sendToBack', visibility: { level: 'secondary' } as any },
            { featureId: 'sendBackward', visibility: { level: 'secondary' } as any },
            { featureId: 'hideConnector', visibility: { level: 'secondary' } as any },
            { featureId: 'showConnector', visibility: { level: 'secondary' } as any },
            { featureId: 'shapeVoting', visibility: { level: 'secondary' } as any },
        ];
    }

    /**
     * Returns all context menu items when text is being edited on a connector.
     */
    public getContextualToolbarItemsForTextEdit(): IContextualToolbarItem[] {
        return [
            {
                featureId: 'boldText',
                data: { style: 'bold', value: DEFUALT_TEXT_STYLES.bold },
            },
            {
                featureId: 'italicText',
                data: { style: 'italic', value: DEFUALT_TEXT_STYLES.italic },
            },
            {
                featureId: 'strikeoutText',
                data: { style: 'strikeout', value: DEFUALT_TEXT_STYLES.strikeout },
            },
            {
                featureId: 'underlineText',
                data: { style: 'underline', value: DEFUALT_TEXT_STYLES.underline },
            },
            {
                featureId: 'sizeText',
                data: {
                    style: 'size',
                    value: DEFUALT_TEXT_STYLES.size,
                    // FIXME This format should be converted and should use type params.
                    data: [ 10, 12, 14, 18, 20, 24, 30, 36, 48, 60, 72, 96 ]
                        .map( i => ({ id: `${i}`, buttonLabel: `${i}`, label: `${i}`, value: i })),
                },
            },
            {
                featureId: 'colorText',
                data: {
                    style: 'color',
                    value: DEFUALT_TEXT_STYLES.color,
                    // FIXME This format should be converted and should use type params.
                    // Color must be given in rgb color code
                    data: [
                        'rgb(255, 255, 255)', 'rgb(0, 0, 0)', 'rgb(231, 230, 230)',
                        'rgb(69, 85, 105)', 'rgb(2, 34, 95)',
                        'rgb(70, 116, 193)', 'rgb(17, 114, 189)', 'rgb(94, 156, 211)',
                        'rgb(157, 194, 227)', 'rgb(222, 234, 245)',
                        'rgb(132, 19, 26)', 'rgb(190, 7, 18)', 'rgb(252, 13, 27)',
                        'rgb(254, 103, 112)', 'rgb(255, 193, 196)',
                        'rgb(218, 139, 20)', 'rgb(255, 153, 0)', 'rgb(238, 186, 107)',
                        'rgb(253, 208, 103)', 'rgb(254, 233, 184)',
                        'rgb(84, 128, 57)', 'rgb(114, 172, 77)', 'rgb(148, 206, 88)',
                        'rgb(169, 207, 144)', 'rgb(197, 223, 181)',
                    ].map( i => ({ id: `${i}`, value: i })),
                },
            },
            {
                featureId: 'openHyperlinkEditor',
            },
            {
                featureId: 'unlinkHyperlink',
            },
        ];
    }

    /**
     * This abstract method creates a new text model with a generated text id,
     *
     */
    public createText(): ConnectorTextModel {
        const text = new ConnectorTextModel();
        text.id = this.generateTextId();
        return text;
    }

    /**
     * Returns true if the connector is a line, that is no shapes are connected to the line
     * @returns boolean
     */
    public isLine() {
        return !this.ends.from && !this.ends.to;
    }


    /**
     * Returns true if the connector is horizontal
     * @param fractionDigits Optional param to specify the precision
     * @returns boolean
     */
    public isHorizontal( fractionDigits: number = 0 ) {
        const points = this.getPoints();
        return points.every( p => Number.isEqual( p.y, points[0].y, fractionDigits ));
    }

    /**
     * Returns true if the connector is vertical
     * @param fractionDigits Optional param to specify the precision
     * @returns boolean
     */
    public isVertical( fractionDigits: number = 0 ) {
        const points = this.getPoints();
        return points.every( p => Number.isEqual( p.x, points[0].x, fractionDigits ));
    }

    /**
     * Returns alignment nodes for this connector
     */
    public getAlignmentNodes( root: DiagramModel ): IAlignmentNode[] {
        const fromEndpoint = this.getFromEndpoint( root );
        const toEndpoint = this.getToEndpoint( root );
        if ( !this.isLine()) {
            return [];
        }
        const nodes = [];
        const points = this.getPoints();
        const p1 = points.find( p => p.id === fromEndpoint.id );
        const p2 = points.find( p => p.id === toEndpoint.id );
        if ( p1 && p2 ) {
            if ( this.isHorizontal()) {
                const left = Math.min( p1.x, p2.x );
                const right = Math.max( p1.x, p2.x );
                nodes.push(
                    { shapeId: this.id, pos: 'top',
                        x: left + ( right - left ) / 2, y: p1.y, type: 'connector' },
                    { shapeId: this.id, pos: 'bottom',
                        x: left + ( right - left ) / 2, y: p2.y, type: 'connector' },
                    { shapeId: this.id, pos: 'left', x: left, y: p1.y, type: 'connector' },
                    { shapeId: this.id, pos: 'right', x: right, y: p2.y, type: 'connector' },
                );
            }
            if ( this.isVertical()) {
                const top = Math.min( p1.y, p2.y );
                const bottom = Math.max( p1.y, p2.y );
                nodes.push(
                    { shapeId: this.id, pos: 'top', y: top, x: p1.x, type: 'connector' },
                    { shapeId: this.id, pos: 'bottom', y: bottom, x: p2.x, type: 'connector' },
                    { shapeId: this.id, pos: 'left',
                        y: top + ( bottom - top ) / 2, x: p1.x, type: 'connector' },
                    { shapeId: this.id, pos: 'right',
                        y: top + ( bottom - top ) / 2, x: p2.x, type: 'connector' },
                );
            }
        }
        return nodes;
    }

    /**
     * Returns the default arrow heads for the conector model
     */
    protected getDefaultArrowheads(): string[] {
        return [
            'connectors.bundle.js#PointerFilled',
            'connectors.bundle.js#PointerLine',
            'connectors.bundle.js#PointerPointed',
            'connectors.bundle.js#PointerHollow',
            'connectors.bundle.js#PointerBarbedFilled',
            'connectors.bundle.js#PointerBarbedLine',
            'connectors.bundle.js#PointerDouble',
            'connectors.bundle.js#PointerIndented',
        ];
    }

    /**
     * Returns default draw styles supported by this connector.
     */
    protected getDefaultDrawStyles(): Array<{ id: string, label: string, icon: string}> {
        return [
            {
                id: 'connectors.bundle.js#ConnectorCurved',
                label: 'Curved',
                icon: 'curved-line',
            },
            {
                id: 'connectors.bundle.js#ConnectorAngled',
                label: 'Angled',
                icon: 'angled-line',
            },
            {
                id: 'connectors.bundle.js#ConnectorSmoothAngled',
                label: 'Smooth Angled',
                icon: 'smooth-angled-line',
            },
            {
                id: 'connectors.bundle.js#ConnectorStraight',
                label: 'Straight',
                icon: 'straight-line',
            },
            {
                id: 'connectors.bundle.js#ConnectorIndented',
                label: 'Indented',
                icon: 'indented-connector',
            },
        ];
    }

    /**
     * Returns all the arrow heads Id supprted by the `from` end
     */
    protected getFromArrowHeadIds(): string[] {
        const options = union(
            this.ends.from ? [ this.ends.from ] : [],
            get( this.ends, 'fromOptions.options', []),
            get( this.ends, 'allOptions.options', []),
        );
        if ( get( this.ends, 'fromOptions.defaults', get( this.ends, 'allOptions.defaults', true ))) {
            options.push( ...this.getDefaultArrowheads());
        }
        return uniq( options );
    }

    /**
     * Returns all the arrow heads Id supprted by the `to` end
     */
    protected getToArrowHeadIds(): string[] {
        const options = union(
            this.ends.to ? [ this.ends.to ] : [],
            get( this.ends, 'toOptions.options', []),
            get( this.ends, 'allOptions.options', []),
        );
        if ( get( this.ends, 'toOptions.defaults', get( this.ends, 'allOptions.defaults', true ))) {
            options.push( ...this.getDefaultArrowheads());
        }
        return uniq( options );
    }

    /**
     * Returns the left end point
     */
    protected getLeftEndPoint( root: DiagramModel ) {
        const fromEndpoint = this.getFromEndpoint( root );
        const toEndpoint = this.getToEndpoint( root );
        if ( fromEndpoint.x < toEndpoint.x ) {
            return fromEndpoint;
        } else if ( fromEndpoint.x > toEndpoint.x ) {
            return toEndpoint;
        } else if ( fromEndpoint.x === toEndpoint.x &&
            fromEndpoint.y < toEndpoint.y ) {
            return fromEndpoint;
        } else {
            return toEndpoint;
        }
    }

    /**
     * This defines the common menu items for all three types
     * such as single selection, multi selection with same type
     * and multi selection with different types.
     */
    private getCommonContextMenuItems(): Array<string> {
        return [
            'copyShapes',
            'cutShapes',
            'duplicateShapes',
            'remove',
            'bringToFront',
            'shapeVoting',
            'sendToBack',
            'sendBackward',
            'bringForward',
        ];
    }

    /**
     * Returns change connector type feature data. Returns null if there are less than 2 options.
     */
    private getFDChangeConnectorType( root: DiagramModel ): IContextualToolbarItem | null {
        const selected = this.defId === LOOKUP_CONNECTOR.defId ?
            `${this.defId}.${this.handshake.join( '|' )}` : `${this.defId}.${this.version}`;
        const availableTypes = this.getAvailableTypes( root );
        const options = availableTypes.map( def => ({
            id: def.defId === LOOKUP_CONNECTOR.defId ?
                `${def.defId}.${def.handshake.join( '|' )}` : `${def.defId}.${def.version}`,
            label: def.name,
            defId: def.defId,
            version: def.version,
            handshake: def.handshake,
        }));
        if ( options.length < 2 ) {
            return null;
        }
        return {
            featureId: 'changeConnectorType',
            data: { shapeId: this.id, options, selected, selectedHandshake: this.handshake },
        };
    }

    /**
     * Returns change draw style feature data. Returns null if there are less than 2 options.
     */
    private getFDChangeDrawStyle(): IContextualToolbarItem | null {
        const selected = this.entryClass;
        const options = this.getDefaultDrawStyles();
        if ( options.length < 2 ) {
            return null;
        }
        return {
            featureId: 'changeDrawStyle',
            data: { shapeId: this.id, options, selected },
        };
    }

    /**
     * Returns the edit text feature data.
     */
    private getFDEditText(): IContextualToolbarItem | null {
        return { featureId: 'editText' };
    }

    /**
     * Returns the flip connector feature data if the connector can be flipped.
     */
    private getFDFlipConnector( root: DiagramModel ): IContextualToolbarItem | null {
        const conn = this.getConnection( root );
        if ( conn && ( conn.shapeA.port.limitSlot || conn.shapeB.port.limitSlot )) {
            return null;
        }
        return { featureId: 'flipConnector' };
    }

    /**
     * Returns an array of feature data for left and right arrow heads.
     * If the number of options are less than 2 it will return null for each feature.
     * From and to endpoitns will be mapped to left/right based on their location.
     */
    private getFDChangeArrowHeads( root: DiagramModel ): ( IContextualToolbarItem | null )[] {
        const fromEndpoint = this.getFromEndpoint( root );
        const toEndpoint = this.getToEndpoint( root );
        const fdata = {
            shapeId: this.id,
            end: 'from',
            point: fromEndpoint,
            options: this.getFromArrowHeadIds(),
            selected: this.ends.from,
        };
        const tdata = {
            shapeId: this.id,
            end: 'to',
            point: toEndpoint,
            options: this.getToArrowHeadIds(),
            selected: this.ends.to,
        };
        const leftEndpoint = this.getLeftEndPoint( root );
        const leftIsFrom = leftEndpoint.id === fromEndpoint.id;
        return [
            { featureId: 'changeLeftArrowHead', data: leftIsFrom ? fdata : tdata },
            { featureId: 'changeRightArrowHead', data: leftIsFrom ? tdata : fdata },
        ].map( feature => {
            if ( feature.data.options.length < 2 ) {
                return null;
            }
            return feature;
        });
    }

    /**
     * Returns the contextual toolbar feature data.
     */
    private getFDAlignShapes(): IContextualToolbarItem {
        return {
            featureId: 'alignShapes',
            data: { shapeId: this.id, options: ALIGNMENT_OPTIONS },
        };
    }

}
