import { INotifierData, NotificationType, AbstractNotification, AppConfig } from 'flux-core';
import { TranslateService } from '@ngx-translate/core';
import { CopyPasteNotification } from '../../editor/ui/copy-paste-notification/copy-paste-notification.cmp';

/**
 * An enum with the current set of Notification ids.
 * This is useful when displaying the notifications in the {@link NotificationMessages} class.
 */
export enum Notifications {
    OFFLINE = 'OfflineNotification',
    IDLE = 'IdleNotification',
    ERROR = 'ErrorNotification',
    ERROR_REPORT_COMPLETE = 'ErrorReportCompleteNotification',
    COPY_PASTE = 'CopyPasteNotification',
    OFFLINE_SEND_REPORT = 'OfflineSendReportNotification',
    IMAGE_EXPORT_STARTED = 'ImageExportStartedNotification',
    IMAGE_EXPORT_FAILED = 'ImageExportFailedNotification',
    COMMENT_ADDED = 'CommentAddedNotification',
    COMMENT_RESOLVED = 'CommentResolvedNotification',
    EMBED_IMAGE_TEXT_COPIED = 'EmbedImageTextCopiedNotification',
    OFFLINE_INSERT_SHAPE = 'OfflineInsertShapeNotification',
    IMAGE_IMPORT_SIZE_EXCEEDED = 'ImageImportSizeExceededNotification',
    OFFLINE_URL_IMPORT = 'OfflineUrlImportNotification',
    OFFLINE_SHAPE_LIBRARY = 'OfflineShapeLibraryNotification',
    RICHTEXT_EDITOR_ERROR = 'RichtextEditorErrorNotification',
    DOCUMENT_NOT_AVAILABLE_OFFLINE = 'DocumentNotAvailableOfflineNotification',
    IMAGE_EXPORT_OFFLINE = 'ImageExportOfflineNotification',
    IMAGE_EXPORT_SIZE_EXCEEDED = 'ImageExportExceededNotification',
    PDF_EXPORT_STARTED = 'PDFExportStartedNotification',
    RESTORE_FAILED = 'RestoreFailed',
    PUSH_NOTIFICATION = 'PushNotification',
    WRONG_REGION = 'WrongAppRegion',
    SETUP_DATABASE = 'setupDatabase',
    CREATE_SAVED_SET = 'createSavedSet',
    REMOVE_ASSOCIATED_LINK = 'removeLinkAssociated',
    REMOVE_ASSOCIATED_ENTITY = 'removeEntityAssociated',
    SLIDE_EXPORT_STARTED = 'slideExportStarted',
}

/**
 * This class contains Notification messages and related data.
 * A single constant in the getNotificationMessage method contains all notification
 * message data so that they may be customized from a single location.
 *
 * @author jerome
 * @since 2020-04-10
 */

export class NotificationMessages {
    /**
     * Local reference to the translate service required for parsing notification text.
     */
    protected translate: TranslateService;

    /**
     * Constructor for class.
     * @param translateService Will be used to parse translations in notification messages.
     */
    constructor( translateService: TranslateService ) {
        this.translate = translateService;
    }

    /**
     * Contains a constant with the list of notification messages and data, and returns an
     * INotifierData item for the given notification id key.
     * @param key The notification id to retrieve data for.
     * @param buttonOneClickHandler The click handler for the first button ( if any )
     * @param buttonTwoClickHandler The click handler for the second button ( if any )
     */
    public getNotificationMessage(
        key: string,
        buttonOneClickHandler?: Function,
        buttonTwoClickHandler?: Function,
        notificationAction?: Function,
        linkAction?: Function,
    ): INotifierData {
        const NOTIFICATION_MESSAGES: { [ key: string ]: INotifierData } = {
            [ Notifications.OFFLINE ]: {
                id: Notifications.OFFLINE,
                component: AbstractNotification,
                type: NotificationType.Warning,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'OFFLINE.NOTIFICATIONS.DEFAULT.HEADING' ),
                        description: this.translate.instant( 'OFFLINE.NOTIFICATIONS.DEFAULT.DESCRIPTION' ),
                    },
                },
            },
            [ Notifications.IDLE ]: {
                id: Notifications.IDLE,
                component: AbstractNotification,
                type: NotificationType.Neutral,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'IDLE.NOTIFICATIONS.HEADING' ),
                        description: this.translate.instant( 'IDLE.NOTIFICATIONS.DESCRIPTION' ),
                    },
                },
            },
            [ Notifications.ERROR ]: {
                id: Notifications.ERROR,
                component: AbstractNotification,
                type: NotificationType.Error,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'ERRORS.GENERIC_ERROR.TITLE' ),
                        description: this.translate.instant( 'ERRORS.GENERIC_ERROR.DESC' ),
                        buttonOneText: this.translate.instant( 'ERRORS.GENERIC_ERROR.BUTTONS.SEND_REPORT' ),
                        buttonOneAction: buttonOneClickHandler,
                        linkText: this.translate.instant( 'ERRORS.GENERIC_ERROR.REPORT_LINK' ),
                        link: AppConfig.get( 'ABOUT_ERROR_REPORT_URL' ),
                    },
                },
            },
            [ Notifications.ERROR_REPORT_COMPLETE ]: {
                id: Notifications.ERROR_REPORT_COMPLETE,
                component: AbstractNotification,
                type: NotificationType.Warning,
                options: {
                    inputs: {
                        description: this.translate.instant( 'ERRORS.GENERIC_ERROR.DESC_PART2' ),
                    },
                },
            },
            [ Notifications.COPY_PASTE ]: {
                id: Notifications.COPY_PASTE,
                component: CopyPasteNotification,
            },
            [ Notifications.OFFLINE_SEND_REPORT ]: {
                id: Notifications.OFFLINE_SEND_REPORT,
                component: AbstractNotification,
                type: NotificationType.Warning,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'OFFLINE.NOTIFICATIONS.DEFAULT.HEADING' ),
                        description: this.translate.instant( 'OFFLINE.NOTIFICATIONS.CUSTOM.ERROR_REPORT' ),
                        autoDismiss: true,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.IMAGE_EXPORT_STARTED ]: {
                id: Notifications.IMAGE_EXPORT_STARTED,
                component: AbstractNotification,
                type: NotificationType.Success,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.IMAGE_EXPORT.START.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.IMAGE_EXPORT.START.BODY' ),
                        autoDismiss: false,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.IMAGE_EXPORT_FAILED ]: {
                id: Notifications.IMAGE_EXPORT_FAILED,
                component: AbstractNotification,
                type: NotificationType.Error,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.IMAGE_EXPORT.ERROR.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.IMAGE_EXPORT.ERROR.BODY' ),
                        autoDismiss: true,
                        dismissAfter: 3000,
                    },
                },
            },
            [ Notifications.EMBED_IMAGE_TEXT_COPIED ]: {
                id: Notifications.EMBED_IMAGE_TEXT_COPIED,
                component: AbstractNotification,
                type: NotificationType.Success,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.EMBED_IMAGE_COPIED.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.EMBED_IMAGE_COPIED.DESCRIPTION' ),
                        autoDismiss: true,
                    },
                },
            },
            [ Notifications.OFFLINE_INSERT_SHAPE ]: {
                id: Notifications.OFFLINE_INSERT_SHAPE,
                component: AbstractNotification,
                type: NotificationType.Warning,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'OFFLINE.NOTIFICATIONS.DEFAULT.HEADING' ),
                        description: this.translate.instant( 'OFFLINE.NOTIFICATIONS.CUSTOM.INSERT_SHAPE' ),
                        autoDismiss: true,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.IMAGE_IMPORT_SIZE_EXCEEDED ]: {
                id: Notifications.IMAGE_IMPORT_SIZE_EXCEEDED,
                component: AbstractNotification,
                type: NotificationType.Warning,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.IMPORT_IMAGE.SIZE_EXCEEDED.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.IMPORT_IMAGE.SIZE_EXCEEDED.DESCRIPTION' ),
                    },
                },
            },
            [ Notifications.OFFLINE_URL_IMPORT ]: {
                id: Notifications.OFFLINE_URL_IMPORT,
                component: AbstractNotification,
                type: NotificationType.Warning,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'OFFLINE.NOTIFICATIONS.DEFAULT.HEADING' ),
                        description: this.translate.instant( 'OFFLINE.NOTIFICATIONS.CUSTOM.URL_IMPORT' ),
                        autoDismiss: true,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.OFFLINE_SHAPE_LIBRARY ]: {
                id: Notifications.OFFLINE_SHAPE_LIBRARY,
                component: AbstractNotification,
                type: NotificationType.Warning,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'OFFLINE.NOTIFICATIONS.DEFAULT.HEADING' ),
                        description: this.translate.instant( 'OFFLINE.NOTIFICATIONS.CUSTOM.SHAPE_LIBRARY' ),
                        autoDismiss: true,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.DOCUMENT_NOT_AVAILABLE_OFFLINE ]: {
                id: Notifications.DOCUMENT_NOT_AVAILABLE_OFFLINE,
                component: AbstractNotification,
                type: NotificationType.Warning,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'OFFLINE.NOTIFICATIONS.DOC_NOT_AVAIL.HEADING' ),
                        description: this.translate.instant( 'OFFLINE.NOTIFICATIONS.DOC_NOT_AVAIL.DESCRIPTION' ),
                        autoDismiss: true,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.IMAGE_EXPORT_OFFLINE ]: {
                id: Notifications.IMAGE_EXPORT_OFFLINE,
                component: AbstractNotification,
                type: NotificationType.Warning,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.IMAGE_EXPORT.OFFLINE.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.IMAGE_EXPORT.OFFLINE.BODY' ),
                        autoDismiss: true,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.IMAGE_EXPORT_SIZE_EXCEEDED ]: {
                id: Notifications.IMAGE_EXPORT_SIZE_EXCEEDED,
                component: AbstractNotification,
                type: NotificationType.Error,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.IMAGE_EXPORT.SIZE_EXCEEDED.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.IMAGE_EXPORT.SIZE_EXCEEDED.BODY' ),
                        autoDismiss: false,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.PDF_EXPORT_STARTED ]: {
                id: Notifications.PDF_EXPORT_STARTED,
                component: AbstractNotification,
                type: NotificationType.Neutral,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.PDF_EXPORT.START.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.PDF_EXPORT.START.BODY' ),
                        autoDismiss: false,
                        icon: 'image',
                    },
                },
            },
            [ Notifications.RESTORE_FAILED ]: {
                id: Notifications.RESTORE_FAILED,
                component: AbstractNotification,
                type: NotificationType.Warning,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.DIAGRAM.RESTORE_FAILED.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.DIAGRAM.RESTORE_FAILED.DESCRIPTION' ),
                        autoDismiss: true,
                        dismissAfter: 3000,
                    },
                },
            },
            [ Notifications.WRONG_REGION ]: {
                id: Notifications.WRONG_REGION,
                component: AbstractNotification,
                type: NotificationType.Error,
                collapsed: false,
                options: {
                    inputs: {
                        description: this.translate.instant( 'NOTIFICATIONS.REGIONAL_DEPLOYMENT.WRONG_REGION' ),
                        autoDismiss: true,
                        dismissAfter: 3000,
                    },
                },
            },
            [ Notifications.SLIDE_EXPORT_STARTED ]: {
                id: Notifications.SLIDE_EXPORT_STARTED,
                component: AbstractNotification,
                type: NotificationType.Neutral,
                collapsed: false,
                options: {
                    inputs: {
                        heading: this.translate.instant( 'NOTIFICATIONS.SLIDE_EXPORT.START.HEADING' ),
                        description: this.translate.instant( 'NOTIFICATIONS.SLIDE_EXPORT.START.BODY' ),
                        autoDismiss: false,
                        icon: 'image',
                    },
                },
            },
        };

        return NOTIFICATION_MESSAGES[ key ];
    }
}
