import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StateService } from 'flux-core';
import { Injectable } from '@angular/core';
import { AbstractRouteResolver } from './framework/controller/abstract.resolver';

/**
 * This is a route resolver that can be used with simple static routes.
 *
 * @author  Ramishka
 * @since   2019-01-12
 */
@Injectable()
export class StaticRouteResolver extends AbstractRouteResolver implements CanActivate {

    constructor ( protected state: StateService<any, any> ) {
        super( state );
    }

    /**
     * canActivate route guard which is invoked before the route is resolved.
     * Loading indicator is removed before the route is resolved.
     */
    public canActivate( route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot ): boolean {
        this.hideLoadingScreen();
        return true;
    }
}
