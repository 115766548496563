<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand" let-onCheckedChange="onCheckedChange">
  <div class="form-inline row-item">
    <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa treeview-arrow"
      [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed">
        <img src="./assets/images/decoratives/expand-arrow.svg" width="18">
    </i>
    <div class="form-check form-input-wrapper" >
      <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
        [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
        <span class="form-custom-checkbox">
          <img class="form-custom-checkbox-tick" src="./assets/images/decoratives/tick.svg" width="18">
        </span>
        <label class="form-check-label" (click)="!item.disableItemCheck && (item.checked = !item.checked); !item.disableChildrenCheck && onCheckedChange(); onCollapseExpand()">
          <div class="library-name">{{item.text}}</div>
          <div *ngIf="((isPremiumLibrary(item.value) && !item.children) || (isPremiumGroup(item.value) && item.children && item.collapsed)) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async))">
            <div class="premium">
                <div class="icon">
                    <svg class="nu-icon" width="11px" hight="11px">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-premium-crown"></use>
                    </svg>
                </div>
                <div class="label" translate>LABELS.PREMIUM</div>
            </div>
        </div>
        </label>
    </div>
  </div>
</ng-template>

<div class="lib-browser-container modal-window-container fx-cover fx-center-all" #window (click)="closeOnOverlayClick($event)">
    <div #windowInner class="modal-window-inner">
        <div class="modal-window-heading" tooltip-tour-step="fab-1.4.2">
            <h1 translate="">LABELS.BROWSE_SHAPES</h1>
            <button class="nu-btn-small nu-btn-icon lib-browser-close-btn" (click)="closeWindow()">
                <svg class="nu-icon nu-icon-med nu-ic-close">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                </svg>
            </button>
        </div>
        <div class="lib-browser-filter">
            <text-input
                [showClearButton]="true"
                icon="search-thin"
                [emitForInputChange]="true"
                placeholder="{{'PLACEHOLDERS.SHAPE_ADD_SEARCH' | translate}}"
                [debounce]="100"
                (updateText)="onFilterChange( $event )">
            </text-input>
        </div>
        <div class="lib-browser-treeview">
            <perfect-scrollbar>
                <ngx-treeview #treeView
                    [config]="config"
                    [items]="items | async"
                    [itemTemplate]="itemTemplate"
                    (selectedChange)="onSelectionChange($event)">
                </ngx-treeview>
            </perfect-scrollbar>
        </div>
    </div>
</div>