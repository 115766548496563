import {
    AfterViewInit, ChangeDetectionStrategy, Component, ElementRef,
    Input, OnDestroy, Output, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { CommandService, Logger, ModalController, StateService, Tracker } from 'flux-core';
import { IDialogBoxData, IEntityDef, IShapeDefinition, PlanPermission } from 'flux-definition';
import { DataStore } from 'flux-store';
import { uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
    catchError, debounceTime, distinctUntilChanged, filter, map, mapTo, scan, switchMap, take, takeUntil, tap,
} from 'rxjs/operators';
import { ViewportToDiagramCoordinate } from '../../../base/coordinate/viewport-to-diagram-coordinate.svc';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { DefinitionLocator } from '../../../base/shape/definition/definition-locator.svc';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { ISearchResultItem, SearchService } from '../../../framework/search/search.svc';
import { AbstractSearch } from '../../../framework/ui/search/abstract-search';
import { ILibraryAndShapesDefs, IShapeThumbnailItem } from '../../../framework/ui/search/sidebar-search.cmp';
import { StaticLibraryLoader } from '../../library/static-library-loader.svc';
import { IShapeSearchResultItem } from '../../shape/providers/shape-search-provider';
import { LibraryList } from '../temp-add-libs-menu/library-list';
import { PlanPermManager, TeamSettingManager, UpgradeDialogWindow } from 'flux-user';
import { VIZ_DATA } from 'apps/nucleus/src/base/shape/model/shape-common';

export interface IQuickSearchItem {
    category: string;
    results: ISearchResultItem[];
}

/**
 * Quick Shape Search
 *
 * This component is a specific dropdown component where it
 * shows a input as the dropdown button for entering search query.
 * Dropdown will show both matching shapes and libraries
 *
 */
@Component({
    selector: 'quick-shape-search',
    templateUrl: './quick-shape-search.cmp.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [ './quick-shape-search.scss' ],
    encapsulation: ViewEncapsulation.None,
})
export class QuickShapeSearch extends AbstractSearch implements OnDestroy, AfterViewInit {

    @ViewChild( 'matSelectInfiniteScroll', { static: true })
    public infiniteScrollSelect: MatSelect;

    /**
     * Data control for form
     */
    public dataControl: FormControl = new FormControl();

    /**
     * control for the search input value
     */
    public searchCtrl: FormControl = new FormControl();

    /**
     * Behavior subject that emits on closing and opening dropdown.
     */
    public isOpen: BehaviorSubject<any> = new BehaviorSubject( true );

    public alignTop: boolean;

    /**
     * Final searched results will be emitted
     */
    public searchResults: BehaviorSubject<IQuickSearchItem[]> = new BehaviorSubject([]);

    /**
     * All the libraries with the shape definition ids
     */
    public allShapesAndDeps: ILibraryAndShapesDefs[] = [];

    public shapeDefLibMap: { [shapeDefId: string]: { libId: string, libTitle: string }[] };

    /**
     * Search input placeholder text key.
     */
    @Input() public placeholder: string = 'Search';

    @Output()
    public actionEmitter: Subject<IShapeDefinition> = new Subject();

    /**
     * number of items added per batch
     */
    batchSize = 20;

    public canvasAreaWidth = 80;

    /**
     * Holds all the subscriptions.
     */
    protected subs: Subscription[] = [];

    protected incrementBatchOffset: Subject<void> = new Subject<void>();
    protected resetBatchOffset: Subject<void> = new Subject<void>();

    protected destroy: Subject<void> = new Subject<void>();

    protected shapeModel: ShapeModel;

    protected previousShapeDefId: string = '';

    protected defaultPlusItemCache: { [key: string]: IQuickSearchItem[]} = {};

    private predefinedPlusItems: string[] = [
        'creately.basic.rectangle',
        'creately.basic.ellipse',
        'creately.basic.square',
        'creately.basic.circle',
        'creately.basic.table',
        'creately.basic.frame_paper',
        'creately.arrows.toleftarrow',
        'creately.arrows.uparrow',
        'creately.arrows.downarrow',
        'creately.arrows.torightarrow',
    ];

    private canvasAreaWidthNormalRender = 80;

    constructor(
        protected modalController: ModalController,
        protected state: StateService<any, any>,
        protected searchService: SearchService,
        protected defLocator: DefinitionLocator,
        public elementRef: ElementRef,
        protected loader: StaticLibraryLoader,
        protected commandService: CommandService,
        protected vToDcoordinate: ViewportToDiagramCoordinate,
        protected datastore: DataStore,
        protected translateService: TranslateService,
        protected libraryList: LibraryList,
        protected ell: EDataLocatorLocator,
        protected permManager: PlanPermManager,
        protected teamSettingManager: TeamSettingManager,
    ) {
        super( state );
        this.shapeDefLibMap = {};
    }


    /**
     * Loads the libraries and the shapes for those libraries
     */
    public ngAfterViewInit() {
        this.subs.push(
            this.loadAllLibrariesWithShapes().pipe(
                switchMap(() => this.searchCtrl.valueChanges ),
                filter( search => ( search !== undefined || search !== null )),
                takeUntil( this.destroy ),
                debounceTime( 100 ),
                switchMap( text => {
                    // console.log( 'start search', new Date().getTime());
                    if ( text.length > 2 ) {
                        if ( !this.isOpen.value ) {
                            this.isOpen.next( true );
                        }
                        this.canvasAreaWidth = this.canvasAreaWidthNormalRender;
                        return this.doSearch( text );
                    } else {
                        if ( this.infiniteScrollSelect.panelOpen ) {
                            this.canvasAreaWidth = this.canvasAreaWidthNormalRender;
                            this.setDefaultItems();
                        }
                        return EMPTY;
                    }
                }),
            ).subscribe(),
            this.infiniteScrollSelect._closedStream.subscribe(() => this.isOpen.next( false )),
            this.infiniteScrollSelect.openedChange.subscribe( opened => {
                if ( opened ) {
                    if ( !this.isOpen.value ) {
                        this.isOpen.next( true );
                    }
                    const selectEl = this.infiniteScrollSelect.panel.nativeElement;
                    selectEl.addEventListener( 'mouseleave', this.handleMouseLeave.bind( this ));
                }
            }),
        );
    }

    /**
     * Creates a thumbnail item for templates, load required info when needed.
     */
    public createShapeTemplateItem( def: IShapeDefinition ): IShapeThumbnailItem {
        return {
            id: def.defId,
            title: def.name,
            type: 'shape',
            data: def,
            thumbnailType: 'image',
            thumbnailUrl: def.thumbnail,
        };
    }

    /**
     * Destroys and clears all the resources used by the
     * interaction handler
     */
    public ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
        this.defaultPlusItemCache = null;
    }

    /**
     * Resets panels with new model
     * @param model
     * @param openPanel
     */
    public resetPanel( model: ShapeModel, openPanel = true ) {
        // This fixes issue on hidden render issue on different DPR
        if ( !openPanel ) {
            if ( window?.devicePixelRatio > 1 ) {
                this.canvasAreaWidth = this.canvasAreaWidthNormalRender * window.devicePixelRatio;
            } else {
                this.canvasAreaWidth = this.canvasAreaWidthNormalRender;
            }
        }

        this.shapeModel = model;
        this.dataControl.reset();
        if ( openPanel && !this.infiniteScrollSelect.panelOpen ) {
            this.infiniteScrollSelect.toggle();
        }
        this.setDefaultItems();
    }

    /**
     * Handles shape click event
     * When a shape is clicked it will be added to canvas
     * @param data - IShapeDefinition
     */
    public handleShapeClick( event: MatSelectChange ) {
        const hasPermission =
            event.value && event.value.permission
            ? this.permManager.check([ event.value.permission ])
            : true;
        if ( hasPermission ) {
            this.actionEmitter.next( event.value.data as IShapeDefinition );
        } else {
            this.openUpgradeWindow( event.value.permission );
        }
        this.infiniteScrollSelect?.close();
    }

    /**
     * Load the next batch
     */
    public getNextBatch(): void {
        this.incrementBatchOffset.next();
    }


    /**
     * Translates given string with parameters and returns an observable.
     * @param str Translation string Id
     * @param params Parameters.
     * @returns a string observable emits only once
     */
    public translate( str: string, params?: any ): Observable<string> {
        return this.translateService.get( str, params ).pipe(
            take( 1 ),
        );
    }

    /**
     * Open the upgrade window
     */
    protected openUpgradeWindow( permisison: PlanPermission ): void {

        const dialogData = {
            id: permisison,
            buttons: [
                {
                    type: 'upgrade',
                    clickHandler: () => {},
                },
            ],
            integrationContext: {
                embedded: this.state.get( 'ApplicationIsEmbedded' ),
                environment: this.state.get( 'PluginApp' ),
            },
        } as IDialogBoxData;

        this.modalController.show( UpgradeDialogWindow, {
            inputs: {
                dialogData,
            },
        });
    }

    /**
     * minimum offset needed for the batch to ensure the selected option is displayed
     */
    protected getMinimumBatchOffset( filteredData ): Observable<number> {
        if ( !this.searchCtrl.value && this.dataControl.value ) {
            const index = filteredData.findIndex( item => item.id === this.dataControl.value );
            return of( index + this.batchSize );
        } else {
            return of( 0 );
        }
    }

    /**
     * Returns data that's needed for tracking the user's interactions.
     */
    protected getDataForTracking( results: ISearchResultItem[]): {
        shapesCount: number,
        libsCount: number,
    } {
        if ( results ) {
            return {
                shapesCount: results.filter( result => result.type === 'shape' ).length,
                libsCount: results.filter( result => result.type === 'libs' ).length,
            };
        }
    }

    /**
     * This method will call relevant search methods
     * All the search methods will be called from here.
     * Returns a combination of all the results
     * @param searchQuery - Search Text
     * @returns an observable of ISearchResultItem array which emits only once
     */
    protected search( searchQuery: string ): Observable<ISearchResultItem[]> {
        return forkJoin([
            this.searchShapesByTags( searchQuery ),
            this.searchAllByName( searchQuery ),
        ]).pipe(
            map(([ resultsFromTags, resultsFromName ]) => {
                const combineResults = resultsFromName.concat( resultsFromTags );
                return uniqBy( combineResults, 'id' );
            }),
        );
    }

    /**
     * length of the visible data / start of the next batch
     */
    protected getBatchOffset( filteredData ) {
        return combineLatest([
            merge(
                this.incrementBatchOffset.pipe( mapTo( true )),
                this.resetBatchOffset.pipe( mapTo( false )),
            ),
            this.getMinimumBatchOffset( filteredData ),
        ]).pipe(
            scan(( batchOffset, [ doIncrement, minimumOffset ]) => {
                if ( doIncrement ) {
                    return Math.max( batchOffset + this.batchSize, minimumOffset + this.batchSize );
                } else {
                    return Math.max( minimumOffset, this.batchSize );
                }
            }, this.batchSize ),
        );
    }

    /**
     * Searching shapes by tags
     * Returns a combination of composed libraries with shapes
     * Libraries will be given a higher priority
     * @param searchQuery - Search Query
     * @returns an observable of ISearchResultItem array which emits only once.
     */
    protected searchShapesByTags( searchQuery: string ): Observable<ISearchResultItem[]> {
        return this.searchShapes( searchQuery, [ 'tags' ]).pipe(
            switchMap(( searchResults: ISearchResultItem[]) => {
                const shapeObservables = searchResults.map( def =>
                    this.defLocator.getDefinition( def.id,
                        ( def as IShapeSearchResultItem ).version ).pipe(
                            map( loadedDef =>
                                this.createLibraryItem( loadedDef as IShapeDefinition ))),
                );
                if ( shapeObservables.length > 0 ) {
                    return forkJoin( shapeObservables );
                }
                return of([]);
            }),
        );
    }

    /**
     * Search All the providers by name.
     * Emits only once
     * @param searchQuery - Search Query
     * @returns An observable of ISearchResultItem array, emits only once.
     */
    protected searchAllByName( searchQuery: string ): Observable<ISearchResultItem[]> {
        return this.searchShapes( searchQuery, [ 'name' ]).pipe(
            switchMap( shapes => {
                const searchResultSorted = this.sortResultsByScore( shapes );
                return this.updateSearchResults( searchResultSorted );
            }),
        );
    }

    /**
     * Returns the ILibraryItem needed for rendering canvas based thumbnail items
     */
    protected createLibraryItem( def: IShapeDefinition ): IShapeThumbnailItem {
        return ( def.type === 'template' )
            ? this.createShapeTemplateItem( def )
            : this.createShapeCanvasItem( def );
    }

    /**
     * Adds a basic type shape to canvas by dispatching addDiagramShape
     * @param data - IShapeDefinition
     * @param x - x position of shape
     * @param y - y position of shape
     */
    protected addShape( data: IShapeDefinition, x: number, y: number ) {
        // TODO
    }

    /**
     * Search is performed by entered search query and emits search results
     * @param searchQuery - Search Query
     * @return An empty observable which emits only once.
     */
    protected doSearch( searchQuery: string ): Observable<any> {
        return this.search( searchQuery ).pipe(
            take( 1 ),
            switchMap(( sortedResults: ISearchResultItem[]) => {
                const trackingData = this.getDataForTracking( sortedResults );
                Tracker.track( 'canvas.shape.plusCreate.search', {
                    value1Type: 'keyword',
                    value1: searchQuery,
                    value2Type: 'shapeCount',
                    value2: `${trackingData.shapesCount}`,
                });
                const categorizedResults: IQuickSearchItem[] = this.getCategorizedResults( sortedResults );
                // FIXME: Need to fix the scrolling after search result
                // result category heading for first result hides under search bar
                this.setSearchResult( categorizedResults );
                // console.log( 'sorted results', sortedResults.length );
                // return this.getBatchOffset( sort edResults ).pipe(
                //     map( batchOffset => sortedResults.slice( 0, batchOffset )),
                //     tap( results => console.log( 'batchOffset results', results.length )),
                //     map( results => this.searchResults.next( results )),
                // );
                // console.log( 'end search', new Date().getTime());
                return EMPTY;
            }),
        );
    }

    /**
     * All the libraries will be loaded along with the relevant shapes
     * @returns An observable with libraries with shape def ids, emits only once.
     */
    protected loadAllLibrariesWithShapes(): Observable<ILibraryAndShapesDefs[]> {
        const observables: Observable<ILibraryAndShapesDefs>[] = [];
        this.libraryList.getAllGroups().forEach( group =>
            this.libraryList.getLibrariesInGroup( group ).map( lib =>
                observables.push(
                    this.loader.getLibrary( lib.id ).pipe(
                        map( shapeDefs => ({
                            shapeIds: this.removeVersionFromDefIds( shapeDefs.defs ),
                            libTitle: lib.label,
                            libId: lib.id,
                            groups: this.libraryList.getGroupsForLibrary( lib.id ),
                        })),
                        catchError(() => of( undefined )),
                    ),
                ),
            ),
        );
        return forkJoin( observables ).pipe(
            tap( libsWithShapes => {
                libsWithShapes = libsWithShapes.filter( libWithShapes => !!libWithShapes );
                this.allShapesAndDeps = libsWithShapes;
                this.populateShapeDefIdLibMap();
            }),
        );
    }

    protected getViz(): IQuickSearchItem {
        const b = this.shapeModel.bounds;
        const bounds = {
            x : b.right + 130,
            y : b.y,
            width : b.width,
            height : b.height,
        };

        const results: any = Object.values( VIZ_DATA )
            .filter(( item: any ) => item.context.includes( 'plus-create' ))
            .map(( item: any ) => {
                const val = {
                    id: item.id,
                    title: item.label,
                    type: 'viz',
                    data: {
                        callback: ({ side }) => {
                            const open = !!item.userInput;
                            this.state.set( 'AIPromptPopupState', {
                                open,
                                promptType: item.id,
                                shapeIds: [ this.shapeModel.id ],
                                bounds,
                                pluscreateSide: side,
                            });
                        },
                    },
                    thumbnailType: 'canvas',
                    canvasIdPrefix: 'thumb-search-drop',
                    permission: PlanPermission.CREATELY_VIZ_ACCESS,
                };
                return val;
            });
        return {
            category: 'AI Generate',
            results,
        };
    }

    private populateShapeDefIdLibMap() {
        this.allShapesAndDeps.forEach( lib => {
            lib.shapeIds.forEach( shapeId => {
                const s = this.shapeDefLibMap[shapeId];
                if ( !s ) {
                    this.shapeDefLibMap[shapeId] = [{ libId: lib.libId, libTitle: lib.libTitle }];
                } else {
                    if ( !s.some( v => v.libId === lib.libId )) {
                        s.push(
                            { libId: lib.libId, libTitle: lib.libTitle },
                        );
                    }
                }
            });
        });
    }

    /**
     * Removes version from defId
     * @param defsWithVersion - An array of defIds
     * @returns a string array
     */
    private removeVersionFromDefIds( defsWithVersion: string[]): string[] {
        return defsWithVersion.map( def => {
            const indexOfDot = def.lastIndexOf( '.' );
            return def.substring( 0, indexOfDot );
        });
    }

    /**
     * Update only shape results for previewing
     * @param sortedResults - sorted results array
     * @returns An observable of ISearchResultItem array, emits only once.
     */
    private updateSearchResults( sortedResults: ISearchResultItem[]): Observable<ISearchResultItem[]> {
        if ( sortedResults && sortedResults.length > 0 ) {
            const observables = sortedResults.map( result => {
                if ( result.type === 'shape' ) {
                    return this.defLocator.getDefinition( result.id,
                        ( result as IShapeSearchResultItem ).version ).pipe(
                            map( def => this.createLibraryItem( def as IShapeDefinition )),
                        );
                }
                return of( result );
            });
            return forkJoin( observables );
        }
        return of([]);
    }

    /**
     * Sort search result array by score
     * @param resultArray - search result array
     * @returns ISearchResultItem array
     */
    private sortResultsByScore( resultArray: ISearchResultItem[]): ISearchResultItem[] {
        return resultArray.sort(( a, b ) => {
            if ( a.score < b.score ) {
                return 1;
            }
            if ( a.score > b.score ) {
                return -1;
            }
            return 0;
        });
    }

    /**
     * Search shapes on shape definitions using by a text and options
     * @param searchQuery - Search Query
     * @param keys - keys to be searched by
     * @returns An observable of ISearchResultItem array
     */
    private searchShapes( searchQuery: string, keys: string[]): Observable<ISearchResultItem[]> {
        return this.searchService.search( searchQuery.trim(), [ 'shape' ],
            { keys: keys, threshold: -10000, limit: 300, allowTypo: false });
    }

    /**
     * Creates a search result for a shape which is compatible with thumbnail-canvas
     * @param def IShapeDefinition
     * @returns IShapeResultItem
     */
    private createShapeCanvasItem( def: IShapeDefinition ): IShapeThumbnailItem {
        return {
            id: def.defId,
            title: def.name,
            type: 'shape',
            data: def,
            thumbnailType: 'canvas',
            canvasIdPrefix: 'thumb-search-drop',
        } as IShapeThumbnailItem;
    }

    private getCategorizedResults( results: ISearchResultItem[]): IQuickSearchItem[] {
        const currentShapeLibData = this.shapeDefLibMap[ this.shapeModel.defId ];
        const categorizedMap: { [key: string]: IQuickSearchItem } = {};
        let catResults: IQuickSearchItem[] = [];
        if ( results ) {
            // Supressing error, since there are high chance for errors from shapes
            try {
                const capped = results.filter(
                    result => result && result.type !== 'libs' && this.shapeDefLibMap[result.id],
                );
                capped.forEach(
                    result => {
                        const libDatas = this.shapeDefLibMap[result.id] || [];
                        const libData = libDatas[0];
                        if ( libData ) {
                            const libId = libData.libId;
                            const mapItem = categorizedMap[libId];
                            if ( mapItem ) {
                                mapItem.results.push( result );
                            } else {
                                categorizedMap[libId] = {
                                    category: libData.libTitle,
                                    results: [ result ],
                                };
                            }
                        }
                    },
                );
                if ( currentShapeLibData ) {
                    const currentShapeLib = categorizedMap[ currentShapeLibData[0].libId ];
                    if ( currentShapeLib?.results?.length ) {
                        const index = currentShapeLib.results.findIndex( v => v.id === this.shapeModel.defId );
                        if ( index > -1 ) {
                            const [ itemToMove ] = currentShapeLib.results.splice( index, 1 );
                            currentShapeLib.results.unshift( itemToMove );
                        }
                        catResults.push( currentShapeLib );
                    }
                    delete categorizedMap[ currentShapeLibData[0].libId ];
                }
                catResults = catResults.concat( Object.values( categorizedMap ));

            } catch ( error ) {
                Logger.debug( error );
            }
        }
        return catResults;
    }

    /**
     * Retrieves defs for given defIds and create a library item
     * @param shapeDefIds
     */
    private getShapeLibraryItems( shapeDefIds: string[]) {
        if ( !shapeDefIds || shapeDefIds.length === 0 ) {
            return of([]);
        }
        const shapeObservables = shapeDefIds.map( defId => this.defLocator.getDefinition( defId ).pipe(
            map( loadedDef => this.createLibraryItem( loadedDef as IShapeDefinition )),
        ));
        return forkJoin( shapeObservables );
    }

    private getRelatedShapes( attachCurrentShape: boolean = false ): Observable<IShapeThumbnailItem[]> {
        const currentShapeLibData = this.shapeDefLibMap[ this.shapeModel.defId ] || [];
        if ( currentShapeLibData[0]) {
            const libId = currentShapeLibData[0].libId;
            const libData = this.allShapesAndDeps.find( lib => lib.libId === libId );
            const shapeIds = libData?.shapeIds.filter( defId => defId !== this.shapeModel.defId ) || [];
            if ( attachCurrentShape ) {
                shapeIds.unshift( this.shapeModel.defId );
            }
            return this.getShapeLibraryItems( shapeIds ).pipe(
                map( items => items.filter( val => (
                    val.data.type !== 'connector' ),
                ),
            ));
        }
        return of([]);
    }

    /**
     * This function sets the default items.
     *
     *  Normal default Items will as follow based on several condition
     *
     *  1. Shape with no plus create shapes defined: This should display all the shapes on that library
     *     and no related shapes is shown.
     *
     *  2. Shape with plus create shapes defined.: Display the plus create defined shapes and rest of the shape in
     *     Library are shown as related shapes
     *
     *  3. EData Shapes: Display all edata shapes on the relevant database, priority no.1 regardless of 1, 2.
     *
     *  4. Older shapes not in any library and doesn't have plus create shapes defined:
     *     Will list a default common shape list
     *
     */
    private setDefaultItems() {
        if ( !this.shapeModel ) {
            return;
        }

        const cacheId = this.getCacheId( this.shapeModel );
        const defaultItems = this.defaultPlusItemCache[ cacheId ];
        if ( defaultItems && !this.shapeModel.eData ) {
            this.setSearchResult( defaultItems );
            return;
        }

        const defaultPlusItems = this.shapeModel?.create?.defs?.map( def => def.defId )
            || [];
        const defaultPlusItemObservable = defaultPlusItems.length ? this.getShapeLibraryItems( defaultPlusItems )
            : this.getRelatedShapes( true ).pipe(
            switchMap( items => items?.length ? of( items ) : this.getPredefinedShapes()),
        );
        const edataShapesObservable = this.getEdataLibraryShapes();
        const relatedShapesObservable = defaultPlusItems.length ? this.getRelatedShapes() : of([]);

        const sub = combineLatest([ edataShapesObservable, defaultPlusItemObservable, relatedShapesObservable ]).pipe(
            switchMap(([ edataThumbs, defaultPlusThumbs, relatedShapesThumbs ]) => {

                const defaultPlusResults = [];
                defaultPlusResults.push( ...this.getCategorizedResults( defaultPlusThumbs ));

                if ( this.teamSettingManager.check( 'TeamLimitationCreatelyViz' )) {
                    defaultPlusResults.push( this.getViz());
                }

                const filteredRelShapes = relatedShapesThumbs.filter( item => item.data.type !== 'connector' );

                if ( filteredRelShapes.length ) {
                    const relatedShapes: IQuickSearchItem = { category: 'Related Shapes', results: filteredRelShapes };
                    defaultPlusResults.push( relatedShapes );
                }

                if ( !defaultItems ) {
                    this.defaultPlusItemCache[ cacheId ] = defaultPlusResults;
                }

                const edataShapes: IQuickSearchItem[] = [];
                if ( edataThumbs.length ) {
                    edataShapes.push({
                        category: 'Data Items',
                        results: edataThumbs,
                    });
                }
                const results = edataShapes.concat( this.defaultPlusItemCache[ cacheId ]);

                this.setSearchResult( results );
                return EMPTY;
            }),
        ).subscribe();

        this.subs.push( sub );
    }

    private setSearchResult( results: IQuickSearchItem[]) {
        if ( this.searchResults.value !== results ) {
            this.searchResults.next( results.filter( r => !!r && r.results.length ));
        }
        this.previousShapeDefId = this.shapeModel.defId;
        this.infiniteScrollSelect?.options?.forEach( item => item?.deselect());
    }

    private handleMouseLeave( event: MouseEvent ) {
        // Temp comment out to see  the whether ux is improved.
        // this.infiniteScrollSelect.close();
        this.infiniteScrollSelect?.panel?.nativeElement.removeEventListener( 'mouseleave', this.handleMouseLeave );
    }

    private getCacheId( shapeModel ): string {
        return shapeModel.defId.replace( '.', '$' );
    }

    private getEdataLibraryShapes(): Observable < IShapeThumbnailItem[] > {
        if ( this .shapeModel && this.shapeModel.eData ) {
            return this.ell.getEData( this.shapeModel.eDataId ).pipe(
                switchMap( l => l.getEDataModel()),
                distinctUntilChanged(( a, b ) => this.comparedEntityIds(
                    a.getActiveCustomEntityDefIds(), b.getActiveCustomEntityDefIds()),
                ),
                map( mdl => mdl.getActiveCustomEntityDefs().map(( val: IEntityDef ) => {
                    const shape = val.defaultShape || { defId: 'creately.basic.rectangle', version: 3 };
                    return {
                        id: val.id,
                        name: val.name,
                        data: val.dataItems,
                        defId: shape.defId,
                        drawCode: shape.drawCode,
                        version: shape.version,
                        entityDefId: val.id,
                        style: shape?.style?.shape,
                        typeStyle: { ...shape.style, defaultShapeContext: shape.defaultShapeContext },
                        textStyle: shape.texts,
                        shapeContext: shape.defaultShapeContext,
                        eData: {
                            [this.shapeModel.eDataId]: null,
                        },
                    };
                })),
                map(( defArr: IShapeDefinition[]) => defArr.map( def => this.createShapeCanvasItem( def ))),
            );
        }
        return of([]);
    }

    private getPredefinedShapes() {
        return this.getShapeLibraryItems( this.predefinedPlusItems );
    }

    private comparedEntityIds( a: string[], b: string[]) {
        if ( !a && !b ) {
            return true;
        }
        if ( !a || !b || a.length !== b.length ) {
            return false;
        }
        const resultA = a.every( v => b.includes( v ));
        return resultA && b.every( v => a.includes( v ));
    }
}
