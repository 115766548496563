import { IShapeViewDefinition } from 'flux-definition';
import { AbstractShapeView } from '../../framework/view/abstract-shape-view';
import { ShapeDataModel } from '../model/shape-data.mdl';
import { IShapeTextView } from '../../framework/text/text-view.i';

/**
 * The generic version of the image view that can render
 * on any space. This must be extended by other views which
 * will be desinged to render on specific space such as canvas or SVG.
 *
 * @author Ramishka
 * @since 2019-02-28
 */
export class ImageRenderView extends AbstractShapeView implements IShapeViewDefinition {

    /**
     * This is the base64 string which is used as the image source
     * of the custom image.
     */
    protected _imageSource: string;

    /**
     * The model associated with this shape. This is only updated
     * when the model is available and can be undefined.
     * @override
     */
    public get model(): ShapeDataModel {
        return <ShapeDataModel>this._model;
    }

    /**
     * Allows setting the image source of the custom image.
     * @param base64 - base64 representation of the image
     */
    public set imageSource( base64: string ) {
        this._imageSource = base64;
    }

    /**
     * Is called after shape is created and added to the view
     * for the first time.
     */
    public initialize() {
        // nothing yet
    }


    /**
     * This method ultimately redraws this shape on the given
     * drawing space. The method would ensure that all data changes
     * are translated into the drawing space as intended by this shape.
     * This means the full shape will be up-to-date on the drawing space
     * when this method completes until the next data change on the model.
     * @param change A json with all the changes that happened to the model.
     *          Can be used to check which props changed or what changes happened.
     * @override
     */
    public render( change?: any ) {
        this.beginDraw();
        this.drawText( change );
        this.move( this.model.x, this.model.y );
        this.rotate( this.model.angle );
        super.endDraw();
    }

    public beginDraw(): void {
        this.graphics.clear();
        super.beginDraw();
        const width = this.model.drawWidth;
        const height = this.model.drawHeight;
        this.graphics.drawRect( 0, 0, width, height );
    }

    /**
     * This function updates the text view accoridng to the given text model
     * @param ShapeTextView
     * @param change The text model changes
     * @override
     */
    protected updateText( text: IShapeTextView, change?: any ) {
        text.update( this.model.texts[ text.name ], change, this.model.defaultBounds, this.model.transform );
    }

    /**
     * Moves the shape to the given location
     * @param x the position on the canvas to move to
     * @param y the position on the canvas to move to
     */
    protected move( x: number, y: number ) {}

    /**
     * Scales the shape in height.
     * @param scale The scale multiplier
     */
    protected scaleHeight( scale: number ) {}

    /**
     * Scales the shape in width.
     * @param scale The scale multiplier
     */
    protected scaleWidth( scale: number ) {}

    /**
     * Rotates the shape to the given angle
     * @param angle The angle to rotate to.
     */
    protected rotate( angle: number ) {}

}
