import { Injectable, Inject } from '@angular/core';
import { Observable, concat, defer } from 'rxjs';
import { CommandService, StateService } from 'flux-core';
import { DataStore } from 'flux-store';
import { UserModel } from './model/user.mdl';
import { switchMap, filter, map, take, tap, skipUntil } from 'rxjs/operators';
import { SubscriptionStatus, ModelSubscriptionManager } from 'flux-subscription';
import { UserInfoSub } from './subscription/user-info.sub';
import { UserInfoModel } from './model/user-info.mdl';
import { PlanType } from '../permission/plan-perm-manager';
import { SubscriptionStatusTypes } from '../permission/model/user-subscription.mdl';
import { UserCommandEvent } from './command/user-command-event';

/**
 * StateService representation for Current user state
 */
@Injectable()
export class CurrentUserStateService extends StateService< 'CurrentUser', string > {}

/**
 * Locator that fetches the current user in the system.
 *
 * @author  Ramishka
 * @since   2018-01-24
 */
@Injectable()
export class UserLocator {

    constructor(
        @Inject( StateService ) protected state: CurrentUserStateService,
        protected modelSubManager: ModelSubscriptionManager,
        protected dataStore: DataStore,
        protected commandService: CommandService ) {}

    /**
     * Fetches the user data for current user.
     * @param userId - userId to be fetched
     * @param subscribe - should start subscription and get data or not
     * @return  An observable that emits the user model of the current user
     */
    public getUserData(): Observable<UserModel> {
        // FIXME - Method name to be changed to getCurrentUserData()
        return this.getCurrentUserId().pipe( switchMap( currentUserId => {
            const selector = { id: currentUserId };
            return this.dataStore.findOne( UserModel, selector );
        }));
    }

    /**
     * Fetches the user data for current user after user sub start
     * @returns An observable that emits the user model of the current user
     */
    public getUserDataAfterUserSubscriptionStarted(): Observable<UserModel> {
        const currentUserIdObs = this.getCurrentUserId().pipe(
            filter( currentUserId => !! currentUserId ),
        );
        const subStarted = currentUserIdObs.pipe(
            switchMap( currentUserId => this.modelSubManager.getFutureSub( currentUserId )),
            switchMap( userSub => userSub.status ),
            filter( subStatus => subStatus.subStatus === SubscriptionStatus.started ),
        );
        return currentUserIdObs.pipe(
            skipUntil( subStarted ),
            switchMap( currentUserId => {
                const selector = { id: currentUserId };
                return this.dataStore.findOne( UserModel, selector );
            }),
        );
    }

    /**
     * this will fetch user data data from storage and return the boolean value of show_comment_indicator preference
     * and of storage does not have show_comment_indicator preference it will return true.
     */
    public getCommentToggleState(): Observable<boolean> {
        return this.getUserData().pipe(
            filter( user => !!user && !!user.preferences ),
            map( user => {
                if ( typeof user.preferences.show_comment_indicator !== 'undefined' &&
                 user.preferences.show_comment_indicator !== null ) {
                    return user.preferences.show_comment_indicator;
                }
                return true;
            }),
        );
    }

    /**
     * this will fetch user data data from storage and will check whether user is subscription paused or not
     * if user subscription is this function will return true if user subscription not paused it will return false.
     * Will ignore subscription status checking for free and demo users and return false since it is not related to them
     * Here also check the user suspended status and if user suspended return false
     */
    public isUserPaused(): Observable<boolean> {
        return this.getUserDataAfterUserSubscriptionStarted().pipe(
            filter( user => !!user && !!user.userSubscription ),
            map( user => {
                if ( user.suspended ) {
                    return true;
                } else if ( !( user.plan.type.startsWith( PlanType.Public ) || user.plan.type === PlanType.Demo )) {
                    if ( user.userSubscription.subStatus === SubscriptionStatusTypes.PAUSED ) {
                        return true;
                     } else {
                        return false;
                     }
                } else {
                    return false;
                }
            }),
        );
    }

    /**
     * Fetches user data for a given userId from datastore.
     * If the user data not found,
     * it will start the subscription with server and fetch user info and added to local storage.
     * After updating local storage, final data will be fetched from the local storage.
     * NOTE: This methods emits only once
     * @param userId - userId to be fetched
     * @param subscribe - should start subscription and get data or not
     * @returns An observable that emits once the user info model of a specific user
     */
    public getUserInfo( userId: string ): Observable<UserInfoModel> {
        const selector = { id: userId };
        return this.dataStore.findOne( UserInfoModel, selector ).pipe(
            tap( userData => {
                if ( !userData ) {
                    this.startUserInfoSubscription( userId ).subscribe();
                }
            }),
        );
    }

    public isUserInOrg( userId: string, orgId: string ): Observable<boolean> {
        return this.getOrgUserInfo( userId, orgId ).pipe(
            map( user => user ? true : false ),
        );
    }

    /**
     *
     * @param userId user id
     * @param orgId org user id
     * @returns
     */
    public getOrgUserInfo( userId: string, orgId: string ): Observable<UserModel> {
        return new Observable( subscriber => {
            concat(
                this.commandService.dispatch( UserCommandEvent.getOrgUserInfo,
                    {
                        orgId : orgId,
                        userId: userId,
                    }),
                defer(() => this.dataStore.findOneLatest( UserModel, { id: userId })).pipe(
                    tap(( tpl: UserModel ) => {
                        subscriber.next( tpl );
                        subscriber.complete();
                    }),
                ),
            ).subscribe();
        });
    }

    /**
     * Function to retrieve the current user id from the state service
     * @return  BehaviorSubject that emits when the current user id changes
     */
    protected getCurrentUserId(): Observable<string> {
        return this.state.changes( 'CurrentUser' );
    }

    /**
     * Starts UserInfo subscription and fetch user info for a given user id.
     * Filters 'created' subscription status.
     * @param userId - user to be fetched
     * @returns SubscriptionStatus observable, emits only once
     */
    private startUserInfoSubscription( userId: string ): Observable<SubscriptionStatus>  {
        return this.modelSubManager.start( UserInfoSub, userId ).pipe(
            switchMap( sub => sub.status ),
            filter( statusSubject => statusSubject.subStatus !== SubscriptionStatus.created ),
            map( statusSubject => statusSubject.subStatus ),
            take( 1 ),
        );
    }
}
