import { sortBy, values, intersection, maxBy } from 'lodash';
import { IShapeModel, IRectangle, ILayoutingData, IDiagramDefinition } from 'flux-definition';
import { last, isEmpty, omit } from 'lodash';
import ELK from '@creately/elkjs/lib/elk.bundled.js';
import { Rectangle } from './rectangle';
import { Logger } from '../logger/logger.svc';
import { TreeUtils } from 'simple-tree-utils';

/**
 * Layout class is to maintain all the native and 3rd party layouting
 * algorhythms like
 * ELK library https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html used
 *
 * @author thisun
 * @since 2020-11-23
 */

export class Layouting {

    public static restriction: any;
    protected static elk = new ELK();
    protected static defaultPadding = [ 10, 10, 10, 10 ];

    /**
     * Apply the layouting algorithm to the passed shapes
     * @param shapes Array of shapes to apply the layouting
     * @param bounds The bounds the layouting shoud be fit into
     * @param layoutingData ILayoutingData
     * @param padding Padding to be applied to the bounds
     * @
     */
    // tslint:disable-next-line: member-ordering
    public static async apply(
        diagram: IDiagramDefinition,
        shapes: IShapeModel[],
        bounds: IRectangle,
        layoutingData: any,
        padding = [ 0, 0, 0, 0 ],
        allowFreeForm = true,
    ): Promise<IShapeModel[]> {
        bounds = Rectangle.from({
            x: bounds.x + padding[3],
            y: bounds.y + padding[0],
            width: bounds.width - padding[1] - padding[3],
            height: bounds.height - padding[0] - padding[2],
        });


        // FIXME: disabling freeform for now
        // if ( allowFreeForm ) {
        //     shapes = shapes.filter(( s, i ) => {
        //         const shapeBounds = ( s as any ).bounds;
        //         const thresholdRect = ( bounds as Rectangle ).clone();
        //         thresholdRect.pad( 5 );
        //         if ( shapeBounds && thresholdRect.contains( shapeBounds ))  {
        //             return true;
        //         }
        //     });
        // }
        shapes = shapes.filter(( s: any, i ) => !( diagram as any )?.skipLayout( s.id ));

        if ( layoutingData.source === 'elk' ) {
            const { children, edges } = Layouting
                .prepareNodesForElk( diagram, shapes, layoutingData.options[ 'org.eclipse.elk.direction' ]);
            const relativeX = bounds.x; // + padding[3];
            // const relativeY = bounds.y; // - padding / 2;
            const relativeY = bounds.y; // + padding[0];
            const width = bounds.width; // - padding[1] - padding[3];
            const height = bounds.height; // - padding[0] - padding[2];
            const aspectRatio = layoutingData.aspectRatio === 'dynamic' ?
                ( width / height ) : layoutingData.aspectRatio || 1.6;

            const graph = {
                id: 'root', // not important
                children,
                edges,
                properties: {
                    'org.eclipse.elk.padding': `` + 0,
                    'org.eclipse.elk.algorithm': layoutingData.algorithm,
                    'elk.aspectRatio': `` + aspectRatio,
                    'org.eclipse.elk.rectpacking.widthApproximation.strategy': 'TARGET_WIDTH',
                    'org.eclipse.elk.rectpacking.widthApproximation.targetWidth':
                        `` + width,
                    ...layoutingData.options as any,
                } as any,
            } as any;

            const result = await Layouting.elk.layout( graph );
            // Update x, y shape's XY
            try {

                result.edges.forEach( edge => {
                    if ( !edge.sections ) {
                        return;
                    }
                    const connector = ( diagram as any ).shapes[ edge.id ] as any;

                    ( connector as any ).triggerAutolayout = Math.random();

                    Object.keys( connector.path ).forEach( key => {
                        const val = connector.path[ key ];
                        if ( val.pathAdjustedManually ) {
                            val.pathAdjustedManually = false;
                        }
                    });
                    const fromShape = ( diagram as any ).shapes[ edge.sources[ 0 ]];
                    const toShape = ( diagram as any ).shapes[ edge.targets[ 0 ]];
                    const _fromShape = result.children.find( c => c.id === edge.sources[ 0 ]);
                    const _toShape = result.children.find( c => c.id === edge.targets[ 0 ]);

                    const startPoint: any = edge.sections[ 0 ].startPoint;
                    const endPoint: any  = edge.sections[ 0 ].endPoint;

                    const gpX = ( startPoint.x - _fromShape.x ) / _fromShape.width;
                    const gpY = ( startPoint.y - _fromShape.y ) / _fromShape.height;
                    const gpstart: any = Object
                        .values( fromShape.gluepoints ).find(( gp: any ) =>
                            ( gpX > 0 && gpX < 1 && gp.y === gpY ) ||
                            ( gp.x === gpX && gpY > 0 && gpY < 1 ));

                    const fromEp = connector.getFromEndpoint( diagram as any );
                    if ( gpstart && fromEp.gluepoint && ( fromEp.gluepoint.id !== gpstart.id )) {
                        ( connector as any ).fromGPAutolayout = `${fromShape.id}-${( gpstart as any ).id}`;
                    }

                    const gpXe = ( endPoint.x - _toShape.x ) / toShape.width;
                    const gpYe = ( endPoint.y - _toShape.y ) / toShape.height;
                    const gpend: any = Object
                        .values( toShape.gluepoints ).find(( gp: any ) =>
                            ( gpXe > 0 && gpXe < 1 && gp.y === gpYe ) ||
                            ( gp.x === gpXe && gpYe > 0 && gpYe < 1 ));

                    const toEp = connector.getToEndpoint( diagram as any );
                    if ( gpend && toEp.gluepoint && ( toEp.gluepoint.id !== gpend.id )) {
                        ( connector as any ).toGPAutolayout = `${toShape.id}-${( gpend as any ).id}`;
                    }

                    if ( isEmpty( fromShape.__sakota__.getChanges())
                        || isEmpty( toShape.__sakota__.getChanges())) {
                        return;
                    }

                    const points = connector.getPoints();
                    const endpoints = [ points[0], last( points ) ];
                    endpoints[0].x = endpoints[0].x + 50;
                });


                result.children.forEach(  s => {
                    const shape = shapes.find(( sh: any ) => sh.id === s.id );
                    const restricted = Layouting.restriction.point({
                        x: relativeX + s.x,
                        y: relativeY + s.y,
                    }, [ 'GridService' ]);
                    shape.x = restricted.x;
                    shape.y = restricted.y;
            });

                return Object.values(( diagram as any ).shapes );
            } catch ( error ) {
                Logger.error( 'Layouting failed:', error );
            }
        } else {
            Layouting.applyNative( shapes, bounds, layoutingData, diagram );
            shapes.forEach( s =>  {
                const { x, y, scaleX, scaleY } = Layouting.restriction.shapeTransform( s, {
                    x: s.x,
                    y: s.y,
                    scaleX: s.scaleX,
                    scaleY: s.scaleY,
                });
                s.x = x;
                s.y = y;
                s.scaleX = scaleX;
                s.scaleY = scaleY;
            });
            return shapes;
        }
    }

    // tslint:disable-next-line: member-ordering
    public static async getNextItems(
        all: { children: any, edges: any },
        current: { children: any },
        layoutingData,
        limit = 50,
    ) {
        const graph = {
            id: 'root', // not important
            children: all.children,
            edges: all.edges,
            properties: {
                'org.eclipse.elk.padding': `` + 0,
                'org.eclipse.elk.rectpacking.widthApproximation.strategy': 'TARGET_WIDTH',
                ...layoutingData as any,
            } as any,
        } as any;

        const result = await Layouting.elk.layout( graph );
        const _shapes = result.children.sort(( a, b ) => a.y - b.y );

        const levels = {};
        _shapes.forEach(( s, i ) => {
            if ( levels[ s.y ]) {
                levels[ s.y ].push( s );
            } else {
                levels[ s.y ] = [ s ];
            }
        });
        const shapes = [];
        Object.keys( levels ).forEach( key => {
            levels[ key ].sort(( a, b ) => a.x - b.x );
            shapes.push( ...levels[ key ]);
        });

        const conns = result.edges;
        const remaining = shapes.filter( s => !( current.children || []).find( c => c.id === s.id ));
        const nextSet = remaining.slice( 0, limit );
        const edges = [];
        nextSet.forEach( s => {
            const edge = conns.find( c => c.targets?.[0] === s.id );
            if ( edge ) {
                edges.push( edge );
            }
        });
        return {
            children: nextSet,
            edges,
        };
    }

    // tslint:disable-next-line: member-ordering
    public static getNextTreeNodes<T extends { id: string, parentId: string | null }>(
        all: Array<T>,
        nodeId?: string,
        levelsLimit = 3,
        nodesLimit = 100,
        exclude: string[] = [],
    ): Array<T> {

        const treeUtils = new TreeUtils({
            idProp: 'id', // the key of a unique identifier for an object (source object)
            parentIdProp: 'parentId', // the key of a unique parent identifier (source object)
            childrenProp: 'children', // the key, where child nodes are stored (destination object tree)
        });

        let output = [];
        const getChildrenRecursive = ( treeObj: any, level = 0 ) => {
            if ( level > levelsLimit  ) {
                return;
            }
            output = output.concat( treeObj.map( v => omit( v, [ 'children' ])));
            treeObj.forEach( node => {
                getChildrenRecursive( node.children, level + 1 );
            });
        };

        const tree = treeUtils.list2Tree( all );
        if ( !nodeId ) { // Start from root nodes
            getChildrenRecursive( tree );
        } else {
            const node = treeUtils.findTreeNodeById( tree, nodeId );
            // const list = treeUtils.findAllChildrenNodes( tree, nodeId ).map( v => ({
            //     id: v.id,
            //     parentId: v.parentId || null,
            // }));
            // list.push({ id: nodeId, parentId: null });
            // const _tree = treeUtils.list2Tree( list );
            getChildrenRecursive([ node ]);
            output.shift();
        }
        return output.filter( n => !exclude.includes( n.id )).slice( 0, nodesLimit );
    }

    /**
     * Convert shapes to ELK nodes
     */
    protected static prepareNodesForElk( diagram, shapes: any[], direction ): any {
        const children = shapes
            .filter( s => !s.isConnector())
            .map( s => ({
                id: s.id,
                width: s.width,
                height: s.height,
                x: s.x,
                y: s.y,
            }));
        const edges = [];
        shapes.filter( s => !s.isConnector()).forEach( shape => {
            // shape as source
            const sourceConnections = shape.getConnectors( diagram ).filter( c => {
                // 'connection' is udefined for some diagrams
                const connection = c.connector.getConnection( diagram );
                const fromEndpoint = c.connector.getFromEndpoint( diagram );
                if ( connection && connection.shapeA.shapeId === shape.id ) {
                    return true;
                } else if ( !connection && fromEndpoint.shape?.id === shape.id ) {
                    return true;
                }
            }).map( c => c.connector );
            if ( sourceConnections.length ) {
                sourceConnections.forEach(( c: any ) => {
                    const src = shape.id;
                    const conn = c.getConnection( diagram );
                    const toEndpoint = c.getToEndpoint( diagram );
                    const fromEndpoint = c.getFromEndpoint( diagram );
                    const hId = diagram.shapes[ c.id ].path.headId;
                    const tId = diagram.shapes[ c.id ].path.tailId;
                    const head = diagram.shapes[ c.id ].path[ hId ];
                    const tail = diagram.shapes[ c.id ].path[ tId ];
                    head.gluepointLocked = false;
                    tail.gluepointLocked = false;
                    toEndpoint.gluepointLocked = false;
                    fromEndpoint.gluepointLocked = false;
                    const target = conn ? conn.shapeB.shapeId :
                        toEndpoint.shape ? toEndpoint.shape.id : null;
                    if ( target && shapes.find( s => s.id === src )
                        && shapes.find( s => s.id === target )) { // An edge should connect 2 shapes
                            edges.push({
                                id: c.id,
                                sources: [ shape.id ],
                                targets: [ conn ? conn.shapeB.shapeId : toEndpoint.shape.id ],
                                ...c.bounds,
                            });
                    }
                });
            }
        });
        if ( direction === 'LEFT' || direction === 'RIGHT' ) {
            edges.sort(( a, b ) => a.top - b.top );
        } else {
            edges.sort(( a, b ) => a.left - b.left );
        }
        children.sort(( a, b ) => a.x - b.x );
        children.sort(( a, b ) => a.y - b.y );

        // const list = [];
        // children.forEach(( c, i ) => {
        //     const edg = edges.find( e => e.targets[0] === c.id );
        //     list.push({
        //         id: c.id,
        //         parentId: edg?.sources[0],
        //     });
        // });

        return {
            children,
            edges,
        };
    }

    /**
     * After the layouting operation done, the shapes should be pan to the top left of
     * the bounds specified
     */
    protected static panToCenter( shapes: IShapeModel[], bounds: IRectangle, layoutingData: ILayoutingData ) {
        if ( !shapes.length ) {
            return shapes;
        }
        let mergedBounds = new Rectangle( shapes[ 0 ].x, shapes[ 0 ].y, shapes[ 0 ].width, shapes[ 0 ].height );
        shapes.forEach(( s, i ) => {
            if ( i > 0 ) {
                mergedBounds = mergedBounds.absorb(
                    new Rectangle( shapes[ i ].x, shapes[ i ].y, shapes[ i ].width, shapes[ i ].height ),
                );
            }
        });
        const deltaX = mergedBounds.x + mergedBounds.width / 2 -
            ( bounds.x + bounds.width / 2 );
        const deltaY = mergedBounds.y - ( bounds.y + layoutingData.padding[0]);
        shapes.forEach(( s, i ) => {
            shapes[ i ].x = shapes[ i ].x - deltaX;
            shapes[ i ].y = shapes[ i ].y - deltaY;
        });
        return shapes;
    }

    protected static applyNative( shapes: IShapeModel[], bounds: IRectangle, layoutData: ILayoutingData, d ) {
        if ( Layouting[ layoutData.algorithm ] && typeof this[ layoutData.algorithm ] === 'function' ) {
            return Layouting[ layoutData.algorithm ]( shapes, bounds, layoutData, d );
        }
        return shapes;
    }


    /**
     * ----------  Native layouting implementations ------------------------
     */

    /**
     * simpleVerticalList
     */

    protected static free( shapes, bounds: IRectangle, ld, diagram ) {
        shapes.forEach( shape => {
            if ( !shape.containerId ) {
                return;
            }
            const container = diagram.shapes[ shape.containerId ];
            const region = container?.containerRegions?.[ shape.containerRegionId ];
            if ( region ) {
                const regionShapeData = region.shapes[ shape.id ];
                if ( regionShapeData && typeof regionShapeData === 'object' ) {
                    const { relativeX, relativeY } = regionShapeData;
                    shape.x = container.x + region.x + ( relativeX || Layouting.defaultPadding[0]);
                    shape.y = container.y + region.y + ( relativeY || Layouting.defaultPadding[3]);
                }
            }

        });

        // Do nothing;
    }

    protected static simpleVerticalList( shapes, bounds: IRectangle, layoutData: ILayoutingData ) {
        const shapesArray = values( shapes ).filter( s => !s.isConnector());
        const orderedVertically = sortBy( shapesArray, s => s.y );
        orderedVertically.forEach(( current: IShapeModel, i ) => {
            if ( i === 0 ) {
                const currentRectWithImage = current.getBoundsWithImages();
                let imagePadding = 0;
                if ( currentRectWithImage.height >  Math.round( current.height )) {
                    imagePadding = currentRectWithImage.height - current.height;
                }
                current.y = bounds.y + imagePadding + layoutData.padding[0];
                if ( layoutData.options.nodeBehavior === 'fill' ) { // fit to the region width padding
                    const effectiveWidth = ( bounds.width - layoutData.padding[3] - layoutData.padding[1]);
                    if ( current.minBounds && ( current.minBounds.width > effectiveWidth )) {
                        current.scaleX = current.minBounds.width / current.defaultBounds.width;
                    } else {
                        current.scaleX = effectiveWidth / current.defaultBounds.width;
                    }
                    current.autoResizeWidth = false;
                    const fixedAspectRatio = ( current.transformSettings || {} as any ).fixAspectRatio;
                    if ( fixedAspectRatio ) {
                        current.scaleY = current.scaleX;
                    }
                    current.x = bounds.x + layoutData.padding[3];
                } else if ( layoutData.options.nodeBehavior === 'center' ) { // center in the region
                    current.x = bounds.x + (
                        bounds.width / 2  -  current.width / 2
                    );
                } else {
                    current.x = bounds.x + layoutData.padding[3];
                }
            }
            const prev = orderedVertically[ i - 1 ];
            if ( prev ) {
                const currentRectWithImage = current.getBoundsWithImages();
                let imagePadding = 0;
                if ( currentRectWithImage.height > current.height ) {
                    imagePadding = currentRectWithImage.height - current.height;
                }
                current.y = prev.y + prev.height + imagePadding + layoutData.spacing[ 0 ];
                if ( layoutData.options.nodeBehavior === 'fill' ) { // fit to the region width padding
                    if ( current.minBounds && ( current.minBounds.width > prev.width )) {
                        current.scaleX = current.minBounds.width / current.defaultBounds.width;
                    } else {
                        current.scaleX = prev.width / current.defaultBounds.width;
                    }
                    current.autoResizeWidth = false;
                    const fixedAspectRatio = ( current.transformSettings || {} as any ).fixAspectRatio;
                    if ( fixedAspectRatio ) {
                        current.scaleY = current.scaleX;
                    }
                    current.x = prev.x;
                } else if ( layoutData.options.nodeBehavior === 'center' ) { // center in the region
                    current.x = bounds.x + (
                        bounds.width / 2  -  current.width / 2
                    );
                } else {
                    current.x = prev.x;
                }
            }
        });
        return shapes;
    }

    /**
     * ----------  Native layouting implementations ------------------------
     */

    /**
     * simpleBoxLayout
     */
    protected static simpleBoxLayout( shapes, bounds: IRectangle, layoutData: ILayoutingData ) {
        const shapesArray = values( shapes );
        const orderedHorizontally = sortBy( shapesArray, [ 'x', 'y' ]);
        const maxColumnWidth = layoutData.options.maxWidth;
        const maxShapeWidth = maxBy( orderedHorizontally, s => s.width )!.width;
        let columnsCounter = 0;
        const columnBounds = {
            x: bounds.x + layoutData.padding[3],
            y: bounds.y + layoutData.padding[0],
            width: maxShapeWidth > maxColumnWidth ? maxColumnWidth : maxShapeWidth,
            height: bounds.height - layoutData.padding[0] - layoutData.padding[2],
        };
        orderedHorizontally.forEach(( current: IShapeModel, i ) => {
            if ( columnsCounter >=  layoutData.options.maxVerticalDepth ) {
                const currentRectWithImage = current.getBoundsWithImages();
                let imagePadding = 0;
                if ( currentRectWithImage.height > Math.round( current.height )) {
                    imagePadding = currentRectWithImage.height - current.height;
                }
                columnsCounter = 1;
                columnBounds.x = columnBounds.x + columnBounds.width + layoutData.spacing[ 1 ];
                columnBounds.y = bounds.y + imagePadding + layoutData.padding[0];
            } else {
                columnsCounter++;
            }
            if ( columnsCounter === 1 ) {
                    const currentRectWithImage = current.getBoundsWithImages();
                    let imagePadding = 0;
                    if ( currentRectWithImage.height > current.height ) {
                        imagePadding = currentRectWithImage.height - current.height;
                    }
                    current.x = columnBounds.x;
                    current.y = columnBounds.y + imagePadding;
                    current.scaleX = columnBounds.width / current.defaultBounds.width;
            }
            const prev = orderedHorizontally[ i - 1 ];
            if ( prev && columnsCounter !== 1 ) {
                const currentRectWithImage = current.getBoundsWithImages();
                let imagePadding = 0;
                if ( currentRectWithImage.height > current.height ) {
                    imagePadding = currentRectWithImage.height - current.height;
                }
                current.x = columnBounds.x;
                current.y = prev.y + prev.height + imagePadding + layoutData.spacing[ 0 ];
                if ( current.minBounds && ( current.minBounds.width > prev.width )) {
                    current.scaleX = current.minBounds.width / current.defaultBounds.width;
                } else {
                    current.scaleX = prev.width / current.defaultBounds.width;
                }
            }
        });
        return shapes;
    }

    /**
     * layout base on the layoutIndex property.
     */
    protected static indexBaseVerticalListLayout( shapes, bounds: IRectangle, layoutData: ILayoutingData ) {
        const shapesArray = values( shapes );
        const orderedVertically = sortBy( shapesArray, s => s.layoutIndex );
        orderedVertically.forEach(( current: IShapeModel, i ) => {
            if ( i === 0 ) {
                const currentRectWithImage = current.getBoundsWithImages();
                let imagePadding = 0;
                if ( currentRectWithImage.height >  Math.round( current.height )) {
                    imagePadding = currentRectWithImage.height - current.height;
                }
                current.y = bounds.y + imagePadding + layoutData.padding[0];
                if ( layoutData.options.nodeBehavior === 'fill' ) { // fit to the region width padding
                    const effectiveWidth = ( bounds.width - layoutData.padding[3] - layoutData.padding[1]);
                    if ( current.minBounds && ( current.minBounds.width > effectiveWidth )) {
                        current.scaleX = current.minBounds.width / current.defaultBounds.width;
                    } else {
                        current.scaleX = effectiveWidth / current.defaultBounds.width;
                    }
                    current.autoResizeWidth = false;
                    const fixedAspectRatio = ( current.transformSettings || {} as any ).fixAspectRatio;
                    if ( fixedAspectRatio ) {
                        current.scaleY = current.scaleX;
                    }
                    current.x = bounds.x + layoutData.padding[3];
                } else if ( layoutData.options.nodeBehavior === 'center' ) { // center in the region
                    current.x = bounds.x + (
                        bounds.width / 2  -  current.width / 2
                    );
                } else {
                    current.x = bounds.x + layoutData.padding[3];
                }
            }
            const prev = orderedVertically[ i - 1 ];
            if ( prev ) {
                const currentRectWithImage = current.getBoundsWithImages();
                let imagePadding = 0;
                if ( currentRectWithImage.height > current.height ) {
                    imagePadding = currentRectWithImage.height - current.height;
                }
                current.y = prev.y + prev.height + imagePadding + layoutData.spacing[ 0 ];
                if ( layoutData.options.nodeBehavior === 'fill' ) { // fit to the region width padding
                    if ( current.minBounds && ( current.minBounds.width > prev.width )) {
                        current.scaleX = current.minBounds.width / current.defaultBounds.width;
                    } else {
                        current.scaleX = prev.width / current.defaultBounds.width;
                    }
                    current.autoResizeWidth = false;
                    const fixedAspectRatio = ( current.transformSettings || {} as any ).fixAspectRatio;
                    if ( fixedAspectRatio ) {
                        current.scaleY = current.scaleX;
                    }
                    current.x = prev.x;
                } else if ( layoutData.options.nodeBehavior === 'center' ) { // center in the region
                    current.x = bounds.x + (
                        bounds.width / 2  -  current.width / 2
                    );
                } else {
                    current.x = prev.x;
                }
            }
        });
        return shapes;
    }


    /**
     * nestedSnapToCenter
     */
    protected static nestedSnapToCenter( shapes, bounds: IRectangle, layoutData: ILayoutingData ) {
        let shapesArray = values( shapes );

        values( shapes ).forEach( parent => {
            const children = shapesArray.filter( shape => shape !== parent
                && shape.y < parent.y + parent.height && shape.y > parent.y );
            shapesArray = shapesArray.filter( s => !children.includes( s ));
        });

        const nestedShapes = {};
        shapesArray.forEach( shape => {
            Object.assign( nestedShapes, this.buildNestedStructure( values( shapes ), shape ));
        });

        const initX = bounds.x + ( bounds.width / 2 );
        this.drawNestedStructure( values( shapes ), nestedShapes, initX );

        return shapes;
    }

    /** Helpers for nestedSnapToCenter for recursive calls */
    protected static buildNestedStructure( shapes, parentShape ) {
        const nestedStructure = {};
        if ( !shapes ) {
            return { [parentShape.id] : nestedStructure };
        }
        const childShapes = shapes.filter( shape => shape !== parentShape
            && shape.y < parentShape.y + parentShape.height && shape.y > parentShape.y );
        const nextLevel = [];
        childShapes.forEach( child => {
            const nest = this.buildNestedStructure( shapes, child );
            nextLevel.push( ...Object.keys( nest[ child.id ]));
            Object.assign( nestedStructure, nest );

        });
        const overlap = intersection( nextLevel, Object.keys( nestedStructure ));
        overlap.forEach( key => delete nestedStructure[key]);
        return { [parentShape.id] : nestedStructure };
    }

    protected static drawNestedStructure( shapes, nest, nextXValue, children? ) {
        if ( !nest ) {
            return;
        }
        Object.keys( nest ).forEach( sid => {
            const shape = shapes.find( item => item.id === sid );
            shape.x = nextXValue - ( shape.width / 2 );
            this.drawNestedStructure( shapes, nest[sid], nextXValue + ( shape.width / 2 ), true );
        });
    }

}
