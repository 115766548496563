import { ApplyModifierEData } from './apply-modifier-edata.cmd';
import { SwitchTypeEditable } from './switch-type-editable.cmd';
import { UpdateEdataDefs } from './update-edata-defs.cmd';
import { UpdateEntityDefs } from './update-entity-defs.cmd';
import { CommandMapper, AbstractEDataCommandEvent, EventSource } from 'flux-core';
import { AddEntity } from './add-entity.cmd';
import { ApplyFormulas } from './apply-forumlas.cmd';
import { EDataChange } from './edata-change.cmd';
import { SyncLinkedEData } from './sync-linked-edata';
import { UpdateEDateRefChanges } from './update-edata-ref-changes.cmd';
import { UpdateEntityData } from './update-entity-data.cmd';
import { UpdateEntityLinkValue } from './update-entity-link-value.cmd';
import { UpdateEntityLinks } from './update-entity-links.cmd';
import { ImportEntities } from './import-entities.cmd';
import { AddEntityListModelToEData } from 'apps/nucleus/src/editor/diagram/command/add-entity-list-model.cmd';
import { CreateMirrorLookupField } from './create-mirror-lookup-field.cmd';
import { UpdateDataSourceMappings } from './update-data-source-mapping.cmd';
import { CreateReversedLookupField } from './create-reversed-lookup-field.cmd';
import { DeleteEntityShapes } from '../../debugger/command/delete-entity-shapes.cmd';
import { ShareEDataWithTeam } from './share-edata-with-team.cmd';
import { GetAllTeamSharedEDataInfo } from './get-all-team-share-edata-info.cmd';
import { CreateSavedSet } from './create-saved-set.cmd';
import { RemoveEntity } from './remove-entity.cmd';

// tslint:disable:member-ordering
/**
 * This class holds the defined events for all command invocations
 * that are on edata resources. Each event will associate to one or more
 * commands that hold the resource type 'edata'.
 */
export class EDataCommandEvent extends AbstractEDataCommandEvent {

    /**
     *
     *  Add an entity to the edata model
     *
     *
     */
    public static addEntity: EDataCommandEvent = new EDataCommandEvent( 'AddEntity' );

    /**
     * Updates Entity with shape dataItems
     *
     */
    public static updateEntityData: EDataCommandEvent = new EDataCommandEvent( 'UpdateEntityData' );

    /**
     * Updates Entity with refUpdates
     *
     */
    public static updateEDateRefChanges: EDataCommandEvent = new EDataCommandEvent( 'UpdateEDateRefChanges' );

    /**
     * Delete the shapes referenced by the given entityId
     *
     */
    public static deleteEntityShapes: EDataCommandEvent = new EDataCommandEvent( 'DeleteEntityShapes' );


    /**
     * Updates entitieis with link identifier values
     *
     */
    public static updateEntityLinkValue: EDataCommandEvent = new EDataCommandEvent( 'UpdateEntityLinkValue' );


    /**
     * update dataItems when told via a link
     */
    public static syncLinkedEData: EDataCommandEvent = new EDataCommandEvent( 'SyncLinkedEData' );


    /**
     * imports entities into a edata model
     */
    public static importEntities: EDataCommandEvent = new EDataCommandEvent( 'ImportEntities', EventSource.USER );


    /**
     * update external data source mappings
     */
    public static updateDataSourceMappings: EDataCommandEvent = new EDataCommandEvent( 'UpdateDataSourceMappings' );

    /**
     * Share with team( Organization wise Share )
     */
    public static shareEDataWithTeam: EDataCommandEvent = new EDataCommandEvent( 'ShareEDataWithTeam' );

    /**
     * Get all team shared database basic information.
     */
    public static getAllTeamSharedEDataInfo: EDataCommandEvent = new EDataCommandEvent( 'GetAllTeamSharedEDataInfo' );

    protected static registerAddEntity( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.addEntity )
            .add( AddEntity as any )
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }
    protected static registerUpdateEntityData( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.updateEntityData )
            .add( UpdateEntityData as any )
            .add( ApplyFormulas as any, {
                transform( progress ) {
                    return { ...progress.eventData };
                },
            })
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Updates dataDefs property in the edata model
     *
     */
    public static updateEdataDefs: EDataCommandEvent = new EDataCommandEvent( 'updateEdataDefs' );
    protected static registerupdateEdataDefs( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.updateEdataDefs )
            .add( UpdateEdataDefs as any )
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Updates the EntityDefs in the edata model
     *
     */
    public static updateEntityDefs: EDataCommandEvent = new EDataCommandEvent( 'UpdateEntityDefs', EventSource.USER );
    protected static registerUpdateEntityDefs( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.updateEntityDefs )
            .add( UpdateEntityDefs as any )
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Updates the EntityDefs in the edata model
     *
     */
    public static updateEntityDefsAndCreateMirrorFields: EDataCommandEvent =
        new EDataCommandEvent( 'updateEntityDefsAndCreateMirrorFields', EventSource.USER );
    protected static registerUpdateEntityDefsAndCreateMirrorFields( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.updateEntityDefsAndCreateMirrorFields )
            .add( UpdateEntityDefs as any )
            .add( CreateReversedLookupField as any, {
                transform( progress ) {
                    return {
                        entityDefId: progress.eventData.entityDefId,
                    };
                },
            })
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Add mirror lookup fields to entity type
     */
    public static createMirrorLookupField: EDataCommandEvent = new EDataCommandEvent( 'createMirrorLookupField' );
    protected static registerCreateMirrorLookupField( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.createMirrorLookupField )
            .add( CreateMirrorLookupField as any )
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Add saved set
     */
    public static createSavedSet: EDataCommandEvent = new EDataCommandEvent( 'createSavedSet' );
    protected static registerCreateSavedSet( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.createSavedSet )
            .add( CreateSavedSet as any );
    }

    /**
     * Make the entity blueprint updateable via shape data editor
     */
    public static switchTypeEditable: EDataCommandEvent = new EDataCommandEvent( 'SwitchTypeEditable' );
    protected static registerSwitchTypeEditable( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.switchTypeEditable )
            .add( SwitchTypeEditable as any )
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    /**
     * Updates Entity with links
     *
     */
    public static updateEntityLinks: EDataCommandEvent = new EDataCommandEvent( 'UpdateEntityLinks' );
    protected static registerUpdateEntityLinks( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.updateEntityLinks )
            .add( UpdateEntityLinks as any )
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    protected static registerImportEntities( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.importEntities )
            .add( ImportEntities as any )
            .add( EDataChange as any );
    }

    protected static registerUpdateDataSourceMappings( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.updateDataSourceMappings )
            .add( UpdateDataSourceMappings as any )
            .add( EDataChange as any );
    }

    /**
     * Updates EData Model with a given modifier
     *
     */
    public static applyModifierEData: EDataCommandEvent = new EDataCommandEvent( 'ApplyModifierEData' );
    public static applyModifierEDataExternal: EDataCommandEvent =
        new EDataCommandEvent( 'ApplyModifierEData', EventSource.EXTERNAL );
    public static applyModifierEDataUser: EDataCommandEvent =
        new EDataCommandEvent( 'ApplyModifierEData', EventSource.USER );
    protected static registerApplyModifierEData( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.applyModifierEData )
            .add( ApplyModifierEData as any )
            .add( EDataChange as any, {
                transform( progress ) {
                    return {
                        diagramId: progress.eventData.diagramId,
                        origin: progress.eventData.origin,
                    };
                },
            });
    }

    /**
     * Updates EData Model with a given modifier
     *
     */
    public static removeEntity: EDataCommandEvent = new EDataCommandEvent( 'RemoveEntity' );
    protected static registerRemoveEntity( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.removeEntity )
            .add( RemoveEntity as any )
            .add( EDataChange as any );
    }

    /**
     * Updates EData Model with a given modifier
     *
     */
    public static addEntityListModel: EDataCommandEvent = new EDataCommandEvent( 'addEntityListModel' );
    protected static registerAddEntityListModel( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.addEntityListModel )
            .add( AddEntityListModelToEData as any )
            .add( ApplyModifierEData as any )
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    protected static registerUpdateEDateRefChanges( mapper: CommandMapper ) {
         mapper.mapSequence( EDataCommandEvent.updateEDateRefChanges )
             .add( UpdateEDateRefChanges as any )
             .add( EDataChange as any, {
                 alter( progress ) {
                     return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                 },
             });
    }

    protected static registerDeleteEntityShapes( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.deleteEntityShapes )
            .add( DeleteEntityShapes as any )
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
   }

    protected static registerUpdateEntityLinkValue( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.updateEntityLinkValue )
            .add( UpdateEntityLinkValue as any )
            .add( ApplyFormulas as any, {
                transform( progress ) {
                    return { ...progress.eventData };
                },
            })
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }
    protected static registerSyncLinkedEData( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.syncLinkedEData )
            .add( SyncLinkedEData as any )
            .add( ApplyFormulas as any, {
                transform( progress ) {
                    return { ...progress.eventData };
                },
            })
            .add( EDataChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            });
    }

    protected static registerShareEDataWithTeam( mapper: CommandMapper ) {
        mapper.mapSequence( EDataCommandEvent.shareEDataWithTeam )
            .add( ShareEDataWithTeam as any );
    }

    protected static registerGetAllTeamSharedEDataInfo( mapper: CommandMapper ) {
        mapper.map( EDataCommandEvent.getAllTeamSharedEDataInfo ).add( GetAllTeamSharedEDataInfo as any );
    }

    public constructor( event: string, source: EventSource = EventSource.SYSTEM, dataTransformer?: CallableFunction ) {
        super( event, source, dataTransformer );
    }

}
