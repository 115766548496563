import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopupWindow } from 'flux-core';
import { IEDataDef } from 'flux-definition';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { switchMapTo, take, tap } from 'rxjs/operators';
import { ObjectConvertor } from '../../../framework/edata/object-convertor.svc';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';
import { EDataModel } from '../../../base/edata/model/edata.mdl';

@Component({
    templateUrl: './setup-database-dialog.cmp.html',
    selector: 'setup-database',
    styleUrls: [ 'setup-database-dialog.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupDatabaseDialog extends PopupWindow implements OnInit, OnDestroy {

    @Input() public defs: IEDataDef[];
    @Input() public shapeDefId: string;

    public selectedDef: Subject<IEDataDef>;
    public dbName: string;
    public readonly = new BehaviorSubject( false );

    /**
     * The window overlay.
     */
    @ViewChild( 'window', { static: true }) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner', { static: true }) protected containerInner;

    /**
     * Array with all the subs.
     */
    protected subs: Array<Subscription> = [];
    private eDataModels: {[defId: string]: EDataModel } = {};

    constructor(
        private modalController: ModalController,
        private objectConvertor: ObjectConvertor,
        private ell: EDataLocatorLocator,
    ) {
        super();
    }

    /**
     * Starts the show animation.
     */
    public ngOnInit(): void {
        const sub = this.showWindow( this.container, this.containerInner ).subscribe();
        this.subs.push( sub );
        this.selectedDef = new BehaviorSubject<IEDataDef>( this.defs[0]);
        this.ell.currentProjectEDataModels().pipe(
            take( 1 ),
            tap( models => {
                const defIds = this.defs.map( d => d.defId );
                models.filter( m => defIds.includes( m.defId )).forEach( m => {
                    this.eDataModels[ m.defId ] = m;
                });
            }),
            switchMapTo( this.selectedDef ),
            tap( def => {
                if ( this.eDataModels[ def.defId ]) {
                    this.dbName = this.eDataModels[ def.defId ].name;
                    this.readonly.next( true );
                } else {
                    this.dbName = '';
                    this.readonly.next( false );
                }
            }),
        ).subscribe();
    }

    ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public closeWindow() {
        const sub = this.hideWindow( this.container, this.containerInner ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * Closes the window when the overlay is clicked.
     */
    public closeOnOverlayClick( event ) {
        const elemClass = event.target.className;
        const elemClassType = typeof( elemClass );

        if ( elemClass && elemClassType === 'string' ) {
            if ( elemClass.includes( 'modal-window-container' )) {
                this.closeWindow();
            }
        }
    }

    public createDatabase() {
        const def = ( this.selectedDef as BehaviorSubject<IEDataDef> ).getValue();
        const name = this.dbName || def.name;
        let obs;
        if ( this.eDataModels[ def.defId ]) {
            obs = this.objectConvertor.linkDatabase( this.eDataModels[ def.defId ].id );
        } else {
            obs = this.objectConvertor.createDatabase({ ...def, name });
        }
        return obs.pipe(
            tap(() => {
                this.closeWindow();
            }),
        ).subscribe();
    }
}
