import { AbstractDiagramCommandEvent, CommandMapper, Random } from 'flux-core';
import { BeginStateSync } from './begin-state-sync.cmd';
import { AddDiagramCollaborators } from './add-diagram-collaborators.cmd';
import { ChangeDiagramCollaborators } from './change-diagram-collaborators.cmd';
import { RemoveDiagramCollaborators } from './remove-diagram-collaborators.cmd';
import { TransferDiagramOwnership } from './transfer-diagram-ownership.cmd';
import { CreateDiagramSnapshot } from './create-diagram-snapshot.cmd';
import { ChangeDiagramPrivacy } from './change-diagram-privacy.cmd';
import { StartDiagramCollabSubscriptions } from './start-diagram-collab-subscriptions.cmd';
import { ExportAsPng } from './export-as-png.cmd';
import { ExportAsPngAPI } from './export-as-png-api.cmd';
import { ExportAsSvg } from './export-as-svg.cmd';
import { ExportAsSvgAPI } from './export-as-svg-api.cmd';
import { ProjectDiagramCommandEvent } from '../../../creator/project/command/project-diagram-command.event';
import { DeleteDiagram } from './delete-diagram.cmd';
import { SelectShapes } from './select-shapes.cmd';
import { ExportAsJpeg } from './export-as-jpeg.cmd';
import { FetchEmbedImageUrl } from './fetch-embed-image-url.cmd';
import { AddComment } from '../../interaction/comments/add-comment.cmd';
import { DeleteComment } from '../../interaction/comments/delete-comment.cmd';
import { StartDiagramCommentSubscription } from './start-diagram-comment-subscription.cmd';
import { ResolveComment } from '../../interaction/comments/resolve-comment.cmd';
import { GeneratePdf } from './generate-pdf.cmd';
import { ExportAsCsv } from './export-as-csv.cmd';
import { ExportAsXmi } from './export-as-xmi.cmd';
import { ExportAsCode } from './export-as-code.cmd';
import { ChangeMouseControlState } from './change-mouse-control-state.cmd';
import { MarkCommentSeen } from '../../interaction/comments/mark-comment-seen.cmd';
import { EditComment } from '../../interaction/comments/edit-comment.cmd';
import { ResetEmbedImageToken } from './reset-embed-image-token.cmd';
import { FetchUserSubscriptionData } from './fetch-user-subscription-data.cmd';
import { StartEDataSubscription } from '../../edata/command/start-edata-subscription.cmd';
import { DocumentChange } from '../../../editor/diagram/command/document-change.cmd';
import { RestoreDocumentChange } from '../../../editor/diagram/command/restore-document-change.cmd';
import { ResetDiagramPreview } from '../../../editor/diagram/command/reset-diagram-preview.cmd';
import { RestoreDiagramSnapshot } from '../../../editor/diagram/command/restore-diagram-snapshot.cmd';
import { OpenGlobalSearch } from './open-global-search.cmd';
import { GetHelpCenterArticles } from './get-help-center-articles.cmd';
import { UpdateTask } from '../../task/commands/update-task.cmd';
import { StartTaskSubscription } from '../../task/commands/start-task-subscription.cmd';
import { SearchDiagram } from './search-diagram.cmd';
import { CheckInteractionState } from '../../../editor/diagram/command/check-interaction-state.cmd';
import { ChangeDiagramTeamShare } from './change-diagram-team-share.cmd';
import { GetTasks } from '../../task/commands/get-tasks.cmd';
import { UpdateTextBounds } from '../../../editor/diagram/command/update-text-bounds.cmd';
import { StartDiagramAndProjectSubscription } from './start-diagram-and-project-subscription.cmd';
import { AutoDrawConnector } from '../../../editor/diagram/command/auto-draw-connector.cmd';
import { ChangeInterfaceControlState } from './change-interface-control-state.cmd';
import { FetchTypesenceSearchKey } from './fetch-typesense-search-key.cmd';
import { CreatePresentation } from '../../presentation/command/create-presentation.cmd';
import { GetPresentations } from '../../presentation/command/get-presentations.cmd';
import { UpdatePresentation } from '../../presentation/command/update-presentation.cmd';
import { StartPresentationSubscription } from '../../presentation/command/start-presentation-subscription.cmd';

/**
 * This class lists commands and command sequences used at the base level.
 * @author  Ramishka
 * @since   2018-03-18
 */
// tslint:disable: member-ordering
export class BaseDiagramCommandEvent extends AbstractDiagramCommandEvent {

    // Start diagram subscription and all of its dependancies
    static startDiagramSubscription: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'StartDiagramSubscription' );
    static startDiagramSubscriptionAndDeps: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'StartDiagramSubscriptionAndDeps' );
    static addDiagramCollaborators: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'AddDiagramCollaborators' );
    static changeDiagramCollaborators: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'ChangeDiagramCollaborators' );
    static removeDiagramCollaborators: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'RemoveDiagramCollaborators' );
    static transferDiagramOwnership: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'TransferDiagramOwnership' );
    static createDiagramSnapshot: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'CreateDiagramSnapshot' );
    static changeDiagramPrivacy: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'ChangeDiagramPrivacy' );
    static changeDiagramTeamShare: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'ChangeDiagramTeamShare' );

    // start edata sub
    static startEDataSubscription: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'StartEDataSubscription' );

    static startTaskSubscription: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'StartTaskSubscription' );

    // Start presentation subscription
    static startPresentationSubscription: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'StartPresentationSubscription' );


    // Fetch user subscription data
    static fetchUserSubscriptionData: BaseDiagramCommandEvent
        = new BaseDiagramCommandEvent( 'FetchUserSubscriptionData' );

    // Export as png
    static exportAsPng: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsPng' );
    static exportAsPngAPI: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsPngAPI' );

    // Fetch embed image url
    static fetchEmbedImageUrl: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'FetchEmbedImageUrl' );

    // Reset the embed image token
    static resetEmbedImageToken: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ResetEmbedImageToken' );

    // Export as jpeg
    static exportAsJpeg: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsJpeg' );

    // Export as svg
    static exportAsSvg: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsSvg' );
    static exportAsSvgAPI: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsSvgAPI' );

    /**
     * Export as PDF
     */
    static exportAsPdf: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsPdf' );

    // Export as csv
    static exportAsCsv: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsCsv' );

    // Export as xmi
    static exportAsXmi: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsXmi' );

    // Export as Java
    static exportAsCode: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ExportAsCode' );

    static selectShapes: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'SelectShapes' );
    // Add Comment
    static addComment: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'AddComment' );
    // Edit Comment
    static editComment: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'EditComment' );
    // Delete Comment
    static deleteComment: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'DeleteComment' );

    // Resolve Comment Thread
    static resolveComment: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ResolveComment' );

    // Mark Comment Seen By
    static markCommentSeen: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'MarkCommentSeen' );


    // Add Task
    static addTask: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'AddTask' );

    /**
     * Delete diagram.
     */
    static deleteDiagram: ProjectDiagramCommandEvent = new ProjectDiagramCommandEvent( 'DeleteDiagram' );

    /**
     * Search diagrams through Creately-Search service
     */
    static searchDiagram: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'SearchDiagram' );

    static createPresentation: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'CreatePresentation' );
    static updatePresentation: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'UpdatePresentation' );

    /**
     * This command is registered here to transform the shapes when
     * user is in the view and edit modes.
     */
    public static documentChange = new BaseDiagramCommandEvent( 'DocumentChange' );

    /**
     * This command is change the interface control state
     */
    public static changeInterfaceControlState = new BaseDiagramCommandEvent( 'ChangeInterfaceControlState' );

    /**
     * This command is registered here to be used in snapshot preview
     */
    public static resetSnapshotPreview = new BaseDiagramCommandEvent( 'ResetSnapshotPreview' );

    /**
     * This command is registered here to be used in snapshot restore
     */
    public static restoreSnapshot = new BaseDiagramCommandEvent( 'RestoreDiagramSnapshot' );

    // Change mouse control state
    static changeMouseControlState: BaseDiagramCommandEvent = new BaseDiagramCommandEvent( 'ChangeMouseControlState' );

    /**
     * Global search
     *
     *  Input Data: {
     *      option: string,
     *  }
     */
    public static openGlobalSearch = new BaseDiagramCommandEvent( 'OpenGlobalSearch' );

    /**
     * Help center articles search
     *
     *  Input Data: {
     *      searchQuery: string,
     *      numberOfArticle: number,
     *  }
     */
    public static helpCenterArticles = new BaseDiagramCommandEvent( 'HelpCenterArticles' );

    public static getTasks = new BaseDiagramCommandEvent( 'GetTasks' );

    public static getPresentations = new BaseDiagramCommandEvent( 'GetPresentations' );

    public static fetchTypesenceSearchKey: BaseDiagramCommandEvent
                = new BaseDiagramCommandEvent( 'FetchTypesenceSearchKey' );

    public static register( mapper: CommandMapper ) {
        mapper.mapSequence( BaseDiagramCommandEvent.startDiagramSubscription )
            .add( <any>StartDiagramAndProjectSubscription );
        mapper.mapSequence( BaseDiagramCommandEvent.startDiagramSubscriptionAndDeps )
            .add( <any>StartDiagramAndProjectSubscription )
            .add( <any>BeginStateSync, {
                alter( progress ) {
                    return true;
                },
            })
            .add( <any>StartDiagramCollabSubscriptions )
            .add( <any>StartDiagramCommentSubscription )
            .add( <any>GetTasks )
            .add( <any>GetPresentations, {
                transform( progress ) {
                    return {
                        projectId: progress.resultData[0]?.projectId,
                    };
                },
            })
            .add( <any>StartEDataSubscription, {
                transform( progress ) {
                    return {
                        projectId: progress.resultData[0]?.projectId,
                        diagramId: progress.resultData[0]?.status?.input?.resourceId,
                    };
                },
            })
            .add( <any>StartTaskSubscription )
            .add( <any>StartPresentationSubscription, {
                transform( progress ) {
                    return {
                        projectId: progress.resultData[0]?.projectId,
                    };
                },
            });


        mapper.mapSequence( BaseDiagramCommandEvent.startEDataSubscription )
            .add( StartEDataSubscription as any );

        mapper.mapSequence( BaseDiagramCommandEvent.startTaskSubscription )
            .add( StartTaskSubscription as any );

        mapper.mapSequence( BaseDiagramCommandEvent.startPresentationSubscription )
            .add( StartPresentationSubscription as any );


        // TODO: Nees to send a command to start the user subsciption before UpdateDiagramCollaborators
        mapper.mapSequence( BaseDiagramCommandEvent.addDiagramCollaborators )
            .add( AddDiagramCollaborators as any );
        mapper.map( BaseDiagramCommandEvent.removeDiagramCollaborators )
            .add( RemoveDiagramCollaborators as any );
        mapper.map( BaseDiagramCommandEvent.changeDiagramCollaborators ).add( ChangeDiagramCollaborators as any );
        mapper.map( BaseDiagramCommandEvent.transferDiagramOwnership ).add( TransferDiagramOwnership as any );
        mapper.map( BaseDiagramCommandEvent.createDiagramSnapshot ).add( CreateDiagramSnapshot as any );
        mapper.mapSequence( BaseDiagramCommandEvent.changeDiagramPrivacy )
            .add( ChangeDiagramPrivacy as any );
        mapper.mapSequence( BaseDiagramCommandEvent.changeDiagramTeamShare )
            .add( ChangeDiagramTeamShare as any );
        // Register png exporter
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsPng )
            .add( ExportAsPng as any );
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsPngAPI )
            .add( ExportAsPngAPI as any );
        // Register jpeg exporter
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsJpeg )
            .add( ExportAsJpeg as any );
        // Register the user subscription data fetcher
        mapper.mapSequence( BaseDiagramCommandEvent.fetchUserSubscriptionData )
            .add( FetchUserSubscriptionData as any );
        // Register the embed image fetcher
        mapper.mapSequence( BaseDiagramCommandEvent.fetchEmbedImageUrl )
            .add( FetchEmbedImageUrl as any );
        // Register the embed image token resetter.
        mapper.mapSequence( BaseDiagramCommandEvent.resetEmbedImageToken )
            .add( ResetEmbedImageToken as any );
        // Register svg exporter
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsSvg )
            .add( UpdateTextBounds as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( ExportAsSvg as any );
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsSvgAPI )
            .add( UpdateTextBounds as any )
            .add( ExportAsSvgAPI as any );
        // Register pdf exporter
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsPdf )
            .add( UpdateTextBounds as any )
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( GeneratePdf as any );
        // Register csv exporter
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsCsv )
            .add( ExportAsCsv as any );
        // Register xmi exporter
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsXmi )
            .add( ExportAsXmi as any );
        // Register java exporter
        mapper.mapSequence( BaseDiagramCommandEvent.exportAsCode )
            .add( ExportAsCode as any );

        // Register delete diagram
        mapper.map( BaseDiagramCommandEvent.deleteDiagram ).add( DeleteDiagram as any );
        // Register select shape command
        mapper.map( BaseDiagramCommandEvent.selectShapes ).add( SelectShapes as any );
        // Register add comment command
        mapper.map( BaseDiagramCommandEvent.addComment ).add( AddComment as any );
        // Register add comment command
        mapper.map( BaseDiagramCommandEvent.editComment ).add( EditComment as any );
        // Register delete comment command
        mapper.map( BaseDiagramCommandEvent.deleteComment ).add( DeleteComment as any );
        // Register resolve comment thread command
        mapper.map( BaseDiagramCommandEvent.resolveComment ).add( ResolveComment as any );
        // Register mark comment seen by command
        mapper.map( BaseDiagramCommandEvent.markCommentSeen ).add( MarkCommentSeen as any );

        // Register addTask command
        mapper.map( BaseDiagramCommandEvent.addTask ).add( UpdateTask as any );

        // Register create presentation command
        mapper.map( BaseDiagramCommandEvent.createPresentation ).add( CreatePresentation as any );
        mapper.map( BaseDiagramCommandEvent.updatePresentation ).add( UpdatePresentation as any );


        /**
         * Change the MouseControlState according to the given data
         * At least one data of the following is required.
         *  Input Data: {
         *      state: MouseControlState,
         *      keyboardEvent: KeyboardEvent,
         *  }
         */
        mapper.mapSequence( BaseDiagramCommandEvent.changeMouseControlState )
            .add( CheckInteractionState as any )
            .add( ChangeMouseControlState as any, {
                transform( progress ) {
                    return progress.eventData;
                },
            })
            .add( AutoDrawConnector as any, {
                transform( progress ) {
                    return { mouseState: progress.eventData.state, id: Random.sessionId() };
                },
            })
            .add( DocumentChange as any, {
                alter( progress ) {
                    return progress.stepName === 'RunExecutionStep' && progress.stepStatus === 'completed';
                },
            })
            .add( SelectShapes as any, {
                transform( progress ) {
                    return { shapeIds: [ progress.data[2].id ]};
                },
            });

        // Register document change command to reflect the diagram changes on view and edit modes.
        mapper.map( BaseDiagramCommandEvent.documentChange ).add( DocumentChange as any );

        // Register diagram reset preview to be used in snapshot preview
        mapper.map( BaseDiagramCommandEvent.resetSnapshotPreview ).add( ResetDiagramPreview as any );

        // Register diagram snapshot restore
        mapper.map( BaseDiagramCommandEvent.restoreSnapshot )
            .add( RestoreDiagramSnapshot as any )
            .add( RestoreDocumentChange as any );

        // Register global search command to open on key board shortcut.
        mapper.map( BaseDiagramCommandEvent.openGlobalSearch ).add( OpenGlobalSearch as any );

        // Register get help center articles command
        mapper.map( BaseDiagramCommandEvent.helpCenterArticles ).add( GetHelpCenterArticles as any );

        // Register the diagram search command
        mapper.mapSequence( BaseDiagramCommandEvent.searchDiagram )
            .add( SearchDiagram as any );

        mapper.mapSequence( BaseDiagramCommandEvent.getTasks )
            .add( GetTasks as any );

        mapper.mapSequence( BaseDiagramCommandEvent.changeInterfaceControlState )
            .add( ChangeInterfaceControlState as any );

        mapper.mapSequence( BaseDiagramCommandEvent.fetchTypesenceSearchKey )
            .add( FetchTypesenceSearchKey as any );

        // Register get presentations
        mapper.mapSequence( BaseDiagramCommandEvent.getPresentations )
            .add( GetPresentations as any );
    }
}
