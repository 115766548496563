import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UserLocator } from '../../user/user-locator.svc';
import { Crisp } from 'crisp-sdk-web';
import { AppConfig } from 'flux-core';

/**
 * Any action on the Crisp API must be performed via this class and
 * this will be the wrapper for Crisp functionality
 */
@Injectable()
export class CrispChat {

    public tokenId: string;

    constructor( protected userLocator: UserLocator ) {
        this.userLocator.getUserData().pipe(
            filter( user => !!user ),
        ).subscribe( user => {
            this.setUser( user.fullName, user.email );
            this.tokenId = btoa( user.id );
        });
    }

    /**
     * Method that loads Crisp chat and keeps it hidden.
     * TokenID is set to keep session continuity
     */
    public load() {
        Crisp.configure( AppConfig.get( 'CRISP_CHAT' ));
        Crisp.chat.hide();
        Crisp.setTokenId( this.tokenId );
    }

    /**
     * Method that shows and opens the Crisp chat widget.
     */
    public open() {
        Crisp.chat.show();
        Crisp.chat.open();
    }

    /**
     * Method that searches and returns help articles as an array.
     * @param query article search query
     */
    public search( query: string ): Promise<any> {
        return new Promise( resolve => {
            Crisp.chat.queryHelpdesk( query );
            Crisp.chat.onHelpdeskQueried( data => {
                // Executed once a helpdesk search has been queried
                if ( data.results.length > 0 ) {
                    resolve( data.results );
                } else {
                    resolve([]); // Resolve with an empty array if no results
                }
            });
        });
    }

    /**
     * Method that hides Crisp chat when the chat is closed
     */
    public setupChatClosedEvent() {
        Crisp.chat.onChatClosed(() => {
            Crisp.chat.hide();
        });
    }

    /**
     * Method that redirects to the crisp help center
     */
    public helpCenter() {
        window.open( 'https://support.creately.com', '_blank' );
    }

    /**
     * Method that adds user details to Crisp
     * @param fullName full name of the app user
     * @param email email of the app user
     */
    private setUser( fullName: string, email: string ) {
        Crisp.user.setEmail( email );
        Crisp.user.setNickname( fullName );
    }

}
