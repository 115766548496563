
import { TextDataModel } from '../../shape/model/text-data.mdl';
import { PreviewDiagramLocator } from '../../diagram/locator/preview-diagram-locator';
import { IModifier, Logger, ModifierUtils, StateService } from 'flux-core';
import { ShapeType } from 'flux-definition';
import { forEach, merge as lodashMerge, values } from 'lodash';
import { combineLatest, concat, empty, from, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, flatMap, map, tap, switchMap, take, debounceTime } from 'rxjs/operators';
import { AbstractShapeView } from '../../framework/view/abstract-shape-view';
import { AbstractShapeModel } from '../../shape/model/abstract-shape.mdl';
import { ConnectorDataModel } from '../../shape/model/connector-data-mdl';
import { ShapeDataModel } from '../../shape/model/shape-data.mdl';
import { ConnectorRenderView } from '../../shape/view/connector-render-view';
import { ConnectorViewFactory } from '../../shape/view/connector-view-factory';
import { ImageRenderView } from '../../shape/view/image-render-view';
import { ImageShapeViewFactory } from '../../shape/view/image-shape-view-factory';
import { ShapeRenderView } from '../../shape/view/shape-render-view';
import { ShapeViewFactory } from '../../shape/view/shape-view-factory';
import { DiagramDataModel } from '../model/diagram-data.mdl';
import { IDiagramLocator } from '../locator/diagram-locator.i';
import { fromPromise } from 'rxjs/internal-compatibility';

/**
 * This is the abstract version of visual renderer for a Diagram. The primary
 * purpose of this component is to visualize a diagram to a given plain.
 *
 * This will be extended by different types of visual rendering spaces to render
 * a diagram.
 *
 * @author hiraash
 * @since 2017-09-05
 */

// FIXME: Ignoring as tests are not written for this class
/* istanbul ignore file */
export abstract class DiagramRenderView {

    /**
     * Subscriptions that need to be destroyed.
     */
    protected _subs: Array<Subscription>;

    /**
     * This subject will emit true and completes
     * When all the shapes in the diagram are created ( when the diagram is loaded )
     */
    protected allShapesCreated: Subject<boolean>;

    /**
     * A flag that indicates if the view has been redrawn
     * and needs to be painted.
     */
    protected _changed: boolean = false;

    /**
     * List of shape views currently rendering/renderable.
     * This includes both shapes and connectors.
     */
    private shapes: { [id: string]: AbstractShapeView };

    constructor(
        protected state: StateService<any, any>,
    ) {
        this.shapes = {};
        this._subs = [];
        this.allShapesCreated = new Subject();
    }

    /**
     * The display name of the diagram as per the model.
     */
    public get name(): Observable<string> {
        return this.locator.pipe(
            switchMap( locator => locator.getDiagramName()),
        );
    }

    /**
     * The observable locator instance to get diagram data from.
     */
    protected abstract get locator(): Observable<IDiagramLocator<DiagramDataModel, AbstractShapeModel>>;


    /**
     * The type of shape view that should be created for the diagram view
     * This must be overridden by any extending diagram view and must provide
     * the shape view type that is supposed to be used for rendering shapes.
     * The type provided for the shape view must extend the ShapeRenderView
     */
    protected abstract get shapeViewType(): typeof ShapeRenderView;

    /**
     * The type of connector view that should be created for the diagram view
     * This must be overridden by any extending diagram view and must provide
     * the connector view type that is supposed to be used for rendering shapes.
     * The type provided for the connector view must extend the ConnectorRenderView
     */
    protected abstract get connectorViewType(): typeof ConnectorRenderView;

    /**
     * The type of image view that should be created for the diagram view.
     * This must be overridden by any extending diagram view and must provide
     * the image view type that is supposed to be used for rendering shapes
     * that are based on custom images.
     * The type provided for the image view must extend the ImageRenderView
     */
    protected abstract get imageViewType(): typeof ImageRenderView;

    /**
     * The method that populates the diagram view as per the diagram model.
     * TODO: these were piped to 'true' before, check whether it's needed.
     */
    public populateDiagram(): Observable<unknown> {
        const initShapes = {};
        this.allShapesCreated = new Subject();
        return this.locator.pipe(
            switchMap( locator =>
                concat (
                    locator.getCurrentShapes().pipe( tap( s => s.map( v => initShapes[ v.id ] = true ))),
                    locator.getAddedShapes()).pipe(
                    concatMap( models => from( models )),
                    flatMap( model => (
                        concat(
                            // TODO: after updating typescript, replace "as any" with "as const"
                            of({ type: 'create' as any, data: { model }}),
                            locator.getShapeChanges( model.id ).pipe(
                                // TODO: after updating typescript, replace "as any" with "as const"
                                map( value => ({ type: 'update' as any, data: value })),
                            ),
                            // TODO: after updating typescript, replace "as any" with "as const"
                            of({ type: 'remove' as any, data: { id: model.id }}),
                        ).pipe(
                            // TODO: after updating typescript, remove "as any", it'll pick correct types
                            concatMap(( action: any ) => {
                                if ( action.type === 'create' ) {
                                    return this.handleCreate( action.data.model ).pipe( tap({ complete: () => {
                                        delete initShapes[ action.data.model.id ];
                                    }}));
                                } else if ( action.type === 'update' ) {
                                    return this.handleUpdate( action.data.model, action.data.modifier );
                                } else {
                                    return this.handleRemove( action.data.id );
                                }
                            }),
                            catchError( err => {
                                Logger.error( err );
                                return empty();
                            }),
                        )
                    )),
            )),
            debounceTime( 100 ),
            tap(() => {
                if ( !this.allShapesCreated.closed && Object.keys( initShapes ).length === 0 ) {
                    this.allShapesCreated.next( true );
                    this.allShapesCreated.unsubscribe();
                    this.state.set( 'DiagramLoadComplete', true );
                }
            }),
        );
    }

    /**
     * Destroys all resources associated to this view.
     */
    public destroy() {
        this._subs.forEach( sub => sub.unsubscribe());
        forEach( this.shapes, shape => this.removeShape( shape.id ));
    }

    /**
     * This function gets called whenever a shapes zIndex value changes.
     * This must be overridden by any extending diagram view to handle zIndex changes.
     * @param shapeId - id of the shape that zIndex changed
     * @param zIndex - changed zIndex value
     */
    protected abstract handleZIndexChange ( shapeId: string, zIndex: number ): void;

    /**
     * This method ultimately redraws all the shapes on the given
     * drawing space. The method would ensure that all data changes
     * are translated into the drawing space as intended by each shape.
     * This means the full diagram will be up-to-date on the drawing space
     * when this method completes until the next data change on any model.
     * @param shape The shape view that needs to trigger render.
     * @param modifier A mongo like modifier describing the change.
     */
    protected render( shape: AbstractShapeView, modifier?: IModifier ) {
        shape.render( modifier );
    }

    /**
     * Returns a map of shape views which are currently available under this diagram view.
     */
    protected getShapeViews() {
        return this.shapes;
    }

    /**
     * Returns a map of shape views for provided shapes
     * @param shapeIds shape ids
     */
    protected getShapeViewsByShapeIds( shapeIds: string[]) {
        return values( this.shapes ).filter( shape => shapeIds.includes( shape.id ));
    }

    /**
     * This method gets called when a new shape view has to be added to the diagram.
     * Returned observable will complete without emitting when the shape view is ready.
     */
    protected handleCreate( model: AbstractShapeModel ): Observable<unknown> {
        const shape = this.shapes[ model.id ];
        if ( shape ) {
            throw new Error( 'unexpected error: shape already exists' );
        }
        return combineLatest(
            this.createShape( model ),
            this.locator.pipe(
                    take( 1 ),
                    switchMap( locator => locator.getDiagramOnce()),
                ),
        ).pipe(
            tap<[ AbstractShapeView, DiagramDataModel ]>(([ view, diagram ]) => {
                if ( model && model.texts ) {
                    for ( const key in model.texts ) {
                        const t = model.texts[ key ];
                        if ( t && !( t instanceof TextDataModel )) {
                            Logger.error( `DiagramRenderView.handleCreate( ‘Text model not deserialized properly’)` );
                            const l = this.locator instanceof PreviewDiagramLocator ? 'Preview' : 'Stored';
                            Logger.error( `DiagramRenderView.handleCreate, Locator: ${l}, Shape name: ${model.defId}` );
                            model.texts[ key ] = lodashMerge( model.createText() , t );
                        }
                    }
                }
                view.updateModel( model );
                this.addShape( view );
                this.render( view );
            }),
        );
    }

    /**
     * This method gets called when the shape model has changed and the view has to update.
     * Returned observable will complete without emitting when the shape view is updated.
     */
    protected handleUpdate( model: AbstractShapeModel, modifier: IModifier ): Observable<unknown> {
        const view = this.shapes[ model.id ];
        if (
            !this.hasChangedProp( modifier, 'entryClass', view ) &&
            !this.hasChangedProp( modifier, 'defId', view ) &&
            !this.hasChangedProp( modifier, 'version', view )
        ) {
            this.updateShape( view, model, modifier );
            this.render( view, modifier );
            return empty();
        }
        return combineLatest(
            this.createShape( model ),
            this.locator.pipe(
                    take( 1 ),
                    switchMap( locator => locator.getDiagramOnce()),
                ),
        ).pipe(
            tap<[ AbstractShapeView, DiagramDataModel ]>(([ createdView, diagram ]) => {
                this.replaceShape( model.id, createdView );
                this.render( createdView, modifier );
            }),
        );
    }

    protected hasChangedProp( modifier: IModifier, prop: string, view: AbstractShapeView ) {
        if ( ModifierUtils.hasChanges( modifier, prop )) {
            return modifier?.$set[ prop ] !==  view?.model[ prop ];
        }
        return false;
    }

    /**
     * This method gets called when the shape model has been removed and the view has to be removed.
     * Returned observable will complete without emitting when the shape view is removed.
     */
    protected handleRemove( shapeId: string ): Observable<unknown> {
        this.removeShape( shapeId );
        return empty();
    }

    /**
     * Adds a shape view to the diagram view and makes it
     * ready for rendering.
     * @param shape The shape view to add.
     * @param viewIndex - the order of this shape in the view
     */
    protected addShape( shape: AbstractShapeView ) {
        this.shapes[ shape.id ] = shape;
        const val = shape.initialize() as any;
        if ( val && val instanceof Promise ) {
            fromPromise( val ).subscribe(() => this._changed = true );
        }
    }

    /**
     * Updates a shape that is already added to the view.
     * @param id - shape id to be updated
     * @param change - shape change that triggered the update
     */
    protected updateShape( shape: AbstractShapeView, model: AbstractShapeModel, modifier: IModifier ): void {
        shape.updateModel( model );
        if ( ModifierUtils.hasChanges( modifier, 'zIndex' )) {
            this.handleZIndexChange( shape.id, model.zIndex );
        }
    }

    /**
     * Removes the shape from the diagram view and gracefully
     * clears all dependancies.
     * @param shape Shape to be removed.
     */
    protected removeShape( id: string ) {
        const shape = this.shapes[ id ];
        shape.destroy();
        delete this.shapes[ shape.id ];
    }

    /**
     * Creates a shape view of type shapeViewType for the model passed in.
     * Uses the ShapeViewFactory to create the shape.
     * @param shape the model for which the view must be created.
     */
    protected createShape( shape: AbstractShapeModel ): Observable<AbstractShapeView> {
        if ( shape.type === ShapeType.Connector ) {
            return ConnectorViewFactory.instance.create( shape as ConnectorDataModel, this.connectorViewType );
        } else if ( shape.type === ShapeType.Image ) {
            return ImageShapeViewFactory.instance.create( shape as ShapeDataModel, this.imageViewType );
        } else if ( shape.type === ShapeType.Freehand ) {
            return ShapeViewFactory.instance.createView( shape, this.shapeViewType );
        } else {
            return ShapeViewFactory.instance.create( shape, this.shapeViewType );
        }
    }

    /**
     * Replaces a shape view identified by the shape id.
     */
    private replaceShape( id: string, view: AbstractShapeView ): void {
        this.removeShape( id );
        this.addShape( view );
    }
}
