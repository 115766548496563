import { Injectable } from '@angular/core';
import { Command, StateService } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { DiagramChangeService } from '../../../base/diagram/diagram-change.svc';

/**
 * UpdateShapeEditors command updating Editors with current user and time
 */
@Injectable()
@Command()
export class UpdateShapeEditors extends AbstractDiagramChangeCommand {

    constructor(
        protected ds: DiagramChangeService,
        protected state: StateService<any, any>,
    ) {
        super( ds );
    }
    /**
     * Prepares the data necessary for update editors
     */
    public prepareData() {
        const shapeIds = this.data?.shapeIds || Object.keys( this.changeModel.shapes );
        const changes = this.changeModel.__sakota__.getChanges();
        shapeIds.forEach( id => {
            const shape = this.changeModel.shapes[id];
            if ( !( shape instanceof ShapeModel )) {
                return;
            }
            if ( changes?.$set?.['shapes.' + id + '.data.reactions'] ||
                changes?.$set?.['shapes.' + id + '.data.reactions.value']) {
                return; // No editors change on reactions add
            }
            const editedBy = shape.editedBy ? shape.editedBy : [];
            const entry = editedBy
                .find( item => {
                    if ( item.userId === this.state.get( 'CurrentUser' )) {
                        item.time = new Date().getTime();
                        return true;
                    }
                });
            if ( !entry ) {
                editedBy.push({
                    userId: this.state.get( 'CurrentUser' ),
                    time: new Date().getTime(),
                });
            }
            shape.editedBy = editedBy;
        });
    }

}

Object.defineProperty( UpdateShapeEditors, 'name', {
    value: 'UpdateShapeEditors',
});
