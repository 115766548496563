import { TextDataModel } from 'flux-diagram-composer';
import { Logger } from 'flux-core';
import { ModifierUtils, StateService } from 'flux-core';
import { IRectangle, IText } from 'flux-definition';
import { DisplayObject, Shape } from '@creately/createjs-module';
import { Rectangle } from 'flux-core';
import html2canvas from 'html2canvas';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { TiptapDocumentsManagerShapeText } from '../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { InteractionSubContext } from '../../base/interaction/interaction-handler';
import { ICursor } from '../../base/interaction/cursor-drawable.i';
import { get } from 'lodash';

// tslint:disable:member-ordering
/**
 * TiptapTextView is to render tiptap content on the canvas by means of the
 * html2canvas module
 * Tiptap content is fetched from Tiptapdoc created for ShapeTexts - TiptapDocumentsManagerShapeText
 */
export class TiptapTextView extends DisplayObject {

    public static state: StateService<any, any>;

    /**
     * The width of the text to start text wrapping
     */
    public lineWidth: number;

    public _html: string;

    protected bounds;
    protected transfrom;
    protected change;

    protected diagramId;

    protected renderingScale = 2;

    public _width: number;
    public _height: number;

    constructor(  protected model: TextDataModel, protected shapeId ) {
        super();
        this.html = model.html;
        this.name = model.id;
        this.diagramId = TiptapTextView.state?.get( 'CurrentDiagram' );
        this.hitArea = new Shape();
    }

    public set html( value: string ) {
        this._html =  value;
    }
    public set width( value: number ) {
        this._width =  value;
    }


    /**
     * Overrding the easeljs DisplayObject's draw function
     */
    public draw( ctx: CanvasRenderingContext2D, ignoreCache: boolean = false ) {
        if ( !this._html ) {
            return true;
        }
        if ( super.draw( ctx, ignoreCache )) {
            return true;
        }
        TiptapDocumentsManagerShapeText.initialized.pipe(
            filter( dId => this.diagramId === dId ),
            take( 1 ),
            switchMap(() =>
                TiptapDocumentsManagerShapeText.getHtmlElement( this.diagramId, this.shapeId, this.model.id )),
            tap(( domNode: HTMLElement ) =>  {
                if ( !( domNode )) {
                    return;
                }
                const textDomVisible = TiptapDocumentsManagerShapeText
                    .isShapeTextDomViewVisible( this.diagramId, this.shapeId, this.model.id );
                if ( !textDomVisible ) {
                    this.prepareHTMLNode();
                }

                // Added to fix text position issue
                // https://github.com/niklasvh/html2canvas/issues/2775
                const s = document.createElement( 'style' );
                document.head.appendChild( s );
                s.sheet?.insertRule( 'body > div:last-child img { display: inline-block; }' );

                html2canvas( domNode , {
                    useCORS: true,
                    logging: false,
                    allowTaint : true,
                    canvas: ctx.canvas as HTMLCanvasElement,
                    x: -TiptapTextView.CACHE_PADDING,
                    y: -TiptapTextView.CACHE_PADDING,
                    ignoreElements: el => {
                        const tagName = el.nodeName.toLowerCase();
                        if ( tagName === 'head' ) {
                            return false;
                        }
                        if ( el?.parentElement.nodeName === 'HEAD' ) {
                            if ( tagName === 'base' ) {
                                return false;
                            }
                            if ( tagName === 'link' ) {
                                return false;
                            }
                            if ( tagName === 'style' ) {
                                return !el.textContent.includes( '@font-face' );
                            }
                            return true;
                        }

                        if ( el.querySelector( 'shape-text-editor' )) {
                            return false;
                        } else if ( tagName === 'shape-text-editor' ) {
                            return false;
                        } else {
                            const shapeTextEditorEl = document.querySelector( 'shape-text-editor' );
                            if ( el.classList.contains( 'tiptap-table-toolbar' )) {
                                return true;
                            }
                            if ( tagName === 'tiptap-hyperlink-editor' ) {
                                return true;
                            }
                            if ( el.classList.contains( 'tiptap-child-editor' ) &&
                                el.getAttribute( 'data-editor-id' ) !== `${this.shapeId}-${this.name}` ) {
                                return true;
                            }
                            return !shapeTextEditorEl.contains( el );
                        }
                    },
                    onclone: c => {
                        const style = document.createElement( 'style' );
                        style.innerHTML = `
                            .tiptap-child-editor-content { transform: scale(1) !important; }
                            .tiptap-child-editor { display: block !important; visibility: visible !important; content-visibility: visible !important; }
                            .collaboration-cursor__caret, p > .ProseMirror-separator, p > .ProseMirror-trailingBreak { display: none !important; }
                            .ProseMirror-yjs-selection { background-color: transparent !important; }`;
                        c.body.appendChild( style );
                        if ( !textDomVisible ) {
                            this.restoreHTMLNode();
                        }
                    },
                    backgroundColor: null,
                    scale: this.renderingScale, // This needs to be fixed
                }).then( canvas => {
                    s.remove();
                    this.updatePos();
                    const { open, shapeId, textId } = TiptapTextView.state.get( 'EditingText' );
                    if ( !( open && shapeId === this.shapeId && textId === this.model.id )) {
                        TiptapDocumentsManagerShapeText.textRenderingState.next({
                            shapeId: this.shapeId,
                            state: 'completed',
                            textId: this.model.id,
                        });
                    }
                    TiptapTextView.state.set( 'UpdateDiagramCanvas', true );
                }).catch( e => {
                    s.remove();
                    TiptapDocumentsManagerShapeText.textRenderingState.next({
                        shapeId: this.shapeId,
                        state: 'failed',
                        textId: this.model.id,

                    });
                    if ( !textDomVisible ) {
                        this.restoreHTMLNode();
                    }
                });
            }),
        ).subscribe();
        return true;
    }

    /**
     * If the html text is hidden ( If the shape text editor is closed )
     * we have to make it available for rendering. By changing the zIndex, the
     * shape text element won't be visible to the user while rendering is in progress
     */
    protected prepareHTMLNode() {
        TiptapDocumentsManagerShapeText.makeShapeTextNodeRenderable(
            this.diagramId,
            this.shapeId,
            this.model.id,
        );
        TiptapDocumentsManagerShapeText.setShapeTextNodeZoomlevel(
            this.diagramId,
            this.shapeId,
            this.model.id,
            'reset',
        );
    }

    /**
     * Reset the values after rendering is done
     */
    protected restoreHTMLNode() {
        TiptapDocumentsManagerShapeText.setShapeTextNodeZoomlevel(
            this.diagramId,
            this.shapeId,
            this.model.id,
            'restore',
        );
        TiptapDocumentsManagerShapeText.setShapeTextNodeVisibility(
            this.diagramId,
            this.shapeId,
            this.model.id,
            'hidden',
        );
    }

    public destroy() {
        if ( !this.parent ) {
            Logger.error( 'TiptapTextView.destroy called but this.parent was null.' );
            return;
        }
        this.parent.removeChild( this );
    }

    /**
     * Returns the cursor and tail defined for the given context.
     * The return value may be null if no cursor is availbale for the given context
     */
     public getCursor( contexts: InteractionSubContext[]): ICursor {
        if (
            contexts.includes( 'editor' ) &&
            contexts.includes( 'normal' ) &&
            contexts.includes( 'selected' )
        ) {
            return { cursor: 'text', tail: null, type: 'css' };
        }
        return { cursor: 'default', tail: null, type: 'css', viewingArea: 'app' };
    }

    public getShapeTextBounds(): Observable<Rectangle> {
        return TiptapDocumentsManagerShapeText.getHtmlElement( this.diagramId, this.shapeId, this.model.id ).pipe(
            map(( domNode: HTMLElement ) =>
                TiptapDocumentsManagerShapeText.getTextBounds( this.diagramId, this.shapeId, this.model.id ) as any,
            ),
        );
    }

    /**
     * The width and height of the cached bitmap image of the shape.
     */
    protected cachedBounds: { width: number, height: number } = null;
    /**
     * Number of pixels to add as padding when caching the text view.
     */
    protected static CACHE_PADDING = 10;

     /**
      * The pixel ratio of the cached image.
      */
    protected static CACHE_DENSITY = 2;
    public updateViewCache( model: IText, change?: any ): void {

        if ( this.cachedBounds ) {
            if ( ModifierUtils.hasChanges( change, `texts.${model.id}.html` )) {
                const newHtml = get( change, `$set.texts.${model.id}.html` )
                    || change.$set[ `texts.${model.id}.html` ];
                if ( newHtml !== this.model.html ) {
                    this.uncache();
                    this.cachedBounds = null;
                }
            } else if ( ModifierUtils.hasChanges( change, `texts.${model.id}.wrapWidth` )) {
                const newWrapWidth = get( change, `$set.texts.${model.id}.wrapWidth` )
                    || change.$set[ `texts.${model.id}.wrapWidth` ];
                if ( newWrapWidth !== ( this.model as any ).wrapWidth ) {
                    this.uncache();
                    this.cachedBounds = null;
                }
            }
        }
        if ( !this.cachedBounds ) {
            if ( !( model as any ).html ) {
                return;
            }
            this.getShapeTextBounds().subscribe( bounds => {
                this.cache(
                    -TiptapTextView.CACHE_PADDING,
                    -TiptapTextView.CACHE_PADDING,
                    bounds.width + 2 * TiptapTextView.CACHE_PADDING,
                    bounds.height + 2 * TiptapTextView.CACHE_PADDING,
                    TiptapTextView.CACHE_DENSITY,
                );
                this.cachedBounds = { width: bounds.width, height: bounds.height };
            });
        }
    }

    public resetCache() {
        this.uncache();
        this.cachedBounds = null;
    }

    public update( model: TextDataModel, _change?: any ) {
    }

    public updateHitArea( model: IText, rect: IRectangle ) {
        const bounds = Rectangle.from( rect );
        const hitArea = this.hitArea as Shape;
        hitArea.graphics.clear();
        hitArea.graphics.beginFill( '#000' );
        if ( model.hitArea ) {
            hitArea.graphics.drawRect(
                // Note: After enabling partial text alignments, the width of the text view
                // is equal to hit area width so x, y can be 0 always.
                0,
                0,
                model.hitArea.width,
                model.hitArea.height,
            );
        } else if ( model.editable ) {
            hitArea.graphics.drawRect( bounds.x, bounds.y, this.lineWidth, bounds.height );
        }
        hitArea.graphics.endFill();
    }

    protected updatePos() {
        // Tobe overridden
    }

}
