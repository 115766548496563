import { ConnectorModel, IConnectorEndPointWithRef } from '../../../base/shape/model/connector.mdl';
import { GluePointModel } from '../../../base/shape/model/gluepoint.mdl';
import { IPoint2D, IShapeDefinition } from 'flux-definition';
import { DiagramModel } from '../../../base/diagram/model/diagram.mdl';
import { ShapeModel } from '../../../base/shape/model/shape.mdl';
import { IRectangle, ITransform } from 'flux-definition/src';
import { Rectangle } from 'flux-core';

/**
 * A change specification for the plus create feature. The PlusCreateHandler uses
 * This data structure to manage changes to the plus create feature.
 */

/**
 * The status of the PlusCreateHandler and
 * The PlusCreateButton that is currently active.
 */
export enum PlusCreateStatus {
    None, // Removed or doenst exist
    Visible, // Currently added, visible and ready to use.
    Showing, // Currently being added or rendered/animated
    Hidden, // Hidden but is available
    Hiding, // Currently being hidden or removed. Animation
}

/**
 * The positioning of a PlusCreate button cluster can be defined
 * by these strings
 */
export type PlusCreatePosition = 'top' | 'left' | 'bottom' | 'right' | 'center';

/**
 * Status of Plus create
 */
export interface IPlusCreateStatus {
    visible: boolean;
    position?: PlusCreatePosition;
}
export interface IPlusCreateChange {
    action:
        'add' | // Adds the plus create for the given shape. model is mandatory.
        'remove' | // Remove the current plus create
        'change' | // Updates the plus create feature based on the current model. model is mandatory.
        'show' | // Shows the current plus create feature. Only valid if it is hidden
        'hide'; // Hides the current plus create feature. Only valid if plus create is added
    model?: ShapeModel;
}
export interface IShapeWithDistanceAndEnpoints {
    shape: ShapeModel;
    distance: number;
    toEndpoint?: IConnectorEndPointWithRef;
    fromEndpoint?: IConnectorEndPointWithRef;
    side?: ShapeSide;
    connector: ConnectorModel;
}

export interface IConnectorWithEndpoints {
    connector: ConnectorModel;
    toEndpoint: IConnectorEndPointWithRef;
    fromEndpoint: IConnectorEndPointWithRef;
}

/**
 * The positioning of a PlusCreate button cluster can be defined
 * by these strings
 */
export type ShapeSide = 'top' | 'left' | 'bottom' | 'right';

/**
 * Scenario for plus create can be defined by these strings.
 * Vertical means we will find the eastmost shape and extend it,
 * horizontal means we will find the southmost shape and extend it
 */
export type PlusCreateScenario = 'vertical' | 'horizontal';

export interface IButtonDefinition {
    id: string;
    x: 0 | 0.5 | 1;
    y: 0 | 0.5 | 1;
}

type TGetClosestShapeInput = {
    shape: ShapeModel; coords: IPoint2D; side: ShapeSide; distance?: number; connector: ConnectorModel
}[];

type TGetClosestShapeOutput = [ shape: ShapeModel, coords: IPoint2D, side: ShapeSide, connector: ConnectorModel ];


/**
 * Helper class to work with shapes for plus create positioning
 */
export class PlusCreateHelpers {
    // button definitions for plus-create button array
    static buttonDefs: Array<IButtonDefinition> = [
        {
            id: 'left',
            x: 0,
            y: 0.5,
        },
        {
            id: 'top',
            x: 0.5,
            y: 0,
        },
        {
            id: 'right',
            x: 1,
            y: 0.5,
        },
        {
            id: 'bottom',
            x: 0.5,
            y: 1,
        },
    ];
    /**
     * Get next shape dimensions. This varies on def and uses scale + sets userset w/h
     * @param shapeToCopyStylesFrom shape to copy styles from
     * @param def new shape def
     * @returns user set w/h, x/y scale
     */
    static getNextShapeDimensions( shapeToCopyStylesFrom: ShapeModel, def: any ) {
        const data: {userSetWidth?: number, userSetHeight?: number, scaleX?: number, scaleY?: number} = {};
        if (( shapeToCopyStylesFrom.userSetHeight === def?.defaultBounds?.height
            || def?.typeStyle?.bounds?.defaultBounds?.height ) && (
            shapeToCopyStylesFrom.userSetWidth === def?.defaultBounds?.width
                || def?.typeStyle?.bounds?.defaultBounds?.width
        )) {
            data.userSetWidth = shapeToCopyStylesFrom.width;
            data.userSetHeight = shapeToCopyStylesFrom.height;
            data.scaleX = shapeToCopyStylesFrom.width /
                ( def?.defaultBounds?.width || def?.typeStyle?.bounds?.defaultBounds?.width );
            data.scaleY = shapeToCopyStylesFrom.height /
                ( def?.defaultBounds?.height || def?.typeStyle?.bounds?.defaultBounds?.height );
        } else {
            data.userSetWidth = shapeToCopyStylesFrom.userSetWidth;
            data.userSetHeight = shapeToCopyStylesFrom.userSetHeight;
            data.scaleX = shapeToCopyStylesFrom.userSetWidth /
                ( def?.defaultBounds?.width || def?.typeStyle?.bounds?.defaultBounds?.width );
            data.scaleY = shapeToCopyStylesFrom.userSetHeight /
                ( def.defaultBounds?.height || def.typeStyle?.bounds?.defaultBounds?.height );
        }
        return data;
    }

    static getRelativeBoundingBoxForRotatedShape( r: IRectangle, transform: ITransform, angle ) {

        let convertAngle = angle >= 0 ? angle : 180 - Math.abs( angle );
        if ( convertAngle === 90 || convertAngle === 180 ) {
            convertAngle = 0;
        }

        const rect = Rectangle.from( r ).transform( transform );

        // console.log( 'Before', 'x', rect.x, 'y', rect.y, 'w', rect.width, 'h', rect.height, 'a', angle );


        const sinA = Math.abs( Math.sin( convertAngle ));
        const cosA = Math.abs( Math.cos( convertAngle ));

        // console.log( 'sinA', sinA, Math.sin( convertAngle ), Math.sin( angle ));
        // console.log( 'cosA', cosA, Math.cos( convertAngle ), Math.cos( angle ));

        const newW = rect.width * cosA + rect.height * sinA;
        const newH = rect.width * sinA + rect.height * cosA;


        const newY = ( rect.y + rect.height / 2 ) - Math.abs( newH / 2 );
        const newX = ( rect.x + rect.width / 2 ) - Math.abs( newW / 2 );

        // console.log( 'After', 'x', newX, 'y', newY, 'w', newW, 'h', newH, 'a', convertAngle );
        return new Rectangle( newX, newY, newW, newH );

    }
    /**
     * This method returns all connected shapes, with outgoing connector gluepoints
     * from selected shape to directly connected ones
     * @param diagramModel - current diagram model
     * @param shapeModel - selected shape model
     * @param initialShapeId - shape id which plus create started within
     * @param visited - visited shapes
     * @param distance - distance from selected shape
     */
    static getAllConnectedShapes( diagramModel: DiagramModel,
                                  shapeModel: ShapeModel,
                                  initialShapeId?: string, visited = [],
                                  distance = 0, dir?: string ): [
                                    shapes: Array<IShapeWithDistanceAndEnpoints>,
                                    connectors: Array<IConnectorWithEndpoints>
                                  ] {
        if ( visited.includes( shapeModel.id )) {
            return;
        }
        visited.push( shapeModel.id );

        const shape = diagramModel.shapes[ shapeModel.id ] as ShapeModel;
        if ( !shape ) {
            return;
        }
        const connectedShapes: { shapeId: string, dir: 'OUT' | 'IN' }[] = shape?.
            getConnectedShapes( diagramModel, true );
        let connectors = [];
        connectedShapes.forEach( cs => {
            if ( cs.shapeId === initialShapeId ) {
                return;
            }
            const connectedShape = diagramModel.shapes[ cs.shapeId ] as ShapeModel;
            const shapeConnectors = connectedShape?.getConnectors( diagramModel, shapeModel.id );
            const connectorWithEndpoints: IConnectorWithEndpoints = shapeConnectors.reduce(( data, next ) => {
                const { connector, endpoint } = next;
                if ( !data.connector ) {
                    data.connector = connector;
                }
                if ( endpoint.id === 'h' ) {
                    data.fromEndpoint = endpoint;
                } else {
                    data.toEndpoint = endpoint;
                }
                return data;
            }, { connector: null, toEndpoint: null, fromEndpoint: null });
            connectors.push( connectorWithEndpoints );
        });
        const shapes = [];
        if ( !shapes.find( item => item.shape.id === shapeModel.id )) {
            shapes.push({ shape: shapeModel, distance, dir });
        }
        if ( initialShapeId === shapeModel.id ) {
            distance = 0;
        }
        distance++;
        connectors = connectors.filter( next => {
            const { toEndpoint, fromEndpoint } = next;
            return toEndpoint?.shape?.id !== fromEndpoint?.shape?.id;
        });
        for ( const connectedShape of connectedShapes ) {
            if ( !visited.includes( connectedShape.shapeId )) {
                const [ nextShapes, nextConnectors ] = PlusCreateHelpers.getAllConnectedShapes( diagramModel,
                    diagramModel.shapes[ connectedShape.shapeId ] as ShapeModel,
                    initialShapeId, visited, distance, dir );
                const nextNewConnectors = nextConnectors.filter( nextConnectorInfo =>
                    connectors.find( c => c?.connector?.id !== nextConnectorInfo?.connector?.id ));
                shapes.push( ...nextShapes );
                connectors.push( ...nextNewConnectors );
            }
        }
        return [ shapes, connectors ];
    }

    /**
     * Get expansion scenario(vertical/horizontal) and shapes to choose the southmost/eastmost from
     * @param connectedShapes shapes, connected to the one in question
     * @param side side of the shape, which plus create was triggered from
     * @returns expansion scenario and shapes
     */
    static getShapeExpansionScenario ( connectedShapes: Array<IShapeWithDistanceAndEnpoints>, side: ShapeSide ) {
        if ( connectedShapes.length === 0 ) {
            return { scenario: null, shapes: []};
        }
        const verticalScenarioShapes: Array<{ shape: ShapeModel, side: ShapeSide, connector: ConnectorModel }> = [];
        const horizontalScenarioShapes: Array<{ shape: ShapeModel, side: ShapeSide, connector: ConnectorModel }> = [];
        connectedShapes.forEach ( next => {
            const { shape, side: receivingSide, connector } = next;
            // receiving side
            const rs = receivingSide;
            const s = side;
            if ( rs === 'left' || ( s === 'left' && rs === 'right' )) {
                verticalScenarioShapes.push({ shape, side: rs, connector });
            } else {
                horizontalScenarioShapes.push({ shape, side: rs, connector });
            }
        });

        if ( verticalScenarioShapes.length === 0 && horizontalScenarioShapes.length === 0 ) {
            return { scenario: null, shapes: []};
        }
        if ( verticalScenarioShapes.length > horizontalScenarioShapes.length ) {
            return { scenario: 'vertical', shapes: verticalScenarioShapes };
        } else {
            return { scenario: 'horizontal', shapes: horizontalScenarioShapes };
        }
    }

    /**
     * Get shapes, connected to the same side gluepoint
     * @param connectors all connectors
     * @param selectedShapeModel selected shape
     * @param plusCreateButtonSide side
     * @returns list of shapes
     */
    static getShapesConnectedToTheSameSide( connectors: Array<IConnectorWithEndpoints>,
                                            selectedShapeModel: ShapeModel,
                                            plusCreateButtonSide ): Array<IShapeWithDistanceAndEnpoints> {
        return connectors.reduce(( out, next ) => {
            const { fromEndpoint, toEndpoint } = next;
            const { shape: fromShape, gluepoint: fromGluepoint } = fromEndpoint;
            const { shape: toShape, gluepoint: toGluepoint } = toEndpoint;
            const fromSide = PlusCreateHelpers.gluepointCoordinatesToSide( fromGluepoint );
            const toSide = PlusCreateHelpers.gluepointCoordinatesToSide( toGluepoint );
            if ( fromShape?.id === selectedShapeModel.id && fromSide === plusCreateButtonSide ) {
                out.push({
                    shape: toEndpoint.shape,
                    fromEndpoint,
                    toEndpoint,
                    connector: next.connector,
                    side: toSide,
                });
            } else if ( toShape?.id === selectedShapeModel.id && toSide === plusCreateButtonSide ) {
                out.push({
                    shape: fromEndpoint.shape,
                    fromEndpoint,
                    toEndpoint,
                    connector: next.connector,
                    side: fromSide,
                });
            }
            return out;
        }, []);
    }

    /**
     * This method will returns all the connected shapes,
     * which are similar to given shapeDef.
     * @param shapes - shapes array
     * @param shapeDefToSearch - shape def id to be matched
     */
    static filterSimilarShapes( shapes: Array<IShapeWithDistanceAndEnpoints>,
                                shapeDefToSearch: string ): Array<IShapeWithDistanceAndEnpoints> {
        return shapes.filter( next => next.shape.defId === shapeDefToSearch );
    }

    /**
     * Filters out original shape
     * @param shapes all shapes
     * @param originalShapeId id of shape to exclude
     */
     static filterOutOriginalShape( shapes: Array<IShapeWithDistanceAndEnpoints>,
                                    originalShapeId: string ): Array<IShapeWithDistanceAndEnpoints> {
        return shapes.filter( next => next.shape.id !== originalShapeId && next.distance === 1 );
    }

    /**
     * Select closest shape to another
     * @param shapes shapes to find closest of
     * @param rootShape shape to find closest to
     * @returns [ closest shape, closest shape coords, closest shape side ]
     */
    static getClosestShape( shapes: TGetClosestShapeInput, rootShape: ShapeModel ): TGetClosestShapeOutput {
        if ( shapes.length === 1 ) {
            return [ shapes[0].shape, shapes[0].coords, shapes[0].side, shapes[0].connector ];
        }
        const { x, y } = rootShape;
        const { shape, coords, side, connector }: { shape: ShapeModel,
                                                    coords: IPoint2D,
                                                    distance?: number,
                                                    side: ShapeSide,
                                                    connector: ConnectorModel } = shapes.reduce(( res, next ) => {
                const { x: nextX, y: nextY } = next.shape;
                const distance =  Math.abs( Math.max( nextX, x ) - Math.min( nextX, x )) +
                                Math.abs( Math.max( nextY, y ) - Math.min( nextY, y ));
                if ( distance < res.distance ) {
                    res.distance = distance;
                    res.shape = next.shape;
                    res.coords = next.coords;
                    res.side = next.side;
                    res.connector = next.connector;
                }
                return res;
        }, { shape: null, coords: null, distance: Infinity, side: null, connector: null });
        return [ shape, coords, side, connector ];
    }

    /**
     * get coordinates and shape for the flowdown automatic next shape positioning
     * @param expansionScenario scenario to expand: vertical/horizontal and shapes to expand from
     * @param offset default flowdown shape offset
     * @returns calculated coordinates and the relative shape they have been chosen upon
     */
    static getFlowdownCoordinates( expansionScenario: {
                                   shapes: Array<{ shape: ShapeModel,
                                   side: ShapeSide,
                                   connector: ConnectorModel }>,
                                   scenario: string },
                                   rootShape: ShapeModel,
                                   offset: number ):
                                       { coords: IPoint2D,
                                       shape: ShapeModel,
                                       side: ShapeSide,
                                       connector: ConnectorModel } {
        const { shapes, scenario } = expansionScenario;
        let southmost: { shape: ShapeModel, side: ShapeSide, coords: IPoint2D, connector: ConnectorModel }[] = [];
        let eastmost: { shape: ShapeModel, side: ShapeSide, coords: IPoint2D, connector: ConnectorModel }[] = [];
        shapes.reduce(( res, next ) => {
            const { shape, connector } = next;
            const { x: nextX, y: nextY, width, height } = shape;
            const nextYWithOffset = nextY + height + offset;
            const nextXWithOffset = nextX + width + offset;

            // set southmost coordinates
            if ( nextYWithOffset >= res[0]) {
                if ( nextYWithOffset !== res[0]) {
                    southmost = [];
                }
                res[0] = nextYWithOffset;
                southmost
                    .push({ shape: next.shape, side: next.side, connector, coords: { x: nextX, y: nextYWithOffset }});
            }

            // set eastmost coordinates
            if ( nextXWithOffset >= res[1]) {
                if ( nextXWithOffset !== res[1]) {
                     eastmost = [];
                }
                res[1] = nextXWithOffset;
                eastmost
                    .push({ shape: next.shape, side: next.side, connector, coords: { x: nextXWithOffset, y: nextY }});
            }
            return res;
        }, [ -Infinity, -Infinity ]);

        let southmostConnector: ConnectorModel;
        let southmostShape: ShapeModel;
        let southmostCoords: IPoint2D;
        let southmostSide: ShapeSide;
        [ southmostShape, southmostCoords, southmostSide, southmostConnector ] =
            PlusCreateHelpers.getClosestShape( southmost, rootShape );

        let eastmostConnector: ConnectorModel;
        let eastmostShape: ShapeModel;
        let eastmostCoords: IPoint2D;
        let eastmostSide: ShapeSide;
        [ eastmostShape, eastmostCoords, eastmostSide, eastmostConnector ] =
            PlusCreateHelpers.getClosestShape( eastmost, rootShape );
        return {
            coords: scenario === 'horizontal' ? eastmostCoords : southmostCoords,
            shape: scenario === 'horizontal' ? eastmostShape : southmostShape,
            side: scenario === 'horizontal' ? eastmostSide : southmostSide,
            connector: scenario === 'horizontal' ? eastmostConnector : southmostConnector,
        };
    }

    /**
     * Get coordinates for the next shape, if we don't have any other shapes, connected to the same endpoint
     * @param selectedShapeModel root shape model
     * @param side side of the root model, which plus create was triggered from
     * @param offset adjacent shape offset
     * @returns coordinates
     */
    static getNewAdjacentShapeCoordinates( selectedShapeModel: ShapeModel,
                                           side: PlusCreatePosition,
                                           offset: number,
                                           def: IShapeDefinition ): IPoint2D {
        const isCard = selectedShapeModel.name.includes( 'Card' );
        let { x, y } = selectedShapeModel;
        const width = selectedShapeModel?.bounds?.width || selectedShapeModel.width;
        const height = selectedShapeModel?.bounds?.height || selectedShapeModel.height;

        const sameDef = selectedShapeModel.defId === def.defId;

        const newWidth = sameDef ? selectedShapeModel?.bounds?.width : def?.defaultBounds?.width
            || selectedShapeModel?.userSetWidth || 100;
        const newHeight = sameDef ? selectedShapeModel?.bounds?.height : def?.defaultBounds?.height
            || selectedShapeModel?.userSetHeight;

        const horizontalOffset = offset + width;
        const verticalOffset = offset + height;
        let direction: 'vertical' | 'horizontal' = 'horizontal';
        if ( side === 'right' ) {
            x += horizontalOffset;
            direction = 'horizontal';
        } else if ( side === 'left' ) {
            x -= horizontalOffset;
            direction = 'horizontal';
        } else if ( side === 'bottom' ) {
            y += verticalOffset;
            direction = 'vertical';
        } else if ( side === 'top' ) {
            y -= verticalOffset;
            direction = 'vertical';
        }

        if ( !isCard ) {
            if ( direction === 'horizontal' ) {
                y += ( height / 2 - newHeight / 2 );
            } else {
                x += ( width / 2 - newWidth / 2 );
            }
        }

        return { x, y };
    }

    static sideToGluepointCoordinates( side: ShapeSide ): IPoint2D {
        if ( side === 'right' ) {
            return { x: 1, y: 0.5 };
        } else if ( side === 'bottom' ) {
            return { x: 0.5, y: 1 };
        } else if ( side === 'left' ) {
            return { x: 0, y: 0.5 };
        } else if ( side === 'top' ) {
            return { x: 0.5, y: 0 };
        }
    }

    static getOppositeSide( side: ShapeSide ): ShapeSide {
        if ( side === 'right' ) {
            return 'left';
        } else if ( side === 'bottom' ) {
            return 'top';
        } else if ( side === 'left' ) {
            return 'right';
        } else if ( side === 'top' ) {
            return 'bottom';
        }
    }

    static getNextClockwiseSide( side: ShapeSide ): ShapeSide {
        if ( side === 'top' ) {
            return 'right';
        } else if ( side === 'right' ) {
            return 'bottom';
        } else if ( side === 'bottom' ) {
            return 'left';
        } else if ( side === 'left' ) {
            return 'top';
        }
    }

    /**
     * Check if we should render plus create buttons and listen to keyboard shortcuts
     * for that shape
     * FIXME: This is unoptimal, should make flags for plus create in shape defs instead.
     * @param def shape definition
     * @returns if plus create should be enabled for this shape def
     */
    static isPlusCreateEnabled( def ) {
        def.tags = def.tags || [];
        return def.plusCreate !== false &&
            !( def.type === 'connector' ) &&
            !def.tags.includes( 'frame shape' ) &&
            !def.defId.startsWith( 'creately.frames' ) &&
            !def.tags.includes( 'frames' ) &&
            !def.tags.includes( 'swimlane' ) &&
            !( def?.name.includes( 'frame' )) &&
            !( def.name === 'Text' ) &&
            !( def.name === 'Table' ) &&
            !( def.defId === 'creately.basic.vectorimage' ) &&
            !( def.defId === 'creately.basic.rasterimage' ) &&
            ! def.defId.includes( 'icons' );
    }

    /**
     * Convert gluepoint coordinates to shape side
     * @param coordinates: gluepoint coordinates
     * @returns side
     */
    static gluepointCoordinatesToSide( coordinates: GluePointModel ) {
        if ( !coordinates ) {
            return 'right';
        }
        const { y } = coordinates;
        let { x } = coordinates;

        if ( coordinates.id ) {
            const { id } = coordinates;
            if ( id === 'gpeast' ) {
                return 'right';
            } else if ( id === 'gpsouth' ) {
                return 'bottom';
            } else if ( id === 'gpwest' ) {
                return 'left';
            } else if ( id === 'gpnorth' ) {
                return 'top';
            } else if ( id === '8Qj4Lmk9ziK' ) {
                x = 0;
            } else if ( id === 'oFFO6e4i7q8' ) {
                x = 1;
            }
        }
        if ( y === 2.5 ) {
            return x === 0 ? 'left' : 'right';
        }
        if ( x === 1 && y === 0.5 ) {
            return 'right';
        } else if ( x === 0.5 && y === 1 ) {
            return 'bottom';
        } else if ( x === 0 && y === 0.5 ) {
            return 'left';
        }  else {
            return 'top';
        }
    }
}
