import { DiagramLocatorLocator } from '../../../base/diagram/locator/diagram-locator-locator';
import { EDataRegistry } from './../../../base/edata/edata-registry.svc';
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TreeviewItem, TreeviewConfig, TreeviewComponent, TreeviewModule, TreeviewI18n } from '@creately/ngx-treeview';
import { StateService, ModalController, PopupWindow } from 'flux-core';
import { CUSTOM_SHAPE_LIB_ID_PREFIX, LibraryType } from '../../library/abstract-shape-library';
import { map, take, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LibraryList } from './library-list';
import { ILoadedLibrary } from '../../library/loaded-library.state';
import { uniq, isEqual, isEmpty } from 'lodash';
import { TempAddLibsTreeviewI18n } from './temp-add-libs-treeview.i18n';
import { PlanPermManager } from 'flux-user';
import { EDataLocatorLocator } from '../../../base/edata/locator/edata-locator-locator';

/**
 * This is the temporary add libs menu component.
 * This component allows the user to add remove libraries from
 * the library panel.
 */
@Component({
    templateUrl: './temp-add-libs-menu.cmp.html',
    selector: 'temp-add-libs-menu',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [ './temp-add-libs-menu.scss' ],
    providers: [
        TreeviewModule,
        { provide: TreeviewI18n, useClass: TempAddLibsTreeviewI18n },
    ],
})
export class TempAddLibsMenu extends PopupWindow implements OnInit, OnDestroy {

    /**
     * The complete library list that is fed to the ngx-treeview
     * component so that it can render the ui.
     */
    public items: BehaviorSubject<TreeviewItem[]>;

    /**
     * Config settings for the ngx-treeview component.
     */
    public config = TreeviewConfig.create({
        hasAllCheckBox: false,
        hasFilter: false,
        hasCollapseExpand: false,
        decoupleChildFromParent: false,
        maxHeight: 9999,
    });

    /**
     * Whether the user is in free or demo plan or not
     */
    public isFreeOrDemoUser: Observable<boolean>;

    /**
     * Whether the user is lite plan user or not
     */
    public isLitePlanUser: Observable<boolean>;

    protected customDBtitle: string = 'Custom Databases';

    /**
     * The window overlay.
     */
    @ViewChild( 'window', { static: true }) protected container;

    /**
     * The the window element.
     */
    @ViewChild( 'windowInner', { static: true }) protected containerInner;

    /**
     * The the tree view component.
     */
    @ViewChild( 'treeView', { static: true }) protected treeView: TreeviewComponent;

    /**
     * Array with all the subs.
     */
    protected subs: Array<Subscription>;

    constructor(
        protected state: StateService<any, any>,
        protected modalController: ModalController,
        protected planPermManager: PlanPermManager,
        protected libraryList: LibraryList,
        protected ell: EDataLocatorLocator,
        protected ll: DiagramLocatorLocator,
    ) {
        super();
        this.subs = [];
        this.items = new BehaviorSubject([]);
        this.isFreeOrDemoUser = this.planPermManager.isFreeOrDemoUser();
        this.isLitePlanUser = this.planPermManager.isLiteUser();
    }

    /**
     * Closes the window when the overlay is clicked.
     */
    public closeOnOverlayClick( event ) {
        const elemClass = event.target.className;
        const elemClassType = typeof( elemClass );

        if ( elemClass && elemClassType === 'string' ) {
            if ( elemClass.includes( 'modal-window-container' )) {
                this.closeWindow();
            }
        }
    }

    /**
     * Creates the initial treeview and sets the default selected libraries.
     */
    public ngOnInit(): void {
        const sub = this.showWindow( this.container, this.containerInner ).subscribe();
        this.state.changes( 'CurrentLibraries' ).pipe(
            take( 1 ),
            map( selectedLibraries => {
                this.createTreeView( selectedLibraries.map( lib => lib.id ));
            }),
        ).subscribe();

        this.subs.push( sub );
    }

    /**
     * Method that is called by the filter text box.
     * Accepts a string that is used to filter the items displayed on the treeview.
     */
    onFilterChange( event: string ): void {
        this.treeView.onFilterTextChange( event );
    }

    /**
     * Method that fires when a selection changes on the tree.
     * Updates the state with the current selected items.
     */
    public onSelectionChange( event ) {
        // Note: uniq added because, when the same library exist in multiple groups, when a single library
        // selected, both will be selected.
        const distinctLibs = uniq( event );
        const currentLibs: ILoadedLibrary[] = this.state.get( 'CurrentLibraries' );
        // Empty list emitted on open of tree view
        if ( event.length === 0 || distinctLibs.length === currentLibs.length ) {
                return;
        }
        const updatedLibs = currentLibs.filter( lib => distinctLibs.includes( lib.id ));
        const libIds = updatedLibs.map( lib => lib.id );
        const newlyAddedLibs = distinctLibs.filter( libId => !libIds.includes( libId ));
        let currGroup = this.state.get( 'CurrentLibraryGroup' );
        newlyAddedLibs.forEach( libId => {
            const newLibrary: ILoadedLibrary = {
                id: libId,
                type: LibraryType.Static,
                libGroup: this.libraryList.getMainGroupForLibrary( libId ),
                isCollapsed: false,
                status: 'loading',
            };
            currGroup = newLibrary.libGroup;
            updatedLibs.push( newLibrary );
        });
        this.state.set( 'CurrentLibraries', updatedLibs );

        // Check if library group has been removed -
        if ( !( updatedLibs.map( lib => lib.libGroup ).includes( currGroup ))) {
            currGroup = updatedLibs[0].libGroup;
        }
        this.state.set( 'CurrentLibraryGroup', currGroup );
    }

    /**
     * Starts the animation for hiding the window and closes the window
     * after it completes.
     */
    public closeWindow() {
        const sub = this.hideWindow( this.container, this.containerInner ).subscribe({
            complete: () => {
                this.modalController.hide();
            },
        });
        this.subs.push( sub );
    }

    /**
     * Unsubscribes from the subscriptions.
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Check whether the library is premium or not
     * @param libId
     * @returns
     */
    public isPremiumLibrary( libId: string ): boolean {
        return this.libraryList.belongsToPremiumGroup( libId );
    }

    /**
     * Check whether the group is premium or not
     * @param grpId
     * @returns
     */
    public isPremiumGroup( grpId: string ): boolean {
        return this.libraryList.isPremiumGroup( grpId );
    }

    /**
     * Method that creates the treeview and emits "items" so that
     * it can be rendered in the ui.
     */
    protected createTreeView( selectedLibIds: string[]) {
        const sub = this.getCustomDatabases().subscribe( customDbs => {
            const allLibs = [];
            this.libraryList.getAllShapeLibraries().forEach( group => {
                const children = [];
                let collapsed = true;
                this.libraryList.getLibrariesInGroup( group ).map( lib => {
                    children.push({
                        text: lib.label,
                        value: lib.id,
                        checked: this.isLibrarySelected( lib.id, selectedLibIds ),
                    });

                    collapsed = collapsed ? !this.isLibrarySelected( lib.id, selectedLibIds ) : collapsed;
                });

                const treeViewItem = new TreeviewItem({
                    text: group, value: group, children: children, collapsed: collapsed, disableChildrenCheck: true,
                });
                treeViewItem.correctChecked();
                allLibs.push( treeViewItem );
            });

            if ( !isEmpty( customDbs )) {
                const tv = new TreeviewItem({
                    text: this.customDBtitle,
                    value: this.customDBtitle,
                    children: customDbs.map( lib => ({
                        text: lib.label,
                        value: lib.id,
                        checked: this.isLibrarySelected( lib.id, selectedLibIds ),
                    })),
                    collapsed: false,
                    disableChildrenCheck: true,
                });
                tv.correctChecked();
                allLibs.push( tv );
            }


            this.items.next( allLibs );
        });

        this.subs.push( sub );
    }

    /**
     * Returns the custom databases info
     */
     protected getCustomDatabases() {
        return this.ll.forCurrent( false ).getDiagramEData().pipe(
            switchMap( ids => this.ell.currentEDataModels().pipe(
                map( models => ( ids || []).map( id => models.find( m => m.id === id )))),
            ),
            distinctUntilChanged(( pre, curr ) => {
                const preItems =  pre.map( v => Object.keys( v.customEntityDefs || {}));
                const currItems =  curr.map( v => Object.keys( v.customEntityDefs || {}));
                return isEqual( preItems, currItems );
            }),
            map( models => {
                const itemsData = [];
                models
                    .filter( m => m && m.defId === EDataRegistry.customEdataDefId &&
                        !isEmpty( m.getActiveCustomEntityDefs()))
                    .forEach( edataModel => {
                        itemsData.push({
                            id: CUSTOM_SHAPE_LIB_ID_PREFIX + edataModel.id,
                            label: edataModel.name,
                            groups: [ this.customDBtitle ],
                            libGroup: this.customDBtitle,
                        });
                    });
                return itemsData;
            }));
    }

    /**
     * Returns true or false based on whether the library is selected.
     */
    protected isLibrarySelected( libraryId: string, selectedLibs: string[]): boolean {
        return selectedLibs && selectedLibs.includes( libraryId );
    }
}
