import clustersKmeans from '@turf/clusters-kmeans';
import { featureCollection, point } from '@turf/helpers';
import { enumerable, Logger, Random, Rectangle } from 'flux-core';
import { IConnectorText, IDataItem, IDiagramDefinition, IPoint2D,
    IShapeText, LayoutTypes, LibraryState, ShapeType } from 'flux-definition';
import { DiagramReviewableModel } from 'flux-diagram';
import { clone, findIndex, orderBy, values } from 'lodash';
import { AbstractShapeModel } from '../../shape/model/abstract-shape.mdl';
import { ShapeTextDataModel } from '../../shape/model/shape-text-data.mdl';
import { ShapeLinkModel } from './../../../../flux-diagram/src/shape/model/shape-link.mdl';
import { TextPostion } from './../../framework/text/text-position';
import { ConnectorDataModel } from './../../shape/model/connector-data-mdl';
import { ShapeDataModel } from './../../shape/model/shape-data.mdl';

/**
 * DiagramDataModel
 * This is the model that represents a full diagram. This primarily contains
 * data of a diagram and is designed for the purpose of the representation
 * of the diagram data. This model can be used to fully render a diagram in any
 * environemnt to any renderable target.
 *
 * A concrete version of the diagram model with all  capabilities will be available out of
 * this module.
 *
 * This extends the features of DiagramReviewableModel which contains capabilities
 * used by viewing apps.
 *
 * @author  hiraash
 * @since   2017-09-01
 */
export class DiagramDataModel extends DiagramReviewableModel implements IDiagramDefinition {
    /**
     * Checks whether all shapes in the given array are of the same type.
     */
    public static sameType( shapes: AbstractShapeModel[]): boolean {
        if ( shapes.length === 0 ) {
            return false;
        }
        if ( shapes.length === 1 ) {
            return true;
        }
        const first = shapes[0].type;
        for ( let i = 1; i < shapes.length; ++i ) {
            if ( shapes[i].type !== first ) {
                return false;
            }
        }
        return true;
    }

    /**
     * This map is to keep the common data of data items shared
     * between shapes
     */
    public dataDefs: { [dataSetId: string ]: { [ dataItemId: string ]: IDataItem<any>}} = {};

    /**
     * This map is to keep the common data of data items shared
     * between shapes
     */
    public defaultDataDefs: { [defAndVersion: string ]: { [ dataItemId: string ]: IDataItem<any>}} = {};

    /**
     * The class identifier for this definition. This will be on the model and
     * will be used to load this def. For the diagram definition to be added to a diagram
     * this is the property that is needed.
     */
    public type: string;

    /**
     * Displayable name for the diagram type.
     */
    public typeName: string;

    /**
     * URL of the type thumbnail.
     */
    public typeThumbnail: string;

    /**
     * A map of libraries and their states for each user who is a collaborator on this document
     */
    public libraries:  { userId: string,  libs: [ string, LibraryState][] }[];

    /**
     * Store the parent template data
     */
    public templateDef?:  { id: string,  name: string, isPremium?: boolean };

    /**
     * A map to store the recent shapes
     */
    public recent?: { userId: string, shapes?: { defId: string, weight: number, added?: number}[]}[];

    /**
     * Array of search tags that will load for this diagram type.
     */
    public searchTags: string[];

    /**
     * The index of the library to open on the library list. Optional
     * and if not provided the first library will be openned.
     */
    public libraryIndex: number = 0;

    /**
     * The type of layout this diagram type works based on. Default is none.
     */
    public layoutType?: LayoutTypes = LayoutTypes.None;

    /**
     * The list of all shapes in the diagram.
     * Includes basic shapes, dynamic shapes and connectors.
     */
    public shapes: { [shapeId: string]: AbstractShapeModel } = {};

    /*
     * The list of all groups and shapes belongs to the groups
     * Includes shapes, connectors and groups.
     */
    public groups: {[groupId: string]: { shapes: string[], groups: string[], links?: ShapeLinkModel[] }} = {};

    /**
     * Populate the diagram data model instance with given diagram id and name.
     * @param id Model id
     * @param name Model name
     * @param extension
     * NOTE: This constructor is not necessary to exist, added to oversome code coverage issues
     */
    public constructor( id: string, name: string, extension?: Object ) {
        super( id, name, extension ) /* istanbul ignore next */;
    }

    /**
     * Returns if this diagram has any shapes added to it.
     * @return true if diagram has shapes.
     */
    @enumerable( true )
    public get hasShapes(): boolean {
        return ( this.shapes && Object.keys( this.shapes ).length > 0 );
    }

    /**
     * Returns the number of shapes added to the diagram
     */
    @enumerable( true )
    public get numShapes(): number {
        if ( this.hasShapes ) {
            return Object.keys( this.shapes ).length;
        }
        return 0;
    }


    /**
     * Return eData if found any, for the given dataSetId
     * @param dataSetId
     * @returns
     */
    public getEdataByDataSetId( dataSetId: string ) {
        let eData;
        values( this.shapes ).some(( shape: ShapeDataModel ) => {
            if ( shape.eData && shape.dataSetId === dataSetId ) {
                eData = shape.eData;
                return true;
            }
            return false;
        });
        return eData;
    }


    /**
     * Gets the compiled dataDef for a given shape.
     * @param shapeId
     */
     public getDataDef( shapeId: string ): any {
        const shape = this.shapes[ shapeId ];
        if ( !shape ) {
            return;
        }

        let dataDef = {};
        if ( shape.eData ) {
            // get the custom def
            Object.assign( dataDef, this.dataDefs[ shape.entityDefId ]);
            Object.assign( dataDef, this.dataDefs[ shape.entityId ]);
        } else if ( shape.dataSetId ) {
            dataDef = this.dataDefs[ shape.dataSetId ];
        }
        return dataDef;
    }

    /**
     * Initializes the dataDefs at shape level
     * @param shapeId
     */
    public getInitializedDataSet( shapeId: string ): any {
        const shape = this.shapes[ shapeId ];
        if ( !shape ) {
            return;
        }

        if ( !shape.dataSetId ) {
            if ( shape.eData && shape.entityId ) {
                shape.dataSetId = shape.entityId;
            } else {
                shape.dataSetId = Random.dataItemId();
            }

            if ( !this.dataDefs[ shape.dataSetId ]) {
                this.dataDefs[ shape.dataSetId ] = {};
            }
        }
        return this.dataDefs[ shape.dataSetId ];
    }

    /**
     * Returns Shapes for the given dataSetId
     * @param dataSetId
     * @returns
     */
    public getShapesByDatasetId( dataSetId: string ): AbstractShapeModel[] {
        return values( this.shapes ).filter(( shape: ShapeDataModel ) => shape.dataSetId === dataSetId );
    }

    /**
     * Returns true if all shapes are of the same type (basic, connector, ...).
     * Returns false if the id list is empty or there are multiple shape types.
     */
    public sameType( ids: string[]): boolean {
        if ( ids.length === 0 ) {
            return false;
        }
        if ( ids.length === 1 ) {
            return true;
        }
        const first = this.shapes[ids[0]].type;
        for ( let i = 1; i < ids.length; ++i ) {
            if ( this.shapes[ids[i]].type !== first ) {
                return false;
            }
        }
        return true;
    }

    /**
     * Returns a filtered array containing the shape types for a given set of ids.
     * Ex: If the list of shapes contains 3 connectors and a basic shape, this
     * will return ['connector, 'basic']. If all shapes were connectors, this will return
     * ['connector'].
     * @returns Array of Shape IDs
     */
    public getShapeTypes( ids: string[]): string[] {
        if ( ids && ids.length > 0 ) {
            const types = ids
                .map( id => this.shapes[ id ])
                .filter( shape => !!shape )
                .map( shape => shape.type );
            return Array.from( new Set( types ));
        }
        return[];
    }

    /**
     * Returns the integer z-index of a shape.
     */
    public getZIndexOf( shapeId: string ): number {
        const shapes = this.getShapesOrderedByIndex();
        for ( let i = 0; i < shapes.length; ++i ) {
            if ( shapes[i].id === shapeId ) {
                return i;
            }
        }
        return null;
    }

    /**
     * Get an array of all shapes in the diagram sorted by z-index.
     * FIXME: cache values returned by this method and invalidate on shape change.
     */
    public getShapesOrderedByIndex( shapeIds?: string[]): AbstractShapeModel[] {
        const shapes: AbstractShapeModel[] = [];
        SHAPE_LOOP:
        for ( const id in this.shapes ) {
            if ( shapeIds && shapeIds.indexOf( id ) === -1 ) {
                continue;
            }
            const shape = this.shapes[id];
            for ( let i = 0; i < shapes.length; ++i ) {
                if ( shapes[i].zIndex > shape.zIndex ) {
                    shapes.splice( i, 0, shape );
                    continue SHAPE_LOOP;
                }
            }
            shapes[shapes.length] = shape;
        }
        return shapes;
    }

    /**
     * Merge all ( or specified ) the shapes to create a Rectangle containing
     * all the shapes of the diagram. The size of this Rectangle
     * can be used to determine the size of the diagram.
     */
    public getBounds( ids?: string[]): Rectangle {
        let shapes: Array<AbstractShapeModel> = values( this.shapes );
        if ( ids ) {
            if ( typeof ids === 'string' ) {
                ids = [ ids ];
            }
            shapes = ids.map( id => this.shapes[ id ]);
        }
        if ( shapes && shapes.length > 0 ) {
            let finalBounds;
            shapes.forEach(( shape, i ) => {
                const shapeBounds = this.getShapeBoundsWithTexts( shape );
                if ( i === 0 ) {
                    finalBounds = clone( shapeBounds );
                    return;
                }
                finalBounds = finalBounds.absorb( shapeBounds );
            });
            // FIXME: If the area of the diagram is ridiculously large,
            // we can assume that a junk shape has been added to the diagram far away from
            // the actual diagraming area.
            // Junk Shape - There is a possibilty for a diagram to have shapes which are added
            // far away to the actual drawing area, these shapes can be added accidently by the user
            // or due to problems in shape transformations.
            // Find why this happenes in shapes / connectors transformation and fix it.
            if ( finalBounds.width > 20000 || finalBounds.height > 20000 ) {
                const clusturedShapes = this.getClusteredShapes( ids );
                if ( clusturedShapes ) {
                    return this.getBounds( clusturedShapes );
                }
            }
            return finalBounds;
        }
        return new Rectangle( 0, 0, 0, 0 );
    }

    /**
     * This function returns the positon of the text spesified by the textId
     * and shape Id
     * @param shapeId: string   The Id of the shape that text is contained
     * @param textId: string    The Id of the text
     */
    public getTextPosition( shapeId: string, textId: string ): IPoint2D {
        let position;
        const shape = this.shapes[ shapeId ];
        if ( !shape ) {
            return;
        }
        const textModel = shape.texts[ textId ];
        if ( !textModel ) {
            return;
        }
        if ( shape.isConnector()) {
            const text: IConnectorText = textModel as IConnectorText;
            if ( typeof text.height === 'number' && typeof text.width === 'number' ) {
                position = TextPostion.forConnector(
                        <ConnectorDataModel>shape,
                        text,
                        { x: 0, y: 0, width: text.width, height: text.height } as any );
            } else {
                Logger.error( `Unable to find connector text height and width "${textId}" on diagram` );
            }
        } else {
            const text: IShapeText = textModel as IShapeText;
            position = TextPostion.forShape(
                text,
                ( <ShapeDataModel>shape ).defaultBounds,
                { x: 0, y: 0, width: text.width, height: text.height } as any,
                ( <ShapeDataModel>shape ).transform );
        }
        return position;
    }

    /**
     * Returns all shapes/connectors which is enclosed by given bounds
     */
    public getEnclosedShapes( bounds: Rectangle ): AbstractShapeModel[] {
        const shapes = [];
        for ( const shapeId in this.shapes ) {
            const shape = this.shapes[shapeId];
            if ( bounds.contains( shape.bounds )) {
                shapes.push( shape );
            }
        }
        return shapes;
    }

    /**
     * Returns all shapes/connectors which intersect given bounds
     */
    public getIntersectingShapes( bounds: Rectangle ): AbstractShapeModel[] {
        const shapes = [];
        for ( const shapeId in this.shapes ) {
            const shape = this.shapes[shapeId];
            if ( bounds.intersection( shape.bounds )) {
                shapes.push( shape );
            }
        }
        return shapes;
    }

    /**
     * Returns the shapes of the current diagram model
     * according to shapes types given
     * @param shapeTypes - ShapeTypes defined
     */
    public getShapes( shapeTypes: ShapeType[]) {
        const shapes = [];
        for ( const shapeId in this.shapes ) {
            if ( this.shapes.hasOwnProperty( shapeId )) {
                if ( shapeTypes.includes( this.shapes[shapeId].type )) {
                    shapes.push( this.shapes[shapeId]);
                }
            }
        }
        return shapes;
    }


    /**
     * Gets libraries for a given userId
     * If the current user does not have a lib preference, it gives the
     * owners lib preference
     * @param userId
     */
    public getLibraries( userId: string ) {
        if ( this.libraries ) {
            let index = findIndex( this.libraries, lib => lib.userId === userId );
            if ( index > -1 ) {
                return this.libraries[index].libs;
            } else {
                index = findIndex( this.libraries, lib => lib.userId === this.owner.id );
                if ( index  > -1 ) {
                    return this.libraries[index].libs;
                }
            }
        }
    }

    /**
     * Gets Recent shapes for a given userId
     * @param userId
     */
    public getRecentShapes( userId: string ) {
        if ( this.recent ) {
            const index = findIndex( this.recent, el => el.userId === userId );
            if ( index > -1 ) {
                return this.recent[index].shapes;
            }
        }
        return [];
    }

    /**
     * Return frame shapes in diagram
     */
    public getAllFrames(): AbstractShapeModel[] {
        const frames = values( this.shapes )
            .filter( shape => shape.defId === 'creately.basic.frame_paper'
                || shape.defId === 'creately.basic.frame_desktop' || shape.defId === 'creately.basic.frame_phone'
                || shape.defId === 'creately.basic.frame_presentation' || shape.defId === 'creately.basic.frame_screen'
                || shape.defId === 'creately.basic.frame_tablet' );
        return orderBy( frames, [ 'data.frameIndex.value' ], [ 'asc' ]);
    }

    /**
     * Returns all shape ids which are within the given frame shape Id bounds.
     * @param frameId frame shape id
     */
    public getAllShapesIdsByFrame( frameId: string ): string[] {
        if ( this.hasShapes ) {
            return values( this.shapes )
                .filter( shape => {
                    const frame = this.shapes[frameId];
                    const intersection = frame.bounds.intersection( shape.bounds );
                    // Checking intersecting shapes
                    let flag = ( intersection && ( intersection.width > 0 || intersection.height > 0 ));
                    // FIXME: Not sure why some connectors are not intersecting, need to fix this
                    if ( !flag  && ( frame as any ).children ) {
                        // Checking whether shape is contained as children in the container
                        flag = !!( frame as any ).children[shape.id];
                    }
                    return flag;
                })
                .map( shape => shape.id )
                .filter( id => id !== frameId );
        }
        return [];
    }

    /**
     * This method check and returns true If there are any
     * shapes outside frame shapes bounds
     */
    public isShapeOutsideFrames(): boolean {
        const frames = this.getAllFrames();
        if ( frames.length === 0 || !this.hasShapes ) {
            return true;
        }
        let frameShapes = [];
        frames.forEach( frame => {
            frameShapes = [ ...frameShapes, ...this.getAllShapesIdsByFrame( frame.id ) ];
        });
        return (( frameShapes.length + frames.length ) !== Object.keys( this.shapes ).length );
    }

    /**
     * This method returns the shapes ids array ignoring the junk shape id
     */
    private getClusteredShapes( ids?: string[]) {
        let shapes: Array<AbstractShapeModel> = values( this.shapes );
        if ( ids ) {
            shapes = shapes.filter( shape => ids.includes( shape.id ));
        }
        const points = shapes.map( s => point([ s.bounds.centerX, s.bounds.centerY ], { id: s.id }));
        const pointsCollection = featureCollection( points );
        // Considered only 2 clustors, one clustor includes actual diagram shapes and
        // other one will include the junk shape
        const clustured = clustersKmeans( pointsCollection, { numberOfClusters: 2 });
        const cluster0 = clustured.features
            .filter( f => f.properties.cluster === 0 )
            .map( f => f.properties.id );
        const cluster1 = clustured.features
            .filter( f => f.properties.cluster === 1 )
            .map( f => f.properties.id );

        let majorCluster;
        let minorCluster;
        if ( cluster0.length > cluster1.length ) {
            majorCluster = cluster0;
            minorCluster = cluster1;
        } else {
            majorCluster = cluster1;
            minorCluster = cluster0;
        }
        // Note: Consider only the case where only one junk shape is added
        if ( minorCluster.length !== 1 ) {
            return;
        }
        Logger.warning( `A junk shape ( ${minorCluster[0]} ) found far away from the actual diagraming` +
            ` area and it was ignored when calculating diagram bounds.` );
        return majorCluster;
    }

    /**
     * This function returns the shape bounds considering the text bounds
     */
    private getShapeBoundsWithTexts( shape: AbstractShapeModel ) {
        if ( !shape.hasAnyText ) {
            return shape.bounds;
        }
        const boundsWithText = Rectangle.from( shape.bounds );
        for ( const key of Object.keys( shape.texts )) {
            const text = shape.texts[ key ];
            if ( shape.type === ShapeType.Connector ) {
                const textPos = this.getTextPosition( shape.id, text.id );
                if ( textPos ) {
                    // FIXME: This if condition should not be here, for some unknown reason getting
                    //        undefined. I didn't investigate why this is happening. Please fix this.
                    const textBounds = new Rectangle( textPos.x, textPos.y, text.width, text.height );
                    boundsWithText.absorb( textBounds );
                }
            } else if ( text && ( text as ShapeTextDataModel ).getBoundingBox ) {
                const textBounds =  ( text as ShapeTextDataModel ).getBoundingBox(
                    ( shape as ShapeDataModel ).defaultBounds,
                    ( shape as ShapeDataModel ).transform,
                );
                boundsWithText.absorb( textBounds );
            }
        }
        return boundsWithText;
    }
}
