import { Injectable } from '@angular/core';
import { Command, StateService, CommandInterfaces } from 'flux-core';
import { DataStore } from 'flux-store';
import { ModelSubscriptionManager } from 'flux-subscription';
import { AbstractMessageCommand } from 'flux-connection';

/**
 * This is the command to unarchive a project, The project can be unarchived by it's owner only
 */
@Injectable()
@Command()
export class UnarchiveProject extends AbstractMessageCommand {

    public static get implements(): Array<CommandInterfaces> {
        return [
            'IMessageCommand',
            'IProjectCommand',
        ];
    }

    constructor(
            protected dataStore: DataStore,
            protected modelSubManager: ModelSubscriptionManager,
            protected state: StateService<any, any> ) {
                super() /* istanbul ignore next */;
    }

    public execute(): any {
        return true;
    }
}

Object.defineProperty( UnarchiveProject, 'name', {
    value: 'UnarchiveProject',
});
