import { Inject, Injectable } from '@angular/core';
import { SharedStateService, StateService } from 'flux-core';
import { CollabModel, CollaboratorType, ProjectLocator, ProjectModel } from 'flux-diagram';
import { DataStore } from 'flux-store';
import { UserInfoModel } from 'flux-user';
import { isEqual } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { RealtimeStateService } from '../../base-states';
import { DiagramModel } from '../model/diagram.mdl';
import { DiagramLocatorLocator } from './diagram-locator-locator';

@Injectable()
export class CollabLocator {

    constructor(
        protected ll: DiagramLocatorLocator,
        protected pl: ProjectLocator,
        protected dataStore: DataStore,
        @Inject( SharedStateService ) protected sharedState: RealtimeStateService,
        @Inject( StateService ) protected state: StateService<any, any> ) {
    }

    /**
     * Returns the collaborators of the diagram which also contains
     * the realtime status of each user for the diagram. While this emits changes
     * to the collaborator, this also emits if the user's realtime information
     * changes as they happen as well.
     * Combines the shared state "Realtime" to the CollabModel.
     * Collaborator list is sorted by the collaborators role.
     * @param diagramId - diagram the collabs need to picked from
     * @return observable that emits the collborator list whenever the collab data or
     * their realtime status change.
     */
    public getCollabs( diagramId?: string, includeViewers = false ): Observable<CollabModel[]> {
        const diagramIdObs = !diagramId ? this.state.changes( 'CurrentDiagram' ).pipe(
            filter( dId => dId && ( dId !== 'start' )),
            take( 1 ),
        ) : of( diagramId );
        const fetchCollab = collab => (
            this.dataStore.findOne( UserInfoModel, { id: collab.id }).pipe(
                map( u => u || {}),
                takeWhile( u => !u.email, true ),
                map( user => Object.assign( new CollabModel( collab.id ), user, collab )),
            ));
        const diagramCollabChange = diagramIdObs.pipe( switchMap( dId => this.dataStore.findOneRaw( DiagramModel,
            { id: dId }).pipe(
                filter( diagram => !!diagram ),
                switchMap( diagram => combineLatest([
                    of( diagram ),
                    this.dataStore.findOneRaw( ProjectModel, { id: diagram.project }),
                ]).pipe(
                    map(([ diag, proj ]) => ([ diag, proj || {} ])),
                )),
                map(([ diagram, project ]) => ([ diagram.collabs as any[] || [], project.collabs as any[] || [] ])),
                distinctUntilChanged( isEqual ),
                map(([ diagramCollabs, projectCollabs ]) => {
                    projectCollabs.forEach( projCollab => {
                        if ( projCollab.role === CollaboratorType.OWNER ) {
                            projCollab.role = CollaboratorType.EDITOR;
                        }
                        if ( !diagramCollabs.find( collab => collab.id === projCollab.id )) {
                            projCollab.inharitedFromParent = true;
                            diagramCollabs.push( projCollab );
                        }
                    });
                    return diagramCollabs;
                }),
                switchMap( collabs => {
                    const observables = collabs.map( fetchCollab );
                    return combineLatest( observables );
                }),
        )));
        return combineLatest([
            diagramCollabChange,
            this.sharedState.changes( 'Realtime' ).pipe(
                map(() => this.sharedState.get( 'Realtime' )),
            ),
        ]).pipe(
            switchMap(([ collabs, userStates ]) => {
                if ( includeViewers ) {
                    const collabIds = collabs.map( collab => collab.id );
                    const viewerIds = Object.keys( userStates ).filter( id => !collabIds.includes( id ));
                    if ( viewerIds.length > 0 ) {
                        return combineLatest( viewerIds.map( id => ({
                            id, role: CollaboratorType.REVIEWER, createdTime: Date.now(), lastSeen: Date.now(),
                        })).map( fetchCollab )).pipe(
                            map( viewers => collabs.concat( viewers )),
                        );
                    }
                }
                return of( collabs );
            }),
            map( collabs => {
                if ( this.state.get( 'CurrentUser' )) {
                    collabs = collabs.map( collab => this.addRealtimeData( collab ));
                }
                return collabs.slice().sort(( c1, c2 ) => c1.roleSortOrder - c2.roleSortOrder );
            }),
        );
    }

    /**
     * Return a collaborator of the diagram by collaborator id with realtime status
     * @param id - collaborator user id
     * @return observable that emits the collborator whenever the data or
     * realtime status change.
     */
    public getCollab( id: string, includeViewers = false ): Observable<CollabModel> {
        return this.getCollabs( undefined, includeViewers ).pipe(
            map( collabs => collabs.find( collab => collab.id === id ),
        ));
    }

    /**
     * Returns the sorted list of collaborators of the diagram.
     * Emits whenever a collaborators details or their realtime status
     * changes. The collaborator list that is emitted is sorted by the role
     * regardless of the online status.
     * For detailed description, refer to <code>getCollabs</code> method.
     * @param diagramId - diagram the collabs need to picked from
     * @return observable that emits the collborator list whenever the collab data or
     * their realtime status change.
     */
     public getCollabsSortedIgnoringOnline( diagramId?: string ): Observable<CollabModel[]> {
        return this.getCollabs().pipe(
            map( collabs => collabs.slice().sort(( c1, c2 ) =>
                c1.roleSortOrderWithoutOnline - c2.roleSortOrderWithoutOnline )),
            );
    }

    /**
     * Returns the collaborators of the project the diagram is in
     */
    public getProjectCollabs(): Observable<CollabModel[]> {
        const projectId = this.state.get( 'CurrentProject' );
        if ( projectId === 'home' ) {
            return of([]);
        }
        return this.pl.getProject( projectId ).pipe(
            switchMap(( project: ProjectModel ) => of( project.collabs )),
        );
    }

    /**
     * Adds realtime data to a collab model.
     * @param collab - collab model to add realtime data into
     */
    private addRealtimeData( collab: CollabModel ): CollabModel {
        let state: any = this.sharedState.getUser( 'Realtime', collab.id );
        collab.online = !!state;
        state = state || {};
        collab.currentActivity = state.action;
        collab.color = state.color;
        return collab;
    }

}
