<div class="datasource-panel-container" [hidden]="( searchResult | async )?.length > 0">
    <div class="container">
        <div class="row">
        <mat-expansion-panel class="custom-search-expansion-panel" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="git-search-title body body--bold">
                    Advanced Search
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <div class="enitiy-search-item-type">
                    <label class="medium-text">Item Type </label>
                        <input class="form-control-input" type="text" matInput placeholder="Select Item Type" [formControl]="entityTypeSelectController"
                            [matAutocomplete]="dataTypeAuto" required>
                        <mat-autocomplete #dataTypeAuto="matAutocomplete" (optionSelected)="selectDataTypeSelector($event)">
                            <mat-option *ngFor="let option of dataTypesListObservable | async" [value]="option.name">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                </div> 
            </div>
            <div>
                <div class="entity-search-form">
                    <label class="medium-text">Which has</label>
                    <div #searchForms></div>
                </div>
            </div>
            <div>
                <div class="align-self-center button-raw">
                    <div class="search-button-row">
                        <button class="search-button-logic nu-btn-med fx-span-width fx-no-padding fx-no-margin"
                            mat-stroked-button (click)="addAnd()">AND</button>
                        <div class="medium-text"> | </div>
                        <button class="search-button-logic nu-btn-med fx-span-width fx-no-padding fx-no-margin"
                            mat-stroked-button (click)="addOr()">OR</button>
                    </div>
                </div>
                <div class="search-button-row">
                    <input class="form-control-input sortBy" type="text" matInput placeholder="SortBy" [formControl]="sortDataItemController"
                    [matAutocomplete]="sortByAuto" required>
                    <mat-autocomplete #sortByAuto="matAutocomplete" [displayWith]="dataItemListDisplayHelper">
                        <mat-option *ngFor="let option of sortDataItemListObservable | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-select class="form-control-input" [formControl]="sortByController" placeholder="None/ default">
                        <mat-option *ngFor="let sortBy of sortByList" [value]="sortBy.value">
                          {{sortBy.viewValue}}
                        </mat-option>
                      </mat-select>
                </div>
                <div class="align-self-center button-raw fx-margin-top-10">
                    <div class="search-button-row">
                        <button class="btn-medium btn-primary fx-span-width fx-no-padding fx-margin-right-8"
                            mat-stroked-button (click)="onSubmit()">Submit</button>
                        <button class="btn-medium btn-secondary fx-span-width fx-no-padding fx-no-margin"
                            mat-stroked-button (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        </div>
    </div>
</div>
<div *ngIf="( searchResult | async )?.length > 0">
    <edata-search-results-panel [searchResult]="searchResult" [searchQuery]="searchQuery" [eDataModel]="eDataModel" (goBack)="emptyResult()"></edata-search-results-panel>
</div>