import { Injectable } from '@angular/core';
import {
    IChainOutcome,
    IChainStatus,
    InitializationChainStatus,
    IResponsibility,
    Logger,
} from 'flux-core';
import { DataStore } from 'flux-store';
import { UserLocator, UserModel } from 'flux-user';
import { DiagramInfoModel } from 'flux-diagram';
import { combineLatest, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

/**
 * This is a responsibility used in the initialization sequence.
 * This responsibility will handle loading current user for plugins.
 */
@Injectable()
export class MicrosoftTeamsUserResponsibility implements IResponsibility {
    /**
     * Responsibility class name.
     */
    public name = 'MicrosoftTeamsUserResponsibility';

    constructor(
        protected logger: Logger,
        protected dataStore: DataStore,
        protected userLocator: UserLocator,
    ) {}

    public checkState( status: InitializationChainStatus ): Observable<boolean> {
        this.logger.debug( this.name + ' checkState' );
        return combineLatest(
            this.dataStore
                .findOne( DiagramInfoModel, { id: status.input.resourceId })
                .pipe( take( 1 )),
            this.userLocator.getUserData().pipe( take( 1 )),
        ).pipe(
            map(([ diagram, user ]) =>
                this.checkPermission( status, diagram, user ),
            ),
        );
    }

    /**
     * Execute the PluginDocumentResponsibility
     */
    public nextResponsibility( status: IChainStatus ): string[] {
        return [ 'PrivacyResponsibility' ];
    }

    /**
     * Check if user is navigating to edit route with limited editing capability
     */
    public result( status: InitializationChainStatus ): IChainOutcome {
        if ( status.states.MicrosoftTeamsUserResponsibility === false && status.input.action === 'edit' ) {
            return { type: 'route', action: `${status.input.resourceId}/view` };
        }
    }

    protected checkPermission(
        status: InitializationChainStatus,
        resource: DiagramInfoModel,
        user: UserModel,
    ): boolean {
        return resource.editable;
    }
}
