import { CarotaUtils, TextDataModel, TextPostion } from 'flux-diagram-composer';
import { ShapeTextModel } from './../../shape/model/text/shape-text.mdl';
import { DiagramModel } from './../../diagram/model/diagram.mdl';
import { splitModifier, TextFormatter } from 'flux-diagram-composer';
import { IModifier } from 'flux-core';
import { SelectionInteractionState } from './../../base-states';
import { ShapeTextEditorPositionService } from './shape-text-editor-position.svc';
import { TiptapDocumentsManager } from './../shape-data-editor/tiptap-documents-manager.cmp';
import { TiptapEditor } from './../../../framework/ui/components/tiptap/tiptap-editor.cmp';
import { filter, switchMap, pairwise, map, startWith, tap, takeUntil, take, mergeMap, mapTo, catchError } from 'rxjs/operators';
import { AfterViewInit, OnDestroy, Component, ChangeDetectionStrategy,
    ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, Injector, ElementRef } from '@angular/core';
import { EMPTY, merge, Subscription, Observable, Subject, of, forkJoin, NEVER, BehaviorSubject, from as rxJsFrom } from 'rxjs';
import { DiagramToViewportCoordinate } from '../../coordinate/diagram-to-viewport-coordinate.svc';
import { DiagramLocatorLocator } from '../../diagram/locator/diagram-locator-locator';
import { CommandService, StateService } from 'flux-core';
import { TiptapTextView } from '../../../framework/easeljs/tiptap-text-view';
import { DEFUALT_TEXT_STYLES, ITextContent, TextAlignment, TEXT_PADDING_HORIZONTAL } from 'flux-definition/src';
import { Editor, getHTMLFromFragment } from '@tiptap/core';
import { TiptapHelper } from '../../../framework/ui/components/tiptap/tiptap-helper';

// tslint:disable:member-ordering
// tslint:disable:max-file-line-count
/**
 * TiptapDocumentsManagerShapeText
 *
 * This component extends TiptapDocumentsManagerShape
 * It's required to maintain a separate tiptap document
 * for the shape texts since it should be possible to open notes section
 * shape text editor at the same time
 *
 * Destruction - Not like TiptapDocumentsManager, we can destroy the tiptap editor
 * as the selected diagram switches.
 * @author thisun
 * @since 2022 - 06 - 29
 */

@Component({
    template: `<div #viewContainer></div>`,
    selector: 'tiptap-documents-manager-shape-text',
    styleUrls: [ './tiptap-documents-manager-shape-text.cmp.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiptapDocumentsManagerShapeText extends TiptapDocumentsManager implements AfterViewInit, OnDestroy {

    /**
     * Tiptap documents ComponentRef
     * currently only one document is created for TiptapDocumentsManagerShapeText
     */
    public static documents: { [diagramId: string]: ComponentRef<TiptapEditor> } = {};

    /**
     * Notifies when the tiptap document for shape text is initialzed by emiting true
     */
    public static initialized: BehaviorSubject<string> = new BehaviorSubject( '' );

    public static initialTextPositionDone: BehaviorSubject<string> = new BehaviorSubject( '' );

    /**
     * To inform if shape(s) added by the current user. This subject is expected to be triggered
     * in the shapeAddedBinder. Identifying additioin of new shapes from the locator
     * will unnecceraly emits for all the collabs.
     */
    public static newShapesAdded: Subject<boolean> = new Subject();

    /**
     * To inform if a particular text is successfully rendered, failed or rendering ignored.
     * The main reason for adding this is to fix the flicker came up when switching between
     * text dom view and tiptap canvas rendered view
     */
    public static textRenderingState: Subject<any> = new Subject();

    /**
     * Notifies when the shape text editor dom element is initialed and positioned
     */
    public static textDomInitialized: BehaviorSubject<any> = new BehaviorSubject({});

    protected static newShapesAddedCollab: BehaviorSubject<any> = new BehaviorSubject({});

    protected static leftInvsible = 10000;

    protected subs: Subscription[] = [];

    @ViewChild( 'viewContainer', { read: ViewContainerRef, static: false })
    protected viewContainer: ViewContainerRef;

    constructor (
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected dtov: DiagramToViewportCoordinate,
        protected ll: DiagramLocatorLocator,
        protected er: ElementRef,
        protected commandSvc: CommandService,
        protected state: StateService<any, any>,
        protected shapeTextEditorPositionSvc: ShapeTextEditorPositionService,
    ) {
        super(
            componentFactoryResolver,
            injector,
            dtov,
            ll,
            er,
            commandSvc,
            state,
        );
    }

    public ngAfterViewInit() {
        this.subs.push(
            this.state.changes( 'CurrentDiagram' ).pipe(
                startWith( null ),
                pairwise(),
                map(([ preDiagramId, diagramId ]) => {
                    const documents = this.getDocuments();
                    if ( preDiagramId && documents[ preDiagramId ]) {
                        documents[ preDiagramId ].destroy();
                        this.removeStyleElements( preDiagramId );
                        delete documents[ preDiagramId ];
                    }
                    return diagramId;
                }),
                switchMap( diagramId => {
                    if ( diagramId && diagramId !== 'start' ) {
                        TiptapDocumentsManagerShapeText.initialTextPositionDone.next( diagramId );
                        return this.manageDocument( diagramId ).pipe( tap(() =>  {
                            // TiptapDocumentsManagerShapeText.initialized.next( diagramId );
                        }));
                    }
                    return NEVER;
                }),
                switchMap(() => merge(
                    this.manageShapeTextEditorPosition(),
                    this.managePositionForTextWrapWidthChange(),
                    this.manageShapeTextEditing(),
                    this.manageShapeDeletes(),
                    this.positionInitialShapeTexts().pipe(
                        tap( x => {
                            TiptapDocumentsManagerShapeText.initialized.next( this.state.get( 'CurrentDiagram' ));
                        }),
                    ),
                    this.positionNewlyAddedShapeTexts(),
                    this.positionNewlyAddedShapeTextsCollab(),
                )),
            ).subscribe(),
        );
    }

    public static textDomInitiallized( dId: string, sid: string, tid: string ) {
        const positioned = TiptapDocumentsManagerShapeText.getPositionStyleElement( dId, sid, tid );
        if ( positioned ) {
            return of( true );
        } else {
            const textEditor = TiptapDocumentsManagerShapeText.getTextElement( dId, sid, tid );
            if ( textEditor ) {
                TiptapDocumentsManagerShapeText.newShapesAddedCollab
                    .next({ diagramId: dId, shapeId: sid, textId: tid });
            }
            return TiptapDocumentsManagerShapeText.textDomInitialized.pipe(
                filter(({ diagramId, shapeId, textId }) => diagramId === dId && shapeId === sid && textId === tid ),
                take( 1 ),
                map(() => true ),
            );
        }
    }

    public static getHtmlElement( diagramId: string, sid: string, tid: string ): Observable<HTMLElement> {
        return TiptapDocumentsManagerShapeText.textDomInitiallized( diagramId, sid, tid ).pipe(
            switchMap(()  => {
                const { domNode } = TiptapDocumentsManagerShapeText.getChildEditorNode( diagramId, sid, tid );
                if ( domNode ) {
                    return of( domNode );
                }
                return NEVER;
            }),
            // switchMap( domNode => TiptapDocumentsManagerShapeText.waitUntillImagesAreLoaded( domNode )),
            // delay( 0 ),
            // // NOTE: mapping domNode didn't work, find why
            // map(() =>  TiptapDocumentsManagerShapeText.getChildEditorNode( diagramId, sid, tid ).domNode ),
            take( 1 ),
        );
    }

    public static waitUntillImagesAreLoaded( domNode: HTMLElement ) {
        const imgs = domNode.querySelectorAll( 'img' );
        const promises = [ Promise.resolve( true ) ];
        imgs.forEach( image =>  {
            if ( image.complete ) {
                return;
            }
            promises.push( new Promise( resolve => {
                const old = image.onload as any;
                image.onload = ( e: Event ) => {
                    if ( old ) {
                        old( e );
                    }
                    resolve( true );
                };
            }));
        });
        return rxJsFrom( Promise.all( promises )).pipe( mapTo( domNode ));
    }

    protected static logParents( htmlEl: HTMLElement ) {
        let parent = htmlEl.parentElement;
        let name = '';
        while ( parent ) {
            name = name + ' | ' + parent.nodeName;
            parent = parent.parentElement;
        }
        return name;
    }

    /**
     * Remove all the shape text editor nodes for the given shapeId
     * @param diagramId
     * @param shapeId
     */
    protected removeShapeTextNodes( diagramId: string, shapeId: string ) {
        const elements = document.querySelectorAll(
            `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"]` ) || [];
        elements.forEach(( el: HTMLElement ) => {
            this.removeStyleElements( `${diagramId}-${shapeId}` );
            el.remove();
        });
    }

    /**
     * Remove shape text nodes which are no longer available in the diagram but still
     * found in the tiptap document
     * @param diagramId
     * @param shapeId
     */
    protected cleanUpTextNodes( diagramId: string, shapeIds: string[]) {
        const elements = document.querySelectorAll(
            `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"]` ) || [];
        elements.forEach(( el: HTMLElement ) => {
            const shapeId = el.getAttribute( 'shapeId' );
            if ( !shapeIds.includes( shapeId )) {
                this.removeStyleElements( `${diagramId}-${shapeId}` );
                el.remove();
            }
        });
    }

    /**
     * Creates a tiptap editor for the diagram if not already created and mmanages shapeDeletes
     * @param diagramId
     */
    public manageDocument( diagramId ) {
        return this.getEditor( diagramId );
    }

    public getDocuments() {
        return TiptapDocumentsManagerShapeText.documents;
    }

    /**
     * Destroys the toolbar
     */
    public ngOnDestroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * Updates the tiptap editor according to the given modifier
     * @param change
     */
    public static updateTiptp( c: IModifier, model: DiagramModel ) {
        const split = splitModifier( c );

        // NOTE: Handle delete shapes and undo ( it's like added a new shape )
        for ( const key in c.$set ) {
            const parts = key.split( '.' );
            const shapeAdded = parts.length === 2 && parts[0] === 'shapes';
            if ( shapeAdded ) {
                TiptapDocumentsManagerShapeText.newShapesAdded.next( true );
            }
        }
        const shapes = Object.keys( split.shapes || {})
            .map( key => {
                const regex = /(texts\.([^\.]+)\.html)|(texts\.([^\.]+)\.content)/g;
                const keys = Object.keys( split.shapes[ key ].$set || {}).filter( k => regex.test( k ));
                return keys.length > 0 ? model.shapes[ key ] : null;
            })
            .filter( v => !!v ) as any;
        shapes.map( shapeMdl => {
            Object.keys( shapeMdl.texts ).map( textId => {
                const textModel = shapeMdl.texts[ textId ];
                const { from, to, editor } = TiptapDocumentsManagerShapeText
                    .getChildEditorNode( model.id, shapeMdl.id, textId );
                if ( editor ) {
                    const rendering = split.shapes[ shapeMdl.id ].$set[ `texts.${ textModel.id }.rendering` ]
                        || ( textModel as any ).rendering;
                    if ( rendering === 'tiptapCanvas' ) {
                        const html = split.shapes[ shapeMdl.id ].$set[ `texts.${ textModel.id }.html` ];
                        if ( html ) {
                            editor.chain()
                                .focus()
                                .insertContentAt({ from: from - 1, to }, html )
                                .blur()
                                .run();
                        }
                    } else {
                        const content = split.shapes[ shapeMdl.id ].$set[ `texts.${ textModel.id }.content` ];
                        if ( content ) {
                            editor.chain()
                                .focus()
                                .insertContentAt({ from: from - 1, to },
                                    TiptapHelper.convertCarotaToTiptap( content ))
                                .blur()
                                .run();
                        }
                    }
                }

            });
        });

    }

    /**
     * Updates the tiptap editor according to the given modifier
     * @param diagramId
     * @param shapeId
     * @param textModel
     * @param force If true, new text node will be created if not already created
     */
    public static updateTiptpChildEditorNode( diagramId: string, shapeId: string, textModel, force = false ) {
        const val: any = TiptapDocumentsManagerShapeText
            .getChildEditorNode( diagramId, shapeId, textModel.id ) || {};

        let editorObservable: Observable<any> = of( val );
        if ( force && !val.editor ) {
            editorObservable = TiptapDocumentsManagerShapeText
                .init( textModel, diagramId, shapeId, textModel.id ).pipe(
                    take( 1 ),
                    map(() =>
                        TiptapDocumentsManagerShapeText.getChildEditorNode( diagramId, shapeId, textModel.id ) || {},
                    ),
                );
        }

        editorObservable.subscribe( value => {
            const { from, to, editor } = value;
            if ( editor ) {
                const rendering = textModel.rendering;
                if ( rendering === 'tiptapCanvas' ) {
                    const html = textModel.html;
                    editor.chain()
                        .focus()
                        .insertContentAt({ from: from - 1, to }, html )
                        .blur()
                        .run();
                } else {
                    const content = textModel.content;
                    editor.chain()
                        .focus()
                        .insertContentAt({ from: from - 1, to },
                            TiptapHelper.convertCarotaToTiptap( content ))
                        .blur()
                        .run();
                }
            }
        });

    }

    public static removeDuplicates( diagramId ) {
        const elements = document.querySelectorAll(
            `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"]` ) || [];
        const ids = [];
        elements.forEach(( el: HTMLElement ) => {
            const id = el.getAttribute( 'data-editor-id' );
            if ( ids.includes( id )) {
                el.remove();
            } else {
                ids.push( el.getAttribute( 'data-editor-id' ));
            }
        });
    }

    public static removeDuplicatesByIds( diagramId, shapeId, textId ) {
        const elements = document.querySelectorAll(
            `tiptap-editor-component[id="${ diagramId }"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"]` ) || [];
        elements.forEach(( el: HTMLElement, i ) => {
            if ( i > 0 ) {
                el.remove();
            }
        });
    }

    public static removeEmptyParas( diagramId ) {
        const elements = document.querySelectorAll(
                `tiptap-editor-component[id="${diagramId}"].shapeText .tiptap-editor > div > p` )
            || [];
        elements.forEach(( el: HTMLElement ) => {
            el.remove();
        });
    }

    /**
     * Create RichTextEditorUIC component and set initial value
     */
    protected createTiptap( diagramId: string ): Observable<ComponentRef<TiptapEditor>> {
        const itemRefTiptap: ComponentRef<TiptapEditor> = this.makeComponent( TiptapEditor );
        const tipTapEditorCmp = itemRefTiptap.instance;
        tipTapEditorCmp.id = 'shapeText';
        tipTapEditorCmp.placeHolderText = '';
        tipTapEditorCmp.diagramId = diagramId;
        tipTapEditorCmp.showTableToolbar = false;
        tipTapEditorCmp.transformPasted = v => this.transformPasted( v );
        tipTapEditorCmp.transformPastedHTML = v => this.transformPastedHTML( v );
        tipTapEditorCmp.handleKeydown = ( v, e ) => this.handleEnterKeyDown( v, e, diagramId );
        this.insert( this.viewContainer, itemRefTiptap );
        itemRefTiptap.changeDetectorRef.detectChanges();
        return itemRefTiptap.instance.localSynced.pipe(
            filter( v => !!v ),
            take( 1 ),
            map(() => itemRefTiptap ),
        );
    }

    protected handleEnterKeyDown( view, event, diagramId ) {
        const { shiftKey, key } = event;
        // Disable shift + enter for shape texts since it execute plus create
        if ( shiftKey && key === 'Enter' ) {
            event.preventDefault();
            // const slashMenuOpened = view.domSelection().anchorNode.data === '/' ||
            //     view.domSelection().anchorNode?.querySelector?.( 'span.suggestion' );
            // if ( slashMenuOpened ) {
            //     ( event as any ).slashMenuOpened = true;
            // } else if ( !shiftKey ) {
            //     event.preventDefault();
            // }
        }

        const editor = TiptapDocumentsManagerShapeText.documents[ diagramId ]?.instance.editor;
        if ( key === 'Enter' || event.key === ' ' ) {
            const cursor = ( editor as any ).state.selection.$cursor;
            const textBeforeCursor = cursor.parent.textBetween( 0, cursor.parentOffset, '\n', '\n' );
            const firstCharacter = textBeforeCursor.trim().charAt( 0 );

            if ([ '-', '+', '*' ].includes( firstCharacter ) && textBeforeCursor.length === 1 ) {
                this.usesPlainText( diagramId ).subscribe({
                    next: value => {
                        if ( !!value ) {
                            const action = key === 'Enter' ? '<p></p>' : ' ';
                            editor.commands.insertContent( action );
                            event.preventDefault();
                        }
                    },
                });
            }
        }
        return false;
    }

    protected usesPlainText ( diagramId ): Observable<boolean> {
        return this.ll.forDiagram( diagramId, false ).getDiagramOnce().pipe(
            map( diagram => {
                const shape = diagram.shapes[ this.state.get( 'Selected' )[0]];
                return shape.usesPlainText;
            }),
        );
    }

    /**
     * This hook is to pre process pasted content, specialy font and font size should be
     * altered to match with font families and sizes suported by creately
     */
    protected transformPasted( data: any ) {
        const content = data.content;
        const normalize = c => {
            if ( !c ) {
                return;
            }
            c.forEach( cc => {
                cc.marks.forEach( mark => {
                    if ( mark?.attrs?.fontFamily ) {
                        mark.attrs.fontFamily = TiptapHelper.validateFont( mark.attrs.fontFamily );
                    }
                    if ( mark?.attrs?.fontSize ) {
                        mark.attrs.fontSize = TiptapHelper.validateFontSize( mark.attrs.fontSize );
                    }
                });
                normalize( cc.content );
            });
        };
        normalize( content );
        return data;
    }

    protected transformPastedHTML( html: string ) {
        // NOTE:
        // Connector texts don't support tiptap rendering,
        // The content that connot be rendered with carota will be ommited
        if ( this.state.get( 'EditingText' )?.npm  ) {
            try {
                const tf = new TextFormatter();
                const carota =  tf.applyRaw( html, {
                    indexStart: 0,
                    indexEnd: -1,
                    styles: { ...DEFUALT_TEXT_STYLES },
                });
                return CarotaUtils.convertToHTML( carota );
            } catch ( error ) {
                const div = document.createElement( 'div' );
                div.innerHTML = html;
                return div.innerText;
            }
        }

        const el = document.createElement( 'div' );
        el.innerHTML = html;
        const elements = el.querySelectorAll( 'div[data-type="detailsContent"]' );
        elements.forEach( elm => {
            elm.removeAttribute( 'data-type' );
        });
        html = el.innerHTML;
        return html;
    }

    public static getChildEditorNode( diagramId: string, shapeId: string, textId?: string ):
        { domNode: HTMLElement, node?: any, from?: number, to?: number, editor?: Editor } {
        const editor = TiptapDocumentsManagerShapeText.documents[ diagramId ]?.instance.editor;
        const domNode: HTMLElement = editor ? editor.view.dom.querySelector(
            `tiptap-editor-component[id="${ diagramId }"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"]`,
        ) : null;
        if ( domNode ) {
            return TiptapDocumentsManagerShapeText.getNodeData( editor, domNode );
        }
        return { domNode };
    }

    public static getChildEditorNodeAsync( diagramId: string, shapeId: string, textId?: string ):
        Observable<{ domNode: HTMLElement, node?: any, from?: number, to?: number, editor?: Editor }> {
        const editor = TiptapDocumentsManagerShapeText.documents[ diagramId ].instance.editor;
        const domNode: HTMLElement = editor.view.dom.querySelector(
            `tiptap-editor-component[id="${ diagramId }"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"]`,
        );
        if ( !domNode  ) {
            return EMPTY;
            // FIXME: Commented out to fix default text not getting apllyed when plus creating.
            // and will introduce empty text styling issue
            // return TiptapDocumentsManagerShapeText
            //     .init( '<p style="text-align: center"></p>', diagramId, shapeId, textId ).pipe(
            //     take( 1 ),
            //     map(() => {
            //         domNode = editor.view.dom.querySelector(
            // tslint:disable-next-line: max-line-length
            //             `tiptap-editor-component[id="${ diagramId }"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"]`,
            //         );
            //         return TiptapDocumentsManagerShapeText.getNodeData( editor, domNode );
            //     }),
            // );
        } else {
            return of( TiptapDocumentsManagerShapeText.getChildEditorNode( diagramId, shapeId, textId ));
        }
    }

    protected static getNodeData ( editor, domNode ) {
        const pos = editor.view.posAtDOM( domNode as HTMLElement, 0 ) + 1;
        const $pos = editor.view.state.doc.resolve( pos );
        for ( let d = $pos.depth; d > 0; d-- ) {
            const node = $pos.node( d );
            if ( node.type.name === 'tiptapChildEditor' ) {
                return { domNode, node, from: pos, to: pos + node.nodeSize - 4, editor };
            }
        }
    }

    /**
     * Switch to shape text html view and update it's position when the shape is resized
     * @returns
     */
    protected manageShapeTextEditorPosition() {
        const started = this.state.changes( 'SelectionInteractionStateScaleX' ).pipe(
            filter( state => state === SelectionInteractionState.Started ),
        );
        const stopped = this.state.changes( 'SelectionInteractionStateScaleX' ).pipe(
            filter( state => state === SelectionInteractionState.Stopped ),
        );
        return started.pipe(
            switchMap(() => {
                const textViews = {};
                return this.ll.forCurrent( true ).getDiagramChanges().pipe(
                    switchMap( c => {
                        const ids = this.state.get( 'Selected' );
                        const obs = ids.map( id => {
                            const shapeMdl =  c.model.shapes[ id ];
                            if ( shapeMdl.isConnector()) {
                                return EMPTY;
                            }
                            const textObs = Object.keys( shapeMdl.texts ).map( textId => {
                                const textView = this.getTextView( id, textId );
                                if ( textView ) {
                                    if ( !( textView instanceof TiptapTextView  )) {
                                        return EMPTY;
                                    }
                                    if ( !textViews[ id ]) {
                                        textViews[ id ] = {};
                                    }
                                    textViews[ id ][textView.name] = textView;
                                    const editor = TiptapDocumentsManagerShapeText
                                        .documents[ c.model.id ].instance.editor;
                                    editor.commands.blur();
                                    TiptapDocumentsManagerShapeText.setShapeTextNodeVisibility(
                                        c.model.id,
                                        id,
                                        textId,
                                        'visible',
                                    );
                                    textView.visible = false;
                                    this.state.set( 'UpdateDiagramCanvas', true );
                                }
                                const textModel = shapeMdl.texts[ textId ];
                                const sel = `tiptap-editor-component[id="${c.model.id}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ id }"][textId="${ textId }"] .tiptap-child-editor-content`;
                                return this.shapeTextEditorPositionSvc.updateLocation( sel, id, textModel, EMPTY ).pipe(
                                    tap(({ positionStyles }) => {
                                        TiptapDocumentsManagerShapeText.handlePositionStyles(
                                            c.model.id,
                                            id,
                                            textModel.id,
                                            positionStyles,
                                            'none',
                                        );
                                    }));
                            });
                            return merge( ...textObs );
                        });
                        return merge( ...obs );
                    }),
                    takeUntil( stopped.pipe( tap(() => {
                            Object.keys( textViews ).forEach(( shapeId: any ) => {
                                Object.keys( textViews[ shapeId ]).forEach( textId => {
                                    this.handleShowTextView( shapeId, textId ).subscribe();
                                });
                            });
                        })),
                    ),
                );
            }),
        );
    }

    /**
     * Updates the position of the shape text html element when the wrapWidth property of the textModel
     * is updated
     */
    protected managePositionForTextWrapWidthChange() {
        return this.ll.forCurrent( false ).getDiagramChanges().pipe( switchMap( c => {
            const shapes = Object.keys( c.split.shapes || {})
                .map( key => {
                    const regex = /texts\.([^\.]+)\.wrapWidth$/;
                    const keys = Object.keys( c.split.shapes[ key ].$set || {}).filter( k => regex.test( k ));
                    return keys.length > 0 ? c.model.shapes[ key ] : null;
                })
                .filter( v => !!v ) as any;
            const diagramId = this.state.get( 'CurrentDiagram' );
            const obs = shapes.map( shapeMdl => {
                if ( shapeMdl.isConnector()) {
                    return EMPTY;
                }
                const textObs = Object.keys( shapeMdl.texts ).map( textId => {
                    const textModel: TextDataModel = shapeMdl.texts[ textId ];
                    if ( !textModel.plainText ) {
                        return EMPTY;
                    }
                    const sel = `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeMdl.id }"][textId="${ textId }"] .tiptap-child-editor-content`;
                    return TiptapDocumentsManagerShapeText.init(
                        textModel,
                        diagramId,
                        shapeMdl.id,
                        textId,
                    ).pipe( switchMap(() => this.shapeTextEditorPositionSvc
                        .updateLocation( sel, shapeMdl.id, textModel, EMPTY ).pipe(
                            take( 1 ),
                            map(({ positionStyles }) => {
                                TiptapDocumentsManagerShapeText.handlePositionStyles(
                                    diagramId,
                                    shapeMdl.id,
                                    textModel.id,
                                    positionStyles,
                                    'none',
                                );
                                this.handleShowTextView( shapeMdl.id, textId ).subscribe();
                                return { shapeId: shapeMdl.id, textId: textId };
                            }))));
                });
                return merge( EMPTY, ...textObs );
            });
            return merge( EMPTY, ...obs );
        }));
    }

    /**
     * Hide the canavs text view when the text edit mode is activated and show the canvas text
     * view again when the editing is done/fialed/ignored
     * @returns
     */
    protected manageShapeTextEditing() {
        return this.state.changes( 'EditingText' ).pipe(
            mergeMap( state => this.ll.forCurrent( false ).getDiagramOnce().pipe(
                switchMap( dModel => state.shapeId && state.textId && dModel.shapes[ state.shapeId ] ?
                    this.positionExisitingTexts([{
                        shapeId: state.shapeId,
                        textModel: dModel.shapes[ state.shapeId ].texts[ state.textId ] }], 'auto' ) : EMPTY ),
                mergeMap(() => {
                    const textView = this.getTextView( state.shapeId, state.textId );
                    if ( state.rendering === 'dom' ) {
                        return EMPTY;
                    }

                    if ( state.open ) {
                        if ( textView  ) {
                            textView.visible = false;
                            this.state.set( 'UpdateDiagramCanvas', true );
                        }
                        return this.handleShowTextView( state.shapeId, state.textId );
                    }
                    if ( !state.open && textView && state.rendering !== 'tiptapCanvas' && state.rendering !== 'dom' ) {
                        // NOTE: Ideally this shoule be set when the carota text
                        // rendering is done, but since it's fast, doing here is fine.
                        TiptapDocumentsManagerShapeText.textRenderingState.next({
                            shapeId: state.shapeId,
                            state: 'completed',
                            textId: state.textId,
                        });
                    }
                    return EMPTY;
                }),
                catchError( err => {
                    TiptapDocumentsManagerShapeText.textRenderingState.next({
                        shapeId: state.shapeId,
                        state: 'completed',
                        textId: state.textId,
                    });
                    return EMPTY;
                }),
            )),
        );
    }

    protected getTextView( shapeId: string, textId: string ) {
        const shapeDisplayView = this.state.get( 'ShapeViews' )
            .find( child => child.name === shapeId ) as any;
        const tv =  shapeDisplayView?.children
            .find( child => child.name === textId );
        return tv;
    }


    /**
     * Listen to textRenderingState subject and handle the visibilty of shape text html and canavs views
     * @param shapeId
     * @param textId
     */
    protected handleShowTextView( shapeId: string, textId: string  ) {
        return TiptapDocumentsManagerShapeText.textRenderingState.pipe(
            filter( rState => rState && rState.shapeId === shapeId
                && ( rState.state === 'completed' || rState.state === 'ignored' || rState.state === 'failed' )
                && rState.textId === textId ),
            take( 1 ),
            tap(() => {
                const textView = this.getTextView( shapeId, textId );
                if ( textView && textView?.model?.rendering !== 'dom' ) {
                    textView.visible = true;
                    TiptapDocumentsManagerShapeText.setShapeTextNodeVisibility(
                        this.state.get( 'CurrentDiagram' ),
                        shapeId,
                        textId,
                        'hidden',
                    );
                }
                this.state.set( 'UpdateDiagramCanvas', true );
            }),
        );
    }

    /**
     * When a diagram, which already has texts is loaded, those shapes should be positioned
     * prior to rendering.
     * @returns
     */
    protected positionInitialShapeTexts() {
        // const textViews = {};
        return this.ll.forCurrent( false ).getDiagramOnce().pipe(
            map( d => Object.values( d.shapes )),
            switchMap(( shapes: any []) => {
                if ( shapes && shapes.length > 0 ) {
                    return this.positionInitial( shapes );
                }
                return of({});
            }),
        );
    }

    protected positionNewlyAddedShapeTexts() {
        // const textViews = {};
        return  TiptapDocumentsManagerShapeText.newShapesAdded.pipe(
            switchMap(() => this.ll.forCurrent( false ).getNewlyAddedShapes().pipe(
                take( 1 ),
            )),
            switchMap(( shapes: any []) => this.positionInitial( shapes )),
        );
    }

    protected positionNewlyAddedShapeTextsCollab() {
        // const textViews = {};
        return  TiptapDocumentsManagerShapeText.newShapesAddedCollab.pipe(
            switchMap( val => {
                if ( val?.shapeId ) {
                    return this.ll.forCurrent( false ).getShapeOnce( val.shapeId );
                }
                return NEVER;
            }),
            filter( v => !!v ),
            switchMap(( shape: any ) => this.positionExisitingTexts(
                Object.values( shape.texts ).map( t => ({ shapeId: shape.id, textModel: t })),
            )),
        );
    }

    protected positionExisitingTexts( texts: Array<{ shapeId: string, textModel }> , pointerEvents = 'none' ) {
        const diagramId = this.state.get( 'CurrentDiagram' );
        const textObs = texts.map( data => {
            const { textModel, shapeId } = data;
            if ( textModel && shapeId ) {
                const sel = `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textModel.id }"] .tiptap-child-editor-content`;
                return this.shapeTextEditorPositionSvc
                    .updateLocation( sel, shapeId, textModel, EMPTY ).pipe(
                        take( 1 ),
                        map(({ positionStyles }) => {
                            TiptapDocumentsManagerShapeText.handlePositionStyles(
                                diagramId,
                                shapeId,
                                textModel.id,
                                positionStyles,
                                pointerEvents,
                            );
                            TiptapDocumentsManagerShapeText
                                .textDomInitialized.next({ diagramId, shapeId, textId: textModel.id });
                            TiptapDocumentsManagerShapeText.removeDuplicates( diagramId );
                            return { shapeId, textId: textModel.id };
                        }));
            }
            return of({});
        });
        if ( textObs.length > 0 ) {
            return forkJoin( ...textObs );
        }
        return of({});
    }

    protected positionInitial( shapes: any []) {
        const diagramId = this.state.get( 'CurrentDiagram' );
        const obs = shapes.map( shapeMdl => {
            if ( shapeMdl.isConnector()) {
                return of({});
            }
            const textObs = Object.keys( shapeMdl.texts ).map( textId => {
                const textModel = shapeMdl.texts[ textId ] as ShapeTextModel;
                if ( textModel.rendering !== 'tiptapCanvas' && textModel.rendering !== 'dom' ) {
                    return of({});
                }
                const sel = `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeMdl.id }"][textId="${ textId }"] .tiptap-child-editor-content`;
                return TiptapDocumentsManagerShapeText.init(
                    textModel,
                    diagramId,
                    shapeMdl.id,
                    textId,
                ).pipe( switchMap(() => this.shapeTextEditorPositionSvc
                    .updateLocation( sel, shapeMdl.id, textModel, EMPTY ).pipe(
                        take( 1 ),
                        map(({ positionStyles }) => {
                            TiptapDocumentsManagerShapeText.handlePositionStyles(
                                diagramId,
                                shapeMdl.id,
                                textModel.id,
                                positionStyles,
                                'none',
                            );
                            // this.handleShowTextView( shapeMdl.id, textId ).subscribe({
                            //     complete: () => TiptapDocumentsManagerShapeText.setTiptapDocumentZIndex( 'restore' ),
                            // });
                            TiptapDocumentsManagerShapeText
                                .textDomInitialized.next({ diagramId, shapeId: shapeMdl.id, textId: textId });
                            TiptapDocumentsManagerShapeText.removeDuplicates( diagramId );
                            return { shapeId: shapeMdl.id, textId: textId };
                        }))));
            });
            if ( textObs.length > 0 ) {
                return forkJoin( ...textObs );
            }
            return of({});
        });
        if ( obs.length > 0 ) {
            return forkJoin( ...obs );
        }
        return of({});
    }

    /**
     * css styles to locate the shape text editors in the proper position cannot be added to
     * tiptap child editor element itself ( inline styles ) so the styles are injected to header
     * @param diagramId
     * @param shapeId
     * @param textId
     * @param positionStyles
     * @param pointerEvents
     */
    public static handlePositionStyles( diagramId, shapeId, textId, positionStyles, pointerEvents = 'auto' ) {
        const dataId = `${shapeId}-${textId}`;
        let styleTag = TiptapDocumentsManagerShapeText.getPositionStyleElement( diagramId, shapeId, textId );
        if ( !styleTag ) {
            const s = document.createElement( 'style' );
            s.setAttribute( 'data-editor-id', `${diagramId}-${dataId}` );
            document.head.appendChild( s );
            styleTag = s;
        }
        styleTag.innerHTML = `div[data-editor-id="${ dataId }"]{ ${ positionStyles.parentStyleString } position: absolute; pointer-events: ${ pointerEvents } }
            div[data-editor-id="${ dataId }"] .tiptap-child-editor-content { ${ positionStyles.childStylesString } }`;

        // This is to set the list marks indentation properly according to the
        // font size
        TiptapDocumentsManagerShapeText.handleListMarks( diagramId, shapeId, textId );
    }

    public static handleListMarks( diagramId, shapeId, textId ) {
        const dataId = `${diagramId}-${shapeId}-${textId}-list`;
        let styleTag =  document.head.querySelector( `style[data-editor-id="${dataId}"]` );
        if ( !styleTag ) {
            const s = document.createElement( 'style' );
            s.setAttribute( 'data-editor-id', dataId );
            document.head.appendChild( s );
            styleTag = s;
        }

        const el = document.querySelector(
            `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"] .tiptap-child-editor-content`,
        ) as HTMLElement;

        const liElements = el.querySelectorAll( 'li' );
        const liElementsArray = Array.from( liElements );
        const fontSize = Math.max( ...liElementsArray.map( li => {
            const span = li.querySelector( 'span' );
            if ( span && span.innerText.trim() !== '' ) {
                return parseFloat( span.style.fontSize );
            }
            return 0;
        }));
        if ( isFinite( fontSize ) && fontSize > 0 ) {
            styleTag.innerHTML = `
                tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"] ol,
                tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"] ul {
                    padding-left: ${ TextPostion.getListPaddingforFontSize( fontSize )}rem !important;
                }`;
        } else {
            styleTag.innerHTML = '';
        }
    }

    public static isEditorInitialized( diagramId: string, shapeId: string, textId: string ) {
        const el = document.querySelector(
            `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"] .tiptap-child-editor-content`,
        ) as HTMLElement;
        return !!el;
    }

    public static getTextBounds( diagramId, shapeId, textId, wrapWidth?: number ) {
        const el = document.querySelector(
            `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"] .tiptap-child-editor-content`,
        ) as HTMLElement;
        if ( !el ) {
            return { width: 0, height: 0 };
        }
        const cssTextEl = el.style.cssText;
        const cssTextParent = el.parentElement.style.cssText;
        el.parentElement.style.cssText = 'transform:none;width:auto;position:relative;display:block !important;content-visibility:visible !important';
        el.style.cssText = 'transform:none';
        if ( wrapWidth !== undefined ) {
            el.style.cssText = `transform:none;width:${wrapWidth}px;white-space:unset`;
        } else {
            el.style.cssText = 'transform:none';
        }
        const rect = el.getBoundingClientRect();
        const width = rect.width;
        const height  = rect.height;
        const y = rect.top;
        const x = rect.left;
        el.style.cssText = cssTextEl;
        el.parentElement.style.cssText = cssTextParent;
        return { x, y, width, height };
    }

    public static getTextElement(  diagramId, shapeId, textId ) {
        const el = document.querySelector(
            `tiptap-editor-component[id="${diagramId}"].shapeText div[data-type="tiptap-child-editor-node"][shapeId="${ shapeId }"][textId="${ textId }"]`,
        ) as HTMLElement;
        return el;
    }


    public static getPositionStyleElement( diagramId, shapeId, textId ) {
        const dataId = `${diagramId}-${shapeId}-${textId}`;
        return document.head.querySelector( `style[data-editor-id="${dataId}"]` );
    }

    /**
     * Show/ hide shape text html view
     * @param diagramId
     * @param shapeId
     * @param textId
     * @param display
     */
    public static setShapeTextNodeVisibility( diagramId, shapeId, textId, display ) {
        const dataId = `${shapeId}-${textId}`;
        let styleTag = document.head.querySelector( `style[data-editor-id="${diagramId}-${dataId}-visibility"]` );
        if ( !styleTag ) {
            const s = document.createElement( 'style' );
            s.setAttribute( 'data-editor-id', `${diagramId}-${dataId}-visibility` );
            document.head.appendChild( s );
            styleTag = s;
        }
        styleTag.innerHTML = `div[data-editor-id="${ dataId }"]{
            display: ${display === 'hidden' ? 'none' : 'block' } !important;
            content-visibility: ${display === 'hidden' ? 'auto' : 'visible' } !important;
        }`;
    }

    /**
     * Set back
     */
    public static setBackgroundColor( color: string ) {
        let styleTag = document.getElementById( 'shape-text-background-color' );
        if ( !styleTag ) {
            const s = document.createElement( 'style' );
            s.id = 'shape-text-background-color';
            document.head.appendChild( s );
            styleTag = s;
        }
        styleTag.innerHTML = `shape-text-editor .tiptap-child-editor-content {
            background-color: ${color};
            border-radius: 2.5px;
            box-shadow: 0px 0px 0px 2.5px ${color};
        }`;
    }

    public static setDefaultTextColor( color: string ) {
        let styleTag = document.getElementById( 'shape-text-default-text-color' );
        if ( !styleTag ) {
            const s = document.createElement( 'style' );
            s.id = 'shape-text-default-text-color';
            document.head.appendChild( s );
            styleTag = s;
        }
        if ( color ) {
            styleTag.innerHTML = `shape-text-editor .text-editor .prose p {
                color: ${color} !important;
            }`;
        } else {
            styleTag.innerHTML = '';
        }
    }

    /**
     * Make the text editor invisible for the user but available for tiptap rendering
     * @param diagramId
     * @param shapeId
     * @param textId
     */
    public static makeShapeTextNodeRenderable( diagramId, shapeId, textId ) {
        const dataId = `${shapeId}-${textId}`;
        let styleTag = document.head.querySelector( `style[data-editor-id="${diagramId}-${dataId}-visibility"]` );
        if ( !styleTag ) {
            const s = document.createElement( 'style' );
            s.setAttribute( 'data-editor-id', `${diagramId}-${dataId}-visibility` );
            document.head.appendChild( s );
            styleTag = s;
        }
        styleTag.innerHTML = `div[data-editor-id="${ dataId }"]{
            display: block !important;
            left: -${TiptapDocumentsManagerShapeText.leftInvsible}px !important;
        }`;
    }

    /**
     * Removeds the style tags by the data id
     * @param dataId
     */
    public removeStyleElements( dataId ) {
        document.head.querySelectorAll( `style[data-editor-id^="${dataId}"]` ).forEach( e => e.remove());
    }

    /**
     * Reset and restore the zoom level of the text html node
     * @param diagramId
     * @param shapeId
     * @param textId
     * @param value 'reset' | 'restore'
     */
    public static setShapeTextNodeZoomlevel( diagramId, shapeId, textId, value: 'reset' | 'restore' ) {
        const dataId = `${shapeId}-${textId}`;
        const styleTag = document.head.querySelector( `style[data-editor-id="${diagramId}-${dataId}-zoomlevel"]` );
        if ( !styleTag && value === 'reset' ) {
            const s = document.createElement( 'style' );
            s.setAttribute( 'data-editor-id', `${diagramId}-${dataId}-zoomlevel` );
            s.innerHTML = `div[data-editor-id="${ dataId }"] .tiptap-child-editor-content { transform: scale(1) !important; }`;
            document.head.appendChild( s );
        } else if ( styleTag && value === 'restore' ) {
            styleTag.remove();
        }
    }

    /**
     * Reset and restore the zindex of the text html node
     * @param value
     */
    public static setTiptapDocumentZIndex( value: 'reset' | 'restore' ) {
        const dataId = 'tiptap-doc-shape-text-zindex';
        const styleTag = document.head.querySelector( `style[data-id="${dataId}"]` );
        if ( !styleTag && value === 'reset' ) {
            const s = document.createElement( 'style' );
            s.setAttribute( 'data-id', dataId );
            s.innerHTML = `shape-text-editor .text-editor-container { z-index: -10000 !important; top: -1000000px !important }`;
            document.head.appendChild( s );
        } else if ( styleTag && value === 'restore' ) {
            styleTag.remove();
        }
    }

    /**
     * To check if the text html element is visible
     * @param diagramId
     * @param shapeId
     * @param textId
     * @returns boolean
     */
    public static isShapeTextDomViewVisible( diagramId, shapeId, textId ) {
        const el = TiptapDocumentsManagerShapeText.getTextElement( diagramId, shapeId, textId );
        if ( el ) {
            const compStyles = window.getComputedStyle( el );
            return ( compStyles.left !== `-${TiptapDocumentsManagerShapeText.leftInvsible}px` &&
                compStyles.display === 'block' ) || compStyles.display === 'block';
        }
    }

    /**
     * Initializes the tiptap child editor node for shape text
     * @param value
     * @param diagramId
     * @param shapeId
     * @param textId
     * @returns
     */
    public static init(
        model: TextDataModel, diagramId?: string, shapeId?: string, textId?: string ): Observable<any> {
        if ( ! ( diagramId && shapeId && textId )) {
            throw new Error( `Invalid input data to init the shape text, diagramId: ${diagramId}, shapeId: ${shapeId}, textId: ${textId}` );
        }
        const cmpRef = TiptapDocumentsManagerShapeText.documents[ diagramId ];
        cmpRef.instance.shapeIdSubject.next( shapeId );
        return cmpRef.instance.localSynced.pipe(
            filter( v => !!v ),
            switchMap(() => cmpRef.instance.editorSubject ),
            filter( v => !!v ),
            take( 1 ),
            tap(( editor: Editor ) => {
                const { domNode } = TiptapDocumentsManagerShapeText
                    .getChildEditorNode( diagramId, shapeId, textId );

                if ( !domNode ) {
                    let value = model.html;
                    if ( model.value && value === '<p></p>' || model.rendering === 'carota' ) {
                        value = TiptapHelper.convertCarotaToTiptapHtml(
                            model.content, editor.schema );
                    }
                    let content = typeof value === 'string' ? value : `<p style="text-align: center"></p>`;
                    content = content.replace( /<tiptap-iframe-node-view/g,
                        `<tiptap-iframe-node-view shapeid="${ shapeId }"` );
                    editor.chain()
                        .setTextSelection( editor.state.doc.content.size )
                        .focus()
                        .insertContent( `<tiptap-child-editor-node shapeid="${ shapeId }" textid="${ textId }">${ content }</tiptap-child-editor-node>` )
                        .blur()
                        .run();
                }

                // NOTE: There's a chance of getting text editor nodes duplicated for a single
                // shape text when collab editing. ( e.g. 2 collabs try to add a text to the
                // same shape at the same time ) This deletes the most recent duplicate.
                setTimeout(() => {
                    TiptapDocumentsManagerShapeText.removeDuplicatesByIds( diagramId, shapeId, textId );
                }, 100 );

                TiptapDocumentsManagerShapeText.removeEmptyParas( diagramId );
            }),
        );
    }

    public static extractCommonStyles( diagramId, shapeId, textId ) {
        const { from, to, editor } = TiptapDocumentsManagerShapeText
            .getChildEditorNode( diagramId, shapeId, textId );
        ( editor as Editor ).commands.setTextSelection({ from, to });
        const styles =  TiptapDocumentsManagerShapeText.styleTiptapToCarota( editor );
        return styles;
    }

    public static getTextAlignment( diagramId, shapeId, textId ) {
        const { domNode } = TiptapDocumentsManagerShapeText
            .getChildEditorNode( diagramId, shapeId, textId );
        let align = TextAlignment.negative;
        const firstEl = domNode?.firstElementChild?.firstElementChild;
        if ( firstEl ) {
            const alignment = ( firstEl as HTMLElement ).style.textAlign;
            align = alignment === 'right' ? TextAlignment.positive :
                alignment === 'left' ? TextAlignment.negative :
                    alignment === 'center' ? TextAlignment.center :
                        alignment === 'justify' ? TextAlignment.negative : TextAlignment.center;
        }
        return align;
    }

    public static applyTextStyles( diagramId, shapeId, textId, styles ): Observable< string | null> {
        return TiptapDocumentsManagerShapeText
            .getChildEditorNodeAsync( diagramId, shapeId, textId ).pipe(
                map(({ from, to, editor }) => {
                    if (  editor ) {
                        ( editor as Editor ).commands.setTextSelection({ from, to });
                        if ( from === to ) {
                            TiptapDocumentsManagerShapeText.applyStylesToEmptyNode({
                                styles, takeFocus: false,
                            } , editor );
                        } else {
                            TiptapDocumentsManagerShapeText.applyStyles({
                                styles, takeFocus: false,
                            } , editor );
                        }
                        ( editor as Editor ).commands.blur();
                        const { node } = TiptapDocumentsManagerShapeText
                            .getChildEditorNode( diagramId, shapeId, textId );
                        return getHTMLFromFragment( node.content, editor.schema );
                    }
                    return null;

                }),
            );
    }

    /**
     * Returns the bounds of the selected area
     */
    public static styleTiptapToCarota( editor?: Editor ) {
        const format = {} as any;
        // Toggleable
        [ 'bold', 'italic', 'underline' ].forEach( f => {
            format[ f ] = editor.isActive( f );
        });

        format.strikeout = editor.isActive( 'strike' );

        const isLink = editor.isActive( 'link' );
        if ( isLink ) {
            const linkAttributes = editor.getAttributes( 'link' );
            format.link = linkAttributes.href;
        }

        format.align = editor.isActive({ textAlign: 'right' }) ? 'right' :
            editor.isActive({ textAlign: 'left' }) ? 'left' :
                editor.isActive({ textAlign: 'center' }) ? 'center' :
                    editor.isActive({ textAlign: 'justify' }) ? 'justify' : 'center';
        const ts = editor.isActive( 'textStyle' );
        format.size = DEFUALT_TEXT_STYLES.size;
        if ( ts ) {
            const tsAttributes = editor.getAttributes( 'textStyle' );
            format.color = tsAttributes.color;
            format.size = parseFloat(  tsAttributes.fontSize || DEFUALT_TEXT_STYLES.size );
            format.font = tsAttributes.fontFamily;
        }
        return format;
    }

    public static applyStylesToEmptyNode( data: { styles: ITextContent, takeFocus: boolean }, editor  ) {
        const { styles, takeFocus } = data;
        const chain = takeFocus ? editor?.chain().focus() : editor?.chain();
        const ds = { ...DEFUALT_TEXT_STYLES, ...styles };
        // FIXME: &#8203; ( Zero width space ) added to retain text styles for empty content, find a better way
        const styled = `<p style="text-align: ${ds.align}"><span style="color: ${ds.color}; font-size: ${ds.size}pt; font-family: ${ds.font}">&#8203;</span></p>`;
        chain.insertContent( styled );
        chain.run();
    }

    public static applyStyles( data: { styles: ITextContent, takeFocus: boolean }, editor  ) {
        const chain = data.takeFocus ? editor?.chain().focus() : editor?.chain();
        if ( data.styles.align ) {
            chain.setTextAlign( data.styles.align );
        }
        if ( data.styles.bold ) {
            chain.setBold();
        }
        if ( data.styles.bold === false ) {
            chain.unsetBold();
        }

        if ( data.styles.italic ) {
            chain.setItalic();
        }
        if ( data.styles.italic === false ) {
            chain.unsetItalic();
        }

        if ( data.styles.underline ) {
            chain.setUnderline();
        }
        if ( data.styles.underline === false ) {
            chain.unsetUnderline();
        }

        if ( data.styles.strikeout ) {
            chain.setStrike();
        }
        if ( data.styles.strikeout === false ) {
            chain.unsetStrike();
        }

        if ( data.styles.color ) {
            chain.setColor( data.styles.color );
        }
        if ( data.styles.font ) {
            chain.setFontFamily( data.styles.font );
        }

        if ( data.styles.size ) {
            chain.setFontSize( data.styles.size + 'pt' );
        }

        if ( data.styles.link ) {
            chain
                .extendMarkRange( 'link' )
                .setColor( 'rgb(94, 156, 211)' )
                .setUnderline()
                .setLink({ href: data.styles.link });
        }
        if ( data.styles.link === null ) {
            chain
                .extendMarkRange( 'link' )
                .unsetColor()
                .unsetUnderline()
                .unsetLink();
        }
        chain.run();
    }


    /**
     * Set alignment for simple shapes which have no hitarea and the text can
     * span over the width of the shape
     */
    public static handleTextAlignment( content: ITextContent, hitArea ) {
        const textModel = {} as any;
        if ( !hitArea ) {
            const alignment = content[0].align;
            if ( alignment === 'center' ) {
                textModel.xType = 'relative';
                textModel.x = 0.5;
            }
            if ( alignment === 'left' || alignment === 'justify' ) {
                textModel.xType = 'fixed-start';
                textModel.x = TEXT_PADDING_HORIZONTAL;
            }
            if ( alignment === 'right' ) {
                textModel.xType = 'fixed-end';
                textModel.x = TEXT_PADDING_HORIZONTAL;
            }
        }
    }

}
