import { Component, ChangeDetectionStrategy, OnDestroy, OnInit,
    ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from 'flux-core';
import { TextInput } from 'flux-core/src/ui';
import { KeyCode } from 'flux-definition';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

/**
 * Global Search Component
 * This component is only responsible of showing the results component.
 *
 * This component should not know about the search and the search results.
 *
 * @author  Vinoch
 * @since   2021-03-16
 */

/**
 * This enum has the state that the
 * Global search widget/component can have.
 */
export enum GlobalSearchState {
    OPEN = 'open',
    CLOSE = 'close',
}

/**
 * This enum has the scope that the
 * Global search widget/component can have.
 */
export enum GlobalSearchScope {
    DOCUMENT = 'document',
    GLOBAL = 'global',
    COMMAND = 'command',
}

@Component({
    templateUrl: './global-search.cmp.html',
    selector: 'global-search',
    styleUrls: [ './global-search.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearch implements OnInit, OnDestroy, AfterViewInit {

    /**
     * This holds the place holder value
     * of the search field.
     */
    public searchPlaceholder: string;

    /**
     * This emits the search key words entered by
     * the user.
     */
    public onSearchQueryChange: BehaviorSubject<string>;

    /**
     * Subject used to show and hide the document search results.
     */
     public showDocumentSearchResults: BehaviorSubject<boolean> = new BehaviorSubject( false );

    /**
     * Holds all subscriptions.
     */
    protected subs: Subscription[];

    /**
     * Search input field
     */
    @ViewChild( 'searchInputField' )
    protected searchInputField: TextInput;

    constructor( protected translateService: TranslateService,
                 protected state: StateService<any, any> ) {
        this.subs = [];
        this.onSearchQueryChange = new BehaviorSubject( '' );
    }

    public ngOnInit(): void {
        this.handlePlaceHolderText();
        this.subs.push(
            fromEvent( window, 'keydown' ).pipe(
                filter(( event: KeyboardEvent ) => event.keyCode === KeyCode.Escape ),
                tap(() => {
                    if ( this.searchInputField.inputText ) {
                        this.searchInputField.clear();
                    } else {
                        this.state.set( 'GlobalSearch', { state: GlobalSearchState.CLOSE });
                    }
                    this.showSearchResultsComponent( false );
                }),
            ).subscribe(),
            this.onSearchQueryChange.pipe(
                tap( text => {
                        if ( text.length > 2 ) {
                            this.state.set( 'GlobalSearchText', { searchText: text });
                            this.showSearchResultsComponent( true );
                        } else if ( text.length === 0 ) {
                            this.showSearchResultsComponent( false );
                        }
                    },
                ),
            ).subscribe(),
        );
    }

    public ngAfterViewInit() {
        this.subs.push(
            this.listenForOutSideClick().subscribe(),
        );
    }

    /**
     * Unsubscribe from all subscriptions
     */
    public ngOnDestroy(): void {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    /**
     * This event listen to the outside click of the search container
     * and fire the close.
     */
    private listenForOutSideClick() {
        return fromEvent( document, 'click' ).pipe(
            tap(( event: any ) => {
                this.validateOutSideContainerClick( event );
            }),
        );
    }

    /**
     * This condition has been added to listen to the clicks
     * out side the global search container.
     * When user clicks outside the search container, the document
     * click event fires the id of the focused element which is
     * globalSearchContainer in this case.
     * @param event - Click event.
     */
    private validateOutSideContainerClick( event: any ) {
        if ( event.target.id === 'globalSearchContainer' ) {
            this.closeGlobalSearchWindow();
        }
    }

    /**
     * This is responsible of closing the global search window entirely.
     */
    private closeGlobalSearchWindow() {
        this.searchInputField.clear();
        this.state.set( 'GlobalSearch', { state: GlobalSearchState.CLOSE });
    }

    /**
     * This function sets the correct place holder text according
     * to the scope selected by the user via keyboard shortcut.
     */
    private handlePlaceHolderText() {
        const scope = this.state.get( 'GlobalSearch' ).scope;
        if ( scope === GlobalSearchScope.DOCUMENT ) {
            this.searchPlaceholder = this.translateService.instant( 'PLACEHOLDERS.DOCUMENT_SEARCH' );
        }
    }

    /**
     * This function defines what componenets should open up
     * for the given scope. Defining the components here only
     * will be enough and the logic will take care the show
     * and hide of the components.
     * @param show - should show or hide search results.
     */
    private showSearchResultsComponent( show: boolean ) {
        const scope = this.state.get( 'GlobalSearch' ).scope;
        if ( scope === GlobalSearchScope.DOCUMENT ) {
            this.showDocumentSearchResults.next( show );
        }
    }
}
